import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { abbreviateNumber } from 'js-abbreviation-number';

import { SORT_BY_TIME } from 'src/constant';
import {
  useFetchVideoByIdQuery,
  useVideoSubscriberPerTimeGraphQuery,
  useVideoViewPerTimeGraphQuery,
  useVideoWatchTimeGraphQuery,
} from 'src/redux/features/videos/videoApi';
import CustomAreaGraph from 'src/components/analytics/CustomAreaGraph';
import Circle from 'src/components/atoms/circle/Circle';
import { EyeSvg, TimeSvg, WebpageSvg } from 'src/assets/svgs';
import { useMemo } from 'react';
import { watchTimeCalculator } from 'src/helpers/general';
import Loader from 'src/components/atoms/Loader';

const SmallCountCard = ({ text, icon: Icon }) => {
  return (
    <div className='d-flex align-items-center'>
      <Circle size={30} style={{ padding: '6px' }} color='#FFEEF1'>
        <Icon />
      </Circle>
      <div className='ml-2 fs-300 fw-500 text-clr-dark-400'>{text}</div>
    </div>
  );
};

const GraphCard = () => {
  const { video_url_id } = useParams();
  const { search: queryString } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(queryString);
  const sort = queryParams.get('sort');
  const [graphCategory, setGraphCategory] = useState('1');
  const [selectedDate, setSelectedDate] = useState(
    sort || SORT_BY_TIME.since_published.value
  );

  const { data: videoData } = useFetchVideoByIdQuery(video_url_id, {
    skip: !video_url_id,
  });

  const { data: viewGraphData, isLoading: viewGraphLoading } =
    useVideoViewPerTimeGraphQuery(
      { videoId: videoData?.id, queryString },
      { skip: !Boolean(videoData?.id) }
    );

  const { data: watchTimeGraphData, isLoading: watchTimeGraphLoading } =
    useVideoWatchTimeGraphQuery(
      { videoId: videoData?.id, queryString },
      { skip: Boolean(videoData?.is_short) }
    );

  const { data: subscriberGraphData, isLoading: subscriberGraphLoading } =
    useVideoSubscriberPerTimeGraphQuery(
      { videoId: videoData?.id, queryString },
      { skip: !Boolean(videoData?.id) }
    );

  const renderCurrentGraphCount = useMemo(() => {
    if (graphCategory === '1' && viewGraphData) {
      return (
        <SmallCountCard
          icon={EyeSvg}
          text={`${abbreviateNumber(viewGraphData.unique_view_count, 1)} Views`}
        />
      );
    }
    if (graphCategory === '2' && watchTimeGraphData) {
      return (
        <SmallCountCard
          icon={TimeSvg}
          text={`${watchTimeCalculator(
            watchTimeGraphData.watch_time_count
          )} Watch Time`}
          // text={`${(watchTimeGraphData.watch_time_count / 3600).toFixed(
          //   1
          // )}Hrs Watch Time`}
        />
      );
    }
    if (graphCategory === '3' && subscriberGraphData) {
      return (
        <SmallCountCard
          icon={WebpageSvg}
          text={`${abbreviateNumber(
            subscriberGraphData.subscriber_count,
            1
          )} Subscribers`}
        />
      );
    }
  }, [graphCategory, subscriberGraphData, viewGraphData, watchTimeGraphData]);
  return (
    <div
      className='mx-auto py-2 py-md-4 mt-4 studio-card'
      style={{
        minWidth: '600px',
      }}
    >
      <div className='d-flex justify-content-between align-items-end flex-wrap  px-4'>
        <div className='d-flex align-items-center'>
          <div className='fs-300 fw-500 text-clr-dark-400'>Statistics</div>
          <div
            className='mx-3 border border-left-0'
            style={{ borderColor: '#eff2f3', height: '30px' }}
          />
          {viewGraphLoading ||
          watchTimeGraphLoading ||
          subscriberGraphLoading ? (
            <Loader size='sm' />
          ) : (
            renderCurrentGraphCount
          )}
        </div>
        <div
          className='d-flex align-items-center'
          style={{ transform: 'translateY(25px)' }}
        >
          <Form.Control
            as='select'
            size='lg'
            className='mr-2'
            value={graphCategory}
            onChange={(e) => {
              const { value } = e.target;
              setGraphCategory(value);
            }}
          >
            <option value={'1'}>Views</option>
            {!videoData?.is_short && <option value={'2'}>Watch Time</option>}
            <option value={'3'}>Subscribers</option>
          </Form.Control>
          {videoData && (
            <Form.Control
              as='select'
              size='lg'
              value={selectedDate}
              onChange={(e) => {
                const { value } = e.target;
                const queryParams = new URLSearchParams(queryString);
                queryParams.set('sort', value);
                setSelectedDate(value);
                history.push(`?${queryParams.toString()}`);
              }}
            >
              <option value={SORT_BY_TIME.since_published.value}>
                {SORT_BY_TIME.since_published.label}
              </option>
              {/* <option value={SORT_BY_TIME.first_24_hours.value}>
                {SORT_BY_TIME.first_24_hours.label}
              </option> */}
              <option value={SORT_BY_TIME.last_7days.value}>
                {SORT_BY_TIME.last_7days.label}
              </option>
              <option value={SORT_BY_TIME.last_28days.value}>
                {SORT_BY_TIME.last_28days.label}
              </option>
              <option value={SORT_BY_TIME.last_90days.value}>
                {SORT_BY_TIME.last_90days.label}
              </option>
              <option value={SORT_BY_TIME.last_365days.value}>
                {SORT_BY_TIME.last_365days.label}
              </option>
              <option value={SORT_BY_TIME.current_year.value}>
                {SORT_BY_TIME.current_year.label}
              </option>
              {new Date(SORT_BY_TIME.current_year.startDate).getFullYear() >=
                new Date(videoData.created_date).getFullYear() && (
                <option value={SORT_BY_TIME.last_year.value}>
                  {SORT_BY_TIME.last_year.label}
                </option>
              )}
              <option value={SORT_BY_TIME.current_month.value}>
                {SORT_BY_TIME.current_month.label}
              </option>
              {new Date(SORT_BY_TIME.current_year.startDate) >=
                new Date(videoData.created_date) && (
                <option value={SORT_BY_TIME.last_month.value}>
                  {SORT_BY_TIME.last_month.label}
                </option>
              )}
            </Form.Control>
          )}
        </div>
      </div>
      <div className='mt-5'>
        {graphCategory === '1' ? (
          viewGraphLoading ? (
            <Loader className='mt-4' />
          ) : viewGraphData ? (
            <CustomAreaGraph data={viewGraphData.results} yLabel='View Count' />
          ) : null
        ) : graphCategory === '2' ? (
          watchTimeGraphLoading ? (
            <Loader className='mt-4' />
          ) : watchTimeGraphData ? (
            <CustomAreaGraph
              data={watchTimeGraphData.results}
              yAxisType='second'
            />
          ) : null
        ) : subscriberGraphLoading ? (
          <Loader className='mt-4' />
        ) : subscriberGraphData ? (
          <CustomAreaGraph
            data={subscriberGraphData.results}
            yLabel='Subscriber Count'
          />
        ) : null}
      </div>
    </div>
  );
};

export default GraphCard;
