import InfiniteScroll from 'react-infinite-scroll-component';
import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Constant, { CLIENT_URL } from 'src/constant';
import { useShortsByHandleNameQuery } from 'src/redux/features/videos/videoApi';
import calculateDuration from 'src/utils/calculate-duration';
import ShortCard from 'src/components/atoms/ShortCard/ShortCard';
import { useFetchChannelByHandleNameQuery } from 'src/redux/features/channels/channelApi';
import handleError from 'src/utils/handle-error';
import { openReportVideoModal } from 'src/redux/features/report-video/reportVideoSlice';
import messages from 'src/constant/messages';
import { toast } from 'react-toastify';
import { checkVideoReported } from 'src/redux/features/videos/videoSlice';
import { useDispatch } from 'react-redux';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import Loader from 'src/components/atoms/Loader';
import { useAddWatchLaterMutation } from 'src/redux/features/watch-later/watchLaterApi';

const ChannelShorts = ({ handle_name }) => {
  const dispatch = useDispatch();
  const limit = 36;
  const [offset, setOffset] = useState(0);
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const { data, isSuccess, isLoading } = useShortsByHandleNameQuery({
    handle_name,
    limit,
    offset,
  });

  const channelRes = useFetchChannelByHandleNameQuery(handle_name, {
    skip: !handle_name,
  });

  const [addWatchLater] = useAddWatchLaterMutation();

  return (
    <>
      <div className='video-block section-padding '>
        {data?.videos && data?.videos?.length === 0 && (
          <h6 className='text-center h6'>This list has no videos</h6>
        )}
        {(isLoading || channelRes.isLoading) && <Loader />}
        {isSuccess && !!data?.videos?.length && (
          <InfiniteScroll
            dataLength={data.videos?.length || 0} //This is important field to render the next data
            next={() => setOffset((prev) => prev + limit)}
            hasMore={data?.total_result_count > data.videos?.length}
            loader={<Loader />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
            className='overflow-hidden'
          >
            <Row className='mx-0'>
              {data.videos?.map((short) => (
                <Col
                  xl={2}
                  sm={4}
                  xs={6}
                  lg={3}
                  className='mb-3 d-flex px-1'
                  key={`channel-shorts-${short.id}`}
                >
                  <ShortCard
                    imgSrc={
                      short?.thumbnail_url ||
                      `${Constant.BASE_URL}img/light/default-thumbnail.png`
                    }
                    time={calculateDuration(short.duration)}
                    videoTitle={short.title}
                    views={short?.views || 0}
                    iconHref={`${Constant.BASE_URL}shorts/${short.video_url_id}`}
                    videoTitleHref={`${Constant.BASE_URL}shorts/${short.video_url_id}`}
                    channel_id={channelRes?.data?.id}
                    videoId={short.id}
                    shareLink={`${CLIENT_URL}/shorts/${short.video_url_id}`}
                    onReportVideo={async () => {
                      if (!authData)
                        return toast.error(messages.require_signin_to_report);
                      if (short.is_reported)
                        return toast.info(messages.video_reported_already);

                      try {
                        const report = await dispatch(
                          checkVideoReported(short.id)
                        ).unwrap();
                        const is_reported = report.data.is_reported;

                        if (is_reported)
                          return toast.info(messages.video_reported_already);

                        dispatch(
                          openReportVideoModal({
                            contractVideoId: short.contract_video_id,
                            videoId: short.id,
                            videoUrlId: short.video_url_id,
                          })
                        );
                      } catch (error) {
                        handleError(error);
                      }
                    }}
                    onAddWatchLater={async () => {
                      if (!authData) return toast.info(messages.require_signin);
                      try {
                        const response = await addWatchLater({
                          videoId: short.id,
                          videoUrlId: short.video_url_id,
                        }).unwrap();
                        toast.success(response.message);
                      } catch (error) {
                        handleError(error);
                      }
                    }}
                  />
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default ChannelShorts;
