import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { abbreviateNumber } from 'js-abbreviation-number';

import { VerifiedTooltip } from 'src/components/atoms/CustomCheckTooltips/CustomCheckTooltips';
import { Dropdown } from 'react-bootstrap';
import UilMenuV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import { UilTrashAlt } from '@iconscout/react-unicons';
import { COLORS } from 'src/constant';
import UisClock from '@iconscout/react-unicons-solid/icons/uis-clock';
import { UilShare } from '@iconscout/react-unicons';
import { UilExclamationOctagon } from '@iconscout/react-unicons';

const VideoCardList = ({
  imgAlt = '',
  imgSrc,
  videoTitle,
  channelName,
  channelHref,
  views = 0,
  timeAgo,
  videoHref = '#',
  time,
  active = null,
  verified = false,
  onShare = null,
  onReportVideo = null,
  onAddWatchLater = null,
}) => {
  const history = useHistory();
  const activeStatus = active
    ? 'video-card video-card-list active'
    : 'video-card video-card-list';

  const categoryClass = 'video-page';

  return (
    <>
      <div className={`${activeStatus} position-relative hover-trigger-container`}>
        <Dropdown
          style={{
            position: 'absolute',
            right: '0px',
            top: '-2px',
          }}
        >
          <Dropdown.Toggle as='a' className='no-after'>
            <button className='icon-circle-wrapper btn-ripple trigger-element'>
              <UilMenuV size={18} className='icon' />
            </button>
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ minWidth: 'min-content' }}>
            {onAddWatchLater && (
              <Dropdown.Item href='#' onClick={onAddWatchLater}>
                <UisClock color={COLORS.dark300} size='20' className='mr-2' />
                <span>Save to Watch later</span>
              </Dropdown.Item>
            )}
            {onShare && (
              <Dropdown.Item onClick={onShare}>
                <UilShare color={COLORS.dark300} size='20' className='mr-2' />
                <span>Share</span>
              </Dropdown.Item>
            )}
            {onReportVideo && (
              <Dropdown.Item href='#' onClick={onReportVideo}>
                <UilExclamationOctagon size='18' className='mr-2' />
                Report Video
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <div className='video-card-image'>
          <Link className='play-icon' to={videoHref}>
            <FontAwesomeIcon icon={faPlayCircle} />
          </Link>
          <Link to={videoHref} style={{ display: 'flex', height: '100%' }}>
            <img className='img-fluid' src={imgSrc} alt={imgAlt} />
          </Link>
          <div className='time'>{time}</div>
        </div>

        <div style={{ flex: 1 }}>
          {/* <SectionHeader dropdownOnly icon={faEllipsisV} noIconLabel /> */}
          <Link
            // className='fs-300 fw-600 text-clr-dark-300 vcl-title'
            className='w-mins-dot-btn text-line-2 fs-300 fw-600 text-clr-dark-300'
            to={videoHref}
          >
            {videoTitle}
          </Link>
          <div
            className={`${categoryClass} fs-200 cursor-pointer`}
            onClick={() => history.push(channelHref)}
          >
            {channelName} {verified ? <VerifiedTooltip /> : null}
          </div>
          <div className='small-video-view'>
            {abbreviateNumber(views, 1)} views &nbsp;
            <FontAwesomeIcon icon={faCalendarAlt} /> {timeAgo}
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default VideoCardList;
