import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';

import './modal.css';
import Constant from '../../../constant';
import messages from 'src/constant/messages';

const VideoDeleteModal = ({ show, handleClose, onConfirm, video }) => {
  const [check, setCheck] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setCheck(false);
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={true}
        keyboard={false}
        centered
        id='confirm-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title className='d-flex overflow-hidden '>
            <img
              alt='confirm'
              src={`${Constant.BASE_URL}img/confirm.png`}
              style={{
                width: '32px',
                height: '32px',
                marginRight: '10px',
              }}
            />
            <span
              style={{
                textWrap: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Delete {video?.title}
            </span>
            <span>?</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='gray-box p-3 rounded-sm mb-2 d-flex align-items-center'>
            <img
              src={
                video?.thumbnail_url ||
                `${Constant.BASE_URL}img/light/default-thumbnail.png`
              }
              alt='thumbnail'
              className='img-fluid mr-3'
              style={{
                aspectRatio: '16/9',
                width: '100px',
                objectFit: 'contain',
              }}
            />
            <h6>
              You are about to delete this video. Once deleted this cannot be
              undone.
            </h6>
          </div>
          <div className='d-flex'>
            <Form.Check
              id='delete-video-confirm'
              type='checkbox'
              custom
              size='lg'
              checked={check}
              onChange={() => setCheck(!check)}
              disabled={disabled}
            />
            <label for='delete-video-confirm' className='mb-0'>
              I acknowledge that deletion is a permanent action and cannot be
              reversed.
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={disabled} variant='light' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='primary'
            disabled={!check || disabled}
            onClick={async () => {
              setDisabled(true);
              await onConfirm();
              setDisabled(false);
            }}
          >
            {disabled ? messages.waiting_wallet_confirmation : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VideoDeleteModal;
