import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  useAccount,
  useWriteContract,
  useSwitchChain,
  usePublicClient,
} from 'wagmi';
import { decodeEventLog } from 'viem';
import { useSaveBlockTransactionMutation } from 'src/redux/features/basic/basicApi';

import Constant, { CLIENT_URL, COMMON_STATUS } from 'src/constant';
import {
  usePublishVideoMutation,
  useUpdateVideoDetailMutation,
} from 'src/redux/features/videos/videoApi';
import VideoPublishModal from './VideoPublishedModal';
import VideoPreviewCard from './VideoPreviewCard';
import { FormContext } from '../UploadVideo';
import handleError from 'src/utils/handle-error';
import { ProcessingModal } from 'src/components/atoms/modals';
import useFindContractData from 'src/hooks/useFindContractData';
import { TransactionStatusTooltip } from 'src/components/atoms/CustomCheckTooltips/CustomCheckTooltips';
import { pinJSONToIPFS } from 'src/utils/ipfs';
import config from 'src/config';
import messages from 'src/constant/messages';
import { useActiveChainId } from 'src/hooks';

const VISIBILITY = ['PUBLIC', 'PRIVATE'];

const validationSchema = Yup.object().shape({
  visibility: Yup.string().oneOf(VISIBILITY).required(),
});

const StepThree = () => {
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    mutation: {
      onError: (err) => {
        toast.error(messages.network_switch_error);
      },
    },
  });
  const history = useHistory();
  const videoModalDataRef = useRef(null);
  const {
    setActiveStepIndex,
    setVideoDetailStore,
    videoDetailStore,
    isSavingInDraft,
    videoProgress,
  } = useContext(FormContext);
  const [disabled, setDisabled] = useState(false);
  const account = useAccount();
  const [show, setShow] = useState(false);
  const { abi, contract_address } = useFindContractData();
  const { waitForTransactionReceipt } = usePublicClient();

  const [updateVideo, { isLoading }] = useUpdateVideoDetailMutation();
  const [publishVideo] = usePublishVideoMutation();

  const [saveBlock] = useSaveBlockTransactionMutation();
  const { writeContractAsync: uploadVideoContract } = useWriteContract();

  const handleClose = () => {
    setShow(false);
    history.push(`${Constant.BASE_URL}studio/content`);
  };
  const handleShow = () => setShow(true);

  const handleVideoStore = (key, value) => {
    setVideoDetailStore((pre) => ({ ...pre, [key]: value }));
  };

  return (
    <Row id='upload-visibility' className='mx-0'>
      <ProcessingModal
        metamask={true}
        show={disabled}
        handleClose={() => setDisabled(false)}
      />
      <VideoPublishModal
        data={videoModalDataRef.current}
        show={show}
        handleClose={handleClose}
      />
      <Col md={12} className='mx-auto p-0'>
        <h3 className='mb-3 fs-500 fw-600'>Upload video</h3>
      </Col>
      <Col md={12} className='mx-auto py-3 py-md-4 px-3 px-md-4 studio-card'>
        <Formik
          initialValues={{
            visibility: 'PUBLIC',
          }}
          onSubmit={async (values) => {
            if (activeChainId !== config.ACTIVE_CHAIN.id) {
              await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
            }
            setDisabled(true);

            if (!videoDetailStore.videoId)
              return toast.info('Video is uploading please wait');

            try {
              // upload description to pinata
              const { IpfsHash: description_hash } = await pinJSONToIPFS(
                values.description,
                (progress) => {
                  console.log(progress, 'progress');
                }
              );

              // pinata uploaded thumbnail hash
              let selectedThumbnail = videoDetailStore.thumbnails.find(
                (thumb) => thumb.id === videoDetailStore.thumbnail_id
              );
              const { hash: thumbnail_hash } = selectedThumbnail;

              // Storing video data in blockchain
              const args = [
                videoDetailStore.video_hash,
                videoDetailStore.title,
                description_hash,
                thumbnail_hash,
              ];
              const hash = await uploadVideoContract({
                args,
                address: contract_address,
                abi: JSON.parse(abi),
                functionName: 'uploadVideo',
                chainId: config.ACTIVE_CHAIN.id,
              });
              const response = await updateVideo({
                videoId: videoDetailStore.videoId,
                formData: {
                  description_hash,
                  thumbnail_hash,
                  visibility: values.visibility,
                  txn_hash: hash,
                },
              }).unwrap();
              videoModalDataRef.current = response.data.video;

              // const block_transaction_data = {
              //   action: 'uploadVideo',
              //   address_out: account.address,
              //   address_in: contract_address,
              //   hash,
              //   network_type: 5,
              //   request: JSON.stringify(args),
              //   hash_verify: 1,
              // };
              // saveBlock({ body: block_transaction_data });

              const receipt = await waitForTransactionReceipt({ hash });
              const decoded = decodeEventLog({
                abi: JSON.parse(abi),
                data: receipt.logs[0].data,
                topics: receipt.logs[0].topics,
              });

              // Publish Video
              const publishResponse = await publishVideo({
                videoId: videoDetailStore.videoId,
                body: {
                  visibility: values.visibility,
                  contract_video_id: parseInt(decoded.args.videoId),
                },
              }).unwrap();
              toast.success(publishResponse?.message || 'Updated Successfully');
              setDisabled(false);
              handleShow();
            } catch (error) {
              handleError(error, 'Error in publishing the video');
              // toast.error(error?.message || 'Error in publishing the video');
              setDisabled(false);
            }
          }}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ values, handleChange, handleSubmit, isValid }) => (
            <Row>
              {/* form */}
              <Col lg={7}>
                <h3 className='fw-600 fs-500'>Visibilty</h3>
                <p className='fs-300 text-clr-dark-100'>
                  Choose when to publish and who can see your video.
                </p>
                {/* public or private */}
                <div className='px-2 py-3 mt-4 rounded-sm border-dropdown'>
                  <div className='d-flex'>
                    {/* <Form.Check type='radio' custom id='customCheck1' /> */}
                    <div>
                      <h6 className='fw-600 fs-400'>Publish</h6>
                      <p className='fs-300 text-clr-dark-100'>
                        Make your video public or private
                      </p>
                    </div>
                  </div>
                  {/* child */}
                  <div className='d-flex ml-5 mt-2'>
                    <Form.Check
                      type='radio'
                      name='visibility'
                      value='PUBLIC'
                      custom
                      id='public-check'
                      disabled={isLoading || disabled}
                      onChange={(e) => {
                        handleChange(e);
                        handleVideoStore('visibility', e.target.value);
                      }}
                      checked={values.visibility === 'PUBLIC'}
                    />
                    <div>
                      <h6 className='fw-600 fs-400'>Public</h6>
                      <p className='fs-300 text-clr-dark-100'>
                        Everyone can watch your video
                      </p>
                    </div>
                  </div>
                  <div className='d-flex ml-5 mt-2'>
                    <Form.Check
                      type='radio'
                      name='visibility'
                      value='PRIVATE'
                      custom
                      id='private-check'
                      disabled={isLoading || disabled}
                      onChange={(e) => {
                        handleChange(e);
                        handleVideoStore('visibility', e.target.value);
                      }}
                      checked={values.visibility === 'PRIVATE'}
                    />
                    <div>
                      <h6 className='fw-600 fs-400'>Private</h6>
                      <p className='fs-300 text-clr-dark-100'>
                        Only you can watch your video
                      </p>
                    </div>
                  </div>
                </div>
                {/* Schedule */}
                {/* <div className='px-4 py-3 mt-4 rounded-sm border-dropdown'>
              <h6 className='fw-600 fs-400'>Sechedule</h6>
              <p className='fs-300 text-clr-dark-100'>
                Select a date to make your video public
              </p>
              <div className='d-flex mt-2'>
                <Form.Group controlId='video-title' className='mr-2'>
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    name='title'
                    placeholder='Select Date'
                  />
                </Form.Group>
                <Form.Group controlId='video-title'>
                  <Form.Label>Time</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    name='title'
                    placeholder='Select Time'
                  />
                </Form.Group>
              </div>
              <p className='fs-300 text-clr-dark-100'>
                Video will be private before publishing
              </p>
            </div> */}
                {/* instruction before publish */}
                <div className='rounded-sm mt-4 p-4 instruction'>
                  <h6 className='fw-600 fs-400 mb-4'>
                    Before you publish, Please check:
                  </h6>
                  <h6 className='fw-600 fs-400 mb-2'>Can Kids Watch This</h6>
                  <p>
                    Ensure that the content you are uploading here is absolutely
                    safe for children to watch. There should be no content that
                    may harm children’s mind and well-being.{' '}
                  </p>
                  <h6 className='fw-600 fs-400 mb-2 mt-4'>
                    Community Guidelines
                  </h6>
                  <p>
                    There are some community guidelines which must be followed
                    before and after uploading any video on Mirror. If any
                    breach is performed, an action could be taken against
                    you/owner.
                  </p>
                </div>
              </Col>
              {/* video */}
              <Col lg={5}>
                <VideoPreviewCard
                  // posterFileUrl={videoDetailStore.thumbnail_url}
                  posterFileUrl={
                    videoDetailStore?.thumbnails?.find(
                      (item) => item.id === videoDetailStore.thumbnail_id
                    )?.thumbnail_url || null
                  }
                  videoFileUrl={videoDetailStore.video_url}
                  videoUrl={`${CLIENT_URL}/${
                    videoDetailStore.is_short ? 'shorts' : 'watch'
                  }/${videoDetailStore.video_url_id}`}
                  videoProgress={videoProgress}
                  videoTitle={
                    videoDetailStore?.video?.name || videoDetailStore.title
                  }
                />
              </Col>
              <Col sm={12}>
                <hr className='clr-white-card-hr' />
              </Col>
              {/* actions  button */}
              <Col sm={12} className='d-flex'>
                <Button
                  variant='light'
                  className='ml-auto mr-2 px-4'
                  onClick={() => setActiveStepIndex((prev) => prev - 1)}
                >
                  Back
                </Button>
                <TransactionStatusTooltip
                  show={
                    videoDetailStore.txn_status === COMMON_STATUS.PROCESSING
                  }
                >
                  <Button
                    className='px-4'
                    type='button'
                    disabled={
                      !isValid ||
                      !videoDetailStore.videoId ||
                      isLoading ||
                      isSavingInDraft ||
                      disabled ||
                      videoDetailStore.txn_status === COMMON_STATUS.PROCESSING
                    }
                    onClick={handleSubmit}
                  >
                    {isSavingInDraft
                      ? 'Saving ...'
                      : disabled
                      ? 'Processing ...'
                      : // : videoType === 'EDIT'
                        // ? 'Save'
                        'Publish'}
                  </Button>
                </TransactionStatusTooltip>
              </Col>
            </Row>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default StepThree;

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
