import React from 'react';
import { useHistory } from 'react-router-dom';
import { abbreviateNumber } from 'js-abbreviation-number';

import Constant, { FIXED_DECIMAL_CONSTANT } from 'src/constant';
import { useChannelDashboardQuery } from 'src/redux/features/channels/channelApi';

const ChannelAnalyticsCard = () => {
  const history = useHistory();

  const { data } = useChannelDashboardQuery();

  return (
    <div className='studio-card channel-analytics px-4 py-4 p-md-4 h-100'>
      {/* heading */}
      <div className='d-flex justify-content-between align-items-start mb-4'>
        <h4 className='align-self-center'>Channel Analytics</h4>
        {/* <div className='d-flex align-items-center'> */}
        {/* <h6>View</h6> */}
        <img
          onClick={() => history.push(`${Constant.BASE_URL}studio/analytics`)}
          alt='right arrow'
          className='ml-1 cursor-pointer'
          src={`${Constant.BASE_URL}img/arrow-right.svg`}
        />
        {/* </div> */}
      </div>
      {/* current subscribers */}
      <div className='d-flex justify-content-between mb-2'>
        <div>
          <h6 className='mb-1'>Current</h6>
          <h6> Subscribers</h6>
        </div>
        <h1 className='h1'>
          {abbreviateNumber(
            data?.subscriber_count || 0,
            FIXED_DECIMAL_CONSTANT
          )}
        </h1>
      </div>
      <hr className='w-100 clr-white-card-hr' />
      {/* summary */}
      <div className='d-flex justify-content-between my-2'>
        <div>
          <h4 className='mb-1'>Summary</h4>
          <h6>Last 28 Days</h6>
        </div>
        {/* <h2>0</h2> */}
      </div>
      <div className='d-flex justify-content-between my-2'>
        <h6>Views</h6>
        <h2 className='h2'>
          {abbreviateNumber(
            data?.view_count_28days || 0,
            FIXED_DECIMAL_CONSTANT
          )}
        </h2>
      </div>
      <div className='d-flex justify-content-between mb-2'>
        <h6>Watch Time (Hrs)</h6>
        <h2 className='h2'>
          {data?.watch_time_28days &&
            (data?.watch_time_28days / 3600).toFixed(1)}
        </h2>
      </div>
      <hr className='w-100 clr-white-card-hr' />
      {/* top videos */}
      {/* <div className='d-flex justify-content-between mt-2 mb-4'>
        <div>
          <h4 className='mb-1'>Top Videos</h4>
          <h6>Last 48 hours</h6>
        </div>
        <h2>0</h2>
      </div> */}
    </div>
  );
};

export default ChannelAnalyticsCard;
