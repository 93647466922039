import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { setAuth } from './features/users/usersSlice';
import { toast } from 'react-toastify';
// import { disconnect } from 'wagmi/actions';
import Constant, { DEVICE_TYPE, ROLES, SERVER_BASE_URL } from 'src/constant';

export const defaultHeaders = {
  devicetype: DEVICE_TYPE.WEB,
  request_type: ROLES.user,
  Token: process.env.REACT_APP_Token,
};

const baseQuery = fetchBaseQuery({
  baseUrl: SERVER_BASE_URL,
  credentials: 'include',
  headers: defaultHeaders,
  prepareHeaders: (headers, { getState }) => {
    const token =
      getState().authStore.accessToken || localStorage.getItem('accessToken');
    headers.set('Token', process.env.REACT_APP_Token);
    headers.set('request_type', ROLES.user);
    if (token) headers.set('Authorization', `Bearer ${token}`);
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.originalStatus === 403) {
    console.log('sending refresh token');
    // // send refresh token to get new access token
    // const refreshResult = await baseQuery('/refresh', api, extraOptions);
    // console.log(refreshResult);
    // if (refreshResult?.data) {
    //   const user = api.getState().userStore.user;
    //   // store the new token
    //   api.dispatch(setAuth({ ...refreshResult.data, user }));
    //   // retry the original query with new access token
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(logOut());
    // }
  } else if (result?.error?.status === 401) {
    console.log('token expired so logging out');
    localStorage.removeItem('accessToken');
    // await disconnect();
    // window.location.href = `${Constant.BASE_URL}`;
    // api.dispatch(logOut());
  } else if (result?.error?.status === 400) {
    toast.error(result?.error?.data?.message || 'Something went wrong');
  }
  return result;
};

const rootApi = createApi({
  reducerPath: 'rootApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'LOGIN',
    'USER',
    'CHANNEL',
    'VIDEO',
    'MY_VIDEO',
    'COMMENT',
    'ALL_VIDEO_COMMENT',
    'REPLIES',
    'WATCH_HISTORY',
    'WATCH_LATER',
    'LIKED_VIDEOS',
    'AUTH_CHECK',
    'POPULAR_CHANNEL',
    'SUBSCRIBED_VIDEOS',
    'SUBSCRIBED_CHANNELS',
    'OVERALL_DRAFT_STATUS',
    'REWARD_METRICS',
    'TRANSACTIONS',
    'SHORTS',
  ],
  endpoints: (builder) => ({}),
});

export default rootApi;
