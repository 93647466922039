import React, { useRef, useState } from 'react';
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useParams } from 'react-router-dom';

import Constant, { CLIENT_URL, COMMON_STATUS } from 'src/constant';
import { useFetchCategoriesQuery } from 'src/redux/features/categories/categoriesApi';
import 'react-tagsinput/react-tagsinput.css';
import {
  useFetchVideoByIdQuery,
  useGetThumbnailsByVideoIdQuery,
  useUpdateVideoDetailMutation,
  useUploadThumbnailMutation,
  useVerifyVideoUpdateTransactionMutation,
} from 'src/redux/features/videos/videoApi';
import VideoPreviewCard from 'src/pages/upload-video/components/VideoPreviewCard';
import { useSaveBlockTransactionMutation } from 'src/redux/features/basic/basicApi';
import { useAccount, useWriteContract, useSwitchChain, usePublicClient } from 'wagmi';
import { toast } from 'react-toastify';
import handleError from 'src/utils/handle-error';
import {
  BlockchainSaveTooltip,
  TransactionStatusTooltip,
} from 'src/components/atoms/CustomCheckTooltips/CustomCheckTooltips';
import { ProcessingModal } from 'src/components/atoms/modals';
import useFindContractData from 'src/hooks/useFindContractData';
import UploadThumbnail from 'src/components/upload-thumbnail/UploadThumbnail';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';
import { pinJSONToIPFS } from 'src/utils/ipfs';
import config from 'src/config';
import messages from 'src/constant/messages';
import CustomTagsInput from 'src/components/atoms/inputs/CustomTagsInput';
import { useActiveChainId } from 'src/hooks';
// import VideoPreviewCard from 'src/pages/upload-video/components/VideoPreviewCard';

const maxFileSize = 2 * 1048576; // 1 MB
const SUPPORT_FILE_TYPE = ['image/jpeg', 'image/png'];
const VISIBILITY = ['PUBLIC', 'PRIVATE'];

export const tagValidation = Yup.array().of(
  Yup.string()
    .test(
      'no-quotes',
      'Tag cannot contain both single and double quotes',
      (value) => {
        // Regular expression to match strings containing both single and double quotes
        const regex = /(['"])(?:(?!\1|\\).|\\.)*\1/g;

        // If the regex matches, the string contains both single and double quotes
        return !regex.test(value);
      }
    )
    .required()
);

const thumbnailSchema = Yup.mixed()
  .test(
    'fileSize',
    'File size is too large',
    (value) => !value || (value && value.size <= maxFileSize)
  )
  .test(
    'fileType',
    'Invalid file type',
    (value) => !value || (value && SUPPORT_FILE_TYPE.includes(value.type))
  );

const validationSchema = Yup.object().shape({
  title: Yup.string().max(100).required().label('Title'),
  description: Yup.string().max(5000).required().label('Description'),
  category_id: Yup.number().required().label('Category'),
  thumbnail_id: Yup.number().required().label('Thumbnail'),
  tags: tagValidation,
  visibility: Yup.string().oneOf(VISIBILITY).required(),
  comments_enabled: Yup.boolean().required().label('Comment Enabled'),
});

const VideoDetails = () => {
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const { video_url_id } = useParams();
  const thumbnailRef = useRef(null);
  const account = useAccount();
  const [disabled, setDisabled] = useState(false);
  const { abi, contract_address } = useFindContractData();
  const { waitForTransactionReceipt } = usePublicClient();

  const { data: categories } = useFetchCategoriesQuery();

  const { data: videoData, isLoading } = useFetchVideoByIdQuery(video_url_id, {
    skip: !video_url_id,
  });
  const { data: thumbnails } = useGetThumbnailsByVideoIdQuery(videoData?.id, {
    skip: !videoData?.id,
  });
  const [uploadThumbnail, { isLoading: thumbnailUploading }] =
    useUploadThumbnailMutation();
  const [updateVideo] = useUpdateVideoDetailMutation();
  const [verifyVideoUpdateTxn] = useVerifyVideoUpdateTransactionMutation();

  const [saveBlock] = useSaveBlockTransactionMutation();

  const updateVideoContract = useWriteContract();

  if (isLoading) {
    return <Loader className='mt-4' />;
  }

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4'>
        <ProcessingModal
          metamask={true}
          show={disabled}
          handleClose={() => setDisabled(false)}
        />
        <Row id='upload-details' className='mx-0'>
          <Col md={12} className='mx-auto p-0'>
            <h3 className='mb-3 fs-500 fw-600'>Video details</h3>
          </Col>
          <Col
            md={12}
            className='mx-auto py-3 py-md-4 px-3 px-md-4 studio-card'
          >
            <Formik
              initialValues={{
                title: videoData?.title || '',
                description: videoData?.description || '',
                category_id: videoData?.category_id || '',
                thumbnail_id: videoData?.thumbnail_id || '',
                tags: (videoData?.tags && JSON.parse(videoData?.tags)) || [],
                comments_enabled: Boolean(videoData?.comments_enabled),
                visibility: videoData?.visibility,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                if (activeChainId !== config.ACTIVE_CHAIN.id) {
                  await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
                }
                setSubmitting(true);
                const formData = { ...values, tags: values.tags };
                const args = [
                  videoData.contract_video_id,
                  values.title,
                  videoData.description_hash || 'default',
                  thumbnails.find((thumb) => thumb.id === values.thumbnail_id)
                    .hash,
                ];

                try {
                  // if description changed then upload on pinata
                  if (videoData.description !== values.description) {
                    const { IpfsHash } = await pinJSONToIPFS(
                      values.description,
                      (progress) => {
                        console.log(progress, 'progress');
                      }
                    );
                    args[2] = IpfsHash;
                    formData.description_hash = IpfsHash;
                  }

                  // if any blockchain field changed then save in blockchain also
                  if (
                    videoData.title !== values.title ||
                    videoData.description !== values.description ||
                    videoData.thumbnail_id !== values.thumbnail_id
                  ) {
                    setDisabled(true);
                    const hash = await updateVideoContract.writeContractAsync({
                      args,
                      address: contract_address,
                      abi: JSON.parse(abi),
                      functionName: 'updateVideo',
                      chainId: config.ACTIVE_CHAIN.id,
                    });

                    formData.txn_hash = hash;
                    await updateVideo({
                      videoId: videoData?.id,
                      formData,
                    }).unwrap();

                    // // save hash data in block_transaction table
                    // const block_transaction_data = {
                    //   action: 'updateVideo',
                    //   address_out: account.address,
                    //   address_in: contract_address,
                    //   hash,
                    //   network_type: NETWORK_TYPE.MATIC,
                    //   request: JSON.stringify(args),
                    // };
                    // saveBlock({ body: block_transaction_data });

                    await waitForTransactionReceipt({ hash });

                    // call to verify transaction or hash
                    const response = await verifyVideoUpdateTxn({
                      videoId: videoData.id,
                    }).unwrap();
                    setDisabled(false);
                    return toast.success(
                      response?.message || 'Updated Successfully'
                    );
                  }

                  // call when no blockchain field changed
                  const response = await updateVideo({
                    videoId: videoData?.id,
                    formData,
                  }).unwrap();

                  setSubmitting(false);
                  setDisabled(false);
                  toast.success(response?.message || 'Updated Successfully');
                } catch (error) {
                  console.log(error, 'error');
                  setSubmitting(false);
                  setDisabled(false);
                  handleError(error, 'Error in publishing the video');
                }
              }}
              enableReinitialize
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                resetForm,
                isSubmitting,
                isValid,
                dirty,
              }) => (
                <div>
                  <Row>
                    <Col lg={7}>
                      {/* <h3 className='mb-3 fs-500 fw-600'>Details</h3> */}
                      <Form.Group controlId='video-category'>
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                          size='lg'
                          as='select'
                          className='custom-select text-capitalize'
                          custom
                          name='category_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.category_id}
                          isInvalid={
                            touched.category_id && !!errors.category_id
                          }
                        >
                          <option value=''>Choose Category</option>
                          {categories?.map((category, idx) => (
                            <option
                              style={{
                                textTransform: 'capitalize',
                              }}
                              key={`category-option-${idx}`}
                              value={category.id}
                            >
                              {category.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          {errors.category_id}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId='video-title'>
                        <Form.Label>
                          Title (Required)
                          <BlockchainSaveTooltip className='ml-1' />
                        </Form.Label>
                        <Form.Control
                          size='lg'
                          type='text'
                          name='title'
                          placeholder='Video name here'
                          onChange={handleChange}
                          onBlur={(e) => {
                            console.log(e, 'blur title');
                            handleBlur(e);
                          }}
                          value={values.title}
                          isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.title}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId='video-description'>
                        <Form.Label>
                          Description <BlockchainSaveTooltip className='ml-1' />
                        </Form.Label>
                        {/*  */}
                        <CKEditor
                          config={{
                            toolbar: {
                              items: [
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                '|',
                                'link',
                                '|',
                                'bulletedList',
                                'numberedList',
                                '|',
                                'undo',
                                'redo',
                              ],
                            },
                            link: {
                              addTargetToExternalLinks: true, // This sets target="_blank" for external links
                            },
                          }}
                          onError={(e) => {
                            console.log(e);
                          }}
                          editor={ClassicEditor}
                          data={values.description}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFieldValue('description', data);
                          }}
                          onBlur={(event, editor) => {
                            setFieldTouched('description', true);
                          }}
                        />

                        {touched.description && errors.description && (
                          <Form.Text className='text-danger fs-200'>
                            {errors.description}
                          </Form.Text>
                        )}
                      </Form.Group>

                      {/* Tags */}
                      {console.log(values.tags, typeof values.tags, 'tags')}

                      <Form.Group controlId='video-tags'>
                        <Form.Label>Tags</Form.Label>
                        <CustomTagsInput
                          value={values.tags || []}
                          onChange={async (tags) => {
                            setFieldValue('tags', tags);
                          }}
                        />
                        {errors.tags && (
                          <Form.Text className='text-danger fs-200'>
                            {errors.tags?.find((tag) => !!tag)}
                          </Form.Text>
                        )}
                      </Form.Group>

                      {/* thumbnail */}
                      <UploadThumbnail
                        maxThumbnail={4}
                        thumbnails={thumbnails}
                        value={values.thumbnail_id}
                        isUploading={thumbnailUploading}
                        error={touched.thumbnail_id && errors.thumbnail_id}
                        onSelectThumbnail={(thumbnail) => {
                          setFieldValue('thumbnail_id', thumbnail.id);
                        }}
                        onUploadBoxClick={(callback) => {
                          if (!videoData?.id)
                            return toast.info(
                              'Please wait to load video data.'
                            );
                          callback();
                        }}
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          try {
                            await thumbnailSchema.validate(file);
                            const formData = new FormData();
                            formData.append('thumbnail', file);
                            const { data } = await uploadThumbnail({
                              videoId: videoData?.id,
                              formData,
                            }).unwrap();
                            setFieldValue('thumbnail_id', data.thumbnail.id);
                            // const remainThumbnails = customThumbnail
                            //   ? thumbnails.slice(1)
                            //   : thumbnails;
                            // setVideoDetailStore((prev) => ({
                            //   ...prev,
                            //   thumbnail_id: data.thumbnail.id,
                            //   thumbnails: [data.thumbnail, ...remainThumbnails],
                            // }));
                            setFieldTouched('thumbnail_id', true);
                          } catch (error) {
                            setFieldTouched('thumbnail_id', true);
                            handleError(error);
                          }
                        }}
                      />

                      {/* comment disable / enable */}
                      <div className='d-flex justify-content-between mt-4'>
                        <div>
                          <h6 className='fw-600'>Comments</h6>
                          <p className='mt-1'>
                            Disable the comments section for the viewers to
                            post/add any comment/review on the channel.
                          </p>
                        </div>
                        <Form.Check
                          type='switch'
                          id='custom-switch'
                          name='comments_enabled'
                          checked={values.comments_enabled}
                          onChange={(e) => {
                            handleChange(e);
                            const { name, checked } = e.target;
                            setFieldValue(name, checked);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={5}>
                      {/* video */}
                      {videoData && (
                        <VideoPreviewCard
                          // posterFileUrl={values.thumbnail_url}
                          posterFileUrl={
                            thumbnails?.find(
                              (item) => item.id === values.thumbnail_id
                            )?.thumbnail_url || null
                          }
                          videoFileUrl={videoData.video_url}
                          videoUrl={`${CLIENT_URL}/${
                            videoData.is_short ? 'shorts' : 'watch'
                          }/${videoData.video_url_id}`}
                          videoProgress={100}
                          videoTitle={videoData.title}
                        />
                      )}
                      <Form.Label className='mt-4'>Visibility</Form.Label>
                      <Dropdown>
                        <Dropdown.Toggle
                          name='visibility'
                          variant='default'
                          className='p-3 w-100'
                          style={{
                            border: '1px solid var(--clr-border-dropdown)',
                          }}
                        >
                          <div className='d-flex align-items-center cursor-pointer'>
                            {values.visibility === 'PUBLIC' ? (
                              <img
                                alt='visibility icon'
                                className='mr-2'
                                style={{ width: '20px', height: '20px' }}
                                src={`${Constant.BASE_URL}img/eye-green.svg`}
                              />
                            ) : (
                              <img
                                alt='visibility icon'
                                className='mr-2'
                                src={`${Constant.BASE_URL}img/eye-red.svg`}
                              />
                            )}
                            <h6 className='fs-300 fw-500'>
                              {values.visibility}
                            </h6>
                            <img
                              className='ml-auto'
                              alt='dropdown icon'
                              src={`${Constant.BASE_URL}img/dropdown-1.svg`}
                            />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            active={values.visibility === 'PUBLIC'}
                            onClick={() =>
                              setFieldValue('visibility', 'PUBLIC')
                            }
                          >
                            Public
                          </Dropdown.Item>
                          <Dropdown.Item
                            active={values.visibility === 'PRIVATE'}
                            onClick={() =>
                              setFieldValue('visibility', 'PRIVATE')
                            }
                          >
                            Private
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col md={12}>
                      <hr className='clr-white-card-hr' />
                    </Col>
                    <Col md={12} className='d-flex'>
                      <Button
                        variant='light'
                        disabled={isSubmitting || !dirty}
                        className='ml-auto px-4'
                        onClick={resetForm}
                      >
                        Undo Changes
                      </Button>
                      <TransactionStatusTooltip
                        show={videoData.txn_status === COMMON_STATUS.PROCESSING}
                      >
                        <Button
                          variant='primary'
                          className='ml-2 px-4'
                          disabled={
                            isSubmitting ||
                            !isValid ||
                            !dirty ||
                            videoData.txn_status === COMMON_STATUS.PROCESSING
                          }
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </TransactionStatusTooltip>
                    </Col>
                  </Row>
                </div>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <ThinFooter />
    </>
  );
};

export default VideoDetails;
