import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { abbreviateNumber } from 'js-abbreviation-number';

import { EyeSvg, TimeSvg, WebpageSvg } from 'src/assets/svgs';
import { useChannelAnalyticsMetricsQuery } from 'src/redux/features/channels/channelApi';
import GraphCard from './components/GraphCard';
import CountCard from 'src/components/analytics/CountCard';
import RealTimeCard from './components/RealTimeCard';
import TopVideoCard from './components/TopVideoCard';
import { FIXED_DECIMAL_CONSTANT } from 'src/constant';
import { watchTimeCalculator } from 'src/helpers/general';
import { ThinFooter } from 'src/components/footer';

const transformTimeDuration = (duration) => {
  if (!duration) return 0;

  if (duration === 0) return 0;
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const remainingSeconds = (duration % 60).toFixed(2);

  if (hours > 0) return `${hours} Hr${hours !== 1 ? 's' : ''}`;
  if (minutes > 0) return `${minutes} Min${minutes !== 1 ? 's' : ''}`;
  if (remainingSeconds > 0)
    return `${remainingSeconds} Sec${remainingSeconds !== 1 ? 's' : ''}`;
};

const ChannelAnalytics = () => {
  const { data: metrics } = useChannelAnalyticsMetricsQuery();

  return (
    <>
      <Container
        fluid='xxl'
        className='p-2 p-md-4 overflow-auto'
        id='video-analytics-root'
      >
        <div
        // style={{ minWidth: '1000px' }}
        >
          <h3 className='mb-3 fs-500 fw-600'>Channel Analytics</h3>
          <Row>
            {/* - Left Metrics and Graph */}
            <Col xl={9} lg={12}>
              <div
                className='d-flex flex-wrap flex-lg-nowrap align-align-items-center justify-content-between gap-2'
                style={{ gap: '16px' }}
              >
                <CountCard
                  icon={EyeSvg}
                  count={abbreviateNumber(
                    metrics?.view_count || 0,
                    FIXED_DECIMAL_CONSTANT
                  )}
                  title='Total Views'
                />
                <CountCard
                  icon={TimeSvg}
                  count={watchTimeCalculator(metrics?.watch_time_count)}
                  title='Total Watch Time'
                  // unit={'Hrs'}
                />
                <CountCard
                  icon={WebpageSvg}
                  count={abbreviateNumber(
                    metrics?.subscriber_count || 0,
                    FIXED_DECIMAL_CONSTANT
                  )}
                  title='Total Subscribers'
                />
              </div>
            </Col>
            <Col lg={12} xl={9} className='pb-3 overflow-auto'>
              <GraphCard />
            </Col>
            {/* Right - Real Time */}
            <Col lg={12} xl={3} className='mt-4'>
              <RealTimeCard />
            </Col>
            <Col lg={12} xl={9}>
              <TopVideoCard />
            </Col>
          </Row>
        </div>
      </Container>
      <ThinFooter />
    </>
  );
};

export default ChannelAnalytics;
