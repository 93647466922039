import { CommentMedia, CustomMedia } from './CommentMedia';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'src/components/atoms/Loader';

export default function CommentBody({
  comments,
  totalResultCount,
  contractVideoId,
  sort,
  limit,
  offset,
  handleNextPage,
}) {
  // const { handleNextPage, limit, offset, sort } = useContext(CommentContext);
  return (
    <>
      <CustomMedia contractVideoId={contractVideoId} />
      <div>
        {comments && (
          <InfiniteScroll
            dataLength={comments?.length}
            scrollThreshold={0}
            next={handleNextPage}
            hasMore={totalResultCount > comments?.length}
            loader={<Loader />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
            className='overflow-hidden'
          >
            {comments?.map((comment) => (
              <CommentMedia
                key={comment.id}
                commentData={comment}
                limit={limit}
                offset={offset}
                sort={sort}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </>
  );
}
