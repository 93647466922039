import React from 'react';
import TagsInput from 'react-tagsinput';

const CustomTagsInput = ({ onChange, value }) => {
  return (
    <TagsInput
      addKeys={[188, 13]}
      maxTags={30}
      onlyUnique={true}
      addOnBlur
      addOnPaste
      renderTag={(props) => {
        let {
          tag,
          key,
          disabled,
          onRemove,
          classNameRemove,
          getTagDisplayValue,
          ...other
        } = props;
        return (
          <div key={key} {...other}>
            <div
              className='overflow-hidden text-ellipse'
              style={{ maxWidth: '200px' }}
            >
              {getTagDisplayValue(tag)}
            </div>
            {!disabled && (
              <a className={classNameRemove} onClick={() => onRemove(key)} />
            )}
          </div>
        );
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default CustomTagsInput;
