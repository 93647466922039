import React, { useContext, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';

import Constant, {
  SUPPORTED_VIDEO_FILE_TYPE,
  MAX_VIDEO_FILE_SIZE,
  SERVER_BASE_URL,
} from 'src/constant';
import { FormContext } from '../UploadVideo';
import * as Yup from 'yup';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { useOverallDraftStatusQuery } from 'src/redux/features/videos/videoApi';
import { Link, useHistory } from 'react-router-dom';
import handleError from 'src/utils/handle-error';
import { defaultHeaders } from 'src/redux/rootApi';
import messages from 'src/constant/messages';
import Loader from 'src/components/atoms/Loader';
// import config from 'src/config';
// import { pinFileToIPFS } from 'src/utils/ipfs';

async function validateVideoDuration(file) {
  const video = document.createElement('video');
  video.preload = 'metadata';
  video.src = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      if (isNaN(video.duration)) {
        reject('Video duration cannot be determined.');
      } else {
        if (video.duration === Infinity) {
          reject('Video has an Infinity duration.');
        } else if (video.duration >= 0) {
          resolve(video.duration);
        } else reject('Video has a negative duration.');
      }
    };

    video.onerror = (error) => {
      reject('Corrupt Video Found.');
    };
  });
}

const videoValidation = Yup.object().shape({
  video: Yup.mixed()
    .test(
      'fileSize',
      `File size is too large (max upto ${MAX_VIDEO_FILE_SIZE})`,
      (value) => !value || (value && value.size <= MAX_VIDEO_FILE_SIZE)
    )
    .test(
      'fileType',
      `Supported file format '${SUPPORTED_VIDEO_FILE_TYPE.join(',')}'`,
      (value) => {
        return (
          !value || (value && SUPPORTED_VIDEO_FILE_TYPE.includes(value.type))
        );
      }
    )
    .test('Load Video File', async function (value) {
      try {
        const duration = await validateVideoDuration(value);
        console.log(duration, 'duration 1');
        return true;
      } catch (error) {
        console.error('Validation error:', error);
        return this.createError({ message: error });
      }
    })
    .required(),
});

const StepOne = () => {
  const videoRef = useRef(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const {
    videoDetailStore,
    setActiveStepIndex,
    setVideoDetailStore,
    setVideoProgress,
  } = useContext(FormContext);
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });

  const {
    data: overallDraftStatusRes,
    isLoading: isLoadingOverallDraftStatus,
  } = useOverallDraftStatusQuery();
  console.log(overallDraftStatusRes, 'overall');

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    try {
      await videoValidation.validate({ video: file });
      setVideoDetailStore((pre) => ({ ...pre, video: file }));
      setActiveStepIndex((prev) => prev + 1);
    } catch (error) {
      handleError(error);
      return;
    }
    // - Passed the validation
    try {
      // const ipfsHash = await pinFileToIPFS(file);
      // setVideoDetailStore((pre) => ({ ...pre, videoHash: ipfsHash }));
      formData.append('video', file);
      // formData.append('is_short', videoDetailStore.is_short);
      // formData.append('videoHash', ipfsHash);

      const url = `${SERVER_BASE_URL}/videos`;
      const result = await axios.post(url, formData, {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${
            authData?.token || localStorage.getItem('accessToken')
          }`,
        },
        //...other options like headers here
        onUploadProgress: (upload) => {
          //Set the progress value to show the progress bar
          let progress = Math.round((100 * upload.loaded) / upload.total);
          setVideoProgress(progress);
        },
      });
      const { video, message } = result.data.data;

      setVideoDetailStore((prev) => ({
        ...prev,
        videoId: video.id,
        video_url: video.video_url,
        video_url_id: video.video_url_id,
        is_short: video.is_short,
        thumbnails: video.thumbnails,
        thumbnail_id: video.thumbnails[0]?.id || null,
        video_hash: video.video_hash,
      }));
      // history.push(`?vid=${video.id}`);
      toast.success(message || 'Video Uploaded!');
    } catch (error) {
      handleError(error, 'Video failed to upload!');
      // toast.error(error?.response.data?.message || 'Video failed to upload!');
      setActiveStepIndex((prev) => prev - 1);
    }
  };

  const history = useHistory();

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (
      !isLoadingOverallDraftStatus &&
      overallDraftStatusRes?.data?.draftStatus
    ) {
      return toast.info(
        overallDraftStatusRes?.message ||
          messages.video_in_draft_upload_disabled
      );
    }
    handleFileUpload(file);
    setIsDraggingOver(false);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };
  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  if (isLoadingOverallDraftStatus) {
    return <Loader />;
  }

  return (
    <Row className='mx-0'>
      <Col md={10} className='mx-auto p-0'>
        <h3 className='mb-3 fs-500 fw-600'>Upload video</h3>
      </Col>
      {overallDraftStatusRes?.data?.draftStatus ? (
        <Col
          md={10}
          className={`mx-auto text-center upload-video pt-5 pb-5 studio-card`}
        >
          <DraftCard message={overallDraftStatusRes?.message} />
        </Col>
      ) : (
        <Col
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          md={10}
          className={`${
            isDraggingOver && 'bg-clr-primary-400'
          } mx-auto text-center upload-video pt-5 pb-5 studio-card`}
        >
          <div style={{ maxWidth: '550px', margin: 'auto' }}>
            <div>
              <img
                src={`${Constant.BASE_URL}img/illustration/upload-video2.svg`}
                alt='upload'
              />
              <h4 className='mt-4 mb-2 fs-500 fw-600'>
                Drag And Drop Your Files To Upload!
              </h4>
              <p className='land'>
                You can drag and drop your files with the maximum size of{' '}
                <span className='fw-600 text-primary'>1GB</span> and in the
                video format
                <span className='fw-600 text-primary'>
                  {' '}
                  video/mp4, video/webm, video/mkv, video/mov, video/3gpp
                  {/* {SUPPORTED_VIDEO_FILE_TYPE.join(', ')} */}
                </span>
                , then upload them on the platform.
              </p>
              <div className='mt-4'>
                <Form.Control
                  size='lg'
                  type='file'
                  hidden
                  ref={videoRef}
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    handleFileUpload(file);
                  }}
                />
                {overallDraftStatusRes?.data?.draftStatus ? (
                  <Button
                    variant='primary'
                    size='lg'
                    className='px-4'
                    disabled={isLoadingOverallDraftStatus}
                    onClick={() =>
                      history.push(`${Constant.BASE_URL}studio/content`)
                    }
                  >
                    Go to Publish / Delete Draft
                  </Button>
                ) : (
                  <Button
                    variant='primary'
                    size='lg'
                    disabled={isLoadingOverallDraftStatus}
                    className='px-4'
                    onClick={() => {
                      videoRef.current.click();
                    }}
                  >
                    Select Files
                  </Button>
                )}
                {/* <Form.Group className='mt-2'>
              <Form.Label>Upload as short</Form.Label>
              <Form.Check
                type='switch'
                id='custom-switch'
                name='is_short'
                checked={videoDetailStore.is_short}
                onChange={(e) => {
                  const { name, checked } = e.target;
                  setVideoDetailStore((pre) => ({ ...pre, is_short: checked }));
                }}
              />
            </Form.Group> */}
                {overallDraftStatusRes?.data?.draftStatus && (
                  <p className='fw-600 text-primary mt-2'>
                    {overallDraftStatusRes?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </Col>
      )}
      <Col md={12} className='mt-4'>
        <p className='text-center'>
          By submitting your videos to Mirror, you acknowledge that you agree to{' '}
          <Link
            className='fw-600 text-primary cursor-pointer'
            as={'span'}
            to={`${Constant.BASE_URL}terms`}
          >
            Terms & Conditions & Community Guidelines.
          </Link>
        </p>
        <p className='text-center'>
          Please be sure not to violate others' copyright or privacy rights.
        </p>
      </Col>
    </Row>
  );
};

const DraftCard = ({ message }) => {
  const history = useHistory();
  return (
    <div>
      <img
        src={`${Constant.BASE_URL}img/illustration/warning.png`}
        style={{
          maxWidth: '300px',
        }}
        className='mb-4'
        alt='upload'
      />
      <div className='mt-4'>
        <Button
          variant='primary'
          size='lg'
          className='px-4'
          // disabled={isLoadingOverallDraftStatus}
          onClick={() => history.push(`${Constant.BASE_URL}studio/content`)}
        >
          Go to Publish / Delete Draft
        </Button>
        <p className='fw-600 text-primary mt-3'>{message}</p>
      </div>
    </div>
  );
};
export default StepOne;
