import React from 'react';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  DropdownIcon,
  LeftAngleIcon,
  RightAngleIcon,
  LeastLeftAngleIcon,
  MostRightAngleIcon,
} from '../../../assets/icons';

const CustomPaginate = ({
  handleLimitChange,
  handlePageChange,
  pageNumber,
  totalPages,
  totalResultCount,
  limit,
}) => {
  return (
    <div
      className='d-flex justify-content-center justify-content-md-end align-items-center custom-pagination mr-md-2 flex-wrap'
      style={{ gap: '40px' }}
    >
      <div className='d-flex align-item-center' style={{ gap: '16px' }}>
        <div className='d-flex align-items-center'>
          <span className='fs-300 text-clr-dark-100'>Rows per page:</span>
          <Dropdown onSelect={handleLimitChange}>
            <Dropdown.Toggle as='a' className='no-after'>
              <div className='d-flex align-align-items-end cursor-pointer'>
                <h6 className='fs-300 text-clr-dark-100 ml-4 mr-2'>{limit}</h6>
                <DropdownIcon
                  className='icon'
                  style={{ width: '14px', height: '14px' }}
                />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={10} active={parseInt(limit) === 10}>
                10
              </Dropdown.Item>
              <Dropdown.Item eventKey={20} active={parseInt(limit) === 20}>
                20
              </Dropdown.Item>
              <Dropdown.Item eventKey={30} active={parseInt(limit) === 30}>
                30
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <span className='fs-300 text-clr-dark-100'>
          {(pageNumber - 1) * limit + 1} -{' '}
          {pageNumber * limit > totalResultCount
            ? totalResultCount
            : pageNumber * limit}{' '}
          of {totalResultCount}
        </span>
      </div>
      <div className='d-flex align-items-center' style={{ gap: '15px' }}>
        <OverlayTrigger
          placement='top'
          overlay={(props) => <Tooltip {...props}>First Page</Tooltip>}
        >
          <Button
            variant='default'
            disabled={pageNumber === 1}
            onClick={() => handlePageChange(1)}
          >
            <LeastLeftAngleIcon
              className='icon'
              style={{ width: '16px', height: '16px' }}
            />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement='top'
          overlay={(props) => <Tooltip {...props}>Previous Page</Tooltip>}
        >
          <Button
            variant='default'
            onClick={() => handlePageChange(pageNumber - 1)}
            disabled={pageNumber === 1}
          >
            <LeftAngleIcon
              className='icon'
              style={{ width: '16px', height: '16px' }}
            />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement='top'
          overlay={(props) => <Tooltip {...props}>Next Page</Tooltip>}
        >
          <Button
            variant='default'
            disabled={pageNumber === totalPages || totalPages === 0}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            <RightAngleIcon
              className='icon'
              style={{ width: '16px', height: '16px' }}
            />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement='top'
          overlay={(props) => <Tooltip {...props}>Last Page</Tooltip>}
        >
          <Button
            variant='default'
            disabled={pageNumber === totalPages || totalPages === 0}
            onClick={() => handlePageChange(totalPages)}
          >
            <MostRightAngleIcon
              className='icon'
              style={{ width: '16px', height: '16px' }}
            />
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default CustomPaginate;
