import { Suspense, createContext, useEffect, useState } from 'react';
import { Switch, Route, useLocation, useParams } from 'react-router-dom';
// import ScrollToTop from 'react-scroll-to-top';
// import {
//   configureChains,
//   WagmiConfig,
//   createConfig,
//   // useSwitchChain,
//   // useAccount,
//   // createClient,
// } from 'wagmi';

// import {
//   EthereumClient,
//   w3mConnectors,
//   w3mProvider,
// } from '@web3modal/ethereum';
// import { Web3Modal } from '@web3modal/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

import FourZeroFour from './components/404';

import {
  StudioDashboard,
  CreateChannel,
  WatchHistory,
  LikedVideos,
  NewVideos,
  VideoOfDay,
  TrendingVideos,
  VideoSearch,
  WatchLater,
  PopularChannels,
  CategoryVideos,
  Subscriptions,
  SubscribedChannels,
  UploadVideo,
  Channel,
  VideoPage,
  Customization,
  Homepage,
  Settings,
  VideoOfWeek,
  VideoOfMonth,
  VideoOfYear,
  Help,
  TermsAndConditions,
  PrivacyPolicy,
  Shorts,
} from './pages';
// import { CustomScrollToTop } from './components/atoms/ScrollToTop/ScrollToTop';
import Constant, { themeMode } from './constant';
import {
  ChannelAnalytics,
  StudioComments,
  StudioContent,
  VideoAnalytics,
  VideoComments,
} from './pages/studio';
import ProtectedRoute from './routes/ProtectedRoute';
import Navigation from './components/navigation';
import Sidebar from './components/sidebar';
import StudioSidebar from './components/studio-sidebar';
import config from './config';
import Reward from './pages/reward/Reward';
import ContentWrapper from './components/atoms/ContentWrapper/ContentWrapper';
import { Container } from 'react-bootstrap';
import {
  AnalyticsSvg,
  CommentsSvg,
  ContentSvg,
  CustomizationSvg,
  DashboardSvg,
} from './assets/svgs';
import VideoDetails from './pages/studio/video-details/VideoDetails';
import { useFetchMyChannelQuery } from './redux/features/channels/channelApi';
import PublicRoute from './routes/PublicRoute';
import { useBasicInfoQuery } from './redux/features/basic/basicApi';
import { useAuthCheckQuery } from './redux/features/auth/authApi';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ReportVideoModal, ShareModal } from './components/atoms/modals';
import Loader from './components/atoms/Loader';
import './pages/studio-dashboard/StudioDashboard.css';
import VerifyMail from './pages/verifymail/VerifyMail';
import { useAccount } from 'wagmi';

// const VideoComments = lazy(() =>
//   import('./pages/studio/video-comments/VideoComments')
// );
// const VideoAnalytics = lazy(() =>
//   import('./pages/studio/video-analytics/VideoAnalytics')
// );
// const StudioContent = lazy(() =>
//   import('./pages/studio/studio-content/index')
// );
// const StudioComments = lazy(() =>
//   import('./pages/studio/studio-comments/index')
// );
// const ChannelAnalytics = lazy(() =>
//   import('./pages/studio/channel-analytics/ChannelAnalytics')
// );
// const VideoDetails = lazy(() =>
//   import('./pages/studio/video-details/VideoDetails')
// );
// const StudioDashboard = lazy(() =>
//   import('./pages/studio-dashboard/Studio-Dashboard')
// );
// const CreateChannel = lazy(() =>
//   import('./pages/create-channel/CreateChannel')
// );
// const WatchHistory = lazy(() => import('./pages/history/History'));
// const LikedVideos = lazy(() => import('./pages/liked-videos/LikedVideos'));
// const NewVideos = lazy(() => import('./pages/new-videos/NewVideos'));
// const VideoOfDay = lazy(() => import('./pages/video-of-day/VideoOfDay'));
// const TrendingVideos = lazy(() =>
//   import('./pages/trending-videos/TrendingVideos')
// );
// const VideoSearch = lazy(() => import('./pages/video-search/VideoSearch'));
// const WatchLater = lazy(() => import('./pages/watch-later/WatchLater'));
// const PopularChannels = lazy(() =>
//   import('./pages/popular-channels/PopularChannels')
// );
// const CategoryVideos = lazy(() =>
//   import('./pages/category-videos/CategoryVideos')
// );
// const Subscriptions = lazy(() => import('./pages/subscriptions/Subscriptions'));
// const SubscribedChannels = lazy(() =>
//   import('./pages/subscribed-channels/SubscribedChannels')
// );
// const UploadVideo = lazy(() => import('./pages/upload-video/UploadVideo'));
// const Channel = lazy(() => import('./pages/channel/Channel'));
// const VideoPage = lazy(() => import('./pages/video-page/VideoPage'));
// const Customization = lazy(() =>
//   import('./pages/studio/customization/Customization')
// );
// const Homepage = lazy(() => import('./pages/homepage/Homepage'));
// const Settings = lazy(() => import('./pages/settings/Settings'));
// const VideoOfWeek = lazy(() => import('./pages/video-of-week/VideoOfWeek'));
// const VideoOfMonth = lazy(() => import('./pages/video-of-month/VideoOfMonth'));
// const VideoOfYear = lazy(() => import('./pages/video-of-year/VideoOfYear'));
// const Help = lazy(() => import('./pages/help/Help'));
// const TermsAndConditions = lazy(() =>
//   import('./pages/terms-and-conditions/TermsAndConditions')
// );
// const PrivacyPolicy = lazy(() => import('./pages/privacy/Privacy'));
// const Shorts = lazy(() => import('./pages/shorts/Shorts'));

// import { createWeb3Modal } from '@web3modal/wagmi/react';
// import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

// import { WagmiProvider, useAccount } from 'wagmi';
// // import { arbitrum, mainnet } from 'wagmi/chains';
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const ThemeContext = createContext(null);
export const SidebarContext = createContext(null);

// const chains = [config.ACTIVE_CHAIN];
// const projectId = config.WALLET_CONNECT_PROJECT_ID;

// // 0. Setup queryClient
// const queryClient = new QueryClient();

// // 2. Create wagmiConfig
// const metadata = {
//   name: 'Mirror - Decentralized Video Sharing Platform',
//   description:
//     'Experience a decentralized video sharing platform like never before. Mirrror empowers creators and viewers with a truly open, secure, and censorship-resistant environment for sharing and discovering content. Join the revolution today!',
//   url: config.project_domain_link, // origin must match your domain & subdomain
//   icons: ['https://avatars.githubusercontent.com/u/37784886'],
// };

// const wagmiConfig = defaultWagmiConfig({
//   chains,
//   projectId,
//   metadata,
//   // ...wagmiOptions, // Optional - Override createConfig parameters
// });

// // 3. Create modal
// createWeb3Modal({
//   defaultChain: config.ACTIVE_CHAIN,
//   wagmiConfig,
//   projectId,
//   enableAnalytics: true, // Optional - defaults to your Cloud configuration
//   enableOnramp: true, // Optional - false as default
// });

// const {
//   // provider, webSocketProvider,
//   publicClient,
//   webSocketPublicClient,
// } = configureChains(chains, [w3mProvider({ projectId })]);

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, version: 2, chains }),
//   // provider,
//   // webSocketProvider,
//   publicClient,
//   webSocketPublicClient,
// });

// const ethereumClient = new EthereumClient(wagmiConfig, chains);

// if (process.env.NODE_ENV !== 'development') {
//   import('./jquery.guard')
//     .then((module) => {
//       console.log(module, 'module');
//       // Access module if needed
//       // For example: console.log(module);
//     })
//     .catch((error) => {
//       console.error('Error importing jquery.guard:', error);
//     });
// }

const App = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [mode, setMode] = useState(themeMode.LIGHT);

  const { isConnected } = useAccount();
  console.log(isConnected, 'isConnected');
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setIsReady(true);
  }, []);

  useEffect(() => {
    console.log('render app.js useeffect contain theme');
    const prefersDarkTheme = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches;
    const userPreference = localStorage.getItem('theme');
    if (
      userPreference === themeMode.DARK ||
      (userPreference === null && prefersDarkTheme)
    ) {
      setMode(themeMode.DARK);
      document.body.setAttribute('data-theme', themeMode.DARK);
    } else {
      document.body.setAttribute('data-theme', themeMode.LIGHT);
      setMode(themeMode.LIGHT);
    }
  }, []);

  const toggleMode = () => {
    const updatedMode =
      localStorage.getItem('theme') === themeMode.LIGHT
        ? themeMode.DARK
        : themeMode.LIGHT;
    localStorage.setItem('theme', updatedMode);
    setMode(updatedMode);
    if (mode === themeMode.LIGHT) {
      document.body.setAttribute('data-theme', themeMode.DARK);
    } else {
      document.body.setAttribute('data-theme', themeMode.LIGHT);
    }
  };

  const themeImageUrlParser = (imagePath) => {
    return `${Constant.BASE_URL}img/${
      mode === themeMode.LIGHT ? 'light' : 'dark'
    }/${imagePath}`;
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (openSidebar) {
      document.body.classList.add('sidebar-toggled');
    } else {
      document.body.classList.remove('sidebar-toggled');
    }
  }, [openSidebar]);

  // code to hide controls in fullscreen
  useEffect(() => {
    const exitHandler = (e) => {
      let theater = document.querySelector('.shaka-theater-mode-container');
      let pip = document.querySelector('.shaka-pip-button');
      if (
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement
      ) {
        if (theater) theater.style.display = 'none';
        if (pip) pip.style.display = 'none';
      } else {
        if (theater) theater.style.display = 'block';
        if (pip) pip.style.display = 'block';
      }
    };

    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
  }, []);

  if (!isReady) return <Loader className='pt-4' />;

  return (
    // <WagmiConfig
    //   config={wagmiConfig}
    //   // client={wagmiConfig}
    // >
    // <WagmiProvider config={wagmiConfig}>
    // <QueryClientProvider client={queryClient}>
    <SkeletonTheme
      baseColor={mode === themeMode.LIGHT ? '#ebebeb' : '#202020'}
      highlightColor={mode === themeMode.LIGHT ? '#f5f5f5' : '#444'}
    >
      <ThemeContext.Provider value={{ mode, toggleMode, themeImageUrlParser }}>
        <SidebarContext.Provider value={{ openSidebar, setOpenSidebar }}>
          <Routes />
          {/* <Web3Modal
              projectId={projectId}
              ethereumClient={ethereumClient}
              defaultChain={config.ACTIVE_CHAIN}
            /> */}
          <ToastContainer />
        </SidebarContext.Provider>
      </ThemeContext.Provider>
    </SkeletonTheme>
    // </QueryClientProvider>
    // </WagmiProvider>
    // {/* </WagmiConfig> */}
  );
};

const Routes = () => {
  const { isLoading: basicInfoLoading } = useBasicInfoQuery();
  const { isLoading: authLoading } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });

  // console.log(switchNetwork, chain, config.ACTIVE_CHAIN.id, 'chain');
  // useEffect(() => {
  //   if (
  //     account.isConnected &&
  //     chain &&
  //     chain?.id !== config.ACTIVE_CHAIN.id &&
  //     switchNetwork
  //   ) {
  //     switchNetwork(config.ACTIVE_CHAIN.id);
  //   }
  // }, [account.isConnected, chain, chain?.id, switchNetwork]);

  if (basicInfoLoading || authLoading) {
    return <Loader className='pt-4' />;
  }

  return (
    <div id='project-root'>
      <ShareModal />
      <ReportVideoModal />
      {/* {chain && chain?.id !== config.ACTIVE_CHAIN.id ? (
        <Alert className='mb-0' variant='warning'>
          <span className='fw-500'>Warning:</span> The active chain does not
          match with the chain polygon. Consider switching to the polygon for
          accurate representation.
        </Alert>
      ) : null} */}
      <Switch>
        {/* without navigation page */}
        <PublicRoute exact path={`${Constant.BASE_URL}app/help`}>
          <Help />
        </PublicRoute>
        <PublicRoute exact path={`${Constant.BASE_URL}app/privacy-policy`}>
          <PrivacyPolicy />
        </PublicRoute>
        <PublicRoute exact path={`${Constant.BASE_URL}app/terms`}>
          <TermsAndConditions />
        </PublicRoute>

        <div>
          <Navigation />
          <Switch>
            {/* with navigation */}
            <ProtectedRoute
              path={`${Constant.BASE_URL}studio/video/:video_url_id`}
              routeType='channel-route'
            >
              <VideoRoute />
            </ProtectedRoute>
            <ProtectedRoute
              path={`${Constant.BASE_URL}studio`}
              routeType='channel-route'
            >
              <Studio />
            </ProtectedRoute>
            <PublicRoute path={`${Constant.BASE_URL}`}>
              <AppRoute />
            </PublicRoute>
            <Route path='*' component={FourZeroFour} />
          </Switch>
        </div>
      </Switch>
    </div>
  );
};

const AppRoute = () => {
  return (
    <>
      <div className='homepage-wrapper'>
        {/* <Navigation /> */}
        <div id='wrapper'>
          <Sidebar />

          <ContentWrapper>
            <Container
              fluid
              className='min-h-without-navbar d-flex flex-column justify-content-between'
            >
              <Suspense fallback={<Loader className='pt-4' />}>
                <Switch>
                  <PublicRoute exact path={`${Constant.BASE_URL}`}>
                    <Homepage />
                  </PublicRoute>
                  <PublicRoute
                    exact
                    path={`${Constant.BASE_URL}verifymail/:id`}
                  >
                    <VerifyMail />
                  </PublicRoute>
                  <PublicRoute
                    path={`${Constant.BASE_URL}shorts/:video_url_id`}
                  >
                    <Shorts />
                  </PublicRoute>
                  <PublicRoute
                    path={`${Constant.BASE_URL}channel/:handle_name`}
                  >
                    <Channel />
                  </PublicRoute>
                  <PublicRoute
                    exact
                    path={`${Constant.BASE_URL}watch/:video_url_id`}
                  >
                    <VideoPage />
                  </PublicRoute>
                  <PublicRoute exact path={`${Constant.BASE_URL}newvideos`}>
                    <NewVideos />
                  </PublicRoute>
                  <PublicRoute exact path={`${Constant.BASE_URL}day`}>
                    <VideoOfDay />
                  </PublicRoute>
                  <PublicRoute exact path={`${Constant.BASE_URL}week`}>
                    <VideoOfWeek />
                  </PublicRoute>
                  <PublicRoute exact path={`${Constant.BASE_URL}month`}>
                    <VideoOfMonth />
                  </PublicRoute>
                  <PublicRoute exact path={`${Constant.BASE_URL}year`}>
                    <VideoOfYear />
                  </PublicRoute>
                  <PublicRoute
                    exact
                    path={`${Constant.BASE_URL}trendingvideos`}
                  >
                    <TrendingVideos />
                  </PublicRoute>
                  <PublicRoute
                    exact
                    path={`${Constant.BASE_URL}videos/categories/:categoryName`}
                  >
                    <CategoryVideos />
                  </PublicRoute>
                  <PublicRoute exact path={`${Constant.BASE_URL}help`}>
                    <Help />
                  </PublicRoute>
                  <PublicRoute
                    exact
                    path={`${Constant.BASE_URL}privacy-policy`}
                  >
                    <PrivacyPolicy />
                  </PublicRoute>
                  <PublicRoute exact path={`${Constant.BASE_URL}terms`}>
                    <TermsAndConditions />
                  </PublicRoute>
                  <PublicRoute
                    exact
                    path={`${Constant.BASE_URL}popularchannels`}
                  >
                    <PopularChannels />
                  </PublicRoute>
                  <ProtectedRoute exact path={`${Constant.BASE_URL}history`}>
                    <WatchHistory />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={`${Constant.BASE_URL}watch-later`}
                  >
                    <WatchLater />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={`${Constant.BASE_URL}liked-videos`}
                  >
                    <LikedVideos />
                  </ProtectedRoute>
                  <PublicRoute exact path={`${Constant.BASE_URL}subscriptions`}>
                    <Subscriptions />
                  </PublicRoute>
                  <ProtectedRoute
                    exact
                    path={`${Constant.BASE_URL}feed/channels`}
                  >
                    <SubscribedChannels />
                  </ProtectedRoute>
                  <PublicRoute exact path={`${Constant.BASE_URL}results`}>
                    <VideoSearch />
                  </PublicRoute>
                  <Route exact path={`${Constant.BASE_URL}profile`}>
                    <Settings />
                  </Route>
                  <ProtectedRoute
                    exact
                    path={`${Constant.BASE_URL}create-channel`}
                  >
                    <CreateChannel />
                  </ProtectedRoute>
                  <Route path='*' component={FourZeroFour} />
                </Switch>
              </Suspense>
            </Container>
            {/* <ThinFooter /> */}
          </ContentWrapper>
        </div>
        {/* <ScrollToTop
          smooth
          component={<CustomScrollToTop />}
          className='scroll-to-top outline-0'
          color='white'
        /> */}
      </div>
    </>
  );
};

const Studio = () => {
  const { data: channel, isLoading } = useFetchMyChannelQuery();

  if (isLoading || !channel) return null;

  const items = [
    {
      label: 'Dashboard',
      icon: DashboardSvg,
      link: `${Constant.BASE_URL}studio`,
    },
    {
      label: 'Content',
      icon: ContentSvg,
      link: `${Constant.BASE_URL}studio/content`,
    },
    {
      label: 'Analytics',
      icon: AnalyticsSvg,
      link: `${Constant.BASE_URL}studio/analytics`,
    },
    {
      label: 'Comments',
      icon: CommentsSvg,
      link: `${Constant.BASE_URL}studio/comments`,
    },
    {
      label: 'Customization',
      icon: CustomizationSvg,
      link: `${Constant.BASE_URL}studio/customization`,
    },
  ];

  return (
    <>
      <div className='homepage-wrapper'>
        {/* <Navigation /> */}
        <div id='wrapper'>
          <StudioSidebar items={items} />

          <ContentWrapper>
            <Container
              fluid
              className='min-h-without-navbar d-flex flex-column justify-content-between'
            >
              <Suspense fallback={<Loader className='pt-4' />}>
                <Switch>
                  <ProtectedRoute
                    exact
                    path={`${Constant.BASE_URL}studio`}
                    routeType='channel-route'
                  >
                    <StudioDashboard />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={`${Constant.BASE_URL}studio/upload`}
                    routeType='channel-route'
                  >
                    <UploadVideo />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={`${Constant.BASE_URL}studio/content`}
                    routeType='channel-route'
                  >
                    <StudioContent />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={`${Constant.BASE_URL}studio/analytics`}
                    routeType='channel-route'
                  >
                    <ChannelAnalytics />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={`${Constant.BASE_URL}studio/comments`}
                    routeType='channel-route'
                  >
                    <StudioComments />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path={`${Constant.BASE_URL}studio/customization`}
                    routeType='channel-route'
                  >
                    <Customization />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path={`${Constant.BASE_URL}studio/reward`}
                    routeType='channel-route'
                  >
                    <Reward />
                  </ProtectedRoute>

                  {/* Video Details Route */}
                  {/* <ProtectedRoute
                  exact
                  path={`${Constant.BASE_URL}studio/video/:id/edit`}
                  routeType='channel-route'
                >
                  <h1>This is video Detail page where u can edit the video</h1>
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path={`${Constant.BASE_URL}studio/video/:id/tab-overview/period-default`}
                  routeType='channel-route'
                >
                  <StudioContent />
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path={`${Constant.BASE_URL}studio/video/:id/comments`}
                  routeType='channel-route'
                >
                  <StudioComments />
                </ProtectedRoute> */}

                  {/* <Route path='*' component={FourZeroFour} /> */}
                </Switch>
              </Suspense>
            </Container>
          </ContentWrapper>
        </div>
        {/* <ScrollToTop
          smooth
          component={<CustomScrollToTop />}
          className='scroll-to-top outline-0'
          color='white'
        /> */}
      </div>
    </>
  );
};

const VideoRoute = () => {
  const { video_url_id } = useParams();
  const items = [
    {
      label: 'Details',
      icon: DashboardSvg,
      link: `${Constant.BASE_URL}studio/video/${video_url_id}/edit`,
    },
    {
      label: 'Analytics',
      icon: AnalyticsSvg,
      link: `${Constant.BASE_URL}studio/video/${video_url_id}/analytics/tab-overview`,
    },
    {
      label: 'Comments',
      icon: CommentsSvg,
      link: `${Constant.BASE_URL}studio/video/${video_url_id}/comments`,
    },
  ];

  return (
    <>
      <div className='homepage-wrapper'>
        {/* <Navigation /> */}
        <div id='wrapper'>
          <StudioSidebar items={items} type='video' />

          <ContentWrapper>
            <Container fluid className='min-h-without-navbar-footer'>
              <Switch>
                <ProtectedRoute
                  exact
                  path={`${Constant.BASE_URL}studio/video/:video_url_id/edit`}
                  routeType='channel-route'
                >
                  <VideoDetails />
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path={`${Constant.BASE_URL}studio/video/:video_url_id/analytics/tab-overview`}
                  routeType='channel-route'
                >
                  <VideoAnalytics />
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path={`${Constant.BASE_URL}studio/video/:video_url_id/comments`}
                  routeType='channel-route'
                >
                  <VideoComments />
                </ProtectedRoute>

                <Route path='*' component={FourZeroFour} />
              </Switch>
            </Container>
          </ContentWrapper>
        </div>
        {/* <ScrollToTop
          smooth
          component={<CustomScrollToTop />}
          className='scroll-to-top outline-0'
          color='white'
        /> */}
      </div>
    </>
  );
};

export default App;
