import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFrown } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'react-bootstrap';
import Constant from 'src/constant';
import messages from 'src/constant/messages';

function VerifiedTooltip({ color = null }) {
  const renderTooltip = (props) => <Tooltip {...props}>Verified</Tooltip>;
  return (
    <>
      <OverlayTrigger placement='top' overlay={renderTooltip}>
        <span className={color ? '' : 'text-success-custom'}>
          <FontAwesomeIcon icon={faCheckCircle} {...color} />
        </span>
      </OverlayTrigger>
    </>
  );
}

function UnverifiedTooltip() {
  const renderTooltip = (props) => <Tooltip {...props}>Unverified</Tooltip>;
  return (
    <>
      <OverlayTrigger placement='top' overlay={renderTooltip}>
        <span className='text-danger-custom'>
          <FontAwesomeIcon icon={faFrown} />
        </span>
      </OverlayTrigger>
    </>
  );
}

function VerifiedTooltipLink() {
  const renderTooltip = (props) => <Tooltip {...props}>Verified</Tooltip>;
  return (
    <>
      <OverlayTrigger placement='top' overlay={renderTooltip}>
        <a className='text-success-custom'>
          <FontAwesomeIcon icon={faCheckCircle} />
        </a>
      </OverlayTrigger>
    </>
  );
}
function VerifiedTooltipLinkDark() {
  const renderTooltip = (props) => <Tooltip {...props}>Verified</Tooltip>;
  return (
    <>
      <OverlayTrigger placement='top' overlay={renderTooltip}>
        <a className=''>
          <FontAwesomeIcon icon={faCheckCircle} />
        </a>
      </OverlayTrigger>
    </>
  );
}
function UnverifiedTooltipLink() {
  const renderTooltip = (props) => <Tooltip {...props}>Unverified</Tooltip>;
  return (
    <>
      <OverlayTrigger placement='top' overlay={renderTooltip}>
        <a className='text-danger-custom'>
          <FontAwesomeIcon icon={faFrown} />
        </a>
      </OverlayTrigger>
    </>
  );
}

const ThumbnailInfoTooltip = ({ children }) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Popover>
          {/* <Popover.Title as='h3'>{`Popover `}</Popover.Title> */}
          <Popover.Content className='popover-content'>
            <div>
              1. Ensure your thumbnail follows 1280 by 720 pixels(16:9) format.
            </div>
            <div>
              2. The thumbnail must be less than <strong>2 MB</strong>.
            </div>
            <div>
              3. Use a <strong>JPG or PNG</strong> file format.
            </div>
            <div>4. Your thumbnail must follow Community Guidelines.</div>
          </Popover.Content>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export const BlockchainSaveTooltip = ({ className = '', ...props }) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={(props) => (
        <Tooltip {...props}>{messages.data_also_store_in_blockchain}</Tooltip>
      )}
    >
      <img
        alt='block_chain_input_marker'
        className={`img-fluid cursor-pointer ${className}`}
        src={`${Constant.BASE_URL}img/blockchain_save_marker.svg`}
        {...props}
      />
    </OverlayTrigger>
  );
};

export const TransactionStatusTooltip = ({
  children,
  show = false,
  message = messages.pending_transaction,
}) => {
  return show ? (
    <OverlayTrigger
      placement='top'
      overlay={(props) => <Tooltip {...props}>{message}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  );
};

// const CustomGraphTooltip = ({
//   xLabel = 'Date',
//   yLabel = 'Count',
//   xValue,
//   yValue,
// }) => {
//   return (
//     <div className='text-light bg-dark fw-600 fs-300 rounded-sm px-2 py-1'>
//       <div>
//         {xLabel} - {xValue}
//       </div>
//       <div>
//         {yLabel} - {yValue}
//       </div>
//     </div>
//   );
// };
export {
  VerifiedTooltip,
  UnverifiedTooltip,
  VerifiedTooltipLink,
  UnverifiedTooltipLink,
  VerifiedTooltipLinkDark,
  ThumbnailInfoTooltip,
  // CustomGraphTooltip,
};
