import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { abbreviateNumber } from 'js-abbreviation-number';

import {
  useFetchVideoByIdQuery,
  useVideoAnalyticsMetricsQuery,
} from 'src/redux/features/videos/videoApi';
import './style.css';
import { EyeSvg, TimeSvg, WebpageSvg } from 'src/assets/svgs';
import RealTimeCard from './components/RealTimeCard';
import GraphCard from './components/GraphCard';
import CountCard from 'src/components/analytics/CountCard';
import { watchTimeCalculator } from 'src/helpers/general';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';

const VideoAnalytics = () => {
  const { video_url_id } = useParams();

  const { data: videoData, isLoading: videoDataIsLoading } =
    useFetchVideoByIdQuery(video_url_id, {
      skip: !video_url_id,
    });

  const { data: metrics, isLoading: metricsIsLoading } =
    useVideoAnalyticsMetricsQuery(
      { videoId: videoData?.id },
      { skip: !Boolean(videoData?.id) }
    );

  const convertSeconds = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);

    const result = [];

    if (hours > 0) {
      result.push(`${hours} Hr${hours !== 1 ? 's' : ''}`);
    }

    if (minutes > 0) {
      result.push(`${minutes} Min${minutes !== 1 ? 's' : ''}`);
    }

    if (remainingSeconds > 0) {
      result.push(
        `${remainingSeconds} Sec${remainingSeconds !== 1 ? 's' : ''}`
      );
    }

    return result.join(', ');
  };

  if (metricsIsLoading || videoDataIsLoading) {
    return <Loader className='mt-4' />;
  }

  return (
    <>
      <Container
        fluid='xxl'
        className='p-2 p-md-4 overflow-auto'
        id='video-analytics-root'
      >
        <div
        // style={{ minWidth: '1000px' }}
        >
          <h3 className='mb-3 fs-500 fw-600'>Video Analytics</h3>
          <Row>
            {/* - Left Metrics and Graph */}
            <Col xl={9} lg={12}>
              <div
                className='d-flex flex-wrap flex-lg-nowrap align-align-items-center justify-content-between gap-2'
                style={{ gap: '16px' }}
              >
                <CountCard
                  icon={EyeSvg}
                  count={abbreviateNumber(metrics?.view_count || 0, 1)}
                  title='Total Views'
                />
                {!videoData?.is_short && (
                  <CountCard
                    icon={TimeSvg}
                    count={watchTimeCalculator(metrics?.watch_time_count)}
                    title='Total Watch Time'
                    // unit={'Hrs'}
                  />
                )}
                <CountCard
                  icon={WebpageSvg}
                  count={abbreviateNumber(metrics?.subscriber_count || 0, 1)}
                  title='Total Subscribers'
                />
              </div>
            </Col>
            {/* Graphs */}
            <Col lg={12} xl={9} className='pb-3 overflow-auto'>
              <GraphCard />
            </Col>
            {/* Right - Real Time */}
            <Col lg={12} xl={3} className='mt-4'>
              <RealTimeCard />
            </Col>
          </Row>
        </div>
      </Container>
      <ThinFooter />
    </>
  );
};

export default VideoAnalytics;
