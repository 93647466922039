import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  contractVideoId: null,
  videoId: null,
  videoUrlId: null,
  timestampInSec: null,
};

const reportVideoSlice = createSlice({
  initialState,
  name: 'reportVideoStore',
  reducers: {
    openReportVideoModal: (state, { payload = null }) => {
      state.open = true;
      state.contractVideoId = payload?.contractVideoId || '';
      state.videoId = payload?.videoId || '';
      state.videoUrlId = payload?.videoUrlId || '';
      state.timestampInSec = payload?.timestampInSec || null;
    },
    closeReportVideoModal: () => {
      return initialState;
    },
  },
});

export const { openReportVideoModal, closeReportVideoModal } =
  reportVideoSlice.actions;
export default reportVideoSlice.reducer;
