import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import SectionHeader from 'src/components/atoms/SectionHeader/SectionHeader';
import ChannelCard from 'src/components/atoms/ChannelCard/ChannelCard';
import Constant from 'src/constant';
import { usePopularChannelsQuery } from 'src/redux/features/channels/channelApi';
import config from 'src/config';

const PopularChannels = () => {
  const { data } = usePopularChannelsQuery({
    limit: config.limits.popularChannels,
    offset: 0,
  });
  const history = useHistory();
  return (
    <>
      {data?.channels?.length > 0 && (
        <div className='video-block section-padding '>
          <Row>
            <Col md={12} className='mb-2'>
              <SectionHeader
                heading='Popular Channels'
                noIconLabel
                headingIconUrl={`${Constant.BASE_URL}img/popular.svg`}
                onViewAllClick={() =>
                  history.push(`${Constant.BASE_URL}popularchannels`)
                }
              />
            </Col>
            {data?.channels?.slice(0, 4)?.map((channel) => (
              <Col
                xl={3}
                sm={6}
                className='mb-2'
                key={`popular-channel-section-${channel.id}`}
              >
                <ChannelCard
                  handleName={channel.handle_name}
                  channelId={channel.id}
                  imgSrc={channel.avatar_url}
                  // views='1.4M'
                  channelName={channel.name}
                  subscriberCount={channel.subscribers}
                  // outline
                  isSubscribed={channel.isUserSubscribed}
                  subscribeTo={channel.block_address}
                  channelHref={`${Constant.BASE_URL}channel/${channel.handle_name}`}
                />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
};

export default PopularChannels;
