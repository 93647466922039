// import shaka from 'shaka-player/dist/shaka-player.ui.js';
import shaka from 'shaka-player/dist/shaka-player.ui';
import theaterModeClose from 'src/assets/icons/theater-mode-close.png';
import theaterModeOpen from 'src/assets/icons/theater-mode-open.png';
import './style.css';

const TheaterMode = class extends shaka.ui.Element {
  constructor(parent, controls) {
    super(parent, controls);

    // The actual button that will be displayed
    this.button_ = document.createElement('button');
    this.button_.classList.add('shaka-theater-mode-container', 'shaka-tooltip');
    this.button_.setAttribute('aria-label', 'Theater Mode');
    // this.button_.innerText = 'Full';
    // new shaka.ui.MuteButton(this.button_, controls);
    // new shaka.ui.VolumeBar(this.button_, controls);
    this.img = document.createElement('img');
    this.img.src = theaterModeClose;
    this.img.setAttribute('open', 'true');
    this.img.alt = 'theater mode';
    this.button_.appendChild(this.img);
    this.parent.appendChild(this.button_);

    // Listen for clicks on the container
    this.eventManager.listen(this.button_, 'click', () => {
      const videoPageGrid = document.querySelector('.video-page-grid');
      videoPageGrid.classList.toggle('toggle');
      const img = document.querySelector('.shaka-theater-mode-container > img');
      const isOpen = img.getAttribute('open') === 'true';
      console.log(isOpen, 'img.getAttribute');
      img.src = isOpen ? theaterModeOpen : theaterModeClose;
      img.setAttribute('open', isOpen ? 'false' : 'true');
    });
  }
};

// Factory that will create a button at run time.
TheaterMode.Factory = class {
  create(rootElement, controls) {
    return new TheaterMode(rootElement, controls);
  }
};

export default TheaterMode;
