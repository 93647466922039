import { polygon, polygonMumbai } from 'wagmi/chains';

const server_base_url = 'https://mirror-uat.call4blessing.com:4455';

const config = {
  development: {
    ACTIVE_CHAIN: polygonMumbai,
    project_name: 'Mirror-UAT',
    project_name_short: 'Mirror-UAT',
    project_domain: 'www.mirror-uat.call4blessing.com',
    project_domain_link: 'https://mirror-uat.call4blessing.com',
    mode: 'development',
    dark_logo: `${server_base_url}/images/dark-logo.svg`,
    light_logo: `${server_base_url}/images/light-logo.svg`,
    short_logo: `${server_base_url}/images/short-logo.svg`,
    disabled_logo: `${server_base_url}/images/disabled-logo.png`,
    default_channel_banner:
      `${server_base_url}/images/default-channel-banner.png`,
    PINATA_API_KEY: process.env.REACT_APP_PINATA_API_KEY,
    PINATA_API_SECRET: process.env.REACT_APP_PINATA_API_SECRET,
    WALLET_CONNECT_PROJECT_ID: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
        polygonscan_base_url: 'https://mumbai.polygonscan.com',
    enabled_pinata: false,
limits: {
      trendingVideos: 40,
      newVideos: 40,
      popularChannels: 40,
      videoOfDay: 40,
      videoOfWeek: 40,
      videoOfMonth: 40,
      videoOfYear: 40,
      categoryVideos: 40,
      videoSearched: 40,
subscribedChannelVideos: 40,
      subscribedChannels: 40,
    },
  },
  production: {
    ACTIVE_CHAIN: polygon,
    project_name: 'Mirror-UAT',
    project_name_short: 'Mirror-UAT',
    project_domain: 'www.mirror-uat.call4blessing.com',
    project_domain_link: 'https://mirror-uat.call4blessing.com',
    mode: 'production',
    dark_logo: `${server_base_url}/images/dark-logo.svg`,
    light_logo: `${server_base_url}/images/light-logo.svg`,
    short_logo: `${server_base_url}/images/short-logo.svg`,
    disabled_logo: `${server_base_url}/images/disabled-logo.png`,
    default_channel_banner:
      `${server_base_url}/images/default-channel-banner.png`,
    PINATA_API_KEY: process.env.REACT_APP_PINATA_API_KEY,
    PINATA_API_SECRET: process.env.REACT_APP_PINATA_API_SECRET,
    WALLET_CONNECT_PROJECT_ID: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
    polygonscan_base_url: 'https://mumbai.polygonscan.com',
    enabled_pinata: false,
  limits: {
      trendingVideos: 40,
      newVideos: 40,
      popularChannels: 40,
      videoOfDay: 40,
      videoOfWeek: 40,
      videoOfMonth: 40,
      videoOfYear: 40,
      categoryVideos: 40,
      videoSearched: 40,
      subscribedChannelVideos: 40,
      subscribedChannels: 40,
    },
  },
};

export default config['production'];
