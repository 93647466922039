import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import VideoCardList from './VideoCardList';
import SectionHeader from 'src/components/atoms/SectionHeader/SectionHeader';
import Constant, { CLIENT_URL } from 'src/constant';
import {
  useFetchVideoByIdQuery,
  useRecommendVideosByVideoIdQuery,
  // useTrendingVideosQuery,
} from 'src/redux/features/videos/videoApi';
import { useDispatch } from 'react-redux';
import {
  checkVideoReported,
  setNextVideo,
} from 'src/redux/features/videos/videoSlice';
import calculateDuration from 'src/utils/calculate-duration';
import { toast } from 'react-toastify';
import handleError from 'src/utils/handle-error';
import { handleOpenShare } from 'src/redux/features/share/shareSlice';
import messages from 'src/constant/messages';
import { openReportVideoModal } from 'src/redux/features/report-video/reportVideoSlice';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { useAddWatchLaterMutation } from 'src/redux/features/watch-later/watchLaterApi';
import Loader from 'src/components/atoms/Loader';

// const TrendingVideoInRecommendation = () => {
//   const { video_url_id } = useParams();
//   const [offset, setOffset] = useState(0);

//   const limit = 10;

//   useEffect(() => {
//     setOffset(0);
//   }, [video_url_id]);

//   const { isSuccess, data, isLoading } = useTrendingVideosQuery({
//     limit,
//     offset,
//   });

//   return (
//     <>
//       <div className='single-video-right mt-4'>
//         {isLoading ? (
//           <div className='d-flex justify-content-center'>
//             <Spinner animation='border' className='' />
//           </div>
//         ) : isSuccess && !!data?.videos?.length ? (
//           <InfiniteScroll
//             dataLength={data.videos.length}
//             next={() => setOffset((prev) => prev + limit)}
//             hasMore={data?.total_result_count > data.videos.length}
//             loader={
//               <div className='d-flex justify-content-center'>
//                 <Spinner animation='border' className='' />
//               </div>
//             }
//             endMessage={
//               <p style={{ textAlign: 'center' }}>
//                 {/* <h6 className='text-center h6 mt-4'>Yup, You have seen all</h6> */}
//               </p>
//             }
//             className='overflow-hidden'
//           >
//             <Row>
//               <Col md={12}>
//                 {/* <AdBlock /> */}
//                 <SectionHeader heading='Trending Videos' />
//               </Col>
//               <Col md={12} className='mt-2'>
//                 {data?.videos?.map((video) => (
//                   <VideoCardList
//                     key={`recommended-video-${video.id}`}
//                     imgSrc={
//                       video.thumbnail_url ||
//                       `${Constant.BASE_URL}img/light/default-thumbnail.png`
//                     }
//                     videoHref={`${Constant.BASE_URL}watch/${video.video_url_id}`}
//                     time={
//                       video.duration > 0
//                         ? moment.utc(video.duration * 1000).format('HH:mm:ss')
//                         : null
//                     }
//                     videoTitle={video.title}
//                     views={video.views || 0}
//                     timeAgo={moment(video.created_date).fromNow()}
//                     channelName={video.channel_name}
//                     channelHref={`${Constant.BASE_URL}channel/${video.channel_id}`}
//                     verified={video?.verified}
//                   />
//                 ))}
//                 {/* <AdBlock /> */}
//               </Col>
//             </Row>
//           </InfiniteScroll>
//         ) : (
//           <h6 className='text-center h6'>No recommendation for this video</h6>
//         )}
//       </div>
//     </>
//   );
// };

const SingleVideoRight = () => {
  const { video_url_id } = useParams();
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const { data: videoData } = useFetchVideoByIdQuery(video_url_id);
  const [addWatchLater] = useAddWatchLaterMutation();

  const limit = 50;
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(0);
  }, [video_url_id]);

  const { data, isLoading, isSuccess } = useRecommendVideosByVideoIdQuery(
    {
      videoId: videoData?.id,
      limit,
      offset,
    },
    { skip: !videoData?.id }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data?.hits?.length > 0) {
      dispatch(setNextVideo(data.hits[0]));
    }
  }, [data, dispatch]);

  return (
    <>
      <div className='single-video-right'>
        {
          isLoading ? (
            <Loader />
          ) : isSuccess && !!data?.hits?.length ? (
            <InfiniteScroll
              dataLength={data.hits.length}
              next={() => setOffset((prev) => prev + limit)}
              // hasMore={true} // alway true because in the backend we are also adding trending video if recommand list ended
              hasMore={data?.total_result_count > data.hits.length}
              loader={<Loader />}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  {/* <b>Yay! You have seen it all</b> */}
                </p>
              }
              className='overflow-hidden'
            >
              <Row>
                <Col md={12}>
                  {/* <AdBlock /> */}
                  <SectionHeader heading='Up Next' />
                </Col>
                <Col md={12} className='mt-2'>
                  {data?.hits?.map((video, idx) => (
                    <VideoCardList
                      key={`recommended-video-${idx}`}
                      imgSrc={
                        video.thumbnail_url ||
                        `${Constant.BASE_URL}img/light/default-thumbnail.png`
                      }
                      videoHref={`${Constant.BASE_URL}watch/${video.video_url_id}`}
                      time={calculateDuration(video.duration)}
                      videoTitle={video.title}
                      views={video.views || 0}
                      timeAgo={moment(video.created_date).fromNow()}
                      channelName={video.channel_name}
                      channelHref={`${Constant.BASE_URL}channel/${video.handle_name}`}
                      verified={video?.verified}
                      onAddWatchLater={async () => {
                        try {
                          const response = await addWatchLater({
                            videoId: video.id,
                            videoUrlId: video.video_url_id,
                          }).unwrap();
                          toast.success(response.message);
                        } catch (error) {
                          // toast.error(error.message);
                          handleError(error);
                        }
                      }}
                      onShare={() =>
                        dispatch(
                          handleOpenShare({
                            shareLink: `${CLIENT_URL}/watch/${video.video_url_id}`,
                          })
                        )
                      }
                      onReportVideo={async () => {
                        if (!authData)
                          return toast.error(messages.require_signin_to_report);
                        if (video.is_reported)
                          return toast.info(messages.video_reported_already);

                        try {
                          const report = await dispatch(
                            checkVideoReported(video.id)
                          ).unwrap();
                          const is_reported = report.data.is_reported;

                          if (is_reported)
                            return toast.info(messages.video_reported_already);

                          dispatch(
                            openReportVideoModal({
                              contractVideoId: video.contract_video_id,
                              videoId: video.id,
                              videoUrlId: video.video_url_id,
                            })
                          );
                        } catch (error) {
                          handleError(error);
                        }
                      }}
                    />
                  ))}
                  {/* <AdBlock /> */}
                </Col>
              </Row>
            </InfiniteScroll>
          ) : null
          // <h6 className='text-center h6'>No recommendation for this video</h6>
        }
        {/* {isSuccess && <TrendingVideoInRecommendation />} */}
      </div>
    </>
  );
};

function AdBlock() {
  return (
    <>
      <div className='adblock'>
        <div className='img'>
          Google AdSense
          <br />
          336 x 280
        </div>
      </div>
    </>
  );
}

export default SingleVideoRight;
export { AdBlock };
