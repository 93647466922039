import React, { useCallback, useState } from 'react';
import Slider from 'react-slick';
import { Col, Row } from 'react-bootstrap';

import Constant from 'src/constant';
import { useGetAdBannersQuery } from 'src/redux/features/basic/basicApi';

function SampleNextArrow(props) {
  return null;
  // return (
  //   <button
  //     className='custom-home-slick-btn custom-home-slick-next'
  //     onClick={props.onClick}
  //   >
  //     <img
  //       className='img-fluid'
  //       alt='next'
  //       src={`${Constant.BASE_URL}img/right.svg`}
  //     />
  //   </button>
  // );
}

function SamplePrevArrow(props) {
  return;
  // return (
  //   <button
  //     className='custom-home-slick-btn custom-home-slick-prev'
  //     onClick={props.onClick}
  //   >
  //     <img
  //       className='img-fluid'
  //       alt='prev'
  //       src={`${Constant.BASE_URL}img/left.svg`}
  //     />
  //   </button>
  // );
}

const bannerData = [
  {
    description:
      'We’re Bringing Our Passion For Ultimate Customer Satisfaction With The Best Quality Videos.',
    ad_banner_url: `${Constant.BASE_URL}img/banner.png`,
    title: 'A Jaw-Dropping Video Experience!',
  },
  {
    description:
      'We’re Bringing Our Passion For Ultimate Customer Satisfaction With The Best Quality Videos.',
    ad_banner_url: `${Constant.BASE_URL}img/banner.png`,
    title: 'A Jaw-Dropping Video Experience!',
  },
];

const HomeCarousel = () => {
  const { data, isLoading } = useGetAdBannersQuery();

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  var settings = {
    rows: 1,
    // variableWidth: true,
    dots: true,
    dotsClass: `slick-dots slick-dots-custom`,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // responsive: [
    //   {
    //     breakpoint: 2700,
    //     settings: {
    //       slidesToShow: 8,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1700,
    //     settings: {
    //       slidesToShow: 6,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1200,
    //     settings: {
    //       slidesToShow: 5,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1000,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 400,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    // ],
  };

  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const handleOnItemClick = useCallback(
    (e, link) => {
      if (dragging) {
        e.stopPropagation();
      } else {
        window.open(link, '_blank');
      }
    },
    [dragging]
  );

  return (
    <div className='home-carousel-root'>
      <Slider
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        {...settings}
      >
        {data?.map(({ ad_banner_url, title, description, id, link }) => (
          <div
            className='home-carousel cursor-pointer'
            key={`home-carousel-${id}`}
            onClick={(e) => handleOnItemClick(e, link)}
          >
            <div
              className='content'
              style={{
                backgroundImage: `url(${ad_banner_url})`,
              }}
            >
              <div className='overlay' />
              <div
                // xl={6}
                // lg={6}
                // md={10}
                // sm={10}
                // xs={12}
                // style={{ padding: '0 8%' }}
                className='content-of'
                style={{ marginLeft: '20px', width: '50%', zIndex: 1 }}
              >
                <h6>{title} </h6>
                <p>{description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {/*  */}
      {/* <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src={`${Constant.BASE_URL}img/banner.png`}
            alt='First slide'
          />
          <Carousel.Caption>
            <h3>A Jaw-Dropping Video Experience!</h3>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam,.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src={`${Constant.BASE_URL}img/banner.png`}
            alt='First slide'
          />
          <Carousel.Caption>
            <h3>A Jaw-Dropping Video Experience!</h3>
            <p>
              We’re Bringing Our Passion For Ultimate Customer Satisfaction With
              The Best Quality Videos.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src={`${Constant.BASE_URL}img/banner.png`}
            alt='First slide'
          />
          <Carousel.Caption>
            <h3>A Jaw-Dropping Video Experience!</h3>
            <p>
              We’re Bringing Our Passion For Ultimate Customer Satisfaction With
              The Best Quality Videos.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel> */}
    </div>
  );
};

export default HomeCarousel;
