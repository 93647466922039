import { Link } from 'react-router-dom';
import './FatFooter.css';

import Container from 'react-bootstrap/Container';
import Constant from 'src/constant';

const ThinFooter = () => {
  return (
    <>
      <footer className='sticky-footer'>
        <div className='footer-container'>
          <p className='mb-0'>
            &copy;Copyright 2023{' '}
            <strong className='text-clr-dark-300'>Mirror</strong>. All Rights
            Reserved
            <br />
          </p>
          <p className='mb-0'>
            <Link to={`${Constant.BASE_URL}terms`} className='mr-1'>
              Terms & Conditions.
            </Link>
            <Link to={`${Constant.BASE_URL}privacy-policy`}>
              Privacy Policy.
            </Link>
          </p>
          {/* <p className='mb-0'>Terms & Conditions. Privacy Policy. FAQ’s</p> */}
          {/* <Row className='no-gutters'>
            <Col lg={6} sm={6}>
              <p className='mt-1 mb-0'>
                &copy; Copyright 2020{' '}
                <strong className='text-dark'>Vidoe</strong>. All Rights
                Reserved
                <br />
                <small className='mt-0 mb-0'>
                  Made with{' '}
                  <span className='text-danger-custom'>
                    <FontAwesomeIcon icon={faHeart} />
                  </span>{' '}
                  by{' '}
                  <a
                    className='text-primary-custom'
                    target='_blank'
                    href='https://askbootstrap.com/'
                    rel='noreferer'
                  >
                    Ask Bootstrap
                  </a>
                </small>
              </p>
            </Col>
            <Col lg={6} sm={6} className='text-right'>
              <div className='app'>
                <a href='#' className='mr-1'>
                  <img alt='' src={`${Constant.BASE_URL}img/google.png`} />
                </a>
                <a href='#'>
                  <img alt='' src={`${Constant.BASE_URL}img/apple.png`} />
                </a>
              </div>
            </Col>
          </Row> */}
        </div>
      </footer>
    </>
  );
};

export default ThinFooter;
