import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row } from 'react-bootstrap';

import {
  useDeleteChannelBrandingMutation,
  useFetchMyChannelQuery,
  useUpdateChannelBrandingMutation,
} from '../../../redux/features/channels/channelApi';
import './Customization.css';
import {
  AvatarCropModal,
  BannerCropModal,
} from '../../../components/atoms/modals';
import Avatar from '../../../components/atoms/Avatar';
import Constant from '../../../constant';
import handleError from 'src/utils/handle-error';

const maxAvatarFileSize = 4 * 1048576; // 4 MB
const maxBannerFileSize = 6 * 1048576; // 6 MB

const SUPPORT_FILE_TYPE = ['image/jpeg', 'image/png'];

const validateAvatar = Yup.mixed()
  .test(
    'fileType',
    'Invalid file type (supported file type jpeg and png)',
    (value) => !value || (value && SUPPORT_FILE_TYPE.includes(value.type))
  )
  .test(
    'fileSize',
    'File size is too large (4MB or less)',
    (value) => !value || (value && value.size <= maxAvatarFileSize)
  )
  .test(
    'dimensions',
    'Avatar dimensions should be atleast 98x98 pixels',
    (value) => {
      if (!value) return true; // Skip validation if no file is selected

      return new Promise((resolve, reject) => {
        const image = new Image();
        const reader = new FileReader();

        reader.onload = function (event) {
          image.onload = function () {
            if (image.width >= 98 && image.height >= 98) resolve(true);
            else resolve(false);
          };
          image.src = event.target.result;
        };

        reader.readAsDataURL(value);
      });
    }
  );

const validateBanner = Yup.mixed()
  .test('fileSize', 'File size is too large (6MB or less)', (value) => {
    return !value || (value && value.size <= maxBannerFileSize);
  })
  .test(
    'fileType',
    'Invalid file type',
    (value) => !value || (value && SUPPORT_FILE_TYPE.includes(value.type))
  )
  .test(
    'dimensions',
    'Banner dimensions should be atleast 2048x1152  pixels',
    (value) => {
      if (!value) return true; // Skip validation if no file is selected

      return new Promise((resolve, reject) => {
        const image = new Image();
        const reader = new FileReader();

        reader.onload = function (event) {
          image.onload = function () {
            if (image.width >= 2048 && image.height >= 1152) resolve(true);
            else resolve(false);
          };
          image.src = event.target.result;
        };

        reader.readAsDataURL(value);
      });
    }
  );

const validationSchema = Yup.object().shape({
  avatar: validateAvatar,
  banner: validateBanner,
  deleteAvatar: Yup.boolean(),
  deleteBanner: Yup.boolean(),
});

const Branding = () => {
  const bannerRef = useRef(null);
  const profileRef = useRef(null);
  const [isAvatarCropModal, setIsAvatarCropModal] = useState(false);
  const [isBannerCropModal, setIsBannerCropModal] = useState(false);

  const { data: channel } = useFetchMyChannelQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [updateChannelBranding, { isLoading: updateIsLoading }] =
    useUpdateChannelBrandingMutation();
  const [deleteChannelBranding, { deleteIsLoading }] =
    useDeleteChannelBrandingMutation();

  return (
    <div className='py-3 py-md-4 px-3 px-md-4' id='branding'>
      <Formik
        initialValues={{
          avatar: '',
          banner: '',
          deleteAvatar: false,
          deleteBanner: false,
        }}
        onSubmit={async (values, { resetForm }) => {
          if (values.avatar || values.banner) {
            const formData = new FormData();
            formData.append('avatar', values.avatar);
            formData.append('banner', values.banner);
            const data = await updateChannelBranding(formData).unwrap();
            toast.success(data?.message || 'Successfully Updated.');
          }
          if (values.deleteAvatar || values.deleteBanner) {
            const data = await deleteChannelBranding({
              avatar: values.deleteAvatar,
              banner: values.deleteBanner,
            }).unwrap();
            toast.success(data?.message || 'Successfully Updated.');
          }
          resetForm();
        }}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          handleBlur,
          handleChange,
          errors,
          touched,
          values,
          isValid,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={10}>
                {/* - Avatar crop modal */}
                <AvatarCropModal
                  show={isAvatarCropModal}
                  file={values.avatar}
                  handleClose={() => setIsAvatarCropModal(false)}
                  handleSaveFile={(file) => {
                    setFieldValue('avatar', file);
                    setIsAvatarCropModal(false);
                  }}
                />
                <Form.Group controlId='channel-profile'>
                  <Form.Label className='mb-0'>Picture</Form.Label>
                  <p className='mb-4'>
                    Upload your profile picture for your fans/viewers to find
                    you instantly. It will appear on your channel and just next
                    to your uploaded videos and comments.
                  </p>
                  <div
                    className='d-flex  flex-wrap flex-md-nowrap'
                    style={{ gap: '2rem' }}
                  >
                    <div className='avatar-preview-container'>
                      <ProfilePreview
                        file={values.avatar}
                        fileUrl={channel?.avatar_url}
                      />
                      {errors.avatar && (
                        <Form.Text className='text-danger fs-200 fw-400 mt-1'>
                          {errors.avatar}
                        </Form.Text>
                      )}
                    </div>
                    <div>
                      <Form.Control
                        size='lg'
                        type='file'
                        hidden
                        ref={profileRef}
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          try {
                            await validateAvatar.validate(file);
                            setFieldValue('avatar', file);
                            setIsAvatarCropModal(true);
                          } catch (error) {
                            handleError(error);
                          }
                        }}
                      />
                      <p>
                        It’s recommended to use a picture that’s at least 98 x
                        98 pixels and 4MB or less. Use a PNG or GIF (no
                        animations) file. Make sure your picture follows the
                        Mirror Community Guidelines.{' '}
                      </p>
                      {!values.deleteAvatar &&
                      (channel?.avatar_url || values.avatar) ? (
                        <>
                          <Button
                            className='mt-4'
                            variant='light'
                            onClick={() => {
                              if (values.avatar) setFieldValue('avatar', '');
                              else setFieldValue('deleteAvatar', true);
                            }}
                          >
                            Remove
                          </Button>
                          <Button
                            className='mt-4 ml-4'
                            onClick={() => {
                              if (values.deleteAvatar)
                                setFieldValue('deleteAvatar', false);
                              profileRef.current.click();
                            }}
                            // disabled={values.avatar}
                          >
                            Change
                          </Button>
                        </>
                      ) : (
                        <Button
                          className='mt-4'
                          onClick={() => {
                            if (values.deleteAvatar)
                              setFieldValue('deleteAvatar', false);
                            profileRef.current.click();
                          }}
                          // disabled={values.avatar}
                        >
                          Upload
                        </Button>
                      )}
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={10}>
                {/* - Banner crop modal */}
                <BannerCropModal
                  show={isBannerCropModal}
                  file={values.banner}
                  handleClose={() => setIsBannerCropModal(false)}
                  handleSaveFile={(file) => {
                    setFieldValue('banner', file);
                    setIsBannerCropModal(false);
                  }}
                />
                <Form.Group controlId='channel-banner'>
                  <Form.Label className='mb-0'>Banner image</Form.Label>
                  <p className='mb-4'>
                    Add a banner image to your Mirror profile. The banner image
                    will give your channel a credibility and your viewers will
                    find you easily.
                  </p>
                  <div
                    className='d-flex  flex-wrap flex-md-nowrap'
                    style={{ gap: '2rem' }}
                  >
                    <div className='banner-preview-container'>
                      <BannerPreview
                        file={values.banner}
                        fileUrl={values.deleteBanner ? '' : channel?.banner_url}
                      />
                      {errors.banner && (
                        <Form.Text className='text-danger fs-200 fw-400 mt-1'>
                          {errors.banner}
                        </Form.Text>
                      )}
                    </div>
                    <div>
                      <Form.Control
                        size='lg'
                        type='file'
                        hidden
                        ref={bannerRef}
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          try {
                            await validateBanner.validate(file);
                            setFieldValue('banner', e.target.files[0]);
                            setIsBannerCropModal(true);
                          } catch (error) {
                            handleError(error);
                          }
                        }}
                      />
                      <p>
                        For the best results on all devices, use an image that’s
                        at least 2048 x 1152 pixels and 6MB or less.
                      </p>
                      {!values.deleteBanner &&
                      (channel?.banner_url || values.banner) ? (
                        <>
                          <Button
                            className='mt-4'
                            variant='light'
                            onClick={() => {
                              if (values.banner) setFieldValue('banner', '');
                              else setFieldValue('deleteBanner', true);
                            }}
                          >
                            Remove
                          </Button>
                          <Button
                            className='mt-4 ml-4'
                            onClick={() => {
                              if (values.deleteBanner)
                                setFieldValue('deleteBanner', false);
                              bannerRef.current.click();
                            }}
                            // disabled={values.banner}
                          >
                            Change
                          </Button>
                        </>
                      ) : (
                        <Button
                          className='mt-4'
                          onClick={() => {
                            if (values.deleteBanner)
                              setFieldValue('deleteBanner', false);
                            bannerRef.current.click();
                          }}
                          // disabled={values.banner}
                        >
                          Upload
                        </Button>
                      )}
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col md={12}>
                <hr className='clr-white-card-hr' />
              </Col>
              <Col md={12} className='d-flex'>
                <Button
                  variant='outlined'
                  className='ml-auto px-4'
                  onClick={resetForm}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  className='ml-4 px-4'
                  type='submit'
                  disabled={
                    deleteIsLoading ||
                    updateIsLoading ||
                    !isValid ||
                    !Object.values(values).some((val) => !!val)
                  }
                >
                  Publish
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const ProfilePreview = ({ file, fileUrl }) => {
  const [imageUrl, setImageUrl] = useState(fileUrl);
  const { data: channel } = useFetchMyChannelQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });

  const loadFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageUrl(reader.result);
    };
  };

  useEffect(() => {
    if (file) loadFile(file);
    else if (fileUrl) setImageUrl(fileUrl);
    else setImageUrl(null);
  }, [file, fileUrl]);

  return (
    <div className='preview gray-box'>
      {imageUrl ? (
        <img src={imageUrl} alt='profile' className='profile-preview-img' />
      ) : (
        <Avatar size={140} character={channel?.name[0]} />
      )}
    </div>
  );
};

const BannerPreview = ({ file, fileUrl }) => {
  const [imageUrl, setImageUrl] = useState('');
  const loadFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageUrl(reader.result);
    };
  };

  useEffect(() => {
    if (fileUrl) setImageUrl(fileUrl);
    else if (file) loadFile(file);
    else setImageUrl(null);
  }, [file, fileUrl]);

  return (
    <div className='preview gray-box'>
      <img
        src={
          imageUrl || `${Constant.BASE_URL}img/illustration/banner-design.png`
        }
        alt='profile'
        className='banner-preview-img'
      />
    </div>
  );
};

export default Branding;
