import { toast } from 'react-toastify';
import { TRANSACTION_MESSAGE } from 'src/constant/messages';
import {
  BaseError as ContractBaseError,
  InsufficientFundsError,
  UserRejectedRequestError,
} from 'viem';

const handleError = (error, msg) => {
  // type - contract error
  const isContractBaseError =
    error.walk && error.walk((e) => e instanceof ContractBaseError);
  console.log(error.message, 'error ---');

  if (isContractBaseError) {
    const isInsufficientFundsError = error.walk(
      (e) => e instanceof InsufficientFundsError
    );
    const isUserRejectedRequestError = error.walk(
      (e) => e instanceof UserRejectedRequestError
    );
    if (error.message.includes('caller is not the owner')) {
      toast.error('Ownable: caller is not the owner');
    } else if (error.message.includes('AlreadyLiked')) {
      toast.error(TRANSACTION_MESSAGE.ALREADY_LIKED);
    } else if (error.message.includes('Already')) {
      toast.error(TRANSACTION_MESSAGE.ALREADY);
    } else if (
      error.message.includes('transaction exceeds the balance of the account.')
    ) {
      toast.error(TRANSACTION_MESSAGE.INSUFFICIENT_FUND);
    } else if (isUserRejectedRequestError) {
      toast.error(TRANSACTION_MESSAGE.METAMASKREQUESTREJECT);
    } else if (isInsufficientFundsError) {
      toast.error(TRANSACTION_MESSAGE.INSUFFICIENT_FUND);
    } else if (error.message.includes('could not be found.')) {
      toast.error(
        'Transaction with hash "0xxxxxxxxxxxxxxxxxxxxx" could not be found. '
      );
    }
toast.error(error?.response?.data?.message || error.message || msg);
    return;
  }
  toast.error(error?.response?.data?.message || error.message || msg);
};

export default handleError;
