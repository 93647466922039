import InfiniteScroll from 'react-infinite-scroll-component';
import { useState } from 'react';

import { useVideosByHandleNameQuery } from 'src/redux/features/videos/videoApi';
import VideoBlock from 'src/components/VideoBlock';
import Loader from 'src/components/atoms/Loader';

const ChannelVideos = ({ handle_name }) => {
  const limit = 36;
  const [offset, setOffset] = useState(0);
  const { data, isSuccess, isLoading } = useVideosByHandleNameQuery({
    handle_name,
    limit,
    offset,
  });

  return (
    <>
      <div className='video-block section-padding '>
        {data?.videos && data?.videos?.length === 0 && (
          <h6 className='text-center h6'>This list has no videos</h6>
        )}
        {isLoading && <Loader />}
        {isSuccess && !!data?.videos?.length && (
          <InfiniteScroll
            dataLength={data.videos?.length || 0} //This is important field to render the next data
            next={() => setOffset((prev) => prev + limit)}
            hasMore={data?.total_result_count > data.videos?.length}
            loader={<Loader />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
            className='overflow-hidden'
          >
            <VideoBlock data={data.videos || []} />
          </InfiniteScroll>
        )}
      </div>
      {/* {videos?.length && <Paginate />} */}
    </>
  );
};

export default ChannelVideos;
