import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { abbreviateNumber } from 'js-abbreviation-number';

import ShowMoreButton from 'src/components/atoms/ShowMoreButton/ShowMoreButton';
import Constant from 'src/constant';
import { Button } from 'react-bootstrap';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import { Tag, TagCloud } from '../Blog/TagCloud';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const minText = 200;
const VideoDescription = ({
  views = 0,
  tags = [],
  descriptionText,
  categoryText,
  createdDate,
}) => {
  const [more, setMore] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setMore(false);
    // return () => {
    //   console.log(more, 'return of more');
    //   setMore(false);
    // };
  }, [descriptionText]);
  
  return (
    <>
      <div
        onClick={() => !more && setMore(true)}
        className='single-video-info-content box rounded-sm'
      >
        <div className='d-flex align-items-center flex-wrap'>
          <h6 className='fs-400 fw-600 mb-1'>
            {abbreviateNumber(views, 1) || 'No'} Views |{' '}
            {moment(createdDate).fromNow()}
          </h6>
          {categoryText && (
            <Button
              variant='link'
              className='ml-3'
              style={{ backgroundColor: '#FFF2F4', color: '#4B4B4B' }}
              size='sm'
              onClick={() => {
                history.push(
                  `${Constant.BASE_URL}videos/categories/${categoryText}`
                );
              }}
            >
              {categoryText}
            </Button>
          )}
          <div className='d-flex align-items-center flex-wrap'>
            {tags &&
              tags?.map((hashTag) => (
                <Link
                  className='fs-300 fw-600 mb-1 ml-2'
                  key={hashTag}
                  to={`${Constant.BASE_URL}results?search_query=${hashTag}`}
                >
                  #{hashTag}
                </Link>
              ))}
          </div>
        </div>
        {/* <DescriptionBlock
          heading='Cast'
          body='Nathan Drake , Victor Sullivan , Sam Drake , Elena
						Fisher'
        /> */}
        {/* <CKEditor
          editor={ClassicEditor}
          data={videoData?.description}
          disabled={true}
          config={{
            toolbar: [],
            readOnly: true,
          }}
        /> */}
        <div
          // style={{
          //   overflow: 'hidden',
          //   maxHeight: more ? '100%' : '60px',
          // }}
          className={`mt-2 description-box ${more ? '' : 'comment-body'}`}
          // dangerouslySetInnerHTML={{
          //   __html: more
          //     ? videoData?.description
          //     : videoData?.description.length > minText
          //     ? `${videoData?.description.slice(0, minText)}....`
          //     : videoData?.description,
          // }}
          dangerouslySetInnerHTML={{
            __html: descriptionText,
          }}
        />
        {/* {videoData?.description?.length > minText && ( */}
        {descriptionText && (
          <>
            <ShowMoreButton
              className='mt-2'
              showMore={more}
              handleChange={() => setMore(!more)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(VideoDescription);
function DescriptionBlock({ heading, body }) {
  return (
    <>
      <h6 className='mt-2'>{heading}</h6>
      <p>{body}</p>
    </>
  );
}
