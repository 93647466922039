// import UilHome from '@iconscout/react-unicons/icons/uil-home';
// import UilClock from '@iconscout/react-unicons/icons/uil-clock';
// import UilLike from '@iconscout/react-unicons/icons/uil-thumbs-up';
// import UilHistory from '@iconscout/react-unicons/icons/uil-history';
// import UilDownload from '@iconscout/react-unicons/icons/uil-download-alt';
// import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
// import UilHelp from '@iconscout/react-unicons/icons/uil-question-circle';
import { useHistory } from 'react-router-dom';

import NavItem from './NavItem.component';

import Constant from '../../constant';
import { useContext, useLayoutEffect } from 'react';
import { SidebarContext } from '../../App';
import { useAuthCheckQuery } from '../../redux/features/auth/authApi';
import {
  HelpSvg,
  HistorySvg,
  HomeSvg,
  LikedSvg,
  SettingSvg,
  SubscribeSvg,
  TrendingSvg,
  WatchLaterSvg,
  StarSvg,
  SpeakerSvg,
  DaySvg,
  WeekSvg,
  MonthSvg,
  YearSvg,
  ShortSvg,
} from 'src/assets/svgs';
import { useDispatch, useSelector } from 'react-redux';
import { useGetShortsQuery } from 'src/redux/features/videos/videoApi';
import { fetchShorts } from 'src/redux/features/videos/shortSlice';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ProfileSvg } from 'src/assets/svgs/profile-dropdown';
const Sidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { openSidebar, setOpenSidebar } = useContext(SidebarContext);
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [shortUrl, setShortUrl] = useState(null);
  // load shorts
  const { limit } = useSelector((state) => state.shortStore);
  // let { data } = useGetShortsQuery({ limit, offset });

  useEffect(() => {
    const init = async () => {
      try {
        const result = await dispatch(
          fetchShorts({
            limit,
            offset: 0,
            token: authData?.token,
          })
        ).unwrap();
        if (result?.data?.videos[0]?.video_url_id) {
          setShortUrl(result.data.videos[0].video_url_id);
        }
      } catch (error) {
        toast.error(error.message || '');
      }
    };
    if (!shortUrl) init();
  }, []);

  useLayoutEffect(() => {
    if (window.innerWidth < 575.98) setOpenSidebar(true);
    else if (openSidebar) setOpenSidebar(false);
  }, []);

  const sidebarClass = openSidebar
    ? 'sidebar navbar-nav toggled'
    : 'sidebar navbar-nav';

  // useEffect(() => {
  //   const navItems = document.querySelectorAll('.sidebar li');
  //   console.log(navItems, 'navitem');

  //   navItems.forEach((item) => {
  //     item.addEventListener('click', (e) => {
  //       setOpenSidebar(true);
  //     });
  //   });
  // }, []);

  return (
    <ul className={sidebarClass}>
      <NavItem
        href={`${Constant.BASE_URL}`}
        exact={true}
        SvgIcon={HomeSvg}
        label='Home'
      />
      {shortUrl && (
        <NavItem
          href={`${Constant.BASE_URL}shorts/${shortUrl}`}
          SvgIcon={ShortSvg}
          label='Shorts'
          onClick={async () => {
            history.push(`${Constant.BASE_URL}shorts/${shortUrl}`);
          }}
        />
      )}
      <NavItem
        href={`${Constant.BASE_URL}subscriptions`}
        SvgIcon={SubscribeSvg}
        exact={true}
        label='Subscriptions'
      />
      <NavItem
        href={`${Constant.BASE_URL}trendingvideos`}
        SvgIcon={TrendingSvg}
        // exact={true}
        label='Trending Videos'
      />
      <NavItem
        href={`${Constant.BASE_URL}newvideos`}
        SvgIcon={SpeakerSvg}
        exact={true}
        label='New Videos'
      />
      <NavItem
        href={`${Constant.BASE_URL}popularchannels`}
        SvgIcon={StarSvg}
        exact={true}
        label='Popular Channels'
      />
      <NavItem
        href={`${Constant.BASE_URL}day`}
        SvgIcon={DaySvg}
        exact={true}
        label='Videos of the Day'
      />
      <NavItem
        href={`${Constant.BASE_URL}week`}
        SvgIcon={WeekSvg}
        exact={true}
        label='Videos of the Week'
      />
      <NavItem
        href={`${Constant.BASE_URL}month`}
        SvgIcon={MonthSvg}
        exact={true}
        label='Videos of the Month'
      />
      <NavItem
        href={`${Constant.BASE_URL}year`}
        SvgIcon={YearSvg}
        exact={true}
        label='Videos of the Year'
      />
      {/* <NavItem
          SvgIcon={VideoCircleSvg}
          label='Top Videos'
          dropdown
          dropdownItems={[
            {
              id: 1,
              label: 'Video of the Day',
              href: `${Constant.BASE_URL}day`,
            },
            {
              id: 2,
              label: 'Video of the Week',
              href: `${Constant.BASE_URL}week`,
            },
            {
              id: 3,
              label: 'Video of the Month',
              href: `${Constant.BASE_URL}month`,
            },
          ]}
        /> */}
      <hr className='w-100' style={{ backgroundColor: '#fff' }} />
      {authData?.token && (
        <>
          <NavItem
            href={`${Constant.BASE_URL}history`}
            SvgIcon={HistorySvg}
            label='History'
          />
          <NavItem
            href={`${Constant.BASE_URL}liked-videos`}
            SvgIcon={LikedSvg}
            label='Liked Videos'
          />
          <NavItem
            href={`${Constant.BASE_URL}watch-later`}
            SvgIcon={WatchLaterSvg}
            label='Watch Later'
          />
        </>
      )}
      {authData?.token && (
        <NavItem
          // href={`${Constant.BASE_URL}settings`}
          // SvgIcon={SettingSvg}
          // label='Settings'
          href={`${Constant.BASE_URL}profile`}
          SvgIcon={ProfileSvg}
          label='Profile'
        />
      )}
      <NavItem
        href={`${Constant.BASE_URL}help`}
        imgPath='img/sidebar/help.svg'
        SvgIcon={HelpSvg}
        label='Helps'
      />
    </ul>
  );
};

export default Sidebar;
