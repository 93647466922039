import Slider from 'react-slick';

import Slide from 'src/components/atoms/Slide/Slide';

import Constant from 'src/constant';
import { useFetchCategoriesQuery } from 'src/redux/features/categories/categoriesApi';

function SampleNextArrow(props) {
  return (
    <button
      className='custom-slick-btn custom-slick-next mobile-preview-next'
      onClick={props.onClick}
    >
      <img alt='right-arrow' src={`${Constant.BASE_URL}img/right.svg`} />
    </button>
  );
}

function SamplePrevArrow(props) {
  return (
    <button
      className='custom-slick-btn custom-slick-prev mobile-preview-prev'
      onClick={props.onClick}
    >
      <img src={`${Constant.BASE_URL}img/left.svg`} />
    </button>
  );
}

const categoriesData = [
  {
    href: '#',
    imgSrc: `${Constant.BASE_URL}img/category/calendar-today.svg`,
    label: 'Daily Vlog',
  },
  {
    href: '#',
    imgSrc: `${Constant.BASE_URL}img/category/crypto.svg`,
    label: 'Crypto',
  },
  {
    href: '#',
    imgSrc: `${Constant.BASE_URL}img/category/game-console.svg`,
    label: 'Gaming',
  },
  {
    href: '#',
    imgSrc: `${Constant.BASE_URL}img/category/music.svg`,
    label: 'Music',
  },
  {
    href: '#',
    imgSrc: `${Constant.BASE_URL}img/category/network-globe-line.svg`,
    label: 'Tech',
  },
  {
    href: '#',
    imgSrc: `${Constant.BASE_URL}img/category/finance.svg`,
    label: 'Finance 2.0',
  },
  {
    href: '#',
    imgSrc: `${Constant.BASE_URL}img/category/newspaper.svg`,
    label: 'News',
  },
  {
    href: '#',
    imgSrc: `${Constant.BASE_URL}img/category/ios-baseball-outline.svg`,
    label: 'Sports',
  },
];

export default function TopCategoriesSlider() {
  const { data } = useFetchCategoriesQuery();

  var settings = {
    rows: 1,
    variableWidth: true,
    dots: false,
    infinite: true,
    speed: 500,
    //slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    swipeToSlide: true,
    // autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  // const isContentLessThanContainer = data.length <= settings.slidesToShow;

  return (
    <>
      <Slider {...settings}>
        {data?.map(({ category_url, name, id }) => (
          <Slide
            key={`categories-${id}`}
            href='#'
            imgSrc={category_url}
            label={name}
          />
        ))}
      </Slider>
    </>
  );
}
