import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import './VideoPage.css';
import 'shaka-player/dist/controls.css';
import { useFetchVideoByIdQuery } from 'src/redux/features/videos/videoApi';
import SingleVideoRight from './components/SingleVideoRight';
import SingleVideoLeft from './components/SingleVideoLeft';
import PrivateVideo from './components/PrivateVideo';
import VideoPlayer from './components/VideoPlayer';
import Constant, { PROCESSING_STATUS } from 'src/constant';
import VideoDescription from './components/VideoDescription';
import AuthorBox from './components/AuthorBox';
import VideoTitle from './components/VideoTitle';
import Loader from 'src/components/atoms/Loader';
// import HelmetMetaData from '../atoms/HelmetMetaTags';

const VideoPage = () => {
  const { video_url_id } = useParams();
  const { data: videoData, isLoading } = useFetchVideoByIdQuery(video_url_id);
 
  return (
    <>
      <Container fluid='xxl' className='p-md-4'>
        <div className='video-block-right-list section-padding'>
          {isLoading ? (
            <Loader className='mt-2' />
          ) : videoData?.isPrivate ? (
            <PrivateVideo />
          ) : videoData ? (
            <div className='video-page-grid'>
              <div className='item-player'>
                <div className='single-video'>
                  {/* {videoData.processing_status === PROCESSING_STATUS.DONE ? (
                    <VideoPlayer
                      video_url={videoData.video_url}
                      video_id={videoData.id}
                      watermark_url={videoData.watermark_url}
                      thumbnail_url={videoData.thumbnail_url}
                      handle_name={videoData?.handle_name}
                    />
                  ) : (
                    <>
                      <video
                        src={videoData.video_url}
                        width='100%'
                        style={{ aspectRatio: '16/9' }}
                        autoPlay
                        controls
                        controlsList='nodownload'
                      />
                      {videoData.processing_status ===
                      PROCESSING_STATUS.PROCESSING ? (
                        <p className='bg-warning text-white py-2 px-2 fw-600'>
                          This video is still being processed. Video quality may
                          improve once processing is complete.
                        </p>
                      ) : (
                        <p className='bg-danger text-white py-2 px-2 fw-600'>
                          This video failed to process. Video quality may
                          improve once author retry process again.
                        </p>
                      )}
                    </>
                  )} */}
                  <VideoPlayer
                    video_url={videoData.video_url}
                    video_id={videoData.id}
                    watermark_url={videoData.watermark_url}
                    thumbnail_url={videoData.thumbnail_url}
                    handle_name={videoData?.handle_name}
                  />
                  {videoData.processing_status ===
                  PROCESSING_STATUS.PROCESSING ? (
                    <p className='bg-warning text-white py-2 px-2 fw-600'>
                      This video is still being processed. Video quality may
                      improve once processing is complete.
                    </p>
                  ) : videoData.processing_status === PROCESSING_STATUS.FAIL ? (
                    <p className='bg-danger text-white py-2 px-2 fw-600'>
                      This video failed to process. Video quality may improve
                      once author retry process again.
                    </p>
                  ) : null}
                </div>
              </div>
              <div className='px-2 px-md-0 item-details'>
                <VideoTitle
                  title={videoData?.title}
                  views='2,729,347'
                  visibility={videoData?.visibility}
                />
                <AuthorBox
                  contract_video_id={videoData?.contract_video_id}
                  subscriberCount={videoData?.subscriber_count}
                  imgSrc={videoData?.avatar_url}
                  channelName={videoData?.channel_name}
                  verified={videoData?.verified}
                  publishedOn='Aug 10, 2020'
                  userLiked={videoData?.user_liked}
                  userDisliked={videoData?.user_disliked}
                  likeCount={videoData?.like_count}
                  channelId={videoData?.channel_id}
                  userSubscribed={videoData?.user_subscribed}
                  isWatchLater={videoData?.isWatchLater}
                  videoData={videoData}
                  channelHref={`${Constant.BASE_URL}channel/${videoData?.handle_name}`}
                  visibility={videoData?.visibility}
                  subscribeTo={videoData?.block_address}
                  handle_name={videoData?.handle_name}
                />
                <VideoDescription
                  views={videoData?.views || 0}
                  tags={videoData?.tags ? JSON.parse(videoData.tags) : []}
                  descriptionText={videoData?.description}
                  categoryText={videoData.category_name}
                  createdDate={videoData?.created_date}
                />
              </div>
              <div className='px-2 px-md-0 item-recommendations'>
                <SingleVideoRight />
              </div>
              <div className='px-2 px-md-0 item-comments mt-4'>
                <SingleVideoLeft />
              </div>
            </div>
          ) : (
            <h1>Video Not Found...</h1>
          )}
        </div>
      </Container>
    </>
  );
  // return (
  //   <>
  //     {/* {videoData?.title && (
  //       <HelmetMetaData title={videoData.title} description='new video' />
  //     )} */}
  //     <ContentWrapper>
  //       <Container fluid>
  //         <div className='video-block-right-list section-padding'>
  //           <Row>
  //             {isLoading ? (
  //               <div className='d-flex justify-content-center w-100'>
  //                 <Spinner animation='border' className='' />
  //               </div>
  //             ) : videoData?.isPrivate ? (
  //               <PrivateVideo />
  //             ) : videoData ? (
  //               <>
  //                 <Col lg={8}>
  //                   <div className='single-video'>
  //                     {videoData.processing_status ===
  //                     PROCESSING_STATUS.DONE ? (
  //                       <VideoPlayer
  //                         video_url={videoData.video_url}
  //                         video_id={videoData.id}
  //                         watermark_url={videoData.watermark_url}
  //                         thumbnail_url={videoData.thumbnail_url}
  //                         handle_name={videoData?.handle_name}
  //                       />
  //                     ) : (
  //                       <>
  //                         <video
  //                           src={videoData.video_url}
  //                           width='100%'
  //                           style={{ aspectRatio: '16/9' }}
  //                           autoPlay
  //                           controls
  //                         />
  //                         {videoData.processing_status ===
  //                         PROCESSING_STATUS.PROCESSING ? (
  //                           <p className='bg-warning text-white py-2 px-2 fw-600'>
  //                             This video is still being processed. Video quality
  //                             may improve once processing is complete.
  //                           </p>
  //                         ) : (
  //                           <p className='bg-danger text-white py-2 px-2 fw-600'>
  //                             This video failed to process. Video quality may
  //                             improve once author retry process again.
  //                           </p>
  //                         )}
  //                       </>
  //                       // <VideoInProcessing
  //                       //   processing_status={videoData.processing_status}
  //                       // />
  //                     )}
  //                   </div>
  //                 </Col>
  //                 <Col lg={4} className='mt-4 mt-lg-0'>
  //                   <SingleVideoRight />
  //                 </Col>
  //                 <Col lg={8}>
  //                   <SingleVideoLeft />
  //                 </Col>
  //               </>
  //             ) : (
  //               <h1>Video Not Found...</h1>
  //             )}
  //           </Row>
  //         </div>
  //       </Container>
  //       <ThinFooter />
  //     </ContentWrapper>
  //   </>
  // );
};

export default VideoPage;
