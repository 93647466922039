import React from 'react';
import Modal from 'react-bootstrap/Modal';

const Description = ({ show, handleClose, description }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0'>
          <div
            className={`description-box mb-2`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Description;
