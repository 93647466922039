import React from 'react';
import {
  UilSort,
  UilAngleDown,
  UilAngleUp,
} from '@iconscout/react-unicons/index';
import { SORT_ORDER_TYPE } from 'src/constant';

const SortButton = ({ order }) => {
  return (
    <>
      {order === SORT_ORDER_TYPE.ascending ? (
        <UilAngleUp size={20} />
      ) : order === SORT_ORDER_TYPE.descending ? (
        <UilAngleDown size={20} />
      ) : (
        <UilSort size={20} />
      )}
    </>
  );
};

export default SortButton;
