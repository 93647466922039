import './Slide.css';

import { VerifiedTooltip } from '../CustomCheckTooltips/CustomCheckTooltips';
import Constant from '../../../constant';
import { useHistory } from 'react-router-dom';

export default function Slide({
  href,
  imgSrc,
  imgAlt = '',
  label,
  views,
  verified = null,
}) {
  const history = useHistory();
  const verifyLabel = verified ? <VerifiedTooltip /> : '';
  return (
    <>
      <div
        className='item cursor-pointer'
        onClick={() =>
          history.push(`${Constant.BASE_URL}videos/categories/${label}`)
        }
      >
        <div className='category-item'>
          <div
            // href={href ? href : '#'}
            className='d-flex align-items-center justify-content-center'
          >
            <img
              className='img-fluid custom-slick-img'
              src={imgSrc}
              // src={`${Constant.BASE_URL}img/disabled-logo.png`}
              alt={imgAlt}
            />
            <h6 className='ml-2 text-capitalize'>
              {label} {verifyLabel}
            </h6>
          </div>
        </div>
      </div>
      {/* <div className='item'>
        <div className='category-item'>
          <a href={href ? href : '#'}>
            <img
              className='img-fluid custom-slick-img'
              src={imgSrc}
              alt={imgAlt}
            />
            <h6>
              {label} {verifyLabel}
            </h6>
            <p>{views} views</p>
          </a>
        </div>
      </div> */}
    </>
  );
}
