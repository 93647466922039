import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import SectionHeader from '../../components/atoms/SectionHeader/SectionHeader';
import VideoBlock from 'src/components/VideoBlock';
import { useSearchVideosQuery } from '../../redux/features/videos/videoApi';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoContent from '../../components/atoms/NoContent/NoContent';
import Loader from 'src/components/atoms/Loader';
import config from 'src/config';

const VideoSearch = () => {
  const [offset, setOffset] = useState(0);
  const limit = config.limits.videoSearched;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const search_query = searchParams.get('search_query');

  useEffect(() => {
    setOffset(0);
  }, [search_query]);

  const { data, isSuccess, isLoading } = useSearchVideosQuery({
    search_query,
    limit,
    offset,
  });

  if (data?.videos?.length === 0) return <NoContent addContainer={true} />;

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4'>
        {isLoading ? (
          <Loader />
        ) : isSuccess && !!data?.videos?.length ? (
          <InfiniteScroll
            dataLength={data?.videos.length}
            hasMore={data?.total_results > data?.videos.length}
            next={() => setOffset((prev) => prev + limit)}
            loader={<Loader />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
            className='overflow-hidden'
          >
            <VideoBlock
              data={data?.videos}
              HeaderComponent={() => (
                <SectionHeader
                  heading={`There are ${data?.total_results} results for '${search_query}'`}
                  noIconLabel
                  className='mb-3'
                />
              )}
            />
          </InfiniteScroll>
        ) : null}
      </Container>
    </>
  );
};

export default VideoSearch;
