import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import './modal.css';
import Constant from 'src/constant';
import Loader from '../Loader';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ProcessingModal = ({ show = false, handleClose, metamask = false }) => {
  useEffect(() => {
    if (show) {
      window.onbeforeunload = function (e) {
        return 'Are you sure you want to leave? You are in the middle of something.';
      };
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={'static'}
        keyboard={false}
        id='processing-modal'
        // size=''
      >
        <Modal.Body>
          {metamask ? (
            <div
              style={{
                // maxHeight: '150px',
                // overflow: 'auto',
                textAlign: 'center',
              }}
            >
              <img
                className='metamask'
                alt='metamask'
                src={`${Constant.BASE_URL}img/logo-circle.png`}
              />
              <div className='content'>
                <Loader />
                <h5 className='mt-2'>
                  Open your connected wallet and confirm the transaction
                </h5>
                <hr className='clr-white-card-hr' />
                {/* info */}
                <div className='text-left'>
                  <h5 className='text-info text-left '>Info</h5>{' '}
                  <p class='warning my-2'>
                    Below- given are some crucial actions that can result in
                    your payment interruption and must be avoided:
                  </p>
                  <div class='message pl-1'>
                    <p>- Don't reload the page.</p>
                    <p>- Don't shut down your system.</p>
                    <p>- Don't click on the back page button.</p>
                    <p>- Don't close the window.</p>
                    <p>
                      - Don't refresh the page but wait for the transaction
                      confirmation.
                    </p>
                  </div>
                </div>
                <Button
                  onClick={() => {
                    handleClose();
                    toast.info(
                      'Request Cancellation Successful - Kindly Reject Any Following Wallet Requests'
                    );
                  }}
                  variant='primary'
                  className='mt-4'
                >
                  Cancel Transaction
                </Button>
                <hr className='clr-white-card-hr' />

                <div className='mt-4 text-left'>
                  <span className='text-danger fw-600'>Note: </span>{' '}
                  <span>
                    {' '}
                    Any of these actions may hamper your successful payment
                    transaction.
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className='d-flex align-items-center justify-content-center'>
              <Loader className='mr-2' />
              <h5 className='text-center'>Processing...</h5>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProcessingModal;
