import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import VideoCard from 'src/components/atoms/VideoCard/VideoCard';
import Constant, { CLIENT_URL } from 'src/constant';
import calculateDuration from 'src/utils/calculate-duration';
import { handleOpenShare } from 'src/redux/features/share/shareSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { openReportVideoModal } from 'src/redux/features/report-video/reportVideoSlice';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { checkVideoReported } from 'src/redux/features/videos/videoSlice';
import messages from 'src/constant/messages';
import handleError from 'src/utils/handle-error';
import { useAddWatchLaterMutation } from 'src/redux/features/watch-later/watchLaterApi';

export default function VideoBlock({ data, HeaderComponent = null }) {
  const dispatch = useDispatch();
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [addWatchLater] = useAddWatchLaterMutation();

  return (
    <>
      <div className='video-block section-padding '>
        <Row>
          {HeaderComponent && (
            <Col md={12} className='mb-2'>
              <HeaderComponent />
            </Col>
          )}

          {data.map((video) => (
            <Col
              xl={3}
              lg={4}
              sm={6}
              className='mb-3 d-flex'
              key={`video-block-${video.id}`}
            >
              <VideoCard
                isShort={Boolean(video.is_short)}
                imgSrc={
                  video.thumbnail_url ||
                  `${Constant.BASE_URL}img/light/default-thumbnail.png`
                }
                time={calculateDuration(video.duration)}
                // time={
                //   video.duration > 0
                //     ? moment.utc(video.duration * 1000).format('HH:mm:ss')
                //     : null
                // }
                videoTitle={video.title}
                channelName={video.channel_name || 'not mentioned'}
                views={video?.views}
                timeAgo={moment(video.created_date).fromNow()}
                verified={video?.verified}
                imgHref={`${Constant.BASE_URL}${
                  video.is_short ? 'shorts' : 'watch'
                }/${video.video_url_id}`}
                iconHref={`${Constant.BASE_URL}${
                  video.is_short ? 'shorts' : 'watch'
                }/${video.video_url_id}`}
                videoTitleHref={`${Constant.BASE_URL}${
                  video.is_short ? 'shorts' : 'watch'
                }/${video.video_url_id}`}
                channelHref={`${Constant.BASE_URL}channel/${video.handle_name}`}
                onAddWatchLater={async () => {
                  if (!authData) return toast.info(messages.require_signin);
                  try {
                    const response = await addWatchLater({
                      videoId: video.id,
                      videoUrlId: video.video_url_id,
                    }).unwrap();
                    toast.success(response.message);
                  } catch (error) {
                    // toast.error(error.message);
                    handleError(error);
                  }
                }}
                onShare={() =>
                  dispatch(
                    handleOpenShare({
                      shareLink: `${CLIENT_URL}/watch/${video.video_url_id}`,
                    })
                  )
                }
                onReportVideo={async () => {
                  if (!authData)
                    return toast.info(messages.require_signin_to_report);
                  if (video.is_reported)
                    return toast.info(messages.video_reported_already);

                  try {
                    const report = await dispatch(
                      checkVideoReported(video.id)
                    ).unwrap();
                    const is_reported = report.data.is_reported;

                    if (is_reported)
                      return toast.info(messages.video_reported_already);

                    dispatch(
                      openReportVideoModal({
                        contractVideoId: video.contract_video_id,
                        videoId: video.id,
                        videoUrlId: video.video_url_id,
                      })
                    );
                  } catch (error) {
                    handleError(error);
                  }
                }}
              />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}
