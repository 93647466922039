import './Channel.css';
import { Container } from 'react-bootstrap';
import { useParams, Switch, Route } from 'react-router-dom';
import {
  ChannelShorts,
  ChannelVideos,
  SingleChannelHero,
  SingleChannelNav,
} from './components';
import Constant from 'src/constant';
import { ThinFooter } from 'src/components/footer';

const Channel = () => {
  const { handle_name } = useParams();
  return (
    <>
      <div className='single-channel-page'>
        <SingleChannelHero />
        <SingleChannelNav />
        <Container fluid='xxl' className='p-2 p-md-4'>
          <Switch>
            <Route exact path={`${Constant.BASE_URL}channel/${handle_name}`}>
              <ChannelVideos handle_name={handle_name} />
            </Route>
            <Route path={`${Constant.BASE_URL}channel/${handle_name}/shorts`}>
              <ChannelShorts handle_name={handle_name} />
            </Route>
          </Switch>
          {/* <ChannelVideos /> */}
        </Container>
      </div>
      <ThinFooter />
    </>
  );
};

export default Channel;
