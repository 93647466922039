import { useParams, NavLink } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { useAccount, useWriteContract, useSwitchChain } from 'wagmi';
import { useState } from 'react';
import { abbreviateNumber } from 'js-abbreviation-number';

import { VerifiedTooltip } from 'src/components/atoms/CustomCheckTooltips/CustomCheckTooltips';
import {
  useFetchChannelByHandleNameQuery,
  useFetchMyChannelQuery,
} from 'src/redux/features/channels/channelApi';
import {
  useSubscribeMutation,
  useUnsubscribeMutation,
} from 'src/redux/features/subscribers/subscribersApi';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { ConfirmModal, ProcessingModal } from 'src/components/atoms/modals';
import handleError from 'src/utils/handle-error';
import { DotSvg } from 'src/assets/svgs';
import Constant, { COMMON_STATUS } from 'src/constant';
import { pendingTxnCheck } from 'src/redux/features/basic/basicApi';
import useFindContractData from 'src/hooks/useFindContractData';
import messages from 'src/constant/messages';
import { useDispatch } from 'react-redux';
import config from 'src/config';
import { useActiveChainId } from 'src/hooks';

export default function SingleChannelNav() {
  const dispatch = useDispatch();
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const { handle_name } = useParams();
  const [processing, setProcessing] = useState(false);
  const [tab, setTab] = useState('videos');

  const { abi, contract_address } = useFindContractData();

  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const { data: myChannel } = useFetchMyChannelQuery(undefined, {
    skip: !(authData?.token || !!localStorage.getItem('accessToken')),
  });

  const { data: channel } = useFetchChannelByHandleNameQuery(handle_name, {
    skip: !handle_name,
  });

  const [subscribed] = useSubscribeMutation();
  const [unsubscribed] = useUnsubscribeMutation();

  const subscribeTo = channel?.block_address;

  const { writeContractAsync: subscribeChannelContract } = useWriteContract();
  const { writeContractAsync: unsubscribeChannelContract } = useWriteContract();

  let buttonVariant;

  buttonVariant = channel?.isUserSubscribed ? 'secondary' : 'primary';
  const buttonText = channel?.isUserSubscribed ? 'Subscribed' : 'Subscribe';

  const [show, setShow] = useState(false);
  const [moreLess, setMoreLess] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSelect = (e) => {
    setTab(e);
  };

  return (
    <>
      <ProcessingModal
        metamask={true}
        show={processing}
        handleClose={() => setProcessing(false)}
      />
      <ConfirmModal
        show={show}
        handleClose={handleClose}
        onConfirm={async () => {
          try {
            if (!authData?.token) return toast.info(messages.require_signin);
            if (activeChainId !== config.ACTIVE_CHAIN.id) {
              await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
            }

            handleClose();
            setProcessing(true);
            const hash = await unsubscribeChannelContract({
              address: contract_address,
              abi: JSON.parse(abi),
              functionName: 'unsubscribe',
              args: [subscribeTo],
              chainId: config.ACTIVE_CHAIN.id,
            });
            // await waitForTransactionReceipt({ hash });
            // const block_transaction_data = {
            //   action: 'unsubscribe',
            //   address_out: account.address,
            //   address_in: contract_address,
            //   hash,
            //   network_type: NETWORK_TYPE.MATIC,
            //   request: JSON.stringify([subscribeTo]),
            // };
            // saveBlock({ body: block_transaction_data });
            await unsubscribed({
              channelId: channel.id,
              handle_name: channel.handle_name,
              body: { hash },
            }).unwrap();
            setProcessing(false);
          } catch (error) {
            handleError(error);
            setProcessing(false);
          }
        }}
        title={`Unsubscribe from ${channel?.name}?`}
        confirmButtonText='Unsubscribe'
      />
      <div className='single-channel-nav'>
        <Navbar expand='lg' className='d-sm-flex d-block'>
          <Navbar.Brand className='channel-brand'>
            <div className='d-flex align-items-center'>
              {channel?.name}{' '}
              {!!channel?.verified && (
                <div className='ml-1'>
                  <VerifiedTooltip />
                </div>
              )}
            </div>

            <div className='d-flex align-items-center mt-1'>
              <p className='text-clr-dark-300 fs-400 fw-600'>
                {channel?.handle_name}
              </p>
              <DotSvg
                style={{
                  width: '5px',
                  height: '5px',
                  fill: 'var(--clr-dark-300)',
                }}
                className='mx-2'
              />
              <p className='text-clr-dark-300 fs-400 fw-500'>
                {abbreviateNumber(channel?.subscribers || 0, 1)} subscribers
              </p>
              <DotSvg
                style={{
                  width: '5px',
                  height: '5px',
                  fill: 'var(--clr-dark-300)',
                }}
                className='mx-2'
              />
              <p className='text-clr-dark-300 fs-400 fw-500'>
                {abbreviateNumber(channel?.videos_count || 0, 1)} videos
              </p>
            </div>
            {channel?.description && (
              <div>
                <p
                  style={{
                    whiteSpace: 'break-spaces',
                  }}
                  // className={`text-wrap  fs-300 fw-400 text-clr-dark-100 ${
                  //   moreLess ? '' : 'text-line-1'
                  // }`}
                  className={`text-wrap  fs-300 fw-400 text-clr-dark-100`}
                >
                  {moreLess
                    ? channel?.description
                    : channel?.description?.substring(0, 100) + '...'}
                </p>
                {channel?.description?.length > 100 && (
                  <span
                    className='cursor-pointer fs-300 fw-700'
                    onClick={() => setMoreLess(!moreLess)}
                  >
                    {moreLess ? 'Less' : 'More'}
                  </span>
                )}
              </div>
            )}
          </Navbar.Brand>

          {/* <Navbar.Toggle aria-controls='navbarSupportedContent' /> */}

          {/* <Navbar.Collapse id='navbarSupportedContent'> */}

          <Form
            inline
            className=' my-2 my-lg-0 display-flex justify-content-center justify-content-sm-end flex-grow-1'
          >
            {myChannel?.id !== channel?.id && (
              <Button
                variant={buttonVariant}
                size='sm'
                disabled={processing}
                onClick={async () => {
                  if (!authData?.token)
                    return toast.info(messages.require_signin);

                  if (activeChainId !== config.ACTIVE_CHAIN.id) {
                    await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
                  }

                  const response = await dispatch(
                    pendingTxnCheck({
                      id: channel.id,
                      type: 'subscribe',
                    })
                  ).unwrap();
                  if (response.data.txn_status === COMMON_STATUS.PROCESSING) {
                    return toast.info(
                      response.message || messages.pending_transaction
                    );
                  }

                  if (channel?.isUserSubscribed) handleShow();
                  else {
                    try {
                      setProcessing(true);
                      const hash = await subscribeChannelContract({
                        address: contract_address,
                        abi: JSON.parse(abi),
                        functionName: 'subscribe',
                        args: [subscribeTo],
                        chainId: config.ACTIVE_CHAIN.id,
                      });
                      // await waitForTransactionReceipt({ hash });
                      // const block_transaction_data = {
                      //   action: 'subscribe',
                      //   address_out: account.address,
                      //   address_in: contract_address,
                      //   hash,
                      //   network_type: NETWORK_TYPE.MATIC,
                      //   request: JSON.stringify([subscribeTo]),
                      // };
                      // saveBlock({ body: block_transaction_data });
                      await subscribed({
                        channelId: channel.id,
                        handle_name: channel.handle_name,
                        body: { hash },
                      }).unwrap();
                      setProcessing(false);
                    } catch (error) {
                      handleError(error);
                      setProcessing(false);
                    }
                  }
                }}
              >
                {processing ? 'Processing...' : buttonText}
                {/* <strong>{channel?.subscribers}</strong> */}
              </Button>
            )}
          </Form>

          {/* </Navbar.Collapse> */}
        </Navbar>
        {/* <Nav as='ul' className='mr-auto'>
          <li className='nav-item active'>
            <div className='nav-link '>Videos</div>
          </li>
        </Nav> */}
        {/* <Nav
          variant='tabs'
          // defaultActiveKey='/home'
          activeKey={tab}
          defaultActiveKey={'videos'}
          onSelect={handleSelect}
        >
          <Nav.Item>
            <Nav.Link eventKey='videos'>Videos</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='shorts'>Shorts</Nav.Link>
          </Nav.Item>
        </Nav> */}
        <div className='custom-tabs mt-4'>
          <NavLink
            exact
            activeClassName='active'
            className='tab-item mr-4'
            to={Constant.BASE_URL + `channel/${handle_name}`}
          >
            Videos
          </NavLink>
          <NavLink
            activeClassName='active'
            className='tab-item'
            to={Constant.BASE_URL + `channel/${handle_name}/shorts`}
          >
            Shorts
          </NavLink>
        </div>
      </div>
    </>
  );
}
