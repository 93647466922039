import { FormControl, InputGroup, Row, Col, Container } from 'react-bootstrap';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import { useRef, useState } from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useHistory } from 'react-router-dom';

import Constant, { CLIENT_URL, COMMON_STATUS } from '../../constant';
import { useFetchMyLikedVideosQuery } from '../../redux/features/liked-videos/likedVideosApi';
import NoContent from '../../components/atoms/NoContent/NoContent';
import { toast } from 'react-toastify';
import { useWriteContract, useSwitchChain } from 'wagmi';
// import { useSaveBlockTransactionMutation } from 'src/redux/features/basic/basicApi';
import { ProcessingModal } from 'src/components/atoms/modals';
import { useAddWatchLaterMutation } from 'src/redux/features/watch-later/watchLaterApi';
import useFindContractData from 'src/hooks/useFindContractData';
import handleError from 'src/utils/handle-error';
import WatchedVideoCard from 'src/components/atoms/WatchedVideoCard/WatchedVideoCard';
import messages from 'src/constant/messages';
import { checkVideoReported } from 'src/redux/features/videos/videoSlice';
import { openReportVideoModal } from 'src/redux/features/report-video/reportVideoSlice';
import { useDispatch } from 'react-redux';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import calculateDuration from 'src/utils/calculate-duration';
import { ThinFooter } from 'src/components/footer';
import { useDislikeVideoMutation } from 'src/redux/features/videos/videoApi';
import Loader from 'src/components/atoms/Loader';
import config from 'src/config';
import { useActiveChainId } from 'src/hooks';
import { pendingTxnCheck } from 'src/redux/features/basic/basicApi';

const LikedVideos = () => {
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const history = useHistory();
  const limit = 50;
  const [offset, setOffset] = useState(0);
  const [processing, setProcessing] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query');

  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const { abi, contract_address } = useFindContractData();

  const unlikeContract = useWriteContract();

  // const [saveBlock] = useSaveBlockTransactionMutation();
  const [dislikeVideo] = useDislikeVideoMutation();
  const [addWatchLater] = useAddWatchLaterMutation();

  const { data, isLoading, isSuccess } = useFetchMyLikedVideosQuery({
    query: query || '',
    limit,
    offset,
  });

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      const newQuery = searchRef.current?.value;
      const queryParam = newQuery ? `?query=${newQuery}` : '';
      history.push({
        pathname: `${Constant.BASE_URL}liked-videos`,
        search: queryParam,
      });
    }
  };

  const handleSearchClick = () => {
    const newQuery = searchRef.current.value;
    if (!newQuery) searchRef.current.focus();

    const queryParam = newQuery ? `?query=${newQuery}` : '';
    history.push({
      pathname: `${Constant.BASE_URL}liked-videos`,
      search: queryParam,
    });
  };

  if (data?.likedVideos?.length === 0 && !query)
    return (
      <NoContent
        addContainer={true}
        title='No Liked Videos Yet'
        description='You have not liked any videos on the platform yet. Visit home page and like the videos.'
        imgUrl={`${Constant.BASE_URL}img/illustration/liked.svg`}
        buttonText='Go to home'
        handleButtonClick={() => history.push(`${Constant.BASE_URL}`)}
      />
    );

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4'>
        <ProcessingModal
          metamask={true}
          show={processing}
          handleClose={() => setProcessing(false)}
        />
        {/* <div
          className='video-block section-padding'
          style={{ maxWidth: '800px', margin: 'auto' }}
        > */}
        <Row>
          <Col md={12} className='mb-2'>
            <div className='d-flex justify-content-between align-items-center'>
              <h6 className='fs-500 fw-600'>Liked Videos</h6>
              <div className='d-flex align-items-center'>
                <InputGroup>
                  <FormControl
                    type='text'
                    ref={searchRef}
                    placeholder='Search'
                    className='input-search2'
                    onKeyPress={handleKeyPress}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      className='cursor-pointer bg-transparent pl-2 border-0'
                      onClick={handleSearchClick}
                    >
                      <UilSearch size={18} />
                    </InputGroup.Text>
                  </InputGroup.Append>{' '}
                </InputGroup>
              </div>
            </div>
            <hr className='clr-white-card-hr' />
          </Col>
          <Col md={12} className='mb-2'>
            <div className='d-flex flex-column'>
              {isLoading && <Loader />}
              {data?.likedVideos && data?.likedVideos?.length === 0 && (
                <h6 className='text-center h5 fw-600'>
                  There is 0 result for {query}
                </h6>
              )}
              {isSuccess && !!data?.likedVideos?.length && (
                <InfiniteScroll
                  dataLength={data?.likedVideos?.length || 0} //This is important field to render the next data
                  next={() => setOffset((prev) => prev + limit)}
                  hasMore={data?.total_result_count > data?.likedVideos?.length}
                  loader={<Loader />}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      {/* <b>Yay! You have seen it all</b> */}
                    </p>
                  }
                  className='overflow-hidden'
                  style={{ minHeight: '60vh' }}
                >
                  {data?.likedVideos?.map((item) => (
                    <WatchedVideoCard
                      key={`liked-videos-${item.id}`}
                      imgSrc={
                        item?.thumbnail_url ||
                        `${Constant.BASE_URL}img/light/default-thumbnail.png`
                      }
                      videoHref={`${Constant.BASE_URL}watch/${item.video_url_id}`}
                      videoTitle={item.title}
                      verified={item?.verified}
                      timeAgo={moment(item.modified_date).fromNow()}
                      channelName={item.channel_name}
                      shareLink={`${CLIENT_URL}/shorts/${item.video_url_id}`}
                      isShort={Boolean(item.is_short)}
                      // videoId={item.video_id}
                      onAddWatchLater={async () => {
                        try {
                          const response = await addWatchLater({
                            videoId: item.video_id,
                            videoUrlId: item.video_url_id,
                          }).unwrap();
                          toast.success(response.message);
                        } catch (error) {
                          toast.error(error.message);
                        }
                      }}
                      onRemovedFromLikedVideo={async () => {
                        try {
                          if (activeChainId !== config.ACTIVE_CHAIN.id) {
                            await switchChainAsync({
                              chainId: config.ACTIVE_CHAIN.id,
                            });
                          }

                          const pResponse = await dispatch(
                            pendingTxnCheck({ id: item.video_id, type: 'like' })
                          ).unwrap();
                          if (
                            pResponse.data.txn_status ===
                            COMMON_STATUS.PROCESSING
                          ) {
                            return toast.info(
                              pResponse.message || messages.pending_transaction
                            );
                          }
                          setProcessing(true);
                          const args = [item.contract_video_id];
                          const hash = await unlikeContract.writeContractAsync({
                            args,
                            address: contract_address,
                            abi: JSON.parse(abi),
                            functionName: 'unlikeVideo',
                            chainId: config.ACTIVE_CHAIN.id,
                            // ...(await fetchFeeData()),
                          });
                          // const receipt = await waitForTransactionReceipt({ hash });
                          // const block_transaction_data = {
                          //   action: 'unlikeVideo',
                          //   address_out: account.address,
                          //   address_in: contract_address,
                          //   hash,
                          //   network_type: 5,
                          //   request: JSON.stringify(args),
                          //   // other_detail: stringifyWithBigInt(receipt),
                          // };
                          // saveBlock({ body: block_transaction_data });

                          // const response = await removeLikeVideo(
                          //   item.video_id
                          // ).unwrap();
                          const response = await dislikeVideo({
                            videoId: item.video_id,
                            body: { hash },
                            extraArg: { video_url_id: item.video_url_id },
                          });
                          setProcessing(false);
                          toast.success(response.message);
                        } catch (error) {
                          setProcessing(false);
                          handleError(error);
                        }
                      }}
                      onReportVideo={async () => {
                        if (!authData)
                          return toast.error(messages.require_signin_to_report);
                        if (item.is_reported)
                          return toast.info(messages.video_reported_already);

                        try {
                          const report = await dispatch(
                            checkVideoReported(item.id)
                          ).unwrap();
                          const is_reported = report.data.is_reported;

                          if (is_reported)
                            return toast.info(messages.video_reported_already);

                          dispatch(
                            openReportVideoModal({
                              contractVideoId: item.contract_video_id,
                              videoId: item.id,
                              videoUrlId: item.video_url_id,
                            })
                          );
                        } catch (error) {
                          handleError(error);
                        }
                      }}
                      time={calculateDuration(item.duration)}
                      views={item?.views || 0}
                      likes={item.like_count}
                      progress='50'
                      progressTime='1:40'
                    />
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </Col>
        </Row>
        {/* </div> */}
      </Container>
      <ThinFooter />
    </>
  );
};

export default LikedVideos;
