import { useBasicInfoQuery } from 'src/redux/features/basic/basicApi';
import { CONTRACT_SYMBOL, NETWORK_TYPE } from 'src/constant';

const useFindContractData = (
  symbol = CONTRACT_SYMBOL.Mirror,
  network_id = NETWORK_TYPE.MATIC
) => {
  const { data } = useBasicInfoQuery();

  if (!data)
    return {
      abi: JSON.stringify([]),
    };
  const contract = data.block_coin.find(
    (contract) =>
      contract.symbol === symbol && contract.network_id === network_id
  );
  return contract;
};

export default useFindContractData;
