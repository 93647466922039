import axios from 'axios';
import { SERVER_BASE_URL } from 'src/constant';
import { defaultHeaders } from 'src/redux/rootApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getNonce = createAsyncThunk(
  'getNonce',
  async (address, { rejectWithValue }) => {
    let url = `${SERVER_BASE_URL}/nonce?address=${address}`;
    try {
      const result = await axios.get(url, {
        headers: { ...defaultHeaders },
      });
      return result.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  initialState: {
    isAuthenticated: false,
    accessToken: null,
    loading: false,
    user: null,
    isChannelCreated: false,
  },
  name: 'authStore',
  reducers: {
    setAuth: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      state.user = payload.user;
      state.isChannelCreated = Boolean(payload.user.handle_name);
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(login.pending, (state, action) => {
    //   // Add user to the state array
    //   state.isLoading = true;
    // });
    // builder.addCase(login.fulfilled, (state, { payload }) => {
    //   // Add user to the state array
    //   console.log(payload, 'payload');
    //   state.isLoading = false;
    // });
    // builder.addCase(login.rejected, (state, action) => {
    //   // Add user to the state array
    //   state.isLoading = false;
    // });
  },
});

export const { setAuth } = authSlice.actions;
export default authSlice.reducer;
