import './Homepage.css';
import { useHistory } from 'react-router-dom';

import { HomeCarousel, PopularChannels, TopCategory } from './components';
import Constant from 'src/constant';
import SectionHeader from 'src/components/atoms/SectionHeader/SectionHeader';
import {
  useNewVideosQuery,
  useTrendingVideosQuery,
  useVideoOfDayQuery,
  useVideoOfWeekQuery,
  useVideoOfMonthQuery,
  useVideoOfYearQuery,
  useTrendingShortsQuery,
} from 'src/redux/features/videos/videoApi';
import VideoBlock from 'src/components/VideoBlock';
import { Container } from 'react-bootstrap';
import { DaySvg, MonthSvg, ShortSvg, WeekSvg, YearSvg } from 'src/assets/svgs';
import ShortBlock from './components/ShortsBlock.component';
import { ThinFooter } from 'src/components/footer';
import config from 'src/config';

const Homepage = () => {
  const history = useHistory();
  const newVideosResponse = useNewVideosQuery({
    limit: config.limits.newVideos,
    offset: 0,
  });
  const trendingVideosResponse = useTrendingVideosQuery({
    limit: config.limits.trendingVideos,
    offset: 0,
  });
  const trendingShortsResponse = useTrendingShortsQuery({
    limit: 20,
    offset: 0,
  });
  const videoOfDayResponse = useVideoOfDayQuery({
    limit: config.limits.videoOfDay,
    offset: 0,
  });
  const videoOfWeekResponse = useVideoOfWeekQuery({
    limit: config.limits.videoOfWeek,
    offset: 0,
  });
  const videoOfMonthResponse = useVideoOfMonthQuery({
    limit: config.limits.videoOfMonth,
    offset: 0,
  });
  const videoOfYearResponse = useVideoOfYearQuery({
    limit: config.limits.videoOfYear,
    offset: 0,
  });
  return (
    <div>
      <Container fluid='xxl' className='p-2 p-md-4 min-h-without-navbar-footer'>
        {/* <TopMobileSearch /> */}
        <HomeCarousel />
        <TopCategory />
        {/* <hr /> */}
        {trendingVideosResponse.isSuccess &&
          trendingVideosResponse.data.videos?.length > 0 && (
            <>
              <VideoBlock
                data={trendingVideosResponse.data.videos.slice(0, 4)}
                HeaderComponent={() => (
                  <SectionHeader
                    heading='Trending Videos'
                    noIconLabel
                    headingIconUrl={`${Constant.BASE_URL}img/trending.svg`}
                    onViewAllClick={() =>
                      history.push(`${Constant.BASE_URL}trendingvideos`)
                    }
                  />
                )}
              />
              <hr className='mt-0 section-hr' />
            </>
          )}
        {trendingShortsResponse.isSuccess &&
          trendingShortsResponse.data.videos?.length > 0 && (
            <>
              <ShortBlock
                data={trendingShortsResponse?.data?.videos || []}
                HeaderComponent={() => (
                  <SectionHeader
                    heading='Shorts'
                    noIconLabel
                    widthCircle={false}
                    headingIcon={<ShortSvg />}
                    // headingIconUrl={`${Constant.BASE_URL}img/shorts.svg`}
                    // onViewAllClick={() => {}}
                  />
                )}
              />
              <hr className='mt-0 section-hr' />
            </>
          )}

        {newVideosResponse.isSuccess &&
          newVideosResponse.data.videos?.length > 0 && (
            <>
              <VideoBlock
                data={newVideosResponse.data.videos.slice(0, 4)}
                HeaderComponent={() => (
                  <SectionHeader
                    heading='New Videos'
                    noIconLabel
                    onViewAllClick={() =>
                      history.push(`${Constant.BASE_URL}newvideos`)
                    }
                    headingIconUrl={`${Constant.BASE_URL}img/speaker.svg`}
                  />
                )}
              />
              <hr className='mt-0 section-hr' />
            </>
          )}

        {videoOfDayResponse.isSuccess &&
          videoOfDayResponse.data.videos?.length > 0 && (
            <>
              <VideoBlock
                data={videoOfDayResponse.data.videos.slice(0, 4)}
                HeaderComponent={() => (
                  <SectionHeader
                    heading='Videos of the Day'
                    noIconLabel
                    onViewAllClick={() =>
                      history.push(`${Constant.BASE_URL}day`)
                    }
                    headingIcon={<DaySvg />}
                  />
                )}
              />
              <hr className='mt-0 section-hr' />
            </>
          )}

        {videoOfWeekResponse.isSuccess &&
          videoOfWeekResponse.data.videos?.length > 0 && (
            <>
              <VideoBlock
                data={videoOfWeekResponse.data.videos.slice(0, 4)}
                HeaderComponent={() => (
                  <SectionHeader
                    heading='Videos of the Week'
                    noIconLabel
                    onViewAllClick={() =>
                      history.push(`${Constant.BASE_URL}week`)
                    }
                    headingIcon={<WeekSvg />}
                  />
                )}
              />
              <hr className='mt-0 section-hr' />
            </>
          )}

        {videoOfMonthResponse.isSuccess &&
          videoOfMonthResponse.data.videos?.length > 0 && (
            <>
              <VideoBlock
                data={videoOfMonthResponse.data.videos.slice(0, 4)}
                HeaderComponent={() => (
                  <SectionHeader
                    heading='Videos of the Month'
                    noIconLabel
                    onViewAllClick={() =>
                      history.push(`${Constant.BASE_URL}month`)
                    }
                    headingIcon={<MonthSvg />}
                  />
                )}
              />
              <hr className='mt-0 section-hr' />
            </>
          )}

        {videoOfYearResponse.isSuccess &&
          videoOfYearResponse.data.videos?.length > 0 && (
            <>
              <VideoBlock
                data={videoOfYearResponse.data.videos.slice(0, 4)}
                HeaderComponent={() => (
                  <SectionHeader
                    heading='Videos of the Year'
                    noIconLabel
                    onViewAllClick={() =>
                      history.push(`${Constant.BASE_URL}year`)
                    }
                    headingIcon={<YearSvg />}
                  />
                )}
              />
              <hr className='mt-0 section-hr' />
            </>
          )}
        <PopularChannels />
      </Container>
      {/* <FatFooter /> */}
      <ThinFooter />
    </div>
  );
};

export default Homepage;
