import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';

import './modal.css';
import {
  useSendOtpForPhoneVerificationMutation,
  useVerifyOtpForPhoneVerificationMutation,
} from 'src/redux/features/auth/authApi';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import handleError from 'src/utils/handle-error';

const OtpVerifyModal = ({ show = false, handleClose, metamask = false }) => {
  const [otp, setOtp] = useState('');
  const [verifyOtp, { isLoading }] = useVerifyOtpForPhoneVerificationMutation();
  const [sendOtp, sendOtpOptions] = useSendOtpForPhoneVerificationMutation();

  let phone = typeof show === 'string' ? show : '';

  const handleResendOtp = async () => {
    setMinutes(1);
    setSeconds(30);
    try {
      const response = await sendOtp(phone).unwrap();
      toast.success(response.message);
    } catch (error) {
      handleError(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await verifyOtp(otp).unwrap();
      handleClose();
      toast.success(response.message);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    setMinutes(1);
    setSeconds(30);
  }, [show]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={'static'}
        keyboard={false}
        id='processing-modal'
      >
        <Modal.Header closeButton className='pb-0' />
        <Modal.Body className='otp-box'>
          <h5 className='text-center mb-2 '>
            Please enter the OTP to verify Phone Number
          </h5>
          <p className='text-center'>
            A code has been send to *****{phone.slice(-5)}
          </p>
          <div className='d-flex flex-column  mt-4 '>
            <div className='mx-auto'>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                containerStyle={{}}
                inputStyle={{}}
                shouldAutoFocus
                renderSeparator={<span className='otp-input-separator' />}
                renderInput={(props) => (
                  <input {...props} className='otp-input' type='number' />
                )}
              />
            </div>
            <div className='d-flex justify-content-between mt-4 align-items-center mb-1'>
              {seconds > 0 || minutes > 0 ? (
                <p>
                  Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <p>Didn't receive code?</p>
              )}

              <Button
                variant='link'
                size='sm'
                disabled={
                  seconds > 0 || minutes > 0 || sendOtpOptions.isLoading
                }
                style={{
                  color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                  // fontSize: '10px',
                }}
                className='text-primary cursor-pointer p-0 fs-300 fw-600'
                onClick={handleResendOtp}
              >
                Resend OTP
              </Button>
            </div>
            <Button
              disabled={otp.length !== 4 || isLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>

            {/* <div className='mt-3 '>
              <h6 className='mr-1'>
                Didn't receive the OTP? {!resend && <span>({time} sec)</span>}
              </h6>
              {resend && (
                <Button
                  variant='link'
                  className='text-primary cursor-pointer p-0 mx-auto d-flex'
                  disabled={sendOtpOptions.isLoading}
                  onClick={handleResendOtp}
                >
                  Resend
                </Button>
              )}
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpVerifyModal;
