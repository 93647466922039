import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import UilThumbsUp from '@iconscout/react-unicons/icons/uil-thumbs-up';
import UilThumbsDown from '@iconscout/react-unicons/icons/uil-thumbs-down';
import UilShare from '@iconscout/react-unicons/icons/uil-share';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus-circle';
import UilMenuV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import UilExclamationOctagon from '@iconscout/react-unicons/icons/uil-exclamation-octagon';

import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAccount, useWriteContract, useSwitchChain } from 'wagmi';
import { abbreviateNumber } from 'js-abbreviation-number';

import { COLORS, COMMON_STATUS, themeMode } from 'src/constant';
import { VerifiedTooltip } from 'src/components/atoms/CustomCheckTooltips/CustomCheckTooltips';
import {
  SaveToModal,
  ConfirmModal,
  ProcessingModal,
} from 'src/components/atoms/modals';
import {
  useDislikeVideoMutation,
  useLikeVideoMutation,
} from 'src/redux/features/videos/videoApi';
import {
  useSubscribeMutation,
  useUnsubscribeMutation,
} from 'src/redux/features/subscribers/subscribersApi';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import Avatar from 'src/components/atoms/Avatar';
import { ThemeContext } from 'src/App';
import {
  pendingTxnCheck,
  useSaveBlockTransactionMutation,
} from 'src/redux/features/basic/basicApi';
import handleError from 'src/utils/handle-error';
import { fetchFeeData } from 'src/utils/gas-price';
import messages from 'src/constant/messages';
import useFindContractData from 'src/hooks/useFindContractData';
import { handleOpenShare } from 'src/redux/features/share/shareSlice';
import { useDispatch } from 'react-redux';
import { openReportVideoModal } from 'src/redux/features/report-video/reportVideoSlice';
import { checkVideoReported } from 'src/redux/features/videos/videoSlice';
import config from 'src/config';
import { useActiveChainId } from 'src/hooks';

const modalIds = {
  share: 'share11',
  save: 'save22',
  unsubscribe: 'unsubscribe33',
  reportVideo: 'report-video44',
};

export default function AuthorBox({
  subscriberCount,
  imgAlt = '',
  imgSrc,
  isSubscribed = false,
  channelHref = '#',
  channelName,
  verified = null,
  publishedOn,
  userLiked,
  userDisliked,
  likeCount,
  channelId,
  userSubscribed,
  isWatchLater,
  videoData,
  visibility,
  contract_video_id,
  subscribeTo,
  handle_name,
}) {
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const { video_url_id } = useParams();
  const history = useHistory();
  const account = useAccount();
  const dispatch = useDispatch();
  const { abi, contract_address } = useFindContractData();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);
  const [likeVideo] = useLikeVideoMutation();
  const [dislikeVideo] = useDislikeVideoMutation();
  const [subscribe] = useSubscribeMutation();
  const [unsubscribe] = useUnsubscribeMutation();
  const { mode } = useContext(ThemeContext);
  const [processing, setProcessing] = useState(false);
  const [saveBlock] = useSaveBlockTransactionMutation();

  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const likeContract = useWriteContract();
  const dislikeContract = useWriteContract();
  const subscribeContract = useWriteContract();
  const unsubscribeContract = useWriteContract();

  const handleLike = async () => {
    if (!authData?.token) return toast.info(messages.require_signin);
    if (activeChainId !== config.ACTIVE_CHAIN.id) {
      await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
    }
    try {
      const response = await dispatch(
        pendingTxnCheck({ id: videoData.id, type: 'like' })
      ).unwrap();
      if (response.data.txn_status === COMMON_STATUS.PROCESSING) {
        return toast.info(response.message || messages.pending_transaction);
      }

      setProcessing(true);
      const args = [contract_video_id];
      if (userLiked) {
        const hash = await dislikeContract.writeContractAsync({
          args,
          address: contract_address,
          abi: JSON.parse(abi),
          functionName: 'unlikeVideo',
          chainId: config.ACTIVE_CHAIN.id,
          ...(await fetchFeeData()),
        });
        await likeVideo({
          videoId: videoData.id,
          body: { hash },
          extraArg: { video_url_id },
        });
        // const block_transaction_data = {
        //   action: 'unlikeVideo',
        //   address_out: account.address,
        //   address_in: contract_address,
        //   hash,
        //   network_type: 5,
        //   request: JSON.stringify(args),
        // };
        // saveBlock({ body: block_transaction_data });
      } else {
        const hash = await likeContract.writeContractAsync({
          args: [contract_video_id],
          address: contract_address,
          abi: JSON.parse(abi),
          functionName: 'likeVideo',
          chainId: config.ACTIVE_CHAIN.id,
          ...(await fetchFeeData()),
        });
        await likeVideo({
          videoId: videoData.id,
          body: { hash },
          extraArg: { video_url_id },
        });
        // const block_transaction_data = {
        //   action: 'likeVideo',
        //   address_out: account.address,
        //   address_in: contract_address,
        //   hash,
        //   network_type: 5,
        //   request: JSON.stringify(args),
        // };
        // saveBlock({ body: block_transaction_data });
      }
      setProcessing(false);
    } catch (error) {
      handleError(error);
      setProcessing(false);
    }
  };
  const handleDislike = async () => {
    if (!authData?.token) return toast.info(messages.require_signin);
    if (activeChainId !== config.ACTIVE_CHAIN.id) {
      await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
    }
    try {
      const response = await dispatch(
        pendingTxnCheck({ id: videoData.id, type: 'like' })
      ).unwrap();
      if (response.data.txn_status === COMMON_STATUS.PROCESSING) {
        return toast.info(response.message || messages.pending_transaction);
      }

      // if already liked then remove form blockchain also
      const args = [contract_video_id];
      if (userLiked) {
        setProcessing(true);
        const hash = await dislikeContract.writeContractAsync({
          args,
          address: contract_address,
          abi: JSON.parse(abi),
          functionName: 'unlikeVideo',
          chainId: config.ACTIVE_CHAIN.id,
          ...(await fetchFeeData()),
        });
        await dislikeVideo({
          videoId: videoData.id,
          body: { hash },
          extraArg: { video_url_id },
        });
        // const block_transaction_data = {
        //   action: 'likeVideo',
        //   address_out: account.address,
        //   address_in: contract_address,
        //   hash,
        //   network_type: 5,
        //   request: JSON.stringify(args),
        // };
        // saveBlock({ body: block_transaction_data });
      } else {
        await dislikeVideo({
          videoId: videoData.id,
          extraArg: { video_url_id },
        });
      }
      setProcessing(false);
    } catch (error) {
      handleError(error);
      setProcessing(false);
    }
  };
  return (
    <>
      <ProcessingModal
        metamask={true}
        show={processing}
        handleClose={() => setProcessing(false)}
      />
      <SaveToModal
        isWatchLater={isWatchLater}
        show={show === modalIds.save}
        handleClose={handleClose}
      />
      <ConfirmModal
        show={show === modalIds.unsubscribe}
        handleClose={handleClose}
        onConfirm={async () => {
          handleClose();
          if (!authData?.token) return toast.info(messages.require_signin);
          if (activeChainId !== config.ACTIVE_CHAIN.id) {
            await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
          }
          const response = await dispatch(
            pendingTxnCheck({ id: channelId, type: 'subscribe' })
          ).unwrap();
          if (response.data.txn_status === COMMON_STATUS.PROCESSING) {
            return toast.info(response.message || messages.pending_transaction);
          }

          setProcessing(true);
          const args = [subscribeTo];
          try {
            const hash = await unsubscribeContract.writeContractAsync({
              args,
              address: contract_address,
              abi: JSON.parse(abi),
              functionName: 'unsubscribe',
              chainId: config.ACTIVE_CHAIN.id,
              ...(await fetchFeeData()),
            });
            // const receipt = await waitForTransactionReceipt({ hash });
            handleClose();
            // const block_transaction_data = {
            //   action: 'unsubscribe',
            //   address_out: account.address,
            //   address_in: contract_address,
            //   hash,
            //   network_type: 5,
            //   request: JSON.stringify(args),
            //   // other_detail: stringifyWithBigInt(receipt),
            // };
            // saveBlock({ body: block_transaction_data });
            unsubscribe({
              channelId,
              videoId: videoData.id,
              handle_name,
              video_url_id,
              body: { hash, videoId: videoData.id },
            });
            setProcessing(false);
          } catch (error) {
            handleError(error);
            setProcessing(false);
          }
        }}
        title={`Unsubscribe from ${channelName}?`}
        confirmButtonText='Unsubscribe'
      />
      <div className='single-video-author mb-3 d-flex align-items-center flex-wrap justify-content-between gap'>
        <div
          className='d-flex align-items-center flex-nowrap cursor-pointer'
          onClick={() => history.push(channelHref)}
        >
          {imgSrc ? (
            <img className='img-fluid' src={imgSrc} alt={imgAlt} />
          ) : (
            <Avatar className='mr-2' size={38} character={channelName[0]} />
          )}
          <div className=''>
            <p>
              <Link
                to={channelHref}
                className='fs-400 fw-600 text-clr-dark-300'
              >
                {channelName}
              </Link>{' '}
              {verified ? <VerifiedTooltip /> : ''}
            </p>
            <p className='fs-200 text-clr-dark-100 text-left'>
              {abbreviateNumber(subscriberCount, 1)} subscribers
            </p>
          </div>
        </div>
        <div className='float-md-right d-flex align-items-center flex-nowrap author-box-right'>
          <ButtonGroup aria-label='Basic example'>
            <Button
              variant='light'
              size='sm'
              className='d-flex align-items-center'
              onClick={handleLike}
            >
              <UilThumbsUp
                size='18'
                color={
                  userLiked
                    ? COLORS.primary400
                    : mode === themeMode.LIGHT
                    ? COLORS.dark300
                    : COLORS.light
                }
              />
              <span className='ml-1 align-middle'>
                {abbreviateNumber(likeCount, 1)}
              </span>
            </Button>
            <Button
              variant='light'
              size='sm'
              className='d-flex align-items-center'
              onClick={handleDislike}
            >
              <UilThumbsDown
                size='18'
                color={
                  userDisliked
                    ? COLORS.primary400
                    : mode === themeMode.LIGHT
                    ? COLORS.dark300
                    : COLORS.light
                }
              />
            </Button>
          </ButtonGroup>
          <Button
            className='ml-2 d-none d-sm-flex align-items-center'
            size='sm'
            variant='light'
            onClick={() =>
              dispatch(handleOpenShare({ shareLink: window.location.href }))
            }
          >
            <UilShare
              size='18'
              color={mode === themeMode.LIGHT ? COLORS.dark300 : COLORS.light}
            />
            <span className='ml-1 align-middle'>Share</span>
          </Button>
          <Button
            className='ml-2 d-flex align-items-center'
            size='sm'
            variant='light'
            onClick={() => {
              if (!authData?.token) return toast.info(messages.require_signin);
              handleShow(modalIds.save);
            }}
          >
            <UilPlus
              color={mode === themeMode.LIGHT ? COLORS.dark300 : COLORS.light}
              size='18'
            />
            <span className='ml-1 align-middle'>Save</span>
          </Button>

          {videoData?.user_id !== authData?.user?.id &&
            visibility === 'PUBLIC' &&
            (userSubscribed ? (
              <Button
                className='ml-2 text-nowrap'
                size='sm'
                variant='secondary'
                onClick={() => handleShow(modalIds.unsubscribe)}
              >
                Subscribed
              </Button>
            ) : (
              <Button
                className='ml-2'
                size='sm'
                variant='primary'
                onClick={async () => {
                  if (!authData?.token)
                    return toast.info(messages.require_signin);
                  if (activeChainId !== config.ACTIVE_CHAIN.id) {
                    await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
                  }
                  const response = await dispatch(
                    pendingTxnCheck({ id: channelId, type: 'subscribe' })
                  ).unwrap();
                  if (response.data.txn_status === COMMON_STATUS.PROCESSING) {
                    return toast.info(
                      response.message || messages.pending_transaction
                    );
                  }

                  setProcessing(true);
                  const args = [subscribeTo];
                  try {
                    const hash = await subscribeContract.writeContractAsync({
                      args,
                      address: contract_address,
                      abi: JSON.parse(abi),
                      functionName: 'subscribe',
                      chainId: config.ACTIVE_CHAIN.id,
                      ...(await fetchFeeData()),
                    });
                    // const receipt = await waitForTransactionReceipt({ hash });
                    // const block_transaction_data = {
                    //   action: 'subscribe',
                    //   address_out: account.address,
                    //   address_in: contract_address,
                    //   hash,
                    //   network_type: 5,
                    //   request: JSON.stringify(args),
                    //   // other_detail: stringifyWithBigInt(receipt),
                    // };
                    // saveBlock({ body: block_transaction_data });
                    await subscribe({
                      channelId,
                      videoId: videoData.id,
                      video_url_id,
                      handle_name,
                      body: { hash, videoId: videoData.id },
                    });
                    setProcessing(false);
                  } catch (error) {
                    handleError(error);
                    setProcessing(false);
                  }
                }}
              >
                Subscribe
              </Button>
            ))}

          <Dropdown>
            <Dropdown.Toggle as='a' className=' no-after'>
              <Button className='ml-2' size='sm' variant='light'>
                <UilMenuV
                  color={
                    mode === themeMode.LIGHT ? COLORS.dark300 : COLORS.light
                  }
                  size='18'
                />
              </Button>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className='d-block d-sm-none'
                onClick={() =>
                  dispatch(handleOpenShare({ shareLink: window.location.href }))
                }
              >
                <UilShare
                  color={
                    mode === themeMode.LIGHT ? COLORS.dark300 : COLORS.light
                  }
                  size='18'
                  className='mr-2'
                />
                Share
              </Dropdown.Item>
              <Dropdown.Item
                onClick={async () => {
                  if (!authData)
                    return toast.error(messages.require_signin_to_report);
                  if (videoData.is_reported)
                    return toast.info(messages.video_reported_already);

                  try {
                    const report = await dispatch(
                      checkVideoReported(videoData.id)
                    ).unwrap();
                    const is_reported = report.data.is_reported;

                    if (is_reported)
                      return toast.info(messages.video_reported_already);

                    dispatch(
                      openReportVideoModal({
                        contractVideoId: contract_video_id,
                        videoId: videoData.id,
                        videoUrlId: videoData.video_url_id,
                        timestampInSec:
                          document.getElementById(video_url_id)?.currentTime,
                      })
                    );
                  } catch (error) {}
                }}
              >
                {/* <UilExclamation
                  size='18'
                  className='mr-2'
                  color={
                    mode === themeMode.LIGHT ? COLORS.dark300 : COLORS.light
                  }
                /> */}
                <UilExclamationOctagon
                  color={
                    mode === themeMode.LIGHT ? COLORS.dark300 : COLORS.light
                  }
                  size='18'
                  className='mr-2'
                />
                {videoData.is_reported ? 'Reported' : 'Report Video'}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}
