import './UploadVideo.css';

import Container from 'react-bootstrap/Container';
import { createContext, useContext, useEffect } from 'react';
import { useState } from 'react';

import { useLocation } from 'react-router-dom';
import {
  useFetchVideoByIdQuery,
  useSaveVideoDetailsInDraftMutation,
} from 'src/redux/features/videos/videoApi';
import StepOne from './components/StepOne';
import StepTwo, { tagValidation } from './components/StepTwo';
import StepThree from './components/StepThree';
import { ThinFooter } from 'src/components/footer';
import axios from 'axios';
import { toast } from 'react-toastify';
import handleError from 'src/utils/handle-error';
import { SERVER_BASE_URL } from 'src/constant';
import rootApi, { defaultHeaders } from 'src/redux/rootApi';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { useDispatch } from 'react-redux';

export const FormContext = createContext();

const Step = () => {
  let stepContent;
  const { activeStepIndex } = useContext(FormContext);
  switch (activeStepIndex) {
    case 0:
      stepContent = <StepOne />;
      break;
    case 1:
      stepContent = <StepTwo />;
      break;
    case 2:
      stepContent = <StepThree />;
      break;
    default:
      break;
  }
  return stepContent;
};

const Upload = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const videoUrlId = searchParams.get('vid');
  const videoType = searchParams.get('vtype');

  const [activeStepIndex, setActiveStepIndex] = useState(videoUrlId ? 1 : 0);
  const [videoDetailStore, setVideoDetailStore] = useState({
    comments_enabled: true,
    // is_short: false,
  });
  const [isSavingInDraft, setIsSavingInDraft] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);

  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const {
    data: videoData,
    isLoading,
    refetch,
  } = useFetchVideoByIdQuery(videoUrlId, {
    skip: !videoUrlId,
  });
  const [saveVideoDetailsInDraft] = useSaveVideoDetailsInDraftMutation();
  const draftApiSource = axios.CancelToken.source();

  console.log('render root', videoDetailStore);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  useEffect(() => {
    if (videoData && videoUrlId) {
      console.log('render root useEffect inside');
      const url = `${SERVER_BASE_URL}/videos/${videoData.id}/thumbnails`;
      axios
        .get(url, {
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${authData?.token}`,
          },
        })
        .then((res) => {
          const thumbnails = res?.data?.data?.thumbnails || [];
          console.log(res.data, 'res');
          setVideoDetailStore((prev) => ({
            ...prev,
            thumbnails: thumbnails,
          }));
        })
        .catch((error) => console.log(error, 'error'));
      setVideoDetailStore((prev) => ({
        ...prev,
        videoId: videoData?.id,
        contract_video_id: videoData?.contract_video_id,
        video_url: videoData?.video_url,
        video_url_id: videoData?.video_url_id,
        title: videoData?.title,
        description: videoData?.description,
        category_id: videoData?.category_id,
        comments_enabled: Boolean(videoData?.comments_enabled),
        thumbnail_url: videoData?.thumbnail_url,
        thumbnail_id: videoData?.thumbnail_id,
        visibility: videoData?.visibility,
        tags: videoData?.tags ? JSON.parse(videoData?.tags) : [],
        txn_status: videoData?.txn_status,
        video_hash: videoData?.video_hash,
      }));
      setVideoProgress(100);
    }
  }, [videoData, videoUrlId]);

  useEffect(() => {
    if (!videoDetailStore?.videoId || videoType === 'EDIT') return;
    const updateDraft = async () => {
      // on edit it draft functionality is disabled
      const {
        title,
        description,
        category_id,
        comments_enabled,
        visibility,
        tags,
        thumbnail_id,
      } = videoDetailStore;
      const formData = {};

      if (title) formData.title = title;
      if (description) formData.description = description;
      if (category_id) formData.category_id = category_id;
      if (thumbnail_id) formData.thumbnail_id = thumbnail_id;

      if (tags && tags?.length > 0) {
        try {
          await tagValidation.validate(tags);
          formData.tags = tags;
        } catch (error) {
          handleError(error);
        }
      } else formData.tags = [];

      if (visibility)
        formData.visibility = videoType === 'EDIT' ? visibility : 'DRAFT';
      // if (comments_enabled) // if comments_enabled false then I will not work
      // formData.append(
      //   'comments_enabled',
      //   videoType === 'EDIT' ? comments_enabled : false
      // );
      formData.comments_enabled = comments_enabled || false;

      // const formData = new FormData();

      // if (title) formData.append('title', title);
      // if (description) formData.append('description', description);
      // if (category_id) formData.append('category_id', category_id);
      // if (thumbnail) formData.append('thumbnail', thumbnail);

      // if (tags && tags?.length > 0) {
      //   try {
      //     await tagValidation.validate(tags);
      //     formData.append('tags', JSON.stringify(tags));
      //   } catch (error) {
      //     handleError(error);
      //   }
      // }

      // if (visibility)
      //   formData.append(
      //     'visibility',
      //     videoType === 'EDIT' ? visibility : 'DRAFT'
      //   );
      // // if (comments_enabled) // if comments_enabled false then I will not work
      // // formData.append(
      // //   'comments_enabled',
      // //   videoType === 'EDIT' ? comments_enabled : false
      // // );
      // formData.append('comments_enabled', comments_enabled || false);

      try {
        await saveVideoDetailsInDraft({
          videoId: videoDetailStore.videoId,
          formData,
        }).unwrap();
        setIsSavingInDraft(false);
      } catch (error) {
        setIsSavingInDraft(false);
        handleError(error);
      }
    };

    setIsSavingInDraft(true);
    const interval = setTimeout(updateDraft, 5000); // 5 seconds
    // Clear the timer when the component unmounts
    return () => clearInterval(interval);
  }, [saveVideoDetailsInDraft, videoDetailStore, videoType]);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      // fetch updated video data when leaves the component
      if (videoData) {
        dispatch(
          rootApi.util.invalidateTags([{ type: 'VIDEO', id: videoData.id }])
        );
      }
    };
  }, []);

  if (videoUrlId && isLoading) return null;
  return (
    <FormContext.Provider
      value={{
        activeStepIndex,
        setActiveStepIndex,
        videoDetailStore,
        setVideoDetailStore,
        setVideoProgress,
        videoProgress,
        isSavingInDraft,
        draftApiSource,
      }}
    >
      {/* <div fluid className=' pt-5 pb-5'> */}
      <Container fluid='xxl' className='p-2 p-md-4'>
        <Step />
      </Container>
      {/* </div> */}
    </FormContext.Provider>
  );
};

export default Upload;
