import React from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Constant from '../../constant';
import './CreateChannel.css';
import { useCreateChannelMutation } from '../../redux/features/channels/channelApi';
import handleError from 'src/utils/handle-error';
import { ThinFooter } from 'src/components/footer';

const requiredMessage = "Field can't be empty";

export const yupHandleNameValidation = Yup.string()
  // .matches(/^[^\s]+$/, 'Handle Name should not contain spaces')
  // .matches(
  //   /^@[\w]{1,15}$/,
  //   "Please enter a handle name starting with '@' followed letters, digits, or underscores."
  // )
  .matches(
    /^@[a-zA-Z0-9_-]+$/,
    "Please enter a handle name starting with '@' followed letters, digits, or underscores."
  )
  .required(requiredMessage);

const createChannelSchema = Yup.object().shape({
  name: Yup.string().max(50).required(requiredMessage),
  handle_name: yupHandleNameValidation,
  acceptedTerms: Yup.boolean().oneOf(
    [true],
    'You must accept the terms and conditions.'
  ),
});

const CreateChannel = () => {
  const history = useHistory();
  const [createChannelApi, { isLoading }] = useCreateChannelMutation();

  return (
    <>
      <Container
        fluid='xxl'
        className='p-2 p-md-4 d-flex justify-content-center align-items-center'
      >
        <div id='create-channel-content'>
          <img
            src={`${Constant.BASE_URL}img/illustration/create-channel.svg`}
            alt='create-channel-svg'
          />
          <h2 className='text-center mt-2 fs-500 font-weight-900 text-clr-dark-300'>
            Lets get started with your channel
          </h2>
          <p className='text-center'>
            Please enter the major credentials, your name, your channel's handle
            name, etc to proceed further towards your channel activaton.
          </p>
          <Formik
            initialValues={{
              name: '',
              handle_name: '',
              acceptedTerms: false,
            }}
            validationSchema={createChannelSchema}
            onSubmit={async (values, { resetForm }) => {
              const { handle_name, name } = values;
              try {
                const result = await createChannelApi({
                  name,
                  handle_name,
                }).unwrap();
                toast.success(result.message);
                history.replace(`${Constant.BASE_URL}studio`);
                resetForm();
              } catch (error) {
                handleError(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              values,
              touched,
              handleSubmit,
              isValid,
            }) => (
              <>
                <Form.Group controlId='create-channel-name'>
                  <Form.Label>Your name</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    placeholder='Your Name'
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                    disabled={isLoading}
                  />
                  <Form.Control.Feedback
                    type='invalid'
                    className='position-relative'
                  >
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='create-channel-handle-name'>
                  <Form.Label>Handle Name</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    placeholder='@'
                    name='handle_name'
                    value={values.handle_name}
                    onChange={(e) => {
                      if (!values.handle_name)
                        e.target.value = '@' + e.target.value;
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.handle_name && !!errors.handle_name}
                    disabled={isLoading}
                  />
                  <Form.Control.Feedback
                    type='invalid'
                    className='position-relative'
                  >
                    {errors.handle_name}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className='term-check-div'>
                  <Form.Check
                    type='checkbox'
                    custom
                    id='term-condition-check-box'
                    name='acceptedTerms'
                    checked={values.acceptedTerms}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.acceptedTerms && !!errors.acceptedTerms}
                    disabled={isLoading}
                  />
                  <Form.Label htmlFor='term-condition-check-box'>
                    You have agreed to Mirror's{' '}
                    <a
                      className='fw-400'
                      href={`${Constant.BASE_URL}privacy-policy`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Privacy Policy
                    </a>
                    ,{' '}
                    <a
                      className='fw-400'
                      href={`${Constant.BASE_URL}terms`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Rules and Regulation
                    </a>
                    , that you will abide by all the internal updates and
                    policies of the platform.
                  </Form.Label>
                </div>
                <Button
                  variant='primary'
                  className='ml-auto mr-auto mt-4 px-4'
                  type='submit'
                  onClick={handleSubmit}
                  disabled={!isValid || isLoading}
                >
                  {isLoading ? 'Processing ...' : 'Create Channel'}
                </Button>
              </>
            )}
          </Formik>
        </div>
      </Container>
      <ThinFooter />
    </>
  );
};

export default CreateChannel;
