import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from 'react-bootstrap';
import UilCopy from '@iconscout/react-unicons/icons/uil-copy';
import CopyToClipboard from 'react-copy-to-clipboard';
import UilCheckCircle from '@iconscout/react-unicons/icons/uil-check-circle';
import moment from 'moment';

import Constant, { COLORS } from 'src/constant';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

const VideoPublishModal = ({
  show,
  handleClose,
  data,
  thumbnailUrl = null,
}) => {
  const [copied, setCopied] = useState(false);
  const shareLink = data?.video_page_link;
  console.log(data, 'data in publish modal');
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        id='video-published-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title>Video Published</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='d-flex justify-justify-content-start py-4 rounded-lg gray-box m-0'>
            <Col md={4}>
              {data?.thumbnail_url ? (
                <img
                  style={{
                    objectFit: 'contain',
                    aspectRatio: '16/9',
                  }}
                  src={data?.thumbnail_url}
                  className='w-100'
                  alt='thumbnail'
                />
              ) : (
                <img
                  src={`${Constant.BASE_URL}img/light/default-thumbnail.png`}
                  className='w-100'
                  alt='thumbnail'
                />
              )}
            </Col>
            <Col md={8} className='mt-2 mt-md-0'>
              <h6 className='fs-400 fw-600 text-line-2'>{data?.title}</h6>
              <p>Published {moment(new Date()).from()}</p>
            </Col>
          </Row>
          <div className='my-4 px-4 py-2 rounded-lg gray-box w-100'>
            <small className='d-block fs-200 text-clr-dark-100 '>
              Video Link
            </small>
            <div className='d-flex justify-content-between align-items-center'>
              <a
                href={shareLink}
                className='btn-link overflow-hidden w-100 text-ellipse'
                target='_blank'
                rel='noreferrer'
              >
                {shareLink}
              </a>
              {!copied ? (
                <CopyToClipboard
                  text={shareLink}
                  onCopy={() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 4000);
                  }}
                >
                  <div className='cursor-pointer'>
                    <UilCopy color={COLORS.primary400} size='20' />
                  </div>
                </CopyToClipboard>
              ) : (
                <UilCheckCircle color={COLORS.primary400} size='20' />
              )}
              {/* <img
                src={themeImageUrlParser('copy.svg')}
                alt='copy'
                style={{ width: '16px' }}
              /> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='flex-column'>
          <h5 className='fs-400 fs-600'>Share your link</h5>
          <div className='d-block'>
            <FacebookShareButton
              url={shareLink}
              className='mx-1 mt-4 social-icon-link'
            >
              <img
                style={{ maxWidth: '50px' }}
                alt='facebook'
                src={`${Constant.BASE_URL}img/facebook.svg`}
              />
            </FacebookShareButton>
            <TwitterShareButton
              url={shareLink}
              className='mx-1 mt-4 social-icon-link'
            >
              <img
                style={{ maxWidth: '50px' }}
                alt='twitter'
                src={`${Constant.BASE_URL}img/twitter.svg`}
              />
            </TwitterShareButton>
            <EmailShareButton
              url={shareLink}
              to='#'
              className='mx-1 mt-4 social-icon-link'
            >
              <img
                style={{ maxWidth: '50px' }}
                alt='mail'
                src={`${Constant.BASE_URL}img/mail.svg`}
              />
            </EmailShareButton>
            <WhatsappShareButton
              url={shareLink}
              to='#'
              className='mx-1 mt-4 social-icon-link'
            >
              <img
                style={{ maxWidth: '50px' }}
                alt='whatsapp'
                src={`${Constant.BASE_URL}img/whatsapp.svg`}
              />
            </WhatsappShareButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VideoPublishModal;
