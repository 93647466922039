import React, { useContext, useRef, useState } from 'react';
import shaka from 'shaka-player';
import {
  Player as ShakaPlayer,
  ui as ShakaUI,
} from 'shaka-player/dist/shaka-player.ui';

import { Button, Dropdown } from 'react-bootstrap';
import UilThumbsUp from '@iconscout/react-unicons/icons/uil-thumbs-up';
import UilThumbsDown from '@iconscout/react-unicons/icons/uil-thumbs-down';
import UilShare from '@iconscout/react-unicons/icons/uil-share';
import UilMenuH from '@iconscout/react-unicons/icons/uil-ellipsis-h';
import UilChat from '@iconscout/react-unicons/icons/uil-comment-alt-lines';
import UilExclamationOctagon from '@iconscout/react-unicons/icons/uil-exclamation-octagon';
import UilParagraph from '@iconscout/react-unicons/icons/uil-paragraph';
import UilPlay from '@iconscout/react-unicons/icons/uil-play';
import UilPause from '@iconscout/react-unicons/icons/uil-pause';
import UilMute from '@iconscout/react-unicons/icons/uil-volume-mute';
import UilVolume from '@iconscout/react-unicons/icons/uil-volume';
import { Link, useHistory } from 'react-router-dom';
import { abbreviateNumber } from 'js-abbreviation-number';

import Constant, {
  COLORS,
  COMMON_STATUS,
  NETWORK_TYPE,
  breakpoints,
} from 'src/constant';
import {
  useDislikeVideoMutation,
  useLikeVideoMutation,
  useViewVideoMutation,
} from 'src/redux/features/videos/videoApi';
import {
  useSubscribeMutation,
  useUnsubscribeMutation,
} from 'src/redux/features/subscribers/subscribersApi';
import {
  pendingTxnCheck,
  useSaveBlockTransactionMutation,
} from 'src/redux/features/basic/basicApi';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { useAccount, useWriteContract, useSwitchChain } from 'wagmi';
import { toast } from 'react-toastify';
import handleError from 'src/utils/handle-error';
import { ThemeContext } from 'src/App';
import { ConfirmModal, ProcessingModal } from 'src/components/atoms/modals';
import { useEffect } from 'react';
import { IconButton } from 'src/components/atoms/buttons';
import Comments from './Comments';
import Description from './Description';
import Avatar from 'src/components/atoms/Avatar';
import { fetchFeeData } from 'src/utils/gas-price';
import { useDispatch, useSelector } from 'react-redux';
import { setMuted } from 'src/redux/features/videos/shortSlice';
import { useAddWatchHistoryMutation } from 'src/redux/features/watch-history/watchHistoryApi';
import useFindContractData from 'src/hooks/useFindContractData';
import { handleOpenShare } from 'src/redux/features/share/shareSlice';
import { openReportVideoModal } from 'src/redux/features/report-video/reportVideoSlice';
import messages from 'src/constant/messages';
import { checkVideoReported } from 'src/redux/features/videos/videoSlice';
import { UilArrowLeft } from '@iconscout/react-unicons';
import { useMediaQuery } from 'react-responsive';
import config from 'src/config';
import { useActiveChainId } from 'src/hooks';
import UisClock from '@iconscout/react-unicons-solid/icons/uis-clock';
import { useAddWatchLaterMutation } from 'src/redux/features/watch-later/watchLaterApi';

const modalIds = {
  share: 'share11',
  save: 'save22',
  unsubscribe: 'unsubscribe33',
  reportVideo: 'report-video44',
  comments: 'comments-video55',
  description: 'description-video55',
};

const ShortPlayer = ({ item }) => {
  const activeChainId = useActiveChainId();

  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const account = useAccount();
  const history = useHistory();
  const videoRef = useRef(null);
  const bigPlayBtnRef = useRef(null);
  const videoContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { mode } = useContext(ThemeContext);
  const [processing, setProcessing] = useState(false);
  const [show, setShow] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [shouldHandleTimeUpdate, setShouldHandleTimeUpdate] = useState(true);
  const { abi, contract_address } = useFindContractData();

  const { muted } = useSelector((state) => state.shortStore);
  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);

  const [likeVideo] = useLikeVideoMutation();
  const [dislikeVideo] = useDislikeVideoMutation();
  const [subscribe] = useSubscribeMutation();
  const [unsubscribe] = useUnsubscribeMutation();
  // const [saveBlock] = useSaveBlockTransactionMutation();
  const [viewVideo] = useViewVideoMutation();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.custom_xs}px)`,
  });
  // useEffect(() => {
  let player;
  async function initPlayer(url) {
    // shaka.polyfill.installAll();
    // // Check to see if the browser supports the basic APIs Shaka needs.
    // if (!shaka.Player.isBrowserSupported()) {
    //   // Everything looks good!
    //   alert('Shaka Player is not supported');
    // }
    if (!url) return alert('no url provided');
    // Create a Player instance.
    // player = new ShakaPlayer(videoRef.current);
    player = new ShakaPlayer();
    await player.attach(videoRef.current);
    try {
      await player.load(url);
      // alert('loaded');
      videoRef.current.play();
      // This runs if the asynchronous load is successful.
      setLoaded(true);
      console.log('The video has now been loaded!');
    } catch (e) {
      // onError is executed if the asynchronous load fails.
      handlePlayerError(e);
    }
  }

  const handlePlayerError = (event) => {
    console.log('Shaka Player Error:', event);
  };

  //   if (item.video_url && item.video_url.includes('mpd')) {
  //     // Install built-in polyfills to patch browser incompatibilities.
  //     shaka.polyfill.installAll();
  //     // Check to see if the browser supports the basic APIs Shaka needs.
  //     if (shaka.Player.isBrowserSupported()) {
  //       // Everything looks good!
  //       initPlayer(item.video_url);
  //     } else {
  //       // This browser does not have the minimum set of APIs we need.
  //       console.error('Browser not supported!');
  //     }
  //   }

  //   // Cleanup function: Remove the error event listener and stop playback when component unmounts
  //   // return () => {
  //   //   if (videoRef.current) {
  //   //     const player = new shaka.Player(videoRef.current);
  //   //     player.unload(); // Unload the video
  //   //   }
  //   // };
  //   return () => {
  //     if (player) {
  //       player.unload(); // Unload the video
  //     }
  //   };
  // }, [item.video_url]);

  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const likeContract = useWriteContract();
  const dislikeContract = useWriteContract();
  const subscribeContract = useWriteContract();
  const unsubscribeContract = useWriteContract();

  const [addWatchHistory] = useAddWatchHistoryMutation();
  const [addWatchLater] = useAddWatchLaterMutation();

  const handleLike = async () => {
    if (!authData?.token) return toast.info(messages.require_signin);
    if (activeChainId !== config.ACTIVE_CHAIN.id) {
      await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
    }
    try {
      const response = await dispatch(
        pendingTxnCheck({ id: item.id, type: 'like' })
      ).unwrap();
      if (response.data.txn_status === COMMON_STATUS.PROCESSING) {
        return toast.info(response.message || messages.pending_transaction);
      }

      setProcessing(true);
      const args = [item.contract_video_id];
      if (item.user_liked) {
        const hash = await dislikeContract.writeContractAsync({
          args,
          address: contract_address,
          abi: JSON.parse(abi),
          functionName: 'unlikeVideo',
          chainId: config.ACTIVE_CHAIN.id,
          ...(await fetchFeeData()),
        });
        console.log(hash, 'hash');
        // await waitForTransactionReceipt({ hash });
        // const block_transaction_data = {
          //   action: 'unlikeVideo',
          //   address_out: account.address,
          //   address_in: contract_address,
          //   hash,
          //   network_type: NETWORK_TYPE.MATIC,
          //   request: JSON.stringify(args),
        // };
        // saveBlock({ body: block_transaction_data });
        await likeVideo({
          videoId: item.id,
          body: { hash },
          extraArg: { video_url_id: item.video_url_id },
        });
      } else {
        console.log('working hai yaha');
        const hash = await likeContract.writeContractAsync({
          args,
          address: contract_address,
          abi: JSON.parse(abi),
          functionName: 'likeVideo',
          chainId: config.ACTIVE_CHAIN.id,
          ...(await fetchFeeData()),
        });
        console.log(hash, 'top');
        // const block_transaction_data = {
          //   action: 'likeVideo',
          //   address_out: account.address,
          //   address_in: contract_address,
          //   hash: hash,
          //   network_type: NETWORK_TYPE.MATIC,
          //   request: JSON.stringify(args),
        // };
        // saveBlock({ body: block_transaction_data });
        await likeVideo({
          videoId: item.id,
          body: { hash },
          extraArg: { video_url_id: item.video_url_id },
        });
      }
      setProcessing(false);
    } catch (error) {
      handleError(error);
      setProcessing(false);
    }
  };

  const handleDislike = async () => {
    if (!authData?.token) return toast.info(messages.require_signin);
    try {
      const response = await dispatch(
        pendingTxnCheck({ id: item.id, type: 'like' })
      ).unwrap();
      if (response.data.txn_status === COMMON_STATUS.PROCESSING) {
        return toast.info(response.message || messages.pending_transaction);
      }

      setProcessing(true);
      // if already liked then remove form blockchain also
      if (item.user_liked) {
        if (activeChainId !== config.ACTIVE_CHAIN.id) {
          await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
        }
        const args = [item.contract_video_id];
        const hash = await dislikeContract.writeContractAsync({
          args,
          address: contract_address,
          abi: JSON.parse(abi),
          functionName: 'unlikeVideo',
          chainId: config.ACTIVE_CHAIN.id,
          ...(await fetchFeeData()),
        });
        // await waitForTransactionReceipt({ hash });
        // const block_transaction_data = {
          //   action: 'likeVideo',
          //   address_out: account.address,
          //   address_in: contract_address,
          //   hash,
          //   network_type: NETWORK_TYPE.MATIC,
          //   request: JSON.stringify(args),
        // };
        // saveBlock({ body: block_transaction_data });
        dislikeVideo({
          videoId: item.id,
          body: { hash },
          extraArg: { video_url_id: item.video_url_id },
        });
      } else {
        dislikeVideo({
          videoId: item.id,
          extraArg: { video_url_id: item.video_url_id },
        });
      }
      setProcessing(false);
    } catch (error) {
      handleError(error);
      setProcessing(false);
    }
  };

  const handleSubscribe = async () => {
    if (!authData?.token) return toast.info(messages.require_signin);
    if (activeChainId !== config.ACTIVE_CHAIN.id) {
      await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
    }
    const response = await dispatch(
      pendingTxnCheck({ id: item.channel_id, type: 'subscribe' })
    ).unwrap();
    if (response.data.txn_status === COMMON_STATUS.PROCESSING) {
      return toast.info(response.message || messages.pending_transaction);
    }

    setProcessing(true);
    const args = [item.block_address];
    try {
      const hash = await subscribeContract.writeContractAsync({
        args,
        address: contract_address,
        abi: JSON.parse(abi),
        functionName: 'subscribe',
        chainId: config.ACTIVE_CHAIN.id,
        ...(await fetchFeeData()),
      });
      // await waitForTransactionReceipt({ hash });
      // const block_transaction_data = {
        //   action: 'subscribe',
        //   address_out: account.address,
        //   address_in: contract_address,
        //   hash,
        //   network_type: NETWORK_TYPE.MATIC,
        //   request: JSON.stringify(args),
      // };
      // saveBlock({ body: block_transaction_data });
      await subscribe({
        channelId: item.channel_id,
        videoId: item.id,
        video_url_id: item.video_url_id,
        handle_name: item.handle_name,
        body: { videoId: item.id, hash },
      });
      setProcessing(false);
    } catch (error) {
      handleError(error);
      setProcessing(false);
    }
  };

  const handleUnsubscribe = async () => {
    handleClose();
    if (!authData?.token) return toast.info(messages.require_signin);
    if (activeChainId !== config.ACTIVE_CHAIN.id) {
      await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
    }
    setProcessing(true);
    const args = [item.block_address];
    try {
      const hash = await unsubscribeContract.writeContractAsync({
        args,
        address: contract_address,
        abi: JSON.parse(abi),
        functionName: 'unsubscribe',
        chainId: config.ACTIVE_CHAIN.id,
        ...(await fetchFeeData()),
      });
      // await waitForTransactionReceipt({ hash });
      // const block_transaction_data = {
        //   action: 'unsubscribe',
        //   address_out: account.address,
        //   address_in: contract_address,
        //   hash,
        //   network_type: 5,
        //   request: JSON.stringify(args),
      // };
      // saveBlock({ body: block_transaction_data });
      await unsubscribe({
        channelId: item.channel_id,
        videoId: item.id,
        handle_name: item.handle_name,
        body: { videoId: item.id, hash },
      });
      handleClose();
      setProcessing(false);
    } catch (error) {
      handleError(error);
      setProcessing(false);
    }
  };

  let timeoutId = null;
  const handlePlay = () => {
    if (!loaded) return;

    bigPlayBtnRef.current.style.animation = 'big-play-btn 1s';
    timeoutId = setTimeout(() => {
      if (bigPlayBtnRef.current) bigPlayBtnRef.current.style.animation = '';
    }, 1000);
    if (isPlaying) videoRef.current.pause();
    else videoRef.current.play();
    setIsPlaying(!isPlaying);
  };
  const handleMute = (e) => {
    e.stopPropagation();
    if (!loaded) return;
    dispatch(setMuted(!muted));
  };

  // // Disable scrolling when the video is loading
  // useEffect(() => {
  //   const handleScroll = () => {
  //     console.log('loaded'+ loaded)
  //     if (!loaded) {
  //       // alert('not loaded')
  //       // const videoRect = videoRef.current.getBoundingClientRect();
  //       // window.scrollTo({
  //       //   top: window.scrollY + videoRect.top,
  //       //   behavior: 'auto',
  //       // });
  //     }
  //   };

  //   // Add event listener for scroll
  //   document.querySelector('.infinite-scroll-component').addEventListener('scroll', handleScroll);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     document.querySelector('.infinite-scroll-component').removeEventListener('scroll', handleScroll);
  //   };
  // }, [loaded]);

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleTimeUpdate = () => {
      const currentTime = videoElement.currentTime;
      const totalTime = videoElement.duration;
      // currentTime >= 10% of total Time
      if (
        shouldHandleTimeUpdate &&
        (currentTime >= totalTime / 10 || currentTime > 10)
      ) {
        viewVideo(item.id);
        setShouldHandleTimeUpdate(false);
      }
    };
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [viewVideo, shouldHandleTimeUpdate, item.id]);

  // pause video when space key pressed
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 32) {
        // 32 is the keycode for the spacebar
        event.preventDefault(); // Prevent default spacebar behavior (e.g., scrolling the page)
        if (videoRef.current) {
          if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
          } else {
            videoRef.current.pause();
            setIsPlaying(true);
          }
        }
      }
    };

    // document.addEventListener('keydown', handleKeyPress);

    // return () => {
    //   document.removeEventListener('keydown', handleKeyPress);
    // };
  }, []);

  const callback = (entries) => {
    entries.forEach((element) => {
      // let video = element.target.childNodes[1];
      let video = element.target;
      // console.log(
      //   element.isIntersecting,
      //   video.id,
      //   // entries.length,
      //   'intersecting'
      // );
      if (element.isIntersecting) {
        console.log(
          element.isIntersecting,
          video.id,
          // entries.length,
          'intersecting'
        );
        let videoId = item.id;
        let videoUrlId = item.video_url_id;

        initPlayer(item.video_url);
        setIsPlaying(true);
        // video.play().catch((error) => {
        //   console.log(error, 'error');
        //   // Autoplay was prevented.
        //   video.muted = true;
        //   video.play();
        //   dispatch(setMuted(true));
        // });
        history.replace(`${Constant.BASE_URL}shorts/${videoUrlId}`);
        // console.log('before add in watch history');
        if (authData?.token) {
          addWatchHistory(videoId);
        }
      } else {
        console.log(element.isIntersecting, video.id, 'intersecting paused');
        video.pause();
        if (player) player.unload();
        setIsPlaying(false);
      }
      // // Check if the video has a valid source
      // if (child.src && child.src !== '') {
      // child.play().then(() => {
      //   //if this video is not in viewport then pause it
      //   if (!child.paused && !element.isIntersecting) {
      //     child.pause();
      //   }
      // });
      // }
    });
  };

  const observer = new IntersectionObserver(callback, {
    threshold: 0.9,
  });

  useEffect(() => {
    observer.observe(videoRef.current);
    return () => {
      if (player) player.unload();
      // if (player) player.destroy();
      console.log(player, 'player');
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {show === modalIds.comments && (
        <Comments
          videoId={item.id}
          contractVideoId={item.contract_video_id}
          handleClose={handleClose}
          show={show === modalIds.comments}
        />
      )}
      <ProcessingModal
        metamask={true}
        show={processing}
        handleClose={() => setProcessing(false)}
      />
      <ConfirmModal
        show={show === modalIds.unsubscribe}
        handleClose={handleClose}
        onConfirm={handleUnsubscribe}
        title={`Unsubscribe from ${item.channel_name}?`}
        confirmButtonText='Unsubscribe'
      />
      <Description
        show={show === modalIds.description}
        handleClose={handleClose}
        description={item.description}
      />
      <div className={`${isDesktop ? 'mb-4 pt-1' : ''}  short-card`}>
        <div
          ref={videoContainerRef}
          className='video-container'
          onClick={handlePlay}
        >
          <div className='header'></div>
          <div className='header'>
            {!isDesktop ? (
              <div onClick={() => history.push(`${Constant.BASE_URL}`)}>
                <UilArrowLeft color={COLORS.light} />
              </div>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePlay();
                  }}
                >
                  {isPlaying ? (
                    <UilPause color={COLORS.light} />
                  ) : (
                    <UilPlay color={COLORS.light} />
                  )}
                </div>
                <div onClick={handleMute} className='cursor-pointer'>
                  {muted ? (
                    <UilMute color={COLORS.light} />
                  ) : (
                    <UilVolume color={COLORS.light} />
                  )}
                </div>
              </>
            )}
          </div>
          <video
            data-shaka-player
            playsInline
            style={{ backgroundColor: COLORS.dark300, objectFit: 'cover' }}
            id={item.video_url_id}
            video_id={item.id}
            video_url_id={item.video_url_id}
            width={'100%'}
            height={'100%'}
            ref={videoRef}
            muted={muted}
            poster={item.thumbnail_url}
            loop
          />
          <div
            ref={bigPlayBtnRef}
            className='big-play-btn'
            // onClick={(e) => {
            //   e.stopPropagation();
            //   handlePlay();
            // }}
          >
            {isPlaying ? (
              <UilPause size={30} color={COLORS.light} />
            ) : (
              <UilPlay size={30} color={COLORS.light} />
            )}
          </div>
          <div className='content'>
            <h6 className='text-line-3 short-title'>{item.title}</h6>
            <div className='d-flex align-items-center profile'>
              <Link to={`${Constant.BASE_URL}channel/${item.handle_name}`}>
                {' '}
                {item.avatar_url ? (
                  <img
                    className='img-fluid'
                    style={{ width: '36px' }}
                    src={item.avatar_url}
                    alt={'profile logo'}
                  />
                ) : (
                  <Avatar
                    character={item.channel_name[0]}
                    size={40}
                    className='mr-1'
                  />
                )}
              </Link>
              <Link
                to={`${Constant.BASE_URL}channel/${item.handle_name}`}
                className='fs-400 fw-600 text-clr-dark-300 text-ellipse overflow-hidden mx-1'
              >
                {item.handle_name}
              </Link>{' '}
              {item.handle_name !== authData?.user?.handle_name && (
                <>
                  {item.user_subscribed ? (
                    <Button
                      className='ml-auto'
                      size='sm'
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShow(modalIds.unsubscribe);
                      }}
                      variant='secondary'
                    >
                      Subscribed
                    </Button>
                  ) : (
                    <Button
                      className='ml-auto'
                      variant='primary'
                      size='sm'
                      onClick={async (e) => {
                        e.stopPropagation();
                        const response = await dispatch(
                          pendingTxnCheck({
                            id: item.channel_id,
                            type: 'subscribe',
                          })
                        ).unwrap();
                        if (
                          response.data.txn_status === COMMON_STATUS.PROCESSING
                        ) {
                          return toast.info(
                            response.message || messages.pending_transaction
                          );
                        }
                        handleSubscribe();
                      }}
                    >
                      Subscribe
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* action buttons */}
        <div className='action-button-container pr-1'>
          <div className='circle-btn mb-2'>
            <IconButton
              size={40}
              onClick={handleLike}
              variant={item.user_liked ? 'primary' : 'default'}
            >
              <UilThumbsUp
                size='20'
                color={item.user_liked ? COLORS.light : COLORS.dark300}
              />
            </IconButton>
            <p className='text-center mt-1 fs-200 text-clr-dark-300'>
              {abbreviateNumber(item.like_count)}
            </p>
          </div>
          <div className='circle-btn mb-2'>
            <IconButton
              size={40}
              onClick={handleDislike}
              variant={item.user_disliked ? 'primary' : 'default'}
            >
              <UilThumbsDown size='20' />
            </IconButton>
            <p className='text-center mt-1 fs-200 text-clr-dark-300'>Dislike</p>
          </div>
          <div className='circle-btn mb-2'>
            <IconButton
              size={40}
              onClick={() => {
                if (item.comments_enabled === 1) {
                  handleShow(modalIds.comments);
                } else {
                  toast.info('Comments are turned off.');
                }
              }}
            >
              <UilChat size='20' />
            </IconButton>
            <p className='text-center mt-1 fs-200 text-clr-dark-300'>
              {abbreviateNumber(item.comment_count)}
            </p>
          </div>
          <div className='circle-btn mb-2'>
            <IconButton
              size={40}
              onClick={() =>
                dispatch(handleOpenShare({ shareLink: window.location.href }))
              }
            >
              <UilShare size='20' />
            </IconButton>
            <p className='text-center mt-1 fs-200 text-clr-dark-300'>Share</p>
          </div>
          <div className='circle-btn mb-2'>
            <Dropdown>
              <Dropdown.Toggle as='a' id='' className=' no-after'>
                <IconButton size={40}>
                  <UilMenuH size='20' />
                </IconButton>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ minWidth: 'min-content' }}>
                <Dropdown.Item
                  href='#'
                  onClick={() => handleShow(modalIds.description)}
                >
                  <UilParagraph size='18' className='mr-2' />
                  <span>Description</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href='#'
                  onClick={async () => {
                    if (!authData)
                      return toast.error(messages.require_signin_to_report);
                    if (item.is_reported)
                      return toast.info(messages.video_reported_already);

                    try {
                      const report = await dispatch(
                        checkVideoReported(item.id)
                      ).unwrap();
                      const is_reported = report.data.is_reported;

                      if (is_reported)
                        return toast.info(messages.video_reported_already);
                      dispatch(
                        openReportVideoModal({
                          contractVideoId: item.contract_video_id,
                          videoId: item.id,
                          videoUrlId: item.video_url_id,
                          timestampInSec: videoRef.current?.currentTime,
                        })
                      );
                    } catch (error) {
                      handleError(error);
                    }
                  }}
                >
                  <UilExclamationOctagon size='18' className='mr-2' />
                  Report Video
                </Dropdown.Item>
                <Dropdown.Item
                  href='#'
                  onClick={async () => {
                    if (!authData) return toast.info(messages.require_signin);
                    try {
                      const response = await addWatchLater({
                        videoId: item.id,
                        videoUrlId: item.video_url_id,
                      }).unwrap();
                      toast.success(response.message);
                    } catch (error) {
                      handleError(error);
                    }
                  }}
                >
                  <UisClock color={COLORS.dark300} size='20' className='mr-2' />
                  <span>Save to Watch later</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShortPlayer;
