import React from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import Constant, { COLORS, SORT_BY } from 'src/constant';
import { useFetchOneVideoCommentsQuery } from 'src/redux/features/comments/commentApi';
import { useFetchVideoByIdQuery } from 'src/redux/features/videos/videoApi';
import CommentMedia from 'src/pages/video-page/components/CommentMedia';
import { useState } from 'react';
import { UilParagraph } from '@iconscout/react-unicons';
import NoContent from 'src/components/atoms/NoContent/NoContent';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';

const VideoComments = () => {
  const { video_url_id } = useParams();
  const history = useHistory();
  const limit = 50;
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState('Newest First');

  const { data: videoData } = useFetchVideoByIdQuery(video_url_id, {
    skip: !video_url_id,
  });

  const { data, isLoading } = useFetchOneVideoCommentsQuery(
    { videoId: videoData?.id, sort, limit, offset },
    { skip: !Boolean(videoData?.id) }
  );

  if (isLoading) {
    return <Loader className='mt-4' />;
  }

  if (data?.comments?.length === 0)
    return (
      <NoContent
        addContainer={true}
        title='No Comments Yet'
        description='There are no comments from any viewers on the uploaded video/s of your channel on the platform.'
        imgUrl={`${Constant.BASE_URL}img/illustration/comment.svg`}
        buttonText='Go to home'
        handleButtonClick={() => history.push(`${Constant.BASE_URL}`)}
      />
    );

  return (
    <>
      <Container fluid='xxl' id='studio-comment-page' className='px-2 p-md-4'>
        <Row className='mx-0'>
          <Col md={12} className='mx-auto p-0'>
            <h3 className='mb-3 fs-500 fw-600'>Video Comments</h3>
          </Col>
          <Col md={12} className='mx-auto py-3 py-md-4 studio-card'>
            {/* comment table */}
            <div>
              <div className='my-4'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h6 className='fs-400 fw-600'>Comments</h6>
                  <Dropdown onSelect={(selected) => setSort(selected)}>
                    <Dropdown.Toggle as='a' id='' className='no-after'>
                      <div className='d-flex align-align-items-end cursor-pointer'>
                        <UilParagraph size='20' color={COLORS.dark300} />
                        <h6 className='fs-300 ml-1'>Sort By</h6>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ minWidth: 'min-content' }}>
                      <Dropdown.Item
                        eventKey={SORT_BY.NEWEST_FIRST}
                        active={sort === SORT_BY.NEWEST_FIRST}
                      >
                        {SORT_BY.NEWEST_FIRST}
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={SORT_BY.TOP_COMMENTS}
                        active={sort === SORT_BY.TOP_COMMENTS}
                      >
                        {SORT_BY.TOP_COMMENTS}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <InfiniteScroll
                    dataLength={data?.comments?.length || 0}
                    scrollThreshold={0}
                    next={() => {
                      setOffset((prev) => prev + limit);
                    }}
                    hasMore={data?.total_result_count > data?.comments?.length}
                    loader={<Loader />}
                    endMessage={
                      <p style={{ textAlign: 'center' }}>
                        {/* <b>Yay! You have seen it all</b> */}
                      </p>
                    }
                    className='overflow-hidden'
                  >
                    {data?.comments?.map((comment) => (
                      <tr key={`all-user-comment-${comment.id}`}>
                        <td colSpan={2}>
                          <CommentMedia
                            limit={limit}
                            offset={offset}
                            sort={sort}
                            commentData={{ ...comment }}
                          />
                        </td>
                      </tr>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ThinFooter />
    </>
  );
};

export default VideoComments;
