import React from 'react';
import { Card } from 'react-bootstrap';
import Circle from '../atoms/circle/Circle';

const CountCard = ({ title = '', icon: Icon, count = 0, unit = null }) => {
  return (
    <Card className='w-100'>
      <Card.Body className='d-flex align-items-center'>
        <Circle size={60} color='#FFEEF1'>
          <Icon />
        </Circle>
        <div className='ml-3'>
          <h6 className='fs-300 mb-1 text-clr-dark-300'>{title}</h6>
          <h3>
            {count}
            {unit && <span className='fs-200 fw-600 ml-1'>{unit}</span>}
          </h3>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CountCard;
