import React, { useState } from 'react';
import SectionHeader from '../../components/atoms/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import ChannelCard from '../../components/atoms/ChannelCard/ChannelCard';
import { usePopularChannelsQuery } from '../../redux/features/channels/channelApi';
import NoContent from '../../components/atoms/NoContent/NoContent';
import Constant from 'src/constant';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';
import config from 'src/config';

const PopularChannels = () => {
  const limit = config.limits.popularChannels;
  const [offset, setOffset] = useState(0);
  const { isSuccess, data, isLoading } = usePopularChannelsQuery({
    limit,
    offset,
  });
  if (data?.channels?.length === 0)
    return <NoContent title='No Popular Channel' addContainer={true} />;

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4'>
        {isLoading && <Loader />}
        {isSuccess && !!data?.channels?.length && (
          <InfiniteScroll
            dataLength={data?.channels.length}
            next={() => setOffset((prev) => prev + limit)}
            hasMore={data?.total_result_count > data?.channels?.length}
            loader={<Loader />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
            className='overflow-hidden'
          >
            <div className='video-block section-padding '>
              <Row>
                <Col md={12} className='mb-2'>
                  <SectionHeader heading='Popular Channels' noIconLabel />
                </Col>
                {data?.channels?.map((channel) => (
                  <Col
                    xl={3}
                    sm={6}
                    className='mb-3'
                    key={`popular-channel-${channel.id}`}
                  >
                    <ChannelCard
                      handleName={channel.handle_name}
                      channelId={channel.id}
                      imgSrc={channel.avatar_url}
                      verified={channel?.verified}
                      // views='1.4M'
                      channelName={channel.name}
                      subscriberCount={channel.subscribers}
                      // outline
                      isSubscribed={channel.isUserSubscribed}
                      subscribeTo={channel.block_address}
                      channelHref={`${Constant.BASE_URL}channel/${channel.handle_name}`}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </InfiniteScroll>
        )}
      </Container>
      <ThinFooter />
    </>
  );
};

export default PopularChannels;
