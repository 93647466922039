import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Constant from 'src/constant';
import { useVerifyMailMutation } from 'src/redux/features/auth/authApi';

const VerifyMail = () => {
  const { id: token } = useParams();
  const history = useHistory();
  const [verifyMail, { isLoading, data, isSuccess }] = useVerifyMailMutation();

  useEffect(() => {
    if (isSuccess && data) {
      console.log(data, 'data');
      toast.success(data.message);
      history.push(`${Constant.BASE_URL}`);
    }
  }, [isSuccess, data]);

  return (
    <div className='d-flex justify-content-center align-items-center min-h-without-navbar-footer'>
      <Container fluid='sm'>
        <center>
          <img
            src={`${Constant.BASE_URL}img/illustration/mail.png`}
            style={{
              maxWidth: '300px',
            }}
            className='mb-4'
            alt='upload'
          />
        </center>
        <h4 className='text-center'>
          Verify your email by clicking below verify button
        </h4>
        <center>
          <Button
            className='mt-4 px-4'
            disabled={isLoading}
            onClick={() => verifyMail(token)}
          >
            {isLoading ? 'Verifying' : 'Verify Mail'}
          </Button>
        </center>
      </Container>
    </div>
  );
};

export default VerifyMail;
