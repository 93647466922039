import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Constant from '../constant';
import { useAuthCheckQuery } from '../redux/features/auth/authApi';
import Loader from 'src/components/atoms/Loader';

export const isUserDetail = (user) => {
  if (user && user.firstname && user.lastname) return true;
  return false;
};

const ProtectedRoute = ({ children, routeType = 'default-route', ...rest }) => {
  const { data: authData, isLoading } = useAuthCheckQuery(undefined, {});

  if (isLoading) {
    // Render a loading state or a placeholder component
    return <Loader className='pt-4' />;
  }

  return (
    <Route
      {...rest}
      // render={(props) => {
      //   console.log(isUserDetail(authData?.user), 'user detail');
      //   if (isUserDetail(authData?.user)) {
      //     return children;
      //   }
      //   return <Redirect to={`${Constant.BASE_URL}settings`} />;
      // }}
      render={(props) => {
        if (authData) {
          if (!isUserDetail(authData.user) && !isLoading) {
            return (
              <Redirect
                to={{
                  pathname: Constant.BASE_URL + 'profile',
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            return routeType === 'channel-route' ? (
              authData?.isChannelCreated ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: Constant.BASE_URL,
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            ) : (
              children
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: Constant.BASE_URL,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
