import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, Nav, NavDropdown } from 'react-bootstrap';

import Avatar from '../atoms/Avatar';
import Constant from 'src/constant';
import {
  CreateChannelSvg,
  HelpSvg,
  ProfileSvg,
  RewardsSvg,
  SignOutSvg,
  StudioSvg,
  UploadSvg,
} from 'src/assets/svgs/profile-dropdown';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { useFetchMyChannelQuery } from 'src/redux/features/channels/channelApi';

const ProfileDropdown = ({
  //   avatarUrl,
  //   address,
  //   name,
  //   username,
  handleLogout,
}) => {
  const history = useHistory();
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });

  const { data: channel } = useFetchMyChannelQuery(undefined, {
    skip: !(authData?.token || !!localStorage.getItem('accessToken')),
  });

  const name = channel?.name || authData?.user?.full_name || 'Guest';
  const username =
    channel?.handle_name || authData?.user?.email || '@guest020890';
  const avatarUrl = channel?.avatar_url;
  const avatarCharacter =
    channel?.name[0] || authData?.user?.firstname[0] || 'G';

  return (
    <NavDropdown
      title={
        <div className='d-flex justify-content-center align-items-center'>
          {avatarUrl ? (
            <div className='nav-btn-circle'>
              <img className='img-fluid' src={avatarUrl} alt='Avatar' />
            </div>
          ) : (
            <Avatar size={36} character={avatarCharacter} />
          )}
          <span className='d-none d-md-inline ml-2 text-white'>
            {`${authData?.user?.block_address?.slice(
              0,
              4
            )}...${authData?.user?.block_address?.slice(-4)}`}
          </span>
          <img
            className='ml-1'
            alt='dropdown icon'
            src={`${Constant.BASE_URL}img/dropdown-1.svg`}
          />
        </div>
      }
      id='f'
      className='no-arrow profile-avatar-dropdown'
    >
      <NavDropdown.Item
        onClick={() => history.push(`${Constant.BASE_URL}profile`)}
        className='dropdown-item'
        rolw='button'
      >
        <div className='left'>
          {avatarUrl ? (
            <div
              className='nav-btn-circle'
              style={{
                maxWidth: '36px',
                maxHeight: '36px',
                width: '100%',
                height: '100%',
              }}
            >
              <img className='img-fluid' src={avatarUrl} alt='Avatar' />
            </div>
          ) : (
            <Avatar
              style={{
                minWidth: '36px',
                minHeight: '36px',
              }}
              size={36}
              character={
                channel?.name[0] || authData?.user?.firstname[0] || 'G'
              }
            />
          )}
          <div className='content ml-2 overflow-hidden'>
            <h6 className='h6 overflow-hidden text-ellipse'>{name}</h6>
            <p className='overflow-hidden text-ellipse text-clr-dark-300'>
              {username}
            </p>
          </div>
        </div>
        <div className='right'>
          <img src={`${Constant.BASE_URL}img/right.svg`} alt='Avatar' />
        </div>
      </NavDropdown.Item>
      <NavDropdown.Divider />
      {authData?.isChannelCreated && (
        <NavDropdown.Item
          onClick={() => history.push(`${Constant.BASE_URL}studio/upload`)}
          className='dropdown-item'
          rolw='button'
        >
          <UploadSvg />
          <h6>Upload Video</h6>
        </NavDropdown.Item>
      )}
      <NavDropdown.Item
        onClick={() =>
          history.push(
            `${Constant.BASE_URL}${
              channel ? `channel/${username}` : 'create-channel'
            }`
          )
        }
        className='dropdown-item'
        rolw='button'
      >
        <CreateChannelSvg />
        <h6>{channel ? 'Your Channel' : 'Create Channel'}</h6>
      </NavDropdown.Item>
      {authData?.token && authData?.isChannelCreated && (
        <>
          <NavDropdown.Item
            onClick={() => history.push(`${Constant.BASE_URL}studio`)}
            className='dropdown-item'
            rolw='button'
          >
            <StudioSvg />
            <h6>Mirror Studio</h6>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            onClick={() => history.push(`${Constant.BASE_URL}studio/reward`)}
            className='dropdown-item'
            rolw='button'
          >
            <RewardsSvg />
            <h6>Reward</h6>
          </NavDropdown.Item>
        </>
      )}
      {/* <Link
        to={`${Constant.BASE_URL}invites`}
        className='dropdown-item'
        rolw='button'
      >
        {' '}
        <InvitesSvg />
        <h6>Invites</h6>
      </Link>
      <Link
        to={`${Constant.BASE_URL}membership`}
        className='dropdown-item'
        rolw='button'
      >
        {' '}
        <MembershipSvg />
        <h6>Membership</h6>
      </Link> */}
      <Dropdown.Divider />
      <NavDropdown.Item
        onClick={() => history.push(`${Constant.BASE_URL}profile`)}
        className='dropdown-item'
        rolw='button'
      >
        {/* <SettingsSvg /> */}
        <ProfileSvg />
        <h6>Profile</h6>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => history.push(`${Constant.BASE_URL}help`)}
        className='dropdown-item'
        rolw='button'
      >
        <HelpSvg />
        <h6>Help</h6>
      </NavDropdown.Item>
      <NavDropdown.Divider />

      <NavDropdown.Item
        onClick={handleLogout}
        // to={'#'}
        className='dropdown-item'
        rolw='button'
      >
        <SignOutSvg />
        <h6>Sign Out</h6>
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default React.memo(ProfileDropdown);
