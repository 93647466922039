import './Sidebar.css';

import NavItem from './NavItem.component';

import Constant from '../../constant';
import { useContext, useLayoutEffect } from 'react';
import { SidebarContext } from '../../App';
import { SettingsSvg, BackSvg } from 'src/assets/svgs';
import { useFetchMyChannelQuery } from '../../redux/features/channels/channelApi';
import { withRouter, Link } from 'react-router-dom';
import { useFetchVideoByIdQuery } from 'src/redux/features/videos/videoApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import calculateDuration from 'src/utils/calculate-duration';

const StudioSidebar = ({ match, items = [], type = 'default' }) => {
  const video_url_id = match.params.video_url_id;

  const { data: videoData, isLoading } = useFetchVideoByIdQuery(video_url_id, {
    skip: !video_url_id,
  });

  const { data: channel } = useFetchMyChannelQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });

  const { openSidebar, setOpenSidebar } = useContext(SidebarContext);
  const sidebarClass = openSidebar
    ? 'sidebar navbar-nav toggled'
    : 'sidebar navbar-nav';

  useLayoutEffect(() => {
    if (window.innerWidth < 575.98) setOpenSidebar(true);
    else if (openSidebar) setOpenSidebar(false);
  }, []);

  return (
    <>
      <ul className={sidebarClass}>
        {videoData ? (
          <li className='nav-item'>
            <div className='mb-2'>
              <NavItem
                href={`${Constant.BASE_URL}studio/content`}
                label='Channel Content'
                SvgIcon={BackSvg}
              />
            </div>
            <div
              className='video-card-image mx-2'
              style={{ aspectRatio: '16 / 9' }}
            >
              <Link
                className='play-icon'
                to={`${Constant.BASE_URL}${
                  videoData.is_short ? 'shorts' : 'watch'
                }/${videoData.video_url_id}`}
              >
                <FontAwesomeIcon icon={faPlayCircle} />
              </Link>
              <Link to={`${Constant.BASE_URL}watch/${videoData.video_url_id}`}>
                <img
                  style={{ aspectRatio: '16 / 9', objectFit: 'contain' }}
                  className='img-fluid'
                  src={videoData?.thumbnail_url}
                  alt='thumbnail'
                />
              </Link>
              <div className={`time ${openSidebar ? 'd-none' : ''}`}>
                {calculateDuration(videoData.duration)}
              </div>
            </div>
            <div className='profile-root my-2'>
              <h4 className={openSidebar ? 'd-none' : 'px-2 d-block'}>
                Your Video
              </h4>
              <p
                className={
                  openSidebar
                    ? 'd-none'
                    : 'd-block px-2 text-ellipse overflow-hidden text-nowrap fs-100 fw-300'
                }
              >
                {videoData?.title}
              </p>
            </div>
          </li>
        ) : (
          <li className='nav-item'>
            <div className='profile-root mb-4'>
              {/* profile photo */}
              <div className='profile-image-circle'>
                {channel?.avatar_url ? (
                  <img
                    className='img-fluid'
                    src={channel?.avatar_url}
                    alt='Avatar'
                  />
                ) : (
                  <h2>{channel?.name[0].toUpperCase()}</h2>
                )}
              </div>
              {/* name */}
              <h4 className={openSidebar ? 'd-none' : 'd-block text-center'}>
                {channel?.name}
              </h4>
              {/* username */}
              <p className={openSidebar ? 'd-none' : 'd-block text-center'}>
                {channel?.handle_name}
              </p>
            </div>
          </li>
        )}
        {items?.map((item) => (
          <NavItem
            href={`${item.link}`}
            SvgIcon={item.icon}
            label={item.label}
          />
        ))}
        {/* <hr className='w-100' />
        <NavItem
          href={`${Constant.BASE_URL}/studio-settings`}
          label='Settings'
          SvgIcon={SettingsSvg}
        /> */}
      </ul>
    </>
  );
};

export default withRouter(StudioSidebar);
