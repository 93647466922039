import React, { useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Constant from 'src/constant';
import CommentMedia from 'src/pages/video-page/components/CommentMedia';
import { useFetchMyVideosCommentsQuery } from 'src/redux/features/comments/commentApi';
import NoContent from 'src/components/atoms/NoContent/NoContent';
import CustomPaginate from 'src/components/atoms/Paginate/CustomPaginate';
import Loader from 'src/components/atoms/Loader';

const CommentTable = () => {
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const history = useHistory();
  const { data, isLoading } = useFetchMyVideosCommentsQuery({
    limit,
    offset: (pageNumber - 1) * limit,
  });

  if (data?.comments?.length === 0)
    return (
      <NoContent
        title='No Comments Yet'
        description='There are no comments from any viewers on the uploaded video/s of your channel on the platform.'
        imgUrl={`${Constant.BASE_URL}img/illustration/comment.svg`}
        buttonText='Go to home'
        handleButtonClick={() => history.push(`${Constant.BASE_URL}`)}
      />
    );

  return (
    <div>
      <Table responsive className='my-4'>
        <thead>
          <tr>
            <th style={{ width: '30%' }}>
              <h6 className='fs-400 fw-600'>Videos</h6>
            </th>
            <th style={{ width: '60%' }}>
              <h6 className='fs-400 fw-600'>Comments</h6>
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={6} className='text-center'>
                <Loader />
              </td>
            </tr>
          )}
          {data?.comments?.map((comment) => (
            <tr key={`all-user-comment-${comment.id}`}>
              <td>
                <Row className='flex-nowrap' style={{ minWidth: '310px' }}>
                  <Col
                    md={4}
                    className='d-flex align-items-start cursor-pointer'
                    onClick={() =>
                      history.push(
                        `${Constant.BASE_URL}watch/${comment.video_url_id}`
                      )
                    }
                  >
                    <img
                      style={{
                        objectFit: 'contain',
                        aspectRatio: '16/9',
                      }}
                      src={comment.thumbnail_url}
                      className='w-100 img-fluid'
                      alt='thumbnail'
                    />
                  </Col>
                  <Col md={8}>
                    <h6 className='fs-400 fw-600 mb-1'>{comment.title}</h6>
                  </Col>
                </Row>
              </td>
              <td>
                <CommentMedia
                  className='p-0 m-0'
                  commentData={{ ...comment }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className='pr-md-4'>
        <CustomPaginate
          pageNumber={pageNumber}
          limit={limit}
          totalPages={Math.ceil(data?.total_result_count / limit)}
          totalResultCount={data?.total_result_count}
          handlePageChange={(page) => setPageNumber(page)}
          handleLimitChange={(limit) => {
            if (pageNumber !== 1) setPageNumber(1);
            setLimit(limit);
          }}
        />
      </div>
    </div>
  );
};

export default CommentTable;
