import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_BASE_URL } from 'src/constant';
import { defaultHeaders } from 'src/redux/rootApi';

export const fetchShorts = createAsyncThunk(
  'shorts',
  async (
    { limit, offset, video_url_id, token, channel_id = null },
    { getState }
  ) => {
    let url = `${SERVER_BASE_URL}/shorts?limit=${limit}&offset=${offset}`;
    if (video_url_id) url += `&video_url_id=${video_url_id}`;
    if (channel_id) url += `&channel_id=${channel_id}`;
    try {
      const result = await axios.get(url, {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${
            token || localStorage.getItem('accessToken')
          }`,
        },
      });
      return result.data;
    } catch (error) {}
  }
);

const initialState = {
  limit: 10,
  offset: 0,
  videos: null,
  totalResultCount: 0,
  isLoading: false,
  muted: false,
  // this is needed because when this change only then will recall or reinitialize intersection observer
  updateIntersectionObserver: false,
};

const shortSlice = createSlice({
  initialState,
  name: 'shortStore',
  reducers: {
    setMuted: (state, { payload = null }) => {
      if (payload === null) state.muted = !state.muted;
      else state.muted = payload;
    },
    setOffset: (state) => {
      state.offset += state.limit;
    },
    setTotalResultCount: (state, { payload }) => {
      state.totalResultCount = payload;
    },
    setVideos: (state, { payload }) => {
      state.videos = payload;
      state.updateIntersectionObserver = !state.updateIntersectionObserver;
    },
    appendVideos: (state, { payload }) => {
      if (state.videos) state.videos.push(...payload);
      else state.videos = payload;
      state.updateIntersectionObserver = !state.updateIntersectionObserver;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    dislikeVideo: (state, { payload: videoId }) => {
      const index = state.videos?.findIndex((video) => video.id === videoId);
      if (index !== undefined && index >= 0) {
        if (state.videos[index].user_liked) {
          state.videos[index].user_liked = 0;
          state.videos[index].like_count -= 1;
        }
        state.videos[index].dislike_count += state.videos[index].user_disliked
          ? -1
          : +1;
        state.videos[index].user_disliked = Number(
          !state.videos[index].user_disliked
        );
      }
    },
    likeVideo: (state, { payload: videoId }) => {
      const index = state.videos?.findIndex((video) => video.id === videoId);
      if (index !== undefined && index >= 0) {
        if (state.videos[index].user_disliked) {
          state.videos[index].user_disliked = 0;
          state.videos[index].dislike_count -= 1;
        }
        state.videos[index].like_count += state.videos[index].user_liked
          ? -1
          : +1;
        state.videos[index].user_liked = Number(
          !state.videos[index].user_liked
        );
      }
    },
    subscribe: (state, { payload: channelId }) => {
      if (state.videos && state.videos.length > 0) {
        const updatedData = state.videos.map((video) => {
          if (video.channel_id === channelId) {
            return { ...video, user_subscribed: true };
          } else return video;
        });
        console.log(updatedData, 'updated data');
        state.videos = updatedData;
      }
      // const index = state.videos?.findIndex(
      //   (video) => video.channel_id === channelId
      // );
      // if (index !== undefined && index >= 0) {
      //   state.videos[index].user_subscribed = true;
      // }
    },
    unsubscribe: (state, { payload: channelId }) => {
      if (state.videos && state.videos.length > 0) {
        const updatedData = state.videos.map((video) => {
          if (video.channel_id === channelId) {
            return { ...video, user_subscribed: false };
          } else return video;
        });
        state.videos = updatedData;
      }
      // const index = state.videos?.findIndex(
      //   (video) => video.channel_id === channelId
      // );
      // if (index !== undefined && index >= 0) {
      //   state.videos[index].user_subscribed = false;
      // }
    },
    setIsReported: (state, { payload }) => {
      const { videoId, is_reported = true } = payload;
      if (state.videos && state.videos.length > 0) {
        const updatedData = state.videos.map((video) => {
          if (video.id === videoId) {
            return { ...video, is_reported };
          } else return video;
        });
        state.videos = updatedData;
      }
      // const index = state.videos?.findIndex(
      //   (video) => video.channel_id === channelId
      // );
      // if (index !== undefined && index >= 0) {
      //   state.videos[index].user_subscribed = false;
      // }
    },
    setDefaultState: (state) => {
      state.limit = initialState.limit;
      state.offset = initialState.offset;
      state.videos = initialState.videos;
      state.totalResultCount = initialState.totalResultCount;
      state.isLoading = initialState.isLoading;
    },
  },
  extraReducers: (builder) => {
    // // Add reducers for additional action types here, and handle loading state as needed
    // builder.addCase(fetchShorts.pending, (state, action) => {
    //   // Add user to the state array
    //   state.isLoading = true;
    // });
    // builder.addCase(fetchShorts.fulfilled, (state, action) => {
    //   // Add user to the state array
    //   state.isLoading = false;
    // });
    // builder.addCase(fetchShorts.rejected, (state, action) => {
    //   false Add user to the state array
    //   state.isLoading = false;
    // });
  },
});

export const {
  setMuted,
  setOffset,
  setLoading,
  setTotalResultCount,
  setVideos,
  setDefaultState,
  appendVideos,
  likeVideo,
  dislikeVideo,
  subscribe,
  unsubscribe,
  setIsReported,
} = shortSlice.actions;
export default shortSlice.reducer;
