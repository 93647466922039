import { Button } from 'react-bootstrap';
import Constant from 'src/constant';

const PrivateVideo = () => (
  <div className='w-100 d-flex flex-column align-items-center justify-content-center min-h-without-navbar-footer'>
    <img
      className='img-fluid mb-4'
      alt='private-video-img'
      src={`${Constant.BASE_URL}img/illustration/private-video.png`}
    />
    <h2 className='text-center fw-600 mb-1'>Video Unavailable</h2>
    <p className='text-center fw-400 fs-400'>This video is private</p>
    <Button variant='primary' size='lg' className='mt-4'>
      Go To Home
    </Button>
  </div>
);

export default PrivateVideo;
