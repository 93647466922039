import moment from 'moment';
import React from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { abbreviateNumber } from 'js-abbreviation-number';

import Constant, { DATE_FORMAT, TIME_FORMAT } from 'src/constant';
import { useTopVideosByHandleNameQuery } from 'src/redux/features/videos/videoApi';
import calculateDuration from 'src/utils/calculate-duration';
import Loader from 'src/components/atoms/Loader';

const TopVideoCard = () => {
  const history = useHistory();
  const { data, isLoading } = useTopVideosByHandleNameQuery();

  if (data?.videos?.length === 0) return;

  return (
    <div className='mx-auto py-3 py-md-4 mt-4 studio-card'>
      <h2 className='fs-500 fw-700 text-left ml-2'>
        Top videos of this channel
      </h2>
      <div>
        {isLoading ? (
          <Loader className='mt-4' />
        ) : data ? (
          <Table responsive className='my-4' hover>
            <thead>
              <tr>
                <th>
                  <h6 className='fs-400 fw-600'>Content</h6>
                </th>
                <th className='text-center'>
                  <h6 className='fs-400 fw-600'>Views</h6>
                </th>
                <th className='text-right text-nowrap'>
                  <h6 className='fs-400 fw-600'>Average View Duration</h6>
                </th>
              </tr>
            </thead>

            <tbody>
              {data.videos?.map((video, idx) => (
                <tr key={`top-video-${video.id}-${idx}`}>
                  <td>
                    <Row className='flex-nowrap'>
                      <Col md={4}>
                        <div className='d-flex align-items-start position-relative cursor-pointer'>
                          <img
                            onClick={() =>
                              history.push(
                                `${Constant.BASE_URL}studio/video/${video.video_url_id}/analytics/tab-overview`
                              )
                            }
                            src={
                              video.thumbnail_url ||
                              `${Constant.BASE_URL}img/light/default-thumbnail.png`
                            }
                            style={{
                              aspectRatio: '16 / 9',
                              objectFit: 'contain',
                            }}
                            className='w-100 '
                            alt='thumbnail'
                          />
                        </div>
                      </Col>
                      <Col
                        md={8}
                        className='d-flex flex-column justify-content-start overflow-hidden'
                      >
                        <h6
                          className='fs-400 fw-600 mb-1 text-line-1 cursor-pointer'
                          onClick={() =>
                            history.push(
                              `${Constant.BASE_URL}studio/video/${video.video_url_id}/analytics/tab-overview`
                            )
                          }
                        >
                          {video.title}
                        </h6>
                        <h6 className='fs-300 fw-500 text-nowrap text-clr-dark-400'>
                          {moment(video.created_date).format(
                            `${DATE_FORMAT} ${TIME_FORMAT}`
                          )}
                        </h6>
                      </Col>
                    </Row>
                  </td>
                  <td className='text-center'>
                    <h6 className='fs-300 fw-500'>
                      {abbreviateNumber(video?.view_count || 0, 1)}
                    </h6>
                  </td>
                  <td className='text-right'>
                    <span className='fs-300 fw-500'>
                      {calculateDuration(video.average_view_duration) ||
                        '00:00'}
                    </span>
                    {video.duration >= 0 ? (
                      <span className='ml-2'>
                        (
                        {(
                          (video.average_view_duration / video.duration) *
                          100
                        ).toFixed(2)}
                        %)
                      </span>
                    ) : (
                      <div>Error</div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : null}
        {data && (
          <Button
            className='ml-1'
            variant='link'
            onClick={() => history.push(`${Constant.BASE_URL}studio/content`)}
          >
            See More
          </Button>
        )}
      </div>
    </div>
  );
};

export default TopVideoCard;
