import Col from 'react-bootstrap/Col';

import { VerifiedTooltip } from '../CustomCheckTooltips/CustomCheckTooltips';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, Row } from 'react-bootstrap';
import { abbreviateNumber } from 'js-abbreviation-number';
import { COLORS } from 'src/constant';
import UilMenuV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import UilShare from '@iconscout/react-unicons/icons/uil-share';
import UisClock from '@iconscout/react-unicons-solid/icons/uis-clock';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import { useDispatch } from 'react-redux';
import { handleOpenShare } from 'src/redux/features/share/shareSlice';
import { UilExclamationOctagon } from '@iconscout/react-unicons';
import { DotSvg } from 'src/assets/svgs';

export default function WatchedVideoCard({
  iconHref = '#',
  videoHref = '#',
  imgSrc,
  imgAlt = '',
  time = '-:-',
  videoTitle = '#',
  views,
  timeAgo,
  channelName,
  channelHref,
  verified = null,
  progressTime = '-:-',
  progress = '0',
  description,
  onRemoveFromHistory,
  onAddWatchLater,
  shareLink,
  onReportVideo,
  onRemoveFromWatchLater,
  onRemovedFromLikedVideo = null,
  isShort = false,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <>
      <Col xl={12} sm={12} className='mb-3 hover-trigger-container'>
        <Dropdown
          style={{
            position: 'absolute',
            right: '8px',
            top: '8px',
          }}
        >
          <Dropdown.Toggle as='a' id='' className='no-after'>
            <button className='icon-circle-wrapper btn-ripple trigger-element z-1'>
              <UilMenuV size={18} className='icon' />
            </button>
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ minWidth: 'min-content' }}>
            {onAddWatchLater && (
              <Dropdown.Item href='#' onClick={onAddWatchLater}>
                <UisClock color={COLORS.dark300} size='20' className='mr-2' />
                <span>Save to Watch later</span>
              </Dropdown.Item>
            )}
            {onRemoveFromHistory && (
              <Dropdown.Item
                href='#'
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveFromHistory();
                }}
              >
                <UilTrashAlt
                  color={COLORS.dark300}
                  size='20'
                  className='mr-2'
                />
                <span>Remove from history</span>
              </Dropdown.Item>
            )}
            {onRemoveFromWatchLater && (
              <Dropdown.Item href='#' onClick={onRemoveFromWatchLater}>
                <UilTrashAlt
                  color={COLORS.dark300}
                  size='20'
                  className='mr-2'
                />
                <span>Remove from watch later</span>
              </Dropdown.Item>
            )}
            {onRemovedFromLikedVideo && (
              <Dropdown.Item href='#' onClick={onRemovedFromLikedVideo}>
                <UilTrashAlt
                  color={COLORS.dark300}
                  size='20'
                  className='mr-2'
                />
                <span>Remove from Liked videos</span>
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={() => dispatch(handleOpenShare({ shareLink }))}
            >
              <UilShare color={COLORS.dark300} size='20' className='mr-2' />
              <span>Share</span>
            </Dropdown.Item>
            {onReportVideo && (
              <Dropdown.Item href='#' onClick={onReportVideo}>
                <UilExclamationOctagon size='18' className='mr-2' />
                Report Video
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>

        <Row className='video-card history-video'>
          <Col sm={3} className='video-card-image'>
            {/* <div
              className='video-close'
              onClick={(e) => {
                e.stopPropagation();
                onRemoveFromHistory();
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </div> */}
            <Link className='play-icon' to={videoHref}>
              <FontAwesomeIcon icon={faPlayCircle} />
            </Link>
            <Link to={videoHref}>
              <img
                className='img-fluid'
                style={isShort ? { objectFit: 'contain' } : {}}
                src={imgSrc}
                alt={imgAlt}
              />
            </Link>
            <div className='time'>{time}</div>
          </Col>
          {/* <div className='progress'>
            <div
              className='progress-bar'
              role='progressbar'
              style={{ width: progress + '%' }}
              aria-valuenow='50'
              aria-valuemin='0'
              aria-valuemax='100'
            >
              {progressTime}
            </div>
          </div> */}
          <Col sm={9} className='video-card-body'>
            <div className='video-title'>
              <Link to={videoHref}>{videoTitle}</Link>
            </div>
            <div className='video-page'>
              <span
                className='cursor-pointer'
                onClick={() => history.push(channelHref)}
              >
                {channelName} {verified ? <VerifiedTooltip /> : null}
              </span>
              <span className='px-1'>
                {!verified && (
                  <DotSvg
                    style={{ fill: 'var(--clr-dark-100)', width: '3px' }}
                  />
                )}
              </span>
              <span
                className='video-view'
                // style={{ marginLeft: '16px' }}
              >
                {abbreviateNumber(views, 1)} Views &nbsp;
                {/* <FontAwesomeIcon icon={faCalendarAlt} /> {timeAgo} */}
              </span>

              <span className='px-1'>
                {/* {!verified && ( */}
                <DotSvg style={{ fill: 'var(--clr-dark-100)', width: '3px' }} />
                {/* )} */}
              </span>
              <span className='video-view'>{timeAgo}</span>
            </div>

            {/* <div className='video-view'>
              {views} views &nbsp;
              <FontAwesomeIcon icon={faCalendarAlt} /> {timeAgo}
            </div> */}
            <div
              className='video-view mt-1 description-text-style-same text-line-2'
              dangerouslySetInnerHTML={{ __html: description }}
              style={{ overflow: 'hidden', maxHeight: '20px' }}
            />
            {/* <div className='video-view mt-1'>{description}</div> */}
          </Col>
        </Row>
      </Col>
    </>
  );
}
