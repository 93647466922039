/** for build */

import { createDate } from 'src/utils/graph';
import moment from 'moment';
import { parseUnits } from 'viem';
import config from '../config';

// const BASE_URL = '/mirror/v1/';
const BASE_URL = '/';
/** for development */
// const BASE_URL = '/';
export const SERVER_BASE_URL = `${config.project_domain_link}:4455/api/v1`;
export const FIXED_DECIMAL_CONSTANT = 1;
export const DATE_FORMAT = 'DD MMM, YYYY';
export const TIME_FORMAT = 'hh:mm:ss A';
export const MAX_VIDEO_FILE_SIZE = 1000 * 1024 * 1025; // 50 MB
export const CLIENT_URL = config.project_domain_link;
export const SERVER_URL = `${config.project_domain_link}:4455`;

export const ROLES = {
  user: 'USER',
};

const GWEI_DECIMAL = 9;
export const IncrementalGasPrice = parseUnits('0.1', GWEI_DECIMAL);
export const IncrementalMaxPriorityFeePerGas = parseUnits('0', GWEI_DECIMAL);

export { GWEI_DECIMAL };

export const NETWORK_TYPE = {
  ETH: 2,
  BNB: 3,
  TRX: 4,
  MATIC: 5,
  SOL: 6,
};

export const CONTRACT_SYMBOL = {
  Mirror: 'M',
  MirrorToken: 'MT',
};

export const SORT_ORDER_TYPE = {
  default: 'default',
  descending: 'DESC',
  ascending: 'ASC',
};

export const COLORS = {
  primary400: '#FC6173',
  primary500: '#FC4080',
  shadow: '#00000029',
  secondaryMain: '#F5F5F5',
  dark300: '#4B4B4B',
  light: '#f6f6f6',
  light2: '#ededed',
white: '#fff',
};

export const themeMode = {
  LIGHT: 'light',
  DARK: 'dark',
};
const Constant = {
  BASE_URL,
};

export const VERIFY_STATUS = {
  UNVERIFIED: 0,
  UNDER_VERIFICATION: 1,
  VERIFIED: 2,
};

export const PROCESSING_STATUS = {
  PROCESSING: 1,
  DONE: 2,
  FAIL: 0,
};

export const COMMON_STATUS = {
  FAIL: 0,
  SUCCESS: 1,
  PROCESSING: 2,
};

export const IPFS_STATUS = {
  FAIL: 0,
  DONE: 1,
};

export const THUMBNAIL_TYPE = {
  DEFAULT: 0,
  CUSTOM: 1,
};

// android device = mp4
// ios device - mov
// obs studio - mp4, flv, mkv
export const SUPPORTED_VIDEO_FILE_TYPE = [
  'video/mp4', // working
  'video/webm', // working
  'video/x-matroska', // .mkv working
  'video/quicktime', // .mov not working
  'video/3gpp',
  // 'video/mpeg',
  // 'video/avi', // not working
  // 'video/mov',
  // 'video/x-ms-wmv',
  // 'video/flv',
  // 'video/mpg',
  // 'video/mpeg-1',
  // 'video/mpeg-2',
  // 'video/mpeg4',
  // 'video/wmv',
  // 'video/mpegps',
  // 'video/flv',
  // 'video/webm',
  // 'video/x-dnxhr',
  // 'video/x-msvideo',
  // 'video/x-matroska',
  // 'video/quicktime',
  // 'video/x-ms-wmv',
  // 'video/x-ms-asf',
  // 'video/x-msvideo',
  // 'video/x-f4v',
  // 'video/x-flv',
  // 'video/x-matroska',
  // 'video/x-mng',
  // 'video/x-ms-asf',
  // 'video/x-ms-wmv',
  // 'video/x-msvideo',
  // 'video/x-sgi-movie',
];

export const PROFIT_TYPE = {
  'LEVEL EARNING': 1,
  'TRENDING EARNING': 2,
  WITHDRAW: 3,
};

export const PAYMENT_TYPE = {
  CREDIT: 1,
  DEBIT: 2,
};

export const ACCOUNTS_STATUS = {
  CONFIRMED: 0, // default state
  PENDING: 1,
  PROCESSING: 2,
  HASH: 3,
  RECEIPT: 4, // last state
};

export const DEVICE_TYPE = {
  WEB: 0,
  ANDROID: 1,
  IOS: 2,
};

export const MONTHS_ARRAY = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const SORT_BY = {
  NEWEST_FIRST: 'Newest First',
  TOP_COMMENTS: 'Top Comments',
};

const currentDate = new Date();

export const SORT_BY_TIME = {
  since_published: {
    label: 'Since published',
    value: 'period-since_publish',
    startDate: null,
    endDate: currentDate,
  },
  first_24_hours: {
    label: 'First 24 hours',
    value: 'period-1st_24hours',
    startDate: currentDate,
    endDate: currentDate,
  },
  last_7days: {
    label: 'Last 7 Days',
    value: 'period-last_7days',
    // date: createDate(new Date(), -7),
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: currentDate,
  },
  last_28days: {
    label: 'Last 28 Days',
    value: 'period-last_28days',
    // date: createDate(new Date(), -28),
    startDate: moment().subtract(28, 'days').toDate(),
    endDate: currentDate,
  },
  last_90days: {
    label: 'Last 90 Days',
    value: 'period-last_90days',
    // date: createDate(currentDate, -90),
    startDate: moment().subtract(90, 'days').toDate(),
    endDate: currentDate,
  },
  last_365days: {
    label: 'Last 365 Days',
    value: 'period-last_365days',
    // date: createDate(currentDate, -365),
    startDate: moment().subtract(365, 'days').toDate(),
    endDate: currentDate,
  },
  current_year: {
    label: currentDate.getFullYear(),
    value: 'period-current_year',
    startDate: new Date(currentDate.getFullYear(), 0, 1),
    endDate: currentDate,
  },
  last_year: {
    label: currentDate.getFullYear() - 1,
    value: 'period-minus_1_year',
    startDate: new Date(currentDate.getFullYear() - 1, 0, 1),
    endDate: moment().subtract(1, 'year').endOf('year').toDate(),
  },
  current_month: {
    label: MONTHS_ARRAY[currentDate.getMonth()],
    value: 'period-current_month',
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    endDate: currentDate,
  },
  last_month: {
    label: MONTHS_ARRAY[currentDate.getMonth() - 1],
    value: 'period-minus_1_month',
    startDate: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    ),
    endDate: moment().subtract(1, 'month').endOf('month').toDate(),
  },
};

export const breakpoints = {
  base: 0,
  custom_xs: 380,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export default Constant;
