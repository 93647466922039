import React from 'react';
import { useRef } from 'react';
import { Button, Form } from 'react-bootstrap';

const CommentInputBox = ({
  onChange,
  value,
  inputDisabled,
  cancelBtnDisabled,
  submitBtnDisabled,
  placeholder = 'Add Comment...',
  onCancel,
  onSubmit,
  onFocus,
  buttonText = 'Comment',
  rows = 2,
}) => {
  const inputRef = useRef();
  return (
    <div onClick={() => inputRef.current.focus()} className='comment-input-box'>
      <Form.Control
        ref={inputRef}
        as='textarea'
        rows={rows}
        placeholder={placeholder}
        value={value}
        onFocus={onFocus}
        disabled={inputDisabled}
        onChange={onChange}
        minLength={1}
      />
      {/* </div> */}
      <div className='form-members-footer text-right p-2'>
        {/* {!!commentCount && (
      <b className='float-left fs-200'>{commentCount} Comments</b>
    )} */}
        <Button
          variant='light'
          size='sm'
          onClick={onCancel}
          disabled={cancelBtnDisabled}
        >
          Cancel
        </Button>
        &nbsp;
        <Button
          variant='primary'
          size='sm'
          onClick={onSubmit}
          disabled={submitBtnDisabled}
          className='ml-2'
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default CommentInputBox;
