import { Button, Container } from 'react-bootstrap';
import { useWeb3Modal } from '@web3modal/wagmi/react';

import SectionHeader from 'src/components/atoms/SectionHeader/SectionHeader';
import Constant from 'src/constant';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { useGetSubscribedVideosQuery } from 'src/redux/features/subscribers/subscribersApi';
import VideoBlock from 'src/components/VideoBlock';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NoContent from 'src/components/atoms/NoContent/NoContent';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';
import { useState } from 'react';
import config from 'src/config';
import InfiniteScroll from 'react-infinite-scroll-component';

const Subscriptions = () => {
  const history = useHistory();
  const { open } = useWeb3Modal({});

  const [offset, setOffset] = useState(0);
  const limit = config.limits.subscribedChannelVideos;

  const {
    data: videosRes,
    isLoading,
    isSuccess,
  } = useGetSubscribedVideosQuery(
    { limit, offset },
    { skip: !localStorage.getItem('accessToken') }
  );

  const { data: authData, isLoading: authLoading } = useAuthCheckQuery(
    undefined,
    { skip: !localStorage.getItem('accessToken') }
  );

  if (!authLoading && !authData?.token)
    return (
      <NoContent
        addContainer={true}
        title={`Don't Miss new videos`}
        description='Subscribe to our platform now to never miss any new videos across the world.'
        imgUrl={`${Constant.BASE_URL}img/illustration/subscriptions.png`}
        buttonText='Sign In'
        handleButtonClick={() => open()}
      />
    );

  // if (videosRes?.videos?.length === 0)
  if (videosRes?.subscribed_channel_count === 0)
    return (
      <NoContent
        addContainer={true}
        title='No Subscriptions Yet'
        description='You have not subscribed to any of the channels on the Mirror platform. Start subscribing now.'
        imgUrl={`${Constant.BASE_URL}img/illustration/subscriptions.png`}
        buttonText='Start subscribing'
        handleButtonClick={() =>
          history.push(`${Constant.BASE_URL}popularchannels`)
        }
      />
    );

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4'>
        {isLoading && <Loader className='mt-4' />}
        {isSuccess && (
          <InfiniteScroll
            dataLength={videosRes?.videos.length}
            next={() => setOffset((prev) => prev + limit)}
            hasMore={videosRes?.total_result_count > videosRes?.videos?.length}
            loader={<Loader />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
            className='overflow-hidden'
          >
            <VideoBlock
              data={videosRes?.videos || []}
              HeaderComponent={() => (
                <>
                  <div className='d-flex justify-content-between align-items-center'>
                    <SectionHeader heading='Subscriptions' noIconLabel />
                    <Button
                      className='bg-transparent'
                      variant='light'
                      as={Link}
                      to={`${Constant.BASE_URL}feed/channels`}
                    >
                      Manage Channels
                    </Button>
                  </div>
                  {videosRes?.videos?.length === 0 && (
                    <NoContent
                      addContainer={true}
                      title='No Videos Found'
                      description='Discover a world of diverse content by expanding your subscriptions. Currently, your subscribed channels may not have any videos available. Start exploring fresh content from a variety of creators and interests. Subscribe to new channels today and unlock a wealth of exciting videos waiting just for you!'
                      imgUrl={`${Constant.BASE_URL}img/illustration/subscriptions.png`}
                      buttonText='Start subscribing'
                      handleButtonClick={() =>
                        history.push(`${Constant.BASE_URL}popularchannels`)
                      }
                    />
                  )}
                </>
              )}
            />
          </InfiniteScroll>
        )}
      </Container>
      <ThinFooter />
    </>
  );
};

export default Subscriptions;
