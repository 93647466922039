import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_BASE_URL } from 'src/constant';
import { defaultHeaders } from 'src/redux/rootApi';

export const handleNameExist = createAsyncThunk(
  'handleNameExist',
  async (handleName, { getState }) => {
    let url = `${SERVER_BASE_URL}/channel/validate_channel_handle/${handleName}`;
    try {
      const result = await axios.get(url, {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      return result.data;
    } catch (error) {}
  }
);

const channelSlice = createSlice({
  initialState: {},
  name: 'channelStore',
  reducers: {},
});

export const {} = channelSlice.actions;
export default channelSlice.reducer;
