import { formatEther, formatUnits, parseUnits } from 'viem';

export const formatBigIntDecimal = (amount = 0, decimal = 18) => {
  if (!amount) return 0;
  if (typeof amount === 'number') amount = amount.toString();
  return formatUnits(parseUnits(amount, decimal), decimal).toString();
  // console.log(typeof formatUnits(amount, decimal), typeof amount,formatUnits(amount, decimal), amount, 'amount')
  // return formatUnits(amount, decimal);
};

export const watchTimeCalculator = (seconds = 0) => {
  if (!seconds) return '0 Sec';
  if (seconds / 3600 >= 1) return `${(seconds / 3600).toFixed(1)} Hrs`;
  if (seconds / 60 >= 1) return `${(seconds / 60).toFixed(1)} Mins`;
  return `${seconds} Secs`;
};

export const convertToDecimalString = (value) => {
  return value
    .replace(/[^\d.]/g, '') // Remove non-numeric characters
    .replace(/(\..*)\./g, '$1'); // Allow only one dot
};
