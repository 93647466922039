import './History.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container, Dropdown, FormControl, InputGroup } from 'react-bootstrap';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import UilMenuV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';

import Constant, { CLIENT_URL, COLORS } from '../../constant';
import { useHistory, useLocation } from 'react-router-dom';
import { useRef, useState } from 'react';
import moment from 'moment';
import WatchedVideoCard from '../../components/atoms/WatchedVideoCard/WatchedVideoCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  useClearHistoryMutation,
  useFetchMyShortsWatchHistoryQuery,
  useFetchMyWatchHistoryQuery,
  useRemoveHistoryVideoMutation,
} from '../../redux/features/watch-history/watchHistoryApi';
import { toast } from 'react-toastify';
import NoContent from '../../components/atoms/NoContent/NoContent';
import handleError from 'src/utils/handle-error';
import calculateDuration from 'src/utils/calculate-duration';
import Slider from 'react-slick';
import ShortCard from 'src/components/atoms/ShortCard/ShortCard';
import SectionHeader from 'src/components/atoms/SectionHeader/SectionHeader';

import { ShortSvg } from 'src/assets/svgs';
import {
  SampleNextArrow,
  SamplePrevArrow,
} from '../../components/atoms/ShortsButtons/ShortButton';
import { useAddWatchLaterMutation } from 'src/redux/features/watch-later/watchLaterApi';
import { openReportVideoModal } from 'src/redux/features/report-video/reportVideoSlice';
import { checkVideoReported } from 'src/redux/features/videos/videoSlice';
import messages from 'src/constant/messages';
import { useDispatch } from 'react-redux';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';

const History = () => {
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const history = useHistory();
  const limit = 50;
  const [offset, setOffset] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query');
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [clearHistory] = useClearHistoryMutation();
  const { data, isLoading, isSuccess, originalArgs } =
    useFetchMyWatchHistoryQuery({ query: query || '', limit, offset });

  const {
    data: shortsData,
    isLoading: shortsLoading,
    isSuccess: shortsSuccess,
  } = useFetchMyShortsWatchHistoryQuery({ query: query || '', limit, offset });
  const [removeWatchHistory] = useRemoveHistoryVideoMutation();
  const [addWatchLater] = useAddWatchLaterMutation();

  // console.log(shortsData,'hyyyyyyyyy')
  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      const newQuery = searchRef.current?.value;
      const queryParam = newQuery ? `?query=${newQuery}` : '';
      history.push({
        pathname: `${Constant.BASE_URL}history`,
        search: queryParam,
      });
    }
  };
  const totalSlides = shortsData?.watchHistory.length;
  var settings = {
    rows: 1,
    variableWidth: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    initialSlide: 0,
    swipeToSlide: true,
    // autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setCurrentSlide(next),
    afterChange: (current) => setCurrentSlide(current),
    nextArrow: (
      <SampleNextArrow
        slidesToShow={6}
        currentSlide={currentSlide}
        totalSlides={totalSlides}
      />
    ),
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
          beforeChange: (current, next) => setCurrentSlide(next),
          afterChange: (current) => setCurrentSlide(current),
          nextArrow: (
            <SampleNextArrow
              slidesToShow={4}
              currentSlide={currentSlide}
              totalSlides={totalSlides}
            />
          ),
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
          beforeChange: (current, next) => setCurrentSlide(next),
          afterChange: (current) => setCurrentSlide(current),
          nextArrow: (
            <SampleNextArrow
              slidesToShow={3}
              currentSlide={currentSlide}
              totalSlides={totalSlides}
            />
          ),
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          beforeChange: (current, next) => setCurrentSlide(next),
          afterChange: (current) => setCurrentSlide(current),
          nextArrow: (
            <SampleNextArrow
              slidesToShow={2}
              currentSlide={currentSlide}
              totalSlides={totalSlides}
            />
          ),
          prevArrow: <SamplePrevArrow />,
        },
      },
    ],
  };

  // Handle after change event to update the current slide index

  const handleSearchClick = () => {
    const newQuery = searchRef.current.value;
    if (!newQuery) searchRef.current.focus();
    const queryParam = newQuery ? `?query=${newQuery}` : '';
    history.push({
      pathname: `${Constant.BASE_URL}history`,
      search: queryParam,
    });
  };
  const handleRemoveFromHistory = async (videoId) => {
    try {
      const response = await removeWatchHistory(videoId).unwrap();
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleAddWatchLater = async (videoId, videoUrlId) => {
    try {
      const response = await addWatchLater({
        videoId,
        videoUrlId,
      }).unwrap();
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message || 'Successfully Removed!');
    }
  };

  if (
    data?.watchHistory?.length === 0 &&
    !query &&
    shortsSuccess &&
    shortsData?.watchHistory?.length === 0
  )
    return (
      <NoContent
        addContainer={true}
        title='No History Yet'
        description='There is no history to show as there are no videos added. Create your history by adding videos now.'
        imgUrl={`${Constant.BASE_URL}img/illustration/history.svg`}
        buttonText='Go to home'
        handleButtonClick={() => history.push(`${Constant.BASE_URL}`)}
      />
    );

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4'>
        {/* <div
          className='video-block section-padding'
          style={{ maxWidth: '800px', margin: 'auto' }}
        > */}
        <Row>
          <Col md={12} className='mb-2'>
            {/* HEADER */}
            <div className='d-flex justify-content-between align-items-center'>
              <h6 className='fs-500 fw-600'>Watch History</h6>
              <div className='d-flex align-items-center'>
                <InputGroup>
                  <FormControl
                    type='text'
                    ref={searchRef}
                    placeholder='Search'
                    className='input-search2'
                    onKeyPress={handleKeyPress}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      className='cursor-pointer bg-transparent pl-2 border-0'
                      onClick={handleSearchClick}
                    >
                      <UilSearch size={18} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Dropdown>
                  <Dropdown.Toggle as='a' id='' className='no-after'>
                    <button className='icon-circle-wrapper btn-ripple'>
                      <UilMenuV size={18} className='icon' />
                    </button>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ minWidth: 'min-content' }}>
                    {/* <Dropdown.Item href='#'>
                        <UilSetting
                          color={COLORS.dark300}
                          size='18'
                          className='mr-2'
                        />
                        <span>Manage History</span>
                      </Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={async () => {
                        try {
                          const response = await clearHistory().unwrap();
                          toast.success(response.data.message);
                        } catch (error) {
                          handleError(error);
                        }
                      }}
                    >
                      <UilTrashAlt
                        color={COLORS.dark300}
                        size='18'
                        className='mr-2'
                      />
                      Clear History
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* DIVIDER */}
            <hr className='clr-white-card-hr' />
          </Col>
          {/* SHORT HISTORY */}
          <Col md={12} className='mb-4'>
            <div className='d-flex flex-column'>
              {(isLoading || shortsLoading) && <Loader />}
              {/* {data?.watchHistory && data?.watchHistory?.length === 0 && (
              <h6 className='text-center h5 fw-600'>
                There is 0 result for {query}
              </h6>
            )} */}

              {shortsSuccess && !!shortsData?.watchHistory?.length && (
                <>
                  <SectionHeader
                    widthCircle={false}
                    heading='Shorts'
                    noIconLabel
                    // onViewAllClick={() => history.push(`${Constant.BASE_URL}week`)}
                    headingIcon={<ShortSvg />}
                    className='mb-3'
                  />
                  <Slider {...settings}>
                    {shortsData?.watchHistory?.map((item) => (
                      <div className='px-2'>
                        <ShortCard
                          imgSrc={
                            item?.thumbnail_url ||
                            `${Constant.BASE_URL}img/light/default-thumbnail.png`
                          }
                          time={calculateDuration(item.duration)}
                          videoTitle={item.title}
                          views={item?.views || 0}
                          iconHref={`${Constant.BASE_URL}shorts/${item.video_url_id}`}
                          videoTitleHref={`${Constant.BASE_URL}shorts/${item.video_url_id}`}
                          videoId={item.video_id}
                          onRemoveFromHistory={() =>
                            handleRemoveFromHistory(item.video_id)
                          }
                          shareLink={`${CLIENT_URL}/shorts/${item.video_url_id}`}
                          onReportVideo={async () => {
                            if (!authData)
                              return toast.error(
                                messages.require_signin_to_report
                              );
                            if (item.is_reported)
                              return toast.info(
                                messages.video_reported_already
                              );

                            try {
                              const report = await dispatch(
                                checkVideoReported(item.id)
                              ).unwrap();
                              const is_reported = report.data.is_reported;

                              if (is_reported)
                                return toast.info(
                                  messages.video_reported_already
                                );

                              dispatch(
                                openReportVideoModal({
                                  contractVideoId: item.contract_video_id,
                                  videoId: item.id,
                                  videoUrlId: item.video_url_id,
                                })
                              );
                            } catch (error) {
                              handleError(error);
                            }
                          }}
                          onAddWatchLater={async () => {
                            if (!authData)
                              return toast.info(messages.require_signin);
                            try {
                              const response = await addWatchLater({
                                videoId: item.id,
                                videoUrlId: item.video_url_id,
                              }).unwrap();
                              toast.success(response.message);
                            } catch (error) {
                              handleError(error);
                            }
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                </>
              )}
            </div>
          </Col>
          {/* VIDEO HISTORY */}
          <Col md={12} className='mb-2'>
            <div className='d-flex flex-column'>
              {data?.watchHistory &&
                data?.watchHistory?.length === 0 &&
                shortsData?.watchHistory?.length === 0 && (
                  <h6 className='text-center h5 fw-600'>
                    There is 0 result for {query}
                  </h6>
                )}
              {isSuccess && !!data?.watchHistory?.length && (
                <InfiniteScroll
                  dataLength={data?.watchHistory?.length || 0} //This is important field to render the next data
                  next={() => setOffset((prev) => prev + limit)}
                  hasMore={
                    data?.total_result_count > data?.watchHistory?.length
                  }
                  loader={<Loader />}
                  className='overflow-hidden'
                  style={{ minHeight: '60vh' }}
                >
                  {data?.watchHistory?.map((item) => (
                    <WatchedVideoCard
                      key={`watch-history-${item.id}`}
                      imgSrc={
                        item?.thumbnail_url ||
                        `${Constant.BASE_URL}img/light/default-thumbnail.png`
                      }
                      videoHref={`${Constant.BASE_URL}watch/${item.video_url_id}`}
                      time={calculateDuration(item.duration)}
                      videoTitle={item.title}
                      views={item.views || 0}
                      timeAgo={moment(item.modified_date).fromNow()}
                      channelName={item.channel_name}
                      progress='50'
                      progressTime='1:40'
                      description={item.description}
                      verified={item?.verified}
                      channelHref={`${Constant.BASE_URL}channel/${item.handle_name}`}
                      shareLink={`${CLIENT_URL}/watch/${item.video_url_id}`}
                      onRemoveFromHistory={() =>
                        handleRemoveFromHistory(item.video_id)
                      }
                      onAddWatchLater={() =>
                        handleAddWatchLater(item.video_id, item.video_url_id)
                      }
                      onReportVideo={async () => {
                        if (!authData)
                          return toast.error(messages.require_signin_to_report);
                        if (item.is_reported)
                          return toast.info(messages.video_reported_already);

                        try {
                          const report = await dispatch(
                            checkVideoReported(item.id)
                          ).unwrap();
                          const is_reported = report.data.is_reported;

                          if (is_reported)
                            return toast.info(messages.video_reported_already);

                          dispatch(
                            openReportVideoModal({
                              contractVideoId: item.contract_video_id,
                              videoId: item.id,
                              videoUrlId: item.video_url_id,
                            })
                          );
                        } catch (error) {
                          handleError(error);
                        }
                      }}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </Col>
        </Row>
        {/* </div> */}
      </Container>
      <ThinFooter />
    </>
  );
};

export default History;
