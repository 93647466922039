import { useParams } from 'react-router-dom';

import VideoTitle from './VideoTitle';
import AuthorBox from './AuthorBox';
import VideoDescription from './VideoDescription';
import CommentBox from './CommentBox';
import Constant from 'src/constant';
import { useFetchVideoByIdQuery } from 'src/redux/features/videos/videoApi';

const SingleVideoLeft = () => {
  const { video_url_id } = useParams();
  const { data: videoData } = useFetchVideoByIdQuery(video_url_id);
  return (
    <>
      <div className='single-video-left'>
        {videoData?.visibility === 'PUBLIC' && videoData?.comments_enabled ? (
          <CommentBox
            videoId={videoData.id}
            contractVideoId={videoData.contract_video_id}
          />
        ) : videoData?.visibility === 'PUBLIC' &&
          !videoData?.comments_enabled ? (
          <p className='text-center'>Comments are turned off.</p>
        ) : (
          <p className='text-center'>
            Comments are not supported on private videos.
          </p>
        )}
      </div>
    </>
  );
};

export default SingleVideoLeft;
