import React from 'react';
import { Button } from 'react-bootstrap';
import Constant from '../../../constant';

const NoContent = ({
  handleButtonClick,
  buttonText,
  title = 'Video Not Found',
  description,
  imgUrl = `${Constant.BASE_URL}img/video-not-found.png`,
  addContainer,
}) => {
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center m-auto px-2 ${
        addContainer ? 'min-h-without-navbar' : 'py-5'
      }`}
      style={{ maxWidth: '600px' }}
    >
      <img alt={title} className='img-fluid mb-4' src={imgUrl} />
      <h3 className='text-center mb-2 mx-auto fw-600'>{title}</h3>
      {description && <p className='text-center'>{description}</p>}
      {buttonText && (
        <Button
          className='mt-4'
          size='lg'
          variant='primary'
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default NoContent;
