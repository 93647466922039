import React, { useRef, useState } from 'react';
import { UilSearch } from '@iconscout/react-unicons';
import { InputGroup } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Constant from 'src/constant';
import { useSearchOptionsQuery } from 'src/redux/features/videos/videoApi';

const SearchInput = ({ className = '' }) => {
  const searchInputRef = useRef(null);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState(null);

  const { data: keywordSearchResult, isLoading } = useSearchOptionsQuery(
    searchQuery,
    { skip: !searchQuery }
  );

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <InputGroup
      style={{ flex: 1, maxWidth: '607px' }}
      className={`osahan-navbar-search ${className}`}
    >
      <AsyncTypeahead
        id='search-video'
        style={{ flex: 1 }}
        labelKey={(props) => props.text}
        filterBy={filterBy}
        onSearch={handleSearch}
        onInputChange={(value) => (searchInputRef.current = value)}
        onChange={(options) => {
          if (options?.length) {
            history.push(
              `${Constant.BASE_URL}results?search_query=${options[0].text}`
            );
          }
        }}
        onKeyDown={(e) => {
          const value = e.target.value;
          if (e.key === 'Enter' && value) {
            history.push(`${Constant.BASE_URL}results?search_query=${value}`);
          }
        }}
        isLoading={isLoading}
        minLength={3}
        options={keywordSearchResult || []}
        placeholder='Search for...'
        renderMenuItemChildren={(option) => (
          <div className='w-100 position-relative'>
            <p className='w-50 overflow-hidden text-ellipse'>{option.text}</p>
          </div>
        )}
      />
      <InputGroup.Append>
        <InputGroup.Text
          className='cursor-pointer'
          onClick={() => {
            const value = searchInputRef.current;
            if (value) {
              history.push(`${Constant.BASE_URL}results?search_query=${value}`);
            } else {
              toast.info('Search box is empty!');
            }
          }}
        >
          <UilSearch
            size={18}
            // color={mode === themeMode.LIGHT ? 'gray' : 'white'}
            color='white'
          />
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
};

export default React.memo(SearchInput);
