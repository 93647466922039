import rootApi from '../../rootApi';
import { subscribe, unsubscribe } from '../videos/shortSlice';

const subscribersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscribedVideos: builder.query({
      query: ({ limit = 8, offset = 0 }) =>
        `/subscribed/videos?limit=${limit}&offset=${offset}`,
      transformResponse: (response) => response.data,
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset === 0) {
          return newItems;
        } else {
          currentCache.videos.push(...newItems.videos);
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      providesTags: ['SUBSCRIBED_VIDEOS'],
    }),
    getSubscribedChannels: builder.query({
      query: ({ limit = 8, offset = 0 }) =>
        `/subscribed/channels?limit=${limit}&offset=${offset}`,
      transformResponse: (response) => response.data,
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset === 0) {
          return newItems;
        } else {
          currentCache.channels.push(...newItems.channels);
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      providesTags: ['SUBSCRIBED_CHANNELS'],
    }),
    subscribe: builder.mutation({
      query: ({ channelId, body }) => ({
        method: 'POST',
        url: `/subscribers/subscribe/channels/${channelId}`,
        body,
      }),
      invalidatesTags: ['SUBSCRIBED_CHANNELS'],
      onQueryStarted: async (
        { video_url_id, channelId, handle_name, videoId },
        { dispatch, queryFulfilled, getState }
      ) => {
        // UPDATE VIDEO DATA
        const patchResult = dispatch(
          rootApi.util.updateQueryData(
            'fetchVideoById',
            video_url_id,
            (draft) => {
              draft.user_subscribed = true;
              draft.subscriber_count += 1;
            }
          )
        );

        // UPDATE POPULAR CHANNEL DATA
        const popularChannelsPatchResult = dispatch(
          rootApi.util.updateQueryData(
            'popularChannels',
            undefined,
            (draft) => {
              const idx = draft.channels.findIndex(
                (channel) => channel.id === channelId
              );
              if (idx >= 0) {
                draft.channels[idx].isUserSubscribed =
                  !draft.channels[idx].isUserSubscribed;
                draft.channels[idx].subscribers += 1;
              }
            }
          )
        );

        // UPDATE CHANNEL DATA
        const channelPatchResult = dispatch(
          rootApi.util.updateQueryData(
            'fetchChannelByHandleName',
            handle_name,
            (draft) => {
              if (draft?.isUserSubscribed) draft.subscribers -= 1;
              else draft.subscribers += 1;
              draft.isUserSubscribed = !draft.isUserSubscribed;
            }
          )
        );

        // UPDATE SHORTS DATA
        const shortsPatchResult = dispatch(subscribe(channelId));
        // const { limit, offset } = getState().shortStore;
        // const shortsPatchResult = dispatch(
        //   rootApi.util.updateQueryData(
        //     'getShorts',
        //     { limit, offset },
        //     (draft) => {
        //       const index = draft.videos.findIndex(
        //         (video) => video.id === videoId
        //       );
        //       draft.videos[index].user_subscribed = true;
        //     }
        //   )
        // );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          popularChannelsPatchResult.undo();
          channelPatchResult?.undo();
          // shortsPatchResult?.undo();
        }
      },
    }),
    unsubscribe: builder.mutation({
      query: ({ channelId, body }) => ({
        method: 'POST',
        url: `/subscribers/unsubscribe/channels/${channelId}`,
        body,
      }),
      invalidatesTags: ['SUBSCRIBED_CHANNELS'],
      onQueryStarted: async (
        { videoId, channelId, handle_name, video_url_id },
        { dispatch, queryFulfilled, getState }
      ) => {
        // UPDATE VIDEO DATA
        const patchResult = dispatch(
          rootApi.util.updateQueryData(
            'fetchVideoById',
            video_url_id,
            (draft) => {
              draft.user_subscribed = false;
              draft.subscriber_count -= 1;
              return draft;
            }
          )
        );

        // UPDATE POPULAR CHANNEL DATA
        const popularChannelsPatchResult = dispatch(
          rootApi.util.updateQueryData(
            'popularChannels',
            undefined,
            (draft) => {
              const idx = draft.channels.findIndex(
                (channel) => channel.id === channelId
              );
              if (idx >= 0) {
                draft.channels[idx].isUserSubscribed =
                  !draft.channels[idx].isUserSubscribed;
                draft.channels[idx].subscribers -= 1;
              }
            }
          )
        );

        // UPDATE CHANNEL DATA
        const channelPatchResult = dispatch(
          rootApi.util.updateQueryData(
            'fetchChannelByHandleName',
            handle_name,
            (draft) => {
              if (draft?.isUserSubscribed) draft.subscribers -= 1;
              else draft.subscribers += 1;
              draft.isUserSubscribed = !draft.isUserSubscribed;
            }
          )
        );

        // UPDATE SHORTS DATA
        const shortsPatchResult = dispatch(unsubscribe(channelId));
        // const { limit, offset } = getState().shortStore;
        // const shortsPatchResult = dispatch(
        //   rootApi.util.updateQueryData(
        //     'getShorts',
        //     { limit, offset },
        //     (draft) => {
        //       const index = draft.videos.findIndex(
        //         (video) => video.id === videoId
        //       );
        //       draft.videos[index].user_subscribed = false;
        //     }
        //   )
        // );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          popularChannelsPatchResult.undo();
          channelPatchResult.undo();
          // shortsPatchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useSubscribeMutation,
  useUnsubscribeMutation,
  useGetSubscribedVideosQuery,
  useGetSubscribedChannelsQuery,
} = subscribersApi;
