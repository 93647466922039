import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SectionHeader from '../../components/atoms/SectionHeader/SectionHeader';
import Constant from '../../constant';
import { useGetSubscribedChannelsQuery } from '../../redux/features/subscribers/subscribersApi';
import ChannelCard from '../../components/atoms/ChannelCard/ChannelCard';
import NoContent from '../../components/atoms/NoContent/NoContent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';
import { useState } from 'react';
import config from 'src/config';
import InfiniteScroll from 'react-infinite-scroll-component';

const Subscriptions = () => {
  const history = useHistory();

  const [offset, setOffset] = useState(0);
  const limit = config.limits.subscribedChannels;

  const {
    data: channelRes,
    isLoading,
    isSuccess,
  } = useGetSubscribedChannelsQuery(
    { limit, offset },
    { skip: !localStorage.getItem('accessToken') }
  );

  if (channelRes?.channels?.length === 0)
    return (
      <NoContent
        addContainer={true}
        title='No Subscriptions Yet'
        description='You have not subscribed to any of the channels on the Mirror platform. Start subscribing now.'
        imgUrl={`${Constant.BASE_URL}img/illustration/subscriptions.png`}
        buttonText='Start subscribing'
        handleButtonClick={() =>
          history.push(`${Constant.BASE_URL}popularchannels`)
        }
      />
    );

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4'>
        {isSuccess && !!channelRes?.channels?.length && (
          <InfiniteScroll
            dataLength={channelRes?.channels.length}
            next={() => setOffset((prev) => prev + limit)}
            hasMore={
              channelRes?.total_result_count > channelRes?.channels?.length
            }
            loader={<Loader />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
            className='overflow-hidden'
          >
            <div className='video-block section-padding'>
              <Row>
                <Col md={12} className='mb-4'>
                  <SectionHeader heading='Manage Channels' />
                </Col>
                {isLoading && (
                  <Col md={12} className='mb-4'>
                    <div className='d-flex justify-content-center'>
                      <Loader />
                    </div>
                  </Col>
                )}

                {channelRes?.channels?.map((channel) => (
                  <Col xl={3} sm={6} className='mb-3' key={channel.id}>
                    <ChannelCard
                      imgSrc={channel.avatar_url}
                      // views='1.4M'
                      channelName={channel?.name}
                      subscriberCount={channel.subscribers}
                      // outline
                      verified={channel.verified}
                      isSubscribed
                      channelHref={`${Constant.BASE_URL}channel/${channel.handle_name}`}
                      channelId={channel.channel_id}
                      subscribeTo={channel.block_address}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </InfiniteScroll>
        )}
      </Container>
      <ThinFooter />
    </>
  );
};

export default Subscriptions;
