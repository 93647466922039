import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAccount, useWriteContract, useSwitchChain } from 'wagmi';

import './modal.css';
import { useReportVideoMutation } from 'src/redux/features/videos/videoApi';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
// import { useSaveBlockTransactionMutation } from 'src/redux/features/basic/basicApi';
import messages from 'src/constant/messages';
import { ProcessingModal } from '.';
import useFindContractData from 'src/hooks/useFindContractData';
import { useDispatch, useSelector } from 'react-redux';
import { closeReportVideoModal } from 'src/redux/features/report-video/reportVideoSlice';
import { useVideoReportCategoriesQuery } from 'src/redux/features/report-video/reportVideoApi';
import config from 'src/config';
import { useActiveChainId } from 'src/hooks';

// const options = [
//   { name: 'Sexual content', id: '1', check_video_description: false },
//   {
//     name: 'Violent or repulsive content',
//     id: '2',
//     check_video_description: false,
//   },
//   {
//     name: 'Hateful or abusive content',
//     id: '3',
//     check_video_description: false,
//   },
//   {
//     name: 'Harassment or bullying',
//     id: '4',
//     check_video_description: false,
//   },
//   {
//     name: 'Harmful or dangerous acts',
//     id: '5',
//     check_video_description: false,
//   },
//   { name: 'Misinformation', id: '6', check_video_description: true },
//   { name: 'Child abuse', id: '7', check_video_description: true },
//   { name: 'Promotes terrorism', id: '8', check_video_description: true },
//   { name: 'Spam or misleading', id: '9', check_video_description: false },
//   { name: 'Legal issue', id: '10', check_video_description: false },
//   { name: 'Captions issue', id: '0', check_video_description: false },
// ];

const validationSchema = Yup.object().shape({
  reason_id: Yup.number().required().label('Reason'),
  check_video_description: Yup.boolean().required(),
  minutes: Yup.number().min(0).max(999).required().label('Timestamp'),
  seconds: Yup.number().min(0).max(60).required().label('Timestamp'),
  additional_detail: Yup.string().max(500),
});

const ReportVideoModal = () => {
  const dispatch = useDispatch();
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const { open, contractVideoId, videoId, videoUrlId, timestampInSec } =
    useSelector((state) => state.reportVideoStore);
  const minutes = Math.floor(timestampInSec / 60);
  const seconds = parseInt(
    timestampInSec - Math.floor(timestampInSec / 60) * 60
  );
  const account = useAccount();
  const [includeVideoDescription, setIncludeVideoDescription] = useState(false);

  const { data: options } = useVideoReportCategoriesQuery();

  // const [saveBlock] = useSaveBlockTransactionMutation();
  const [reportVideo] = useReportVideoMutation();
  const [processing, setProcessing] = useState(false);

  const { abi, contract_address } = useFindContractData();

  const { writeContractAsync: reportVideoContract, reset } = useWriteContract();
  useEffect(() => {
    return () => setIncludeVideoDescription(false);
  }, []);

  const handleClose = () => dispatch(closeReportVideoModal());

  return (
    <>
      <ProcessingModal
        show={processing}
        metamask={true}
        handleClose={() => reset()}
      />
      <Modal
        show={open}
        onHide={handleClose}
        backdrop={true}
        keyboard={false}
        id='save-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title>Report Video</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            reason_id: null,
            check_video_description: false,
            minutes,
            seconds,
            additional_detail: '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            handleClose();
            if (activeChainId !== config.ACTIVE_CHAIN.id) {
              await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
            }
            setProcessing(true);
            try {
              const args = [contractVideoId];
              const hash = await reportVideoContract({
                args,
                address: contract_address,
                abi: JSON.parse(abi),
                functionName: 'reportVideo',
                chainId: config.ACTIVE_CHAIN.id,
              });
              // console.log(isLoading, 'isloading after');

              // const block_transaction_data = {
              //   action: 'reportVideo',
              //   address_out: account.address,
              //   hash,
              //   network_type: 5,
              //   request: JSON.stringify(args),
              // };
              // saveBlock({ body: block_transaction_data });

              const res = await reportVideo({
                video_id: videoId,
                video_url_id: videoUrlId,
                body: {
                  reason_id: parseInt(values.reason_id),
                  check_video_description: values.check_video_description,
                  timestamp: `${values.minutes}:${values.seconds}`,
                  additional_detail: values.additional_detail,
                  hash,
                },
              }).unwrap();
              toast.success(res.message || messages.report_video);
              handleClose();
              setProcessing(false);
              setSubmitting(true);
            } catch (error) {
              setProcessing(false);
              setSubmitting(true);
              toast.error(error.message);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
            handleSubmit,
            isValid,
            isSubmitting,
          }) => (
            <>
              <Modal.Body style={{ overflow: 'auto' }}>
                <div style={{ maxHeight: '60vh' }}>
                  {options?.map((option) => (
                    <>
                      <Form.Group className='d-flex align-items-center mb-2 px-4 py-2 rounded-lg w-100 gray-box'>
                        <Form.Check
                          type='radio'
                          name='reason_id'
                          value={option.id.toString()}
                          custom
                          id={option.name}
                          checked={option.id.toString() === values.reason_id}
                          onChange={(e) => {
                            handleChange(e);
                            setIncludeVideoDescription(
                              Boolean(option.check_video_description)
                            );
                          }}
                          // isInvalid={touched.reason_id && !!errors.reason_id}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                        />
                        <Form.Label
                          for={option.name}
                          className='d-block fs-400 text-clr-dark-300 fw-600 ml-2 mb-0'
                        >
                          {option.name}
                        </Form.Label>
                      </Form.Group>
                    </>
                  ))}
                  <hr className='clr-white-card-hr' />
                  {includeVideoDescription && (
                    <div className='d-flex align-items-center mb-2 px-4 py-2 rounded-lg w-100 gray-box'>
                      <Form.Check
                        type='checkbox'
                        name='check_video_description'
                        value={values.check_video_description}
                        custom
                        id='include_video_description'
                        checked={values.check_video_description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                      />
                      <label
                        for='include_video_description'
                        className='d-block fs-400 text-clr-dark-300 fw-600 ml-2 mb-0'
                      >
                        This applies to links within the video description
                      </label>
                    </div>
                  )}
                  <Form.Group md={6} controlId='first-name'>
                    <Form.Label>
                      Timestamp selected
                      <span className='text-danger'>*</span>
                    </Form.Label>
                    <div className='d-flex align-items-center'>
                      <Form.Control
                        type='number'
                        placeholder=''
                        required
                        size='lg'
                        name='minutes'
                        min={0}
                        max={999}
                        value={values.minutes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.minutes && !!errors.minutes}
                        disabled={isSubmitting}
                      />
                      <span className='mx-1'>:</span>
                      <Form.Control
                        type='number'
                        placeholder=''
                        required
                        size='lg'
                        name='seconds'
                        min={0}
                        max={60}
                        value={values.seconds}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.seconds && !!errors.seconds}
                        disabled={isSubmitting}
                      />
                    </div>
                    <Form.Control.Feedback type='invalid'>
                      {errors.minutes || errors.seconds}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group md={6} controlId='first-name'>
                    <Form.Label>Provide Additional Details</Form.Label>
                    <Form.Control
                      size='lg'
                      name='additional_detail'
                      as='textarea'
                      rows={4}
                      value={values.additional_detail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                  </Form.Group>
                  <span>
                    Flagged videos and users are reviewed by Mirror staff 24
                    hours a day, 7 days a week to determine whether they violate
                    Community Guidelines. Accounts are penalized for Community
                    Guidelines violations, and serious or repeated violations
                    can lead to account termination.
                  </span>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='light'
                  onClick={() => handleClose()}
                  disabled={isSubmitting}
                >
                  Close
                </Button>
                <Button
                  variant='primary'
                  onClick={handleSubmit}
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? 'Reporting...' : 'Report'}
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ReportVideoModal;
