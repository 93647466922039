import React from 'react';
import './style.css';
import { Button } from 'react-bootstrap';

const IconButton = ({
  onClick,
  startIcon = null,
  endIcon = null,
  children,
  variant = 'default',
  shadow = true,
  size = null,
  style = {},
}) => {
  let defaultStyle = {};
  if (size) {
    style.width = `${size}px`;
    style.height = `${size}px`;
  }
  if (style) {
    defaultStyle = {
      ...defaultStyle,
      ...style,
    };
  }
  
  return (
    // <button
    //   className='btn-circle'
    //   style={{ backgroundColor }}
    //   onClick={onClick}
    // >
    //   {startIcon && startIcon}
    //   {children}
    //   {endIcon && endIcon}
    // </button>

    <Button
      style={
        shadow
          ? { ...defaultStyle }
          : { boxShadow: 'none', background: 'transparent', ...defaultStyle }
      }
      className='btn-circle btn-icon'
      variant={variant}
      size='sm'
      onClick={onClick}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </Button>
  );
};

export default IconButton;
