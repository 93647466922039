import { Row, Col } from 'react-bootstrap';

import SectionHeader from 'src/components/atoms/SectionHeader/SectionHeader';
import TopCategoriesSlider from './TopCategoriesSlider.component';

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

function TopCategory() {
  return (
    <>
      <div className='top-category section-padding mb-4 mt-4'>
        <Row>
          <Col md={12} className='mb-2'>
            <SectionHeader
              heading='Categories'
              noIconLabel
              icon={faEllipsisH}
            />
          </Col>

          <Col md={12}>
            <TopCategoriesSlider />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TopCategory;
