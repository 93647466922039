import UilParagraph from '@iconscout/react-unicons/icons/uil-paragraph';
import { Dropdown } from 'react-bootstrap';
import { createContext, useState } from 'react';
import { abbreviateNumber } from 'js-abbreviation-number';

import CommentBody from './CommentBody';
import { COLORS, SORT_BY } from 'src/constant';
import { useFetchOneVideoCommentsQuery } from 'src/redux/features/comments/commentApi';
import Loader from 'src/components/atoms/Loader';

export const CommentContext = createContext();

const CommentBox = ({ videoId, contractVideoId, handleClose, ...props }) => {
  const [sort, setSort] = useState(SORT_BY.NEWEST_FIRST);
  const limit = 50;
  const [offset, setOffset] = useState(0);

  const handleNextPage = () => {
    setOffset((prev) => prev + limit);
  };

  const { data, isLoading, originalArgs } = useFetchOneVideoCommentsQuery(
    {
      videoId,
      sort,
      limit,
      offset,
    },
    { skip: !videoId }
  );
  return (
    <CommentContext.Provider value={{ limit, offset, sort, handleNextPage }}>
      <div className='box  single-video-comment-tabs' {...props}>
        {(isLoading || originalArgs?.videoId !== videoId) && <Loader />}
        {data && (
          <>
            <div className='d-flex justify-content-between align-items-start'>
              <h5 className='fs-500 fw-600 mb-4'>
                {!data?.comment_count && 'No'} Comments{' '}
                <span className='clr-dark-100 fs-200 fw-200'>
                  {abbreviateNumber(data?.comment_count)}
                </span>
              </h5>
              <div className='d-flex align-items-center'>
                <Dropdown onSelect={(selected) => setSort(selected)}>
                  <Dropdown.Toggle as='a' id='' className='no-after'>
                    <div className='d-flex align-align-items-end cursor-pointer'>
                      <UilParagraph size='20' color={COLORS.dark300} />
                      <h6 className='fs-300 ml-1'>Sort By</h6>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ minWidth: 'min-content' }}>
                    <Dropdown.Item
                      eventKey={SORT_BY.NEWEST_FIRST}
                      active={sort === SORT_BY.NEWEST_FIRST}
                    >
                      {SORT_BY.NEWEST_FIRST}
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey={SORT_BY.TOP_COMMENTS}
                      active={sort === SORT_BY.TOP_COMMENTS}
                    >
                      {SORT_BY.TOP_COMMENTS}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {handleClose && (
                  <button
                    type='button'
                    class='close ml-3 mb-1'
                    onClick={handleClose}
                  >
                    <span aria-hidden='true'>×</span>
                    <span class='sr-only'>Close</span>
                  </button>
                )}
              </div>
            </div>
            <CommentBody
              sort={sort}
              comments={data.comments}
              contractVideoId={contractVideoId}
              totalResultCount={data.total_result_count}
              limit={limit}
              offset={offset}
              handleNextPage={handleNextPage}
            />
          </>
        )}
      </div>
    </CommentContext.Provider>
  );
};

function Soon() {
  return <p>Coming Soon...</p>;
}

export default CommentBox;
