import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { COMMON_STATUS, SERVER_BASE_URL } from 'src/constant';
import rootApi, { defaultHeaders } from 'src/redux/rootApi';

export const pendingTxnCheck = createAsyncThunk(
  'pendingTxnCheck',
  async ({ id, type }, { getState }) => {
    let url = `${SERVER_BASE_URL}/pending-txn/${type}/check/${id}`;
    // let url = `${SERVER_BASE_URL}/txn/check`;
    try {
      // if (typeof id === 'number' && id > 0) {
      const result = await axios.get(url, {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        // data: body,
      });
      return result.data;
      // }
      // return { data: { txn_status: COMMON_STATUS.SUCCESS } };
    } catch (error) {
      console.log(error, 'error');
    }
  }
);

const basicApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    saveBlockTransaction: builder.mutation({
      query: ({ body }) => ({
        url: '/block_transactions',
        method: 'POST',
        body,
      }),
    }),
    getAdBanners: builder.query({
      query: () => `/ad_banners`,
      transformResponse: (response) => response.data,
    }),
    basicInfo: builder.query({
      query: () => `/basic-info`,
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useSaveBlockTransactionMutation,
  useGetAdBannersQuery,
  useBasicInfoQuery,
} = basicApi;
