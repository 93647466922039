import { useParams } from 'react-router-dom';

import { useFetchChannelByHandleNameQuery } from 'src/redux/features/channels/channelApi';
import Avatar from 'src/components/atoms/Avatar';
import Constant from 'src/constant';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// const Avatar = ({ value }) => {
//   return (
//     <div
//       style={{
//         width: '90px',
//         height: '90px',
//         borderRadius: '50%',
//         background: '#fd7888',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       <h2
//         style={{
//           color: 'white',
//           fontSize: '26px',
//           fontWeight: '600',
//         }}
//       >
//         {value}
//       </h2>
//     </div>
//   );
// };
const SingleChannelHero = () => {
  const { handle_name } = useParams();

  const {
    data: channel,
    isLoading,
    originalArgs,
  } = useFetchChannelByHandleNameQuery(handle_name, {
    skip: !handle_name,
  });
  console.log(originalArgs, handle_name, 'orignal arg and handle');
  return (
    <>
      <div className='single-channel-image'>
        <img
          className='img-fluid banner-img'
          alt=''
          // style={{ height: 'calc(16.1290322581vw - 1px)' }}
          src={
            channel?.banner_url || `${Constant.BASE_URL}img/channel-banner.png`
          }
        />
        <div className='channel-profile'>
          {channel?.avatar_url ? (
            <img
              className='channel-profile-img'
              alt=''
              src={channel?.avatar_url}
            />
          ) : (
            <Avatar character={channel?.name[0]} size={80} />
          )}
          {channel?.level && (
            <OverlayTrigger
              placement='bottom'
              overlay={(props) => (
                <Tooltip {...props}>{channel?.level}</Tooltip>
              )}
            >
              <img
                className='img-fluid level-img'
                src={channel?.level_image_url}
                alt='Level'
              />
            </OverlayTrigger>
          )}
          {/* <div className='social hidden-xs'>
            Social &nbsp;
            <a className='fb mr-1' href='#'>
              Facebook
            </a>
            <a className='tw mr-1' href='#'>
              Twitter
            </a>
            <a className='gp' href='#'>
              Google
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SingleChannelHero;
