import React, { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TagsInput from 'react-tagsinput';

import { CLIENT_URL, THUMBNAIL_TYPE } from 'src/constant';
import { useFetchCategoriesQuery } from 'src/redux/features/categories/categoriesApi';
import VideoPreviewCard from './VideoPreviewCard';
import { FormContext } from '../UploadVideo';
import 'react-tagsinput/react-tagsinput.css';
import { BlockchainSaveTooltip } from 'src/components/atoms/CustomCheckTooltips/CustomCheckTooltips';
import { useUploadThumbnailMutation } from 'src/redux/features/videos/videoApi';
import { toast } from 'react-toastify';
import messages from 'src/constant/messages';
import handleError from 'src/utils/handle-error';
import UploadThumbnail from 'src/components/upload-thumbnail/UploadThumbnail';
import CustomTagsInput from 'src/components/atoms/inputs/CustomTagsInput';

const maxFileSize = 2 * 1048576; // 1 MB
const SUPPORT_FILE_TYPE = ['image/jpeg', 'image/png'];

export const tagValidation = Yup.array().of(
  Yup.string()
    .test(
      'no-quotes',
      'Tag cannot contain both single and double quotes',
      (value) => {
        // Regular expression to match strings containing both single and double quotes
        const regex = /(['"])(?:(?!\1|\\).|\\.)*\1/g;

        // If the regex matches, the string contains both single and double quotes
        return !regex.test(value);
      }
    )
    // .matches(/^[a-zA-Z]+$/, 'Value must be alphanumeric')
    .required()
);

const thumbnailSchema = Yup.mixed()
  .test(
    'fileSize',
    'File size is too large',
    (value) => !value || (value && value.size <= maxFileSize)
  )
  .test(
    'fileType',
    'Invalid file type',
    (value) => !value || (value && SUPPORT_FILE_TYPE.includes(value.type))
  );

const validationSchema = Yup.object().shape({
  title: Yup.string().max(100).required().label('Title'),
  description: Yup.string().max(5000).required().label('Description'),
  category_id: Yup.number().required().label('Category'),
  thumbnail_id: Yup.number().required().label('Thumbnail'),
  tags: tagValidation,
  comments_enabled: Yup.boolean().required().label('Comment Enabled'),
});

const StepTwo = () => {
  const { data: categories } = useFetchCategoriesQuery();
  const [uploadThumbnail, { isLoading: thumbnailUploading }] =
    useUploadThumbnailMutation();
  const {
    setActiveStepIndex,
    setVideoDetailStore,
    videoDetailStore,
    videoProgress,
  } = useContext(FormContext);

  const handleVideoStore = (key, value) => {
    setVideoDetailStore((pre) => ({ ...pre, [key]: value }));
  };
  // if (!videoDetailStore?.category_id) return null;
  const customThumbnail = videoDetailStore?.thumbnails?.find(
    (item) => item.type === THUMBNAIL_TYPE.CUSTOM
  );

  return (
    <Row id='upload-details' className='mx-0'>
      <Col md={12} className='mx-auto p-0'>
        <h3 className='mb-3 fs-500 fw-600'>Upload video</h3>
      </Col>
      <Col md={12} className='mx-auto py-3 py-md-4 px-3 px-md-4 studio-card'>
        <Formik
          initialValues={{
            title: videoDetailStore?.title || '',
            description: videoDetailStore?.description || '',
            category_id: videoDetailStore?.category_id || '',
            thumbnail_id: videoDetailStore?.thumbnail_id || null,
            thumbnail_url: videoDetailStore?.thumbnail_url || '',
            comments_enabled: Boolean(videoDetailStore?.comments_enabled),
            tags: videoDetailStore?.tags || [],
          }}
          onSubmit={(values) => {
            // setVideoDetailStore((prev) => ({ ...prev, ...values }));
            setActiveStepIndex((prev) => prev + 1);
          }}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <div>
              <Row>
                <Col lg={7}>
                  <h3 className='mb-3 fs-500 fw-600'>Details</h3>
                  <Form.Group controlId='video-category'>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      size='lg'
                      as='select'
                      className='custom-select text-capitalize'
                      custom
                      name='category_id'
                      onChange={(e) => {
                        handleChange(e);
                        const { name, value } = e.target;
                        handleVideoStore(name, value);
                      }}
                      onBlur={handleBlur}
                      value={values.category_id}
                      isInvalid={touched.category_id && !!errors.category_id}
                    >
                      <option value=''>Choose Category</option>
                      {categories?.map((category, idx) => (
                        <option
                          style={{
                            textTransform: 'capitalize',
                          }}
                          key={`category-option-${idx}`}
                          value={category.id}
                        >
                          {category.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      {errors.category_id}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='video-title'>
                    <Form.Label>
                      Title (Required)
                      <BlockchainSaveTooltip className='ml-1' />
                    </Form.Label>
                    <Form.Control
                      size='lg'
                      type='text'
                      name='title'
                      placeholder='Video name here'
                      onChange={(e) => {
                        handleChange(e);
                        const { name, value } = e.target;
                        handleVideoStore(name, value);
                      }}
                      onBlur={(e) => {
                        console.log(e, 'blur title');
                        handleBlur(e);
                      }}
                      value={values.title}
                      isInvalid={touched.title && !!errors.title}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='video-description'>
                    <Form.Label>
                      Description
                      <BlockchainSaveTooltip className='ml-1' />
                    </Form.Label>
                    {/*  */}
                    <CKEditor
                      config={{
                        toolbar: {
                          items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'link',
                            '|',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'undo',
                            'redo',
                          ],
                        },
                        link: {
                          addTargetToExternalLinks: true, // This sets target="_blank" for external links
                        },
                      }}
                      onError={(e) => {
                        console.log(e);
                      }}
                      editor={ClassicEditor}
                      data={values.description}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('description', data);
                        handleVideoStore('description', data);
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', event, editor);
                        setFieldTouched('description', true);
                      }}
                      // onFocus={(event, editor) => {
                      //   console.log('Focus.', editor);
                      // }}
                    />

                    {touched.description && errors.description && (
                      <Form.Text className='text-danger fs-200'>
                        {errors.description}
                      </Form.Text>
                    )}
                  </Form.Group>

                  {/* Tags */}
                  <Form.Group controlId='video-tags'>
                    <Form.Label>Tags</Form.Label>
                    <CustomTagsInput
                      value={values.tags}
                      onChange={async (tags) => {
                        setFieldValue('tags', tags);
                        handleVideoStore('tags', tags);
                      }}
                    />
                    {errors.tags && (
                      <Form.Text className='text-danger fs-200'>
                        {errors.tags?.find((tag) => !!tag)}
                      </Form.Text>
                    )}
                  </Form.Group>

                  {/* thumbnail  */}
                  <UploadThumbnail
                    maxThumbnail={4}
                    thumbnails={videoDetailStore?.thumbnails}
                    value={values.thumbnail_id}
                    isUploading={thumbnailUploading}
                    error={touched.thumbnail_id && errors.thumbnail_id}
                    onSelectThumbnail={(thumbnail) => {
                      setFieldValue('thumbnail_id', thumbnail.id);
                      handleVideoStore('thumbnail_id', thumbnail.id);
                    }}
                    onUploadBoxClick={(callback) => {
                      if (!videoDetailStore?.videoId)
                        return toast.info(messages.uploading_thumbnail);
                      callback();
                    }}
                    onChange={async (e) => {
                      const file = e.target.files[0];
                      try {
                        await thumbnailSchema.validate(file);
                        const formData = new FormData();
                        formData.append('thumbnail', file);
                        const { data } = await uploadThumbnail({
                          videoId: videoDetailStore.videoId,
                          formData,
                        }).unwrap();
                        setFieldValue('thumbnail_id', data.thumbnail.id);
                        const remainThumbnails = customThumbnail
                          ? videoDetailStore.thumbnails.slice(1)
                          : videoDetailStore.thumbnails;
                        setVideoDetailStore((prev) => ({
                          ...prev,
                          thumbnail_id: data.thumbnail.id,
                          thumbnails: [data.thumbnail, ...remainThumbnails],
                        }));
                        setFieldTouched('thumbnail_id', true);
                      } catch (error) {
                        setFieldTouched('thumbnail_id', true);
                        handleError(error);
                      }
                    }}
                  />
                  {/* Comment (Disable / Unable) */}
                  <div className='d-flex justify-content-between mt-4'>
                    <div>
                      <h6 className='fw-600'>Comments</h6>
                      <p className='mt-1'>
                        Disable the comments section for the viewers to post/add
                        any comment/review on the channel.
                      </p>
                    </div>
                    <Form.Check
                      type='switch'
                      id='custom-switch'
                      name='comments_enabled'
                      checked={values.comments_enabled}
                      onChange={(e) => {
                        handleChange(e);
                        const { name, checked } = e.target;
                        handleVideoStore(name, checked);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={5}>
                  {/* video */}
                  <VideoPreviewCard
                    // posterFileUrl={values.thumbnail_url}
                    posterFileUrl={
                      videoDetailStore?.thumbnails?.find(
                        (item) => item.id === values.thumbnail_id
                      )?.thumbnail_url || null
                    }
                    videoFileUrl={videoDetailStore.video_url}
                    videoUrl={`${CLIENT_URL}/${
                      videoDetailStore.is_short ? 'shorts' : 'watch'
                    }/${videoDetailStore.video_url_id}`}
                    videoProgress={videoProgress}
                    videoTitle={
                      videoDetailStore?.video?.name || videoDetailStore.title
                    }
                  />
                </Col>
                <Col md={12}>
                  <hr className='clr-white-card-hr' />
                </Col>
                <Col md={12} className='d-flex'>
                  <Button
                    variant='primary'
                    className='ml-auto px-4'
                    // type='submit'
                    onClick={() => handleSubmit()}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default StepTwo;
