import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, Container, Form } from 'react-bootstrap';

import {
  useFetchMyChannelQuery,
  useSendChannelForVerificationMutation,
} from 'src/redux/features/channels/channelApi';
import './Customization.css';
import Constant, { VERIFY_STATUS } from 'src/constant';
import { ThemeContext } from 'src/App';
import handleError from 'src/utils/handle-error';
import { UilImage } from '@iconscout/react-unicons';

const maxDocumentFileSize = 5 * 1048576; // 4 MB
const SUPPORT_FILE_TYPE = ['application/pdf'];

const DOCUMENT_TYPE = [
  { id: 'passport', name: 'Passport' },
  { id: 'government-id', name: 'Government ID' },
  { id: 'driving-license', name: 'Driving License' },
];
const validationSchema = () =>
  Yup.object().shape({
    document: Yup.mixed()
      .test(
        'fileType',
        'Invalid file type (supported file type pdf only)',
        (value) => !value || (value && SUPPORT_FILE_TYPE.includes(value.type))
      )
      .test(
        'fileSize',
        'File size is too large (5MB or less)',
        (value) => !value || (value && value.size <= maxDocumentFileSize)
      )
      .required()
      .label('Document'),
    document_type: Yup.number().required().label('Document Type'),
  });

const VerifyChannel = () => {
  const documentRef = useRef(null);
  const { themeImageUrlParser } = useContext(ThemeContext);
  const { data: channel } = useFetchMyChannelQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [sendForChannelVerification, { error, isError }] =
    useSendChannelForVerificationMutation();

  if (isError) {
    console.log(error, 'error');
  }

  return (
    <div className='py-3 py-md-4 px-3 px-md-4' id='verify-channel'>
      {channel?.verify_status === VERIFY_STATUS.UNVERIFIED ? (
        <Formik
          initialValues={{
            document: '',
            document_type: '',
          }}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            console.log(values);
            setSubmitting(true);
            const { document, document_type } = values;
            const formData = new FormData();
            formData.append('document_type', document_type);
            formData.append('document', document);
            try {
              const response = await sendForChannelVerification(
                formData
              ).unwrap();
              console.log(response, 'response');
              setSubmitting(false);
              toast.success(response?.message || 'Successfully Updated.');
              resetForm();
            } catch (error) {
              setSubmitting(false);
              handleError(error);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            handleBlur,
            handleChange,
            errors,
            touched,
            values,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            resetForm,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Container
                style={{ maxWidth: '500px' }}
                className='d-flex flex-column'
              >
                <img
                  alt='auth-verify-channel'
                  className='mx-auto mt-4 mb-1'
                  src={`${Constant.BASE_URL}img/auth.png`}
                />
                <h3 className='h3 text-center mb-4'>
                  Apply for Account verification
                </h3>
                <Form.Group controlId='channel-name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    name='name'
                    placeholder='Enter channel name'
                    value={channel?.name}
                    disabled
                  />
                </Form.Group>

                <Form.Group controlId='handle-name'>
                  <Form.Label>Handle</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    name='handle_name'
                    placeholder='Set your handle name'
                    value={channel?.handle_name}
                    disabled
                  />
                </Form.Group>
                <Form.Group controlId='document'>
                  <Form.Label>Document Type</Form.Label>
                  <Form.Control
                    size='lg'
                    as='select'
                    className='custom-select'
                    custom
                    name='document_type'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.document_type}
                    isInvalid={touched.document_type && !!errors.document_type}
                  >
                    <option value=''>Choose Document Type</option>
                    {DOCUMENT_TYPE?.map((item, idx) => (
                      <option
                        key={`category-option-${item.id}`}
                        value={idx + 1}
                      >
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {errors.document_type}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Document */}
                <div className='mt-4 d-flex align-items-center mb-1'>
                  <h6 className='fw-600 mr-2'>Document</h6>
                  <img src={`${Constant.BASE_URL}img/info.svg`} alt='info' />
                </div>
                <p>Select or upload a pdf document. 5MB max.</p>
                <div
                  onClick={() => documentRef.current.click()}
                  className='rounded-sm d-flex justify-content-center align-items-center p-1 p-sm-3 mt-2 w-100'
                  style={{
                    aspectRatio: '16/5',
                    border: `1px solid ${
                      touched.document && errors.document
                        ? 'var(--clr-primary-400)'
                        : '#343434'
                    }`,
                    cursor: 'pointer',
                  }}
                >
                  <Form.Group id='document'>
                    <Form.Control
                      size='lg'
                      type='file'
                      hidden
                      name='document'
                      ref={documentRef}
                      onChange={async (e) => {
                        setFieldTouched('document', true);
                        const file = e.target.files[0];
                        console.log(file, 'file');
                        setFieldValue('document', file);
                        // const reader = new FileReader();
                        // reader.readAsDataURL(file);
                        // reader.onload = () => {
                        //   setDocumentUrl(reader.result);
                        // };
                      }}
                    />
                  </Form.Group>

                  <div className='gray-box h-100 w-100  d-flex flex-column justify-content-center align-items-center rounded-sm'>
                    <UilImage size={30} />
                    {!values.document ? (
                      <>
                        {/* <img
                          src={themeImageUrlParser('upload/gallery.svg')}
                          className='mb-2 upload-image-verifychannel'
                          alt='info'
                        /> */}
                        <h6 className='fs-200 fw-200 mt-2'>Upload</h6>
                      </>
                    ) : (
                      <div className='dynamic-text mt-1 text-center overflow-hidden text-nowrap text-ellipse w-100'>
                        {values.document?.name}{' '}
                      </div>
                    )}
                  </div>
                </div>
                {touched.document && errors.document && (
                  <Form.Text className='text-danger fs-200'>
                    {errors.document}
                  </Form.Text>
                )}

                <hr className='clr-white-card-hr' />
                {/* <div className='d-flex justify-content-center align-items-center'> */}
                <Button
                  variant='primary'
                  className='px-4 mx-auto'
                  type='submit'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Saving' : 'Submit'}
                </Button>
                {/* </div> */}
              </Container>
            </Form>
          )}
        </Formik>
      ) : (
        <div className='d-flex flex-column align-items-center'>
          <img
            style={{ width: '200px' }}
            alt='channle-verification'
            src={`${Constant.BASE_URL}img/illustration/${
              channel?.verify_status === VERIFY_STATUS.UNDER_VERIFICATION
                ? 'pending'
                : 'verified'
            }.png`}
          />
          <Form.Label className='text-center mt-2'>
            {channel?.verify_status === VERIFY_STATUS.UNDER_VERIFICATION
              ? 'Account Verification under process.'
              : 'Your account has already been verified.'}
          </Form.Label>
        </div>
      )}
    </div>
  );
};

export default VerifyChannel;
