import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import UilCopy from '@iconscout/react-unicons/icons/uil-copy';
import UilCheck from '@iconscout/react-unicons/icons/uil-check-circle';
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';

import { COLORS } from '../../../constant';
import { useDispatch, useSelector } from 'react-redux';
import { handleCloseShare } from 'src/redux/features/share/shareSlice';

const ShareModal = () => {
  // const [isLoaded, setIsLoaded] = useState(false);
  const [copied, setCopied] = useState(false);
  const { open, title, description, shareLink } = useSelector(
    (state) => state.shareStore
  );
  const dispatch = useDispatch();
  // const shareLink = metaData?.shareLink || window.location.href;

  // useEffect(() => {
  //   setIsLoaded(true);
  // }, []);

  return (
    <>
      <Modal
        show={open}
        onHide={() => dispatch(handleCloseShare())}
        backdrop={true}
        keyboard={false}
        id='video-published-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Via</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Social Icons */}
          <div
            className='d-flex justify-content-center align-items-center flex-wrap'
            style={{ gap: '.6rem' }}
          >
            <WhatsappShareButton
              url={shareLink}
              className='social-icon-link'
              title=''
              separator='&&'
            >
              {/* <img
                className='mx-2 mx-md-3'
                style={{ maxWidth: '50px' }}
                alt='whatsapp'
                src={`${Constant.BASE_URL}img/whatsapp.svg`}
              /> */}
              <WhatsappIcon round='full' size={50} className='mx-3' />

              <p className='fs-300 text-clr-dark-100 mt-1'>Whatsapp</p>
            </WhatsappShareButton>
            <FacebookShareButton
              url={shareLink}
              title={title}
              quote={title}
              hashtag='#mirror'
              className='social-icon-link'
            >
              {/* <img
                className='mx-2 mx-md-3'
                style={{ maxWidth: '50px' }}
                alt='facebook'
                src={`${Constant.BASE_URL}img/facebook.svg`}
              /> */}
              <FacebookIcon round='full' size={50} className='mx-3' />
              <p className='fs-300 text-clr-dark-100 mt-1'>Facebook</p>
            </FacebookShareButton>
            <TwitterShareButton url={shareLink} className='social-icon-link'>
              {/* <img
                className='mx-2 mx-md-3'
                style={{ maxWidth: '50px' }}
                alt='twitter'
                src={`${Constant.BASE_URL}img/twitter.svg`}
              /> */}
              <TwitterIcon round='full' size={50} className='mx-3' />
              <p className='fs-300 text-clr-dark-100 mt-1'>Twitter</p>
            </TwitterShareButton>
            <EmailShareButton url={shareLink} className='social-icon-link'>
              {/* <img
                className='mx-2 mx-md-3'
                style={{ maxWidth: '50px' }}
                alt='mail'
                src={`${Constant.BASE_URL}img/mail.svg`}
              /> */}
              <EmailIcon
                round='full'
                size={50}
                className='mx-2 mx-md-3'
                color={COLORS.primary400}
              />
              <p className='fs-300 text-clr-dark-100 mt-1'>Email</p>
            </EmailShareButton>
            <TelegramShareButton url={shareLink} className='social-icon-link'>
              {/* <img
                className='mx-2 mx-md-3'
                style={{ maxWidth: '50px' }}
                alt='mail'
                src={`${Constant.BASE_URL}img/mail.svg`}
              /> */}
              <TelegramIcon round='full' size={50} className='mx-3' />
              <p className='fs-300 text-clr-dark-100 mt-1'>Email</p>
            </TelegramShareButton>
          </div>
          <div className='mt-4 px-4 py-2 rounded-lg w-100 d-flex justify-content-between align-items-center gray-box'>
            <a
              href={shareLink}
              className='text-clr-dark-300 text-ellipse overflow-hidden pr-3'
            >
              {shareLink}{' '}
            </a>
            {!copied ? (
              <CopyToClipboard
                text={shareLink}
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 4000);
                }}
              >
                <div className='cursor-pointer'>
                  <UilCopy color={COLORS.primary400} size='20' />
                </div>
              </CopyToClipboard>
            ) : (
              <UilCheck color={COLORS.primary400} size='20' />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
