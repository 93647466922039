export const TRANSACTION_MESSAGE = {
  METAMASKREQUESTREJECT: 'User Rejected Transaction',
  REJECTEDAPPROVING: 'Rejected approving!',
  WRONGADDRESS: 'This address is not correct!',
  SOMETHINGWENTWRONG: 'Something went wrong! Refresh & Reconnect!',
  // INSUFFICIENT_FUND: 'Insufficient funds for gas * price + value!',
  INSUFFICIENT_FUND: `This error could arise when the account does not have enough funds to pay for the total gas fee or pay for the value to send.`,
  CANCELPRESALE: 'Presale cancelled!',
  MAXBUYLIMITEXCEED: 'Max Buy Limit Exceeded',
  HARDCAPLIMITEXCEED: 'Hard CapLimit Exceeded',
  ADDRESS_ALREADY_WHITELISTED: 'This address already whitelisted',
  ALREADY_LIKED: 'Already Liked.',
  ALREADY: 'Already recorded this action.',
};

const messages = {
  video_in_draft_upload_disabled:
    'Before uploading a new video, please ensure that any existing draft videos are either cleared or published.',
  require_signin_to_report: 'Sign in to report inappropriate content.',
  require_signin: 'Sign in required to proceed.',
  video_reported_already: 'You have already reported this video.',
  report_video: 'Video Report Successfully.',
  waiting_wallet_confirmation: 'Waiting for wallet confirmation.',
  uploading_thumbnail:
    'Thumbnail upload will occur once the video is fully uploaded.',
  data_also_store_in_blockchain:
    'ℹ️ This input field data will also be stored on blockchain.',
  pending_transaction:
    'Your previous transaction is currently being processed. Please wait until the transaction is completed before attempting to update again. Thank you for your patience!!',
  network_switch_error: 'Fail to switch the network.',
};

export default messages;
