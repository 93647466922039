import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import config from 'src/config';
import { CONTRACT_SYMBOL } from 'src/constant';
import {
  convertToDecimalString,
  formatBigIntDecimal,
} from 'src/helpers/general';
import useFindContractData from 'src/hooks/useFindContractData';
import { useRewardRedeemMutation } from 'src/redux/features/reward/rewardApi';
import handleError from 'src/utils/handle-error';
import { formatEther, parseUnits } from 'viem';
import * as Yup from 'yup';

const RedeemModal = ({ show, handleClose, handleShow, data }) => {
  const [confirm, setConfirm] = useState(false);
  const { decimal, symbol } = useFindContractData(CONTRACT_SYMBOL.MirrorToken);

  const validationSchema = Yup.object().shape({
    redeem_amount: Yup.string()
      .test(
        'max amount',
        `You do not have sufficient funds for this withdrawal. Your current balance is ${formatBigIntDecimal(
          data.balance,
          decimal
        )} ${symbol}.`,
        (value) => {
          return Number(value) <= Number(data.balance);
        }
      )
      .test(
        'max decimal',
        'Please enter a number with a maximum of 18 decimal places.',
        (value) => {
          const [left, right] = value.split('.');
          if (right) return right.length <= decimal;

          return true;
        }
      )
      .test('zero', '', (value) => {
        const [left, right] = value.split('.');
        console.log(left, right, 'left rigfh');
        if (parseInt(left) === 0 && parseInt(right) === 0) return false;
        if (right === undefined && parseInt(left) === 0) return false;
        return true;
      })
      .min(1)
      .required()
      .label('Redeem amount'),
  });

  const [rewardRedeem, {}] = useRewardRedeemMutation();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Formik
          initialValues={{
            redeem_amount: null,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            // call api
            try {
              const data = await rewardRedeem({
                body: { redeem_amount: values.redeem_amount }, // parseUnits(values.redeem_amount,18).toString(), //
              }).unwrap();
              toast.success(data.message);
            } catch (error) {
              handleError(error);
            }
            handleClose();
            setConfirm(false);
            setSubmitting(true);
          }}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isValid,
            dirty,
            isSubmitting,
          }) =>
            !confirm ? (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Current Balance:{' '}
                    {formatBigIntDecimal(data.balance, decimal)} {symbol}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group controlId='redeem_amount'>
                    <Form.Label>Redeem Amount</Form.Label>
                    <Form.Control
                      size='lg'
                      type='string'
                      name='redeem_amount'
                      placeholder='Redeem amount'
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const value = convertToDecimalString(inputValue);
                        handleChange({
                          target: {
                            name: 'redeem_amount',
                            value: value,
                          },
                        });
                      }}
                      onBlur={handleBlur}
                      value={values.redeem_amount}
                      isInvalid={
                        touched.redeem_amount && !!errors.redeem_amount
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.redeem_amount}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant='light'
                    className=''
                    disabled={isSubmitting}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant='primary'
                    className='ml-4'
                    disabled={!isValid || !dirty || isSubmitting}
                    onClick={() => {
                      setConfirm(true);
                    }}
                  >
                    Redeem
                  </Button>
                </Modal.Footer>
              </>
            ) : (
              <div>
                <Modal.Header>
                  <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h6 className='text-center'>
                    Are you sure you want to redeem: {values.redeem_amount} (
                    {symbol})?
                  </h6>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant='light'
                    className=''
                    onClick={() => setConfirm(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='primary'
                    className='ml-4'
                    onClick={handleSubmit}
                  >
                    Confirm
                  </Button>
                </Modal.Footer>
              </div>
            )
          }
        </Formik>
      </Modal>
    </>
  );
};

export default RedeemModal;
