import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = ({ className = '', size = '', role = 'status', style = {} }) => {
  return (
    <div className={`d-flex justify-content-center ${className}`} style={style}>
      <Spinner animation='border' variant='primary' size={size} role={role} />
    </div>
  );
};

export default Loader;
