import moment from 'moment';
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { abbreviateNumber } from 'js-abbreviation-number';
import PropTypes from 'prop-types';

import Constant, {
  COLORS,
  DATE_FORMAT,
  FIXED_DECIMAL_CONSTANT,
} from 'src/constant';
import NoContent from '../atoms/NoContent/NoContent';

const convertSeconds = (seconds) => {
  if (seconds === 0) return 0;
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  // const remainingSeconds = (seconds % 60).toFixed(2);
  const remainingSeconds = parseInt(seconds % 60);

  const result = [];

  if (hours > 0) {
    result.push(`${hours} Hr${hours !== 1 ? 's' : ''}`);
  }

  if (minutes > 0) {
    result.push(`${minutes} Min${minutes !== 1 ? 's' : ''}`);
  }

  if (remainingSeconds > 0) {
    result.push(`${remainingSeconds} Sec${remainingSeconds !== 1 ? 's' : ''}`);
  }

  return result.join(', ');
};

const timeFormatter = (value) => {
  if (value >= 3600) {
    return `${Math.floor(value / 3600)}h`;
  } else {
    return `${Math.floor(value / 60)}m`;
  }
};

const CustomAreaGraph = ({ data, yAxisType = 'number', yLabel = 'Count' }) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      return (
        <div className='text-light bg-dark fw-600 fs-300 rounded-sm px-2 py-1'>
          <div>Date - {moment(label).format(DATE_FORMAT)}</div>
          {yAxisType === 'second' ? (
            <div>Duration - {convertSeconds(payload[0].value)}</div>
          ) : (
            <div>
              {yLabel} -{' '}
              {abbreviateNumber(payload[0].value, FIXED_DECIMAL_CONSTANT)}
            </div>
          )}
        </div>
      );
    }
  };
  if (!data || data.length === 0) {
    return (
      <NoContent
        imgUrl={`${Constant.BASE_URL}img/illustration/no-analytics.png`}
        title="We couldn't find any analytics data for the selected period. Please
          adjust your filters or try a different time range."
        addContainer={false}
      />
    );
  }
  return (
    <>
      <ResponsiveContainer
        // minWidth={700}
        width='100%'
        height={400}
      >
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
              <stop
                offset='0%'
                stopColor={COLORS.primary400}
                stopOpacity={0.4}
              />
              <stop
                offset='7'
                stopColor={COLORS.primary400}
                stopOpacity={0.05}
              />
            </linearGradient>
          </defs>
          <Area
            type='monotone'
            dataKey='y'
            stroke={COLORS.primary400}
            fillOpacity={1}
            strokeWidth={2}
            fill='url(#colorUv)'
          />
          <XAxis
            dataKey='x'
            axisLine={true}
            tickLine={true}
            allowDataOverflow={true}
            tickSize={0}
            tickCount={7}
            minTickGap={20}
            stroke='#707070'
            strokeWidth={0.3}
            interval={'preserveEnd'}
            tickFormatter={(date) => moment(date).format(DATE_FORMAT)}
            dy={15}
          />
          <YAxis
            dataKey='y'
            axisLine={true}
            tickLine={true}
            tickSize={0}
            name='count'
            tickFormatter={(number) =>
              yAxisType === 'second'
                ? timeFormatter(number)
                : abbreviateNumber(number, 0)
            }
            interval={'preserveEnd'}
            dx={-10}
            stroke='#707070'
            strokeWidth={0.3}
            // tickCount={7} ONLY WORK IN type=number
          />
          <CartesianGrid opacity={0.2} vertical={false} horizontal={false} />
          <Tooltip content={CustomTooltip} />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default CustomAreaGraph;

CustomAreaGraph.propTypes = {
  data: PropTypes.array.isRequired,
  yAxisType: PropTypes.oneOf(['number', 'second']),
};
