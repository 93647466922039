import React, { useState, createContext } from 'react';
import { UilParagraph } from '@iconscout/react-unicons';
import { Dropdown, Modal } from 'react-bootstrap';
import { abbreviateNumber } from 'js-abbreviation-number';

import { COLORS, SORT_BY } from 'src/constant';
import { useFetchOneVideoCommentsQuery } from 'src/redux/features/comments/commentApi';
import CommentMedia, {
  CustomMedia,
} from 'src/pages/video-page/components/CommentMedia';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'src/components/atoms/Loader';

export const CommentContext = createContext();

const Comments = ({ show, handleClose, videoId, contractVideoId }) => {
  console.log(videoId, 'video id');
  const [sort, setSort] = useState(SORT_BY.NEWEST_FIRST);
  const limit = 50;
  const [offset, setOffset] = useState(0);

  const handleNextPage = () => {
    setOffset((prev) => prev + limit);
  };

  const { data, isLoading, originalArgs } = useFetchOneVideoCommentsQuery(
    {
      videoId,
      sort,
      limit,
      offset,
    },
    { skip: !videoId }
  );

  return (
    <>
      <Modal
        id='short-comments-modal'
        show={show}
        onHide={handleClose}
        // backdrop='static'
        keyboard={false}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>
            <div>
              Comments{' '}
              <span className='fs-300 clr-dark-300'>
                {abbreviateNumber(commentData?.comment_count || 0)}
              </span>
            </div>
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {/* <CustomMedia /> */}
          {/* <CommentBox
            style={{ boxShadow: 'none', padding: 0 }}
            contractVideoId={data.contract_video_id}
            videoId={data.id}
            handleClose={handleClose}
          /> */}
          {/* Content */}

          <CommentContext.Provider
            value={{ limit, offset, sort, handleNextPage }}
          >
            <div
              className='box  single-video-comment-tabs'
              style={{ boxShadow: 'none', padding: 0 }}
            >
              {(isLoading || originalArgs?.videoId !== videoId) && <Loader />}
              {data && (
                <>
                  <div className='d-flex justify-content-between align-items-start'>
                    <h5 className='fs-500 fw-600 mb-4'>
                      {!data?.comment_count && 'No'} Comments{' '}
                      <span className='clr-dark-100 fs-200 fw-200'>
                        {abbreviateNumber(data?.comment_count)}
                      </span>
                    </h5>
                    <div className='d-flex align-items-center'>
                      <Dropdown onSelect={(selected) => setSort(selected)}>
                        <Dropdown.Toggle as='a' id='' className='no-after'>
                          <div className='d-flex align-align-items-end cursor-pointer'>
                            <UilParagraph size='20' color={COLORS.dark300} />
                            {/* <h6 className='fs-300 ml-1'>Sort By</h6> */}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ minWidth: 'min-content' }}>
                          <Dropdown.Item
                            eventKey={SORT_BY.NEWEST_FIRST}
                            active={sort === SORT_BY.NEWEST_FIRST}
                          >
                            {SORT_BY.NEWEST_FIRST}
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey={SORT_BY.TOP_COMMENTS}
                            active={sort === SORT_BY.TOP_COMMENTS}
                          >
                            {SORT_BY.TOP_COMMENTS}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {handleClose && (
                        <button
                          type='button'
                          class='close ml-3 mb-1'
                          onClick={handleClose}
                        >
                          <span aria-hidden='true'>×</span>
                          <span class='sr-only'>Close</span>
                        </button>
                      )}
                    </div>
                  </div>
                  {/* <CommentBody
                    sort={sort}
                    comments={data.comments}
                    contractVideoId={contractVideoId}
                    totalResultCount={data.total_result_count}
                    limit={limit}
                    offset={offset}
                    handleNextPage={handleNextPage}
                  /> */}
                  {/* -- */}

                  <CustomMedia contractVideoId={contractVideoId} />
                  <div>
                    {data?.comments && (
                      <InfiniteScroll
                        height={'50vh'}
                        className='overflow-x-hidden pr-2'
                        dataLength={data?.comments?.length}
                        scrollThreshold={0}
                        next={handleNextPage}
                        hasMore={
                          data?.total_result_count > data?.comments?.length
                        }
                        loader={<Loader />}
                        endMessage={
                          <p style={{ textAlign: 'center' }}>
                            {/* <b>Yay! You have seen it all</b> */}
                          </p>
                        }
                        // className='overflow-hidden'
                      >
                        {data?.comments?.map((comment) => (
                          <CommentMedia
                            key={comment.id}
                            commentData={comment}
                            limit={limit}
                            offset={offset}
                            sort={sort}
                          />
                        ))}
                      </InfiniteScroll>
                    )}
                  </div>
                </>
              )}
            </div>
          </CommentContext.Provider>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(Comments);
