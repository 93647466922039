import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_BASE_URL } from 'src/constant';
import { defaultHeaders } from 'src/redux/rootApi';

export const checkVideoReported = createAsyncThunk(
  'checkVideoReported',
  async (videoId, { getState }) => {
    let url = `${SERVER_BASE_URL}/videos/${videoId}/reported-check`;
    try {
      const result = await axios.get(url, {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      return result.data;
    } catch (error) {}
  }
);

const videoSlice = createSlice({
  initialState: {
    nextVideo: null,
  },
  name: 'videoStore',
  reducers: {
    setNextVideo: (state, { payload }) => {
      state.nextVideo = payload;
    },
  },
});

export const { setNextVideo } = videoSlice.actions;
export default videoSlice.reducer;
