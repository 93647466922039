import { createSlice } from '@reduxjs/toolkit';

const likedVideoSlice = createSlice({
  initialState: {
    offset: 0,
  },
  name: 'likedVideoStore',
  reducers: {
    setOffset: (state, { payload }) => {
      state.offset += payload;
    },
    setDefaultOffset: (state) => {
      state.offset = 0;
    },
  },
});

export const { setOffset, setDefaultOffset } = likedVideoSlice.actions;
export default likedVideoSlice.reducer;
