import React, { useState, useEffect } from 'react';
import {
  useChannelAnalyticsMetricsQuery,
  useChannelViewCountQuery,
} from 'src/redux/features/channels/channelApi';
import SubscriberPerViewContent from 'src/components/analytics/SubscriberPerViewContent';
import RealTimeViewContent from 'src/components/analytics/RealTimeViewContent';

const SORT_OPTIONS = [
  { label: '60 Mins', value: 'period-60mins' },
  { label: '48 Hours', value: 'period-48hours' },
];

const RealTimeCard = () => {
  const [sort, setSort] = useState(SORT_OPTIONS[0].value);

  const { data: metrics } = useChannelAnalyticsMetricsQuery();
  const { data, refetch } = useChannelViewCountQuery({ sort });

  useEffect(() => {
    if (!data) return;
    const intervalId = setInterval(() => {
      refetch();
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [data, refetch]);

  return (
    <>
      {/* - MOBILE VIEW */}
      <div
        className='d-none d-sm-flex d-xl-none justify-content-between '
        style={{ gap: '1rem' }}
      >
        <div className='p-4 studio-card' style={{ width: '50%' }}>
          <RealTimeViewContent
            views={data?.unique_view_count}
            sort={sort}
            onSelect={(e) => setSort(e.target.value)}
            options={SORT_OPTIONS}
          />
        </div>
        <div className='p-4 studio-card' style={{ width: '50%' }}>
          {metrics && (
            <SubscriberPerViewContent
              views={metrics?.view_count}
              subscribers={metrics?.subscriber_count}
            />
          )}
        </div>
      </div>

      {/* - WEB VIEW - */}
      <div className='p-4 studio-card d-sm-none d-xl-flex'>
        {/* Real Time View Content */}
        <RealTimeViewContent
          views={data?.unique_view_count}
          sort={sort}
          onSelect={(e) => setSort(e.target.value)}
          options={SORT_OPTIONS}
        />

        {/* Divider */}
        <div className='dropdown-divider my-4 d-none d-xl-block' />

        {/* Subscriber / View Content */}
        {metrics && (
          <SubscriberPerViewContent
            views={metrics.view_count}
            subscribers={metrics.subscriber_count}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(RealTimeCard);
