import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import CustomizationContent from './CustomizationContent';
import Constant from 'src/constant';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import FourZeroFour from 'src/components/404';
import VerifyChannel from './VerifyChannel';
import { ThinFooter } from 'src/components/footer';

const CustomizationRoute = () => {
  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4' id='studio-content-page'>
        <Row className='mx-0'>
          <Col md={12} className='mx-auto p-0'>
            <h3 className='mb-3 fs-500 fw-600'>Channel customization</h3>
          </Col>
          <Col md={12} className='p-0 mx-auto py-3 py-md-4 studio-card'>
            <div className='custom-tabs mx-4'>
              <NavLink
                exact
                activeClassName='active'
                className='tab-item mr-4'
                to={Constant.BASE_URL + `studio/customization`}
              >
                Basic info
              </NavLink>
              {/* <NavLink
                      activeClassName='active'
                      className='tab-item mr-4'
                      to={Constant.BASE_URL + 'studio/customization/basic-info'}
                    >
                      Basic info
                    </NavLink> */}
              <NavLink
                activeClassName='active'
                className='tab-item'
                to={Constant.BASE_URL + `studio/customization/verify-channel`}
              >
                Account Verification
              </NavLink>
            </div>
            <Switch>
              <ProtectedRoute
                exact
                path={`${Constant.BASE_URL}studio/customization`}
              >
                <CustomizationContent />
                {/* <Branding /> */}
              </ProtectedRoute>
              {/* <ProtectedRoute
                      path={`${Constant.BASE_URL}studio/customization/branding`}
                    >
                      <Branding />
                    </ProtectedRoute> */}
              {/* <ProtectedRoute
                      path={`${Constant.BASE_URL}studio/customization/basic-info`}
                    >
                      <BasicInfo />
                    </ProtectedRoute> */}
              <ProtectedRoute
                path={`${Constant.BASE_URL}studio/customization/verify-channel`}
              >
                <VerifyChannel />
              </ProtectedRoute>
              <Route path='*' component={FourZeroFour} />
            </Switch>
          </Col>
        </Row>
      </Container>
      <ThinFooter />
    </>
  );
};

export default CustomizationRoute;

//   <Tab.Container defaultActiveKey='basicinfo'>
//                 <Nav>
//                   <Nav.Item>
//                     <Nav.Link
//                       eventKey='basicinfo'
//                       as={NavLink}
//                       to={
//                         Constant.BASE_URL +
//                         'studio/customization/basic-info'
//                       }
//                       className='tab-item'
//                       activeClassName='active'
//                     >
//                       Basic Info
//                     </Nav.Link>
//                   </Nav.Item>
//                   <Nav.Item>
//                     <Nav.Link
//                       eventKey='profiles'
//                       as={NavLink}
//                       to={Constant.BASE_URL + 'studio/customization'}
//                       className='tab-item'
//                       activeClassName='active'
//                     >
//                       Branding
//                     </Nav.Link>
//                   </Nav.Item>
//                   <Nav.Item>
//                     <Nav.Link
//                       eventKey='errorcodes'
//                       as={Link}
//                       to={`/workhub/ErrorCodes`}
//                     >
//                       Error Codes
//                     </Nav.Link>
//                   </Nav.Item>
//                 </Nav>
//               </Tab.Container>
//               <Tab.Content>
//                 <Switch>
//                   <ProtectedRoute
//                     exact
//                     path={`${Constant.BASE_URL}studio/customization`}
//                   >
//                     <Branding />
//                   </ProtectedRoute>
//                   <ProtectedRoute
//                     path={`${Constant.BASE_URL}studio/customization/branding`}
//                   >
//                     <Branding />
//                   </ProtectedRoute>
//                   <ProtectedRoute
//                     path={`${Constant.BASE_URL}studio/customization/basic-info`}
//                   >
//                     <BasicInfo />
//                   </ProtectedRoute>
//                   <Route path='*' component={fourZeroFour} />
//                 </Switch>
//               </Tab.Content>
