import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { abbreviateNumber } from 'js-abbreviation-number';

import Constant from '../../constant';
import './StudioDashboard.css';
import ChannelAnalyticsCard from './components/ChannelAnalyticsCard';
import { useChannelDashboardQuery } from 'src/redux/features/channels/channelApi';
import { ThinFooter } from 'src/components/footer';

const StudioDashboard = () => {
  const history = useHistory();
  const { data } = useChannelDashboardQuery();
  return (
    <>
      <Container
        className='p-2 p-md-4'
        fluid='xxl'
        id='channel-dashboard-content'
      >
        <div className='d-flex justify-content-between align-items-start mt-4 flex-column flex-sm-row'>
          <div>
            <h2>Channel Dashboard</h2>
            <div className='mt-1 fs-300 text-clr-dark-100'>
              {data?.subscriber_count
                ? abbreviateNumber(data?.subscriber_count, 1)
                : 'No'}{' '}
              subscribers |{' '}
              {data?.video_count
                ? abbreviateNumber(data?.video_count, 1)
                : 'No'}{' '}
              Videos
            </div>
          </div>
          {/* <Button
          variant='light'
          as={Link}
          to={`${Constant.BASE_URL}studio/upload`}
          color='#fff'
          className='rounded-pill shadow-sm upload-video-btn mt-4 mt-sm-0 mx-auto mx-sm-0'
        >
          <img
            src={`${Constant.BASE_URL}img/upload.svg`}
            alt='create-channel-svg'
            className='mr-2'
          />
          Upload Video
        </Button> */}
        </div>
        <Row className='mt-4'>
          <Col md={12} lg={7} className='mb-5'>
            <div className='studio-card upload-video-container'>
              <img
                src={`${Constant.BASE_URL}img/illustration/upload-video.svg`}
                alt='create-channel-svg'
              />
              <h2 className='text-center mt-4 mb-2'>
                Upload Video and get started
              </h2>
              <p className='text-center'>
                You can now instantly upload videos from the platform. Get
                started to make the world see the best-ever videos.
              </p>
              <Button
                onClick={() =>
                  history.push(`${Constant.BASE_URL}studio/upload`)
                }
                size='lg'
                variant='primary'
                className='ml-auto mr-auto mt-4'
              >
                Upload Video
              </Button>
            </div>
          </Col>
          <Col md={12} lg={5} className='mb-5'>
            <ChannelAnalyticsCard />
          </Col>
        </Row>
      </Container>
      {/* <div className='d-flex align-items-start flex-wrap mt-4 '>
    //   <div className='studio-card upload-video'>
    //     <img
    //       src={`${Constant.BASE_URL}img/illustration/upload-video.svg`}
    //       alt='create-channel-svg'
    //     />
    //     <h2 className='text-center mt-4'>Upload Video and get started</h2>
    //     <p className='text-center'>
    //       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //       eiusmod tempor incididunt ulabore et dolore magna aliqua.
    //     </p>
    //     <Button variant='primary' className='ml-auto mr-auto mt-4'>
    //       Upload Video
    //     </Button>
    //   </div>
    //   <div className='studio-card channel-analytics ml-4'>
    //     <div className='d-flex justify-content-between align-items-start'>
    //       <h4>Channel Analytics</h4>
    //       <div className='d-flex'>
    //         <h6>View</h6>
    //         <img
    //           style={{ width: '20px' }}
    //           alt='right arrow'
    //           src={`${Constant.BASE_URL}img/right.svg`}
    //         />
    //       </div>
    //     </div>
    //     <div className='d-flex justify-content-between'>
    //       <div>
    //         <h6>Current</h6>
    //         <h6> Subscribers</h6>
    //       </div>
    //       <h2>0</h2>
    //     </div>
    //   </div>
    // </div> */}
      <ThinFooter />
    </>
  );
};

export default StudioDashboard;
