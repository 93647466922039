import React, { useState } from 'react';
import { useNewVideosQuery } from '../../redux/features/videos/videoApi';
import VideoBlock from 'src/components/VideoBlock';
import SectionHeader from '../../components/atoms/SectionHeader/SectionHeader';
import { Container } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoContent from '../../components/atoms/NoContent/NoContent';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';
import config from 'src/config';

const NewVideos = () => {
  const [offset, setOffset] = useState(0);
  const limit = config.limits.newVideos;
  let { isSuccess, data, isLoading } = useNewVideosQuery({ limit, offset });

  if (data?.videos?.length === 0) return <NoContent addContainer={true} />;

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4'>
        {' '}
        {isLoading && <Loader />}
        {isSuccess && !!data?.videos?.length && (
          <InfiniteScroll
            dataLength={data?.videos.length}
            next={() => setOffset((prev) => prev + limit)}
            hasMore={data?.total_result_count > data?.videos?.length}
            loader={<Loader />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
            className='overflow-hidden'
          >
            <VideoBlock
              data={data?.videos}
              HeaderComponent={() => (
                <SectionHeader heading='New Videos' noIconLabel />
              )}
            />
          </InfiniteScroll>
        )}
      </Container>
      <ThinFooter />
    </>
  );
};

export default NewVideos;
