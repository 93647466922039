import Lock from '@iconscout/react-unicons/icons/uil-lock-alt';

export default function VideoTitle({
  title,
  videoHref = '#',
  views,
  visibility,
}) {
  return (
    <>
      <div className='single-video-title mb-4 mt-2'>
        <h2 className='fw-600 fs-500'>
          {title}
          {/* <a href={videoHref}>{title}</a> */}
        </h2>
        {visibility !== 'PUBLIC' && (
          <div className=''>
            <Lock size={16} />
            <span className='ml-1'>Private</span>
          </div>
        )}
        {/* <p className='mb-0'>
          <FontAwesomeIcon icon={faEye} /> {views} views
        </p> */}
      </div>
    </>
  );
}
