import React, { useEffect, useRef, useState } from 'react';
import shaka from 'shaka-player';
import {
  Player as ShakaPlayer,
  ui as ShakaUI,
} from 'shaka-player/dist/shaka-player.ui';
import CopyToClipboard from 'react-copy-to-clipboard';
import UilCopy from '@iconscout/react-unicons/icons/uil-copy';
import UilCheck from '@iconscout/react-unicons/icons/uil-check-circle';
import 'shaka-player/dist/controls.css';

import { COLORS } from 'src/constant';
import VerticalVolume from 'src/components/atoms/player-controls/VerticalVolume';

const VideoPreviewCard = ({
  posterFileUrl,
  videoFileUrl,
  videoUrl,
  videoTitle = '',
  videoProgress = null,
}) => {
  const [copied, setCopied] = useState(false);
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    let player;
    async function initPlayer(url) {
      // shaka.polyfill.installAll();
      // Check to see if the browser supports the basic APIs Shaka needs.
      if (!shaka.Player.isBrowserSupported()) {
        // Everything looks good!
        alert('Shaka Player is not supported');
      }
      videoContainerRef.current.style.visibility = 'hidden';

      // removing default controls
      const previousControls = document.querySelectorAll(
        '.shaka-bottom-controls'
      );
      const previousWatermarks = document.querySelectorAll('#watermark');
      if (previousControls.length > 0)
        previousControls?.forEach((item) => item.remove());
      if (previousWatermarks.length > 0)
        previousWatermarks?.forEach((item) => item.remove());

      // vertical volume control
      ShakaUI.Controls.registerElement(
        'vertical_volume',
        new VerticalVolume.Factory()
      );

      // Create a Player instance.
      player = new ShakaPlayer();
      await player.attach(videoRef.current);

      const ui = new ShakaUI.Overlay(
        player,
        videoContainerRef.current,
        videoRef.current
      );

      // Attach player to the window to make it easy to access in the JS console.
      window.player = player;

      const uiConfig = {
        enableTooltips: true,
        // addSeekBar: true,
        controlPanelElements: [
          'play_pause',
          // 'rewind',
          // 'fast_forward',
          // 'mute',
          // 'volume',
          'vertical_volume',
          'time_and_duration',
          'spacer',
          'overflow_menu',
        ],
        overflowMenuButtons: ['quality', 'playback_rate', 'cast'],
        customContextMenu: true,
        contextMenuElements: ['statistics'],
        statisticsList: ['width', 'height', 'playTime', 'bufferingTime'],
      };
      ui.configure(uiConfig);

      try {
        await player.load(url);
        videoContainerRef.current.style.visibility = 'visible';

        // This runs if the asynchronous load is successful.
        console.log('The video has now been loaded!');
      } catch (e) {
        // onError is executed if the asynchronous load fails.
        handlePlayerError(e);
      }
    }

    const handlePlayerError = (event) => {
      console.log('Shaka Player Error:', event);
    };

    if (videoFileUrl && videoRef.current && videoContainerRef.current) {
      // // Install built-in polyfills to patch browser incompatibilities.
      // shaka.polyfill.installAll();
      // // Check to see if the browser supports the basic APIs Shaka needs.
      // if (shaka.Player.isBrowserSupported()) {
      // Everything looks good!
      initPlayer(videoFileUrl);
      // } else {
      //   // This browser does not have the minimum set of APIs we need.
      //   console.error('Browser not supported!');
      // }
    }

    // // Cleanup function: Remove the error event listener when the component unmounts
    // return () => {
    //   if (player) player.unload();
    // };
  }, [videoFileUrl]);

  useEffect(() => {
    return () => {
      console.log(window.player, 'player')
      if (window.player) window.player.unload();
    };
  }, []);

  return (
    <div style={{ background: '#0C0B0B', marginTop: '40px' }}>
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ aspectRatio: '16/9' }}
      >
        {/* <img
          alt='video thumbnail'
          className='w-100'
          src={`${Constant.BASE_URL}img/dark/upload/demo-video-thumbnail.svg`}
        /> */}
        {videoFileUrl ? (
          // videoFileUrl.includes('mpd') ? (
          <div ref={videoContainerRef}>
            <video
              // playsInline
              ref={videoRef}
              style={{ aspectRatio: '16/9', background: 'black' }}
              poster={posterFileUrl}
              width='100%'
            >
              {/* Provide fallback content for non-supported browsers */}
              <p>Your browser doesn't support HTML5 video.</p>
            </video>
          </div>
        ) : (
          // ) : (
          //   <video
          //     style={{ aspectRatio: '16/9' }}
          //     width='100%'
          //     controls
          //     src={videoFileUrl}
          //     alt='preview-video'
          //   />
          // )
          <p className='text-center pt-2'>
            {videoProgress === 100
              ? 'Processing video ...'
              : 'Uploading video...'}
          </p>
        )}
      </div>
      {/* Progress line */}
      <div className='bg-white w-100'>
        {/* <ProgressBar
          striped
          animated
          now={videoProgress}
          label={`${videoProgress}% uploaded`}
        /> */}
        <div
          className='d-flex justify-content-end align-items-center overflow-hidden'
          style={{
            width: `${videoProgress}%`,
            background: COLORS.primary400,
            height: '20px',
            transition: 'width .1s ease-out',
          }}
        >
          <small
            className='px-2 text-nowrap overflow-hidden text-ellipse'
            style={{ color: '#fff' }}
          >
            {videoProgress}% uploaded
          </small>
        </div>
      </div>
      <div className='my-2 px-4 pb-2'>
        <small className='d-block fs-200 text-white '>Video Link</small>
        {videoFileUrl ? (
          <div className='d-flex justify-content-between align-items-center'>
            <a
              target='_blank'
              href={videoUrl}
              className='btn-link overflow-hidden text-ellipse p-1'
              rel='noreferrer'
            >
              {videoUrl}
            </a>
            {/* <img
              src={`${Constant.BASE_URL}img/dark/copy.svg`}
              alt='copy'
              style={{ width: '16px' }}
            /> */}
            <CopyToClipboard
              text={videoUrl}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 4000);
              }}
            >
              <div className='cursor-pointer'>
                {copied ? (
                  <UilCheck color={COLORS.primary400} size='20' />
                ) : (
                  <UilCopy color={COLORS.primary400} size='20' />
                )}
              </div>
            </CopyToClipboard>
          </div>
        ) : (
          <small>loading...</small>
        )}
      </div>
      <div className='px-4 pb-4 '>
        <small className='fs-200 text-white'>File Name</small>
        <p className=' overflow-hidden text-nowrap text-white'>{videoTitle}</p>
      </div>
    </div>
  );
};

export default React.memo(VideoPreviewCard);
