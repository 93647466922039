import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import './modal.css';

const ConfirmModal = ({
  show,
  handleClose,
  onConfirm,
  confirmButtonText = 'Confirm',
  title,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={true}
        keyboard={false}
        centered
        id='unsubscribe-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='light' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModal;
