import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Constant, { CLIENT_URL, COLORS } from '../../../constant';
import ShortCard from 'src/components/atoms/ShortCard/ShortCard';
import Slider from 'react-slick';
import { useState } from 'react';
import {
  SampleNextArrow,
  SamplePrevArrow,
} from '../../../components/atoms/ShortsButtons/ShortButton';
import { toast } from 'react-toastify';
import messages from 'src/constant/messages';
import { checkVideoReported } from 'src/redux/features/videos/videoSlice';
import { openReportVideoModal } from 'src/redux/features/report-video/reportVideoSlice';
import handleError from 'src/utils/handle-error';
import { useDispatch } from 'react-redux';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { useAddWatchLaterMutation } from 'src/redux/features/watch-later/watchLaterApi';

const ShortBlock = ({ data, HeaderComponent = null }) => {
  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [addWatchLater] = useAddWatchLaterMutation();

  const totalSlides = data?.length;

  var settings = {
    rows: 1,
    variableWidth: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    initialSlide: 0,
    swipeToSlide: true,
    // autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setCurrentSlide(next),
    afterChange: (current) => setCurrentSlide(current),
    nextArrow: (
      <SampleNextArrow
        slidesToShow={6}
        currentSlide={currentSlide}
        totalSlides={totalSlides}
      />
    ),
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          initialSlide: 0,
          infinite: false,
          dots: false,
          beforeChange: (current, next) => setCurrentSlide(next),
          afterChange: (current) => setCurrentSlide(current),
          nextArrow: (
            <SampleNextArrow
              slidesToShow={4}
              currentSlide={currentSlide}
              totalSlides={totalSlides}
            />
          ),
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: false,
          dots: false,
          beforeChange: (current, next) => setCurrentSlide(next),
          afterChange: (current) => setCurrentSlide(current),
          nextArrow: (
            <SampleNextArrow
              slidesToShow={3}
              currentSlide={currentSlide}
              totalSlides={totalSlides}
            />
          ),
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          beforeChange: (current, next) => setCurrentSlide(next),
          afterChange: (current) => setCurrentSlide(current),
          nextArrow: (
            <SampleNextArrow
              slidesToShow={2}
              currentSlide={currentSlide}
              totalSlides={totalSlides}
            />
          ),
          prevArrow: <SamplePrevArrow />,
        },
      },
    ],
  };

  return (
    <>
      <div className='video-block section-padding '>
        <Row>
          {HeaderComponent && (
            <Col md={12} className='mb-2'>
              <HeaderComponent />
            </Col>
          )}

          {/* <Row></Row> */}
          <Col
            // xl={2}
            // lg={3}
            // sm={4}
            xs={12}
            className='mb-3 p-1'
          >
            <Slider {...settings}>
              {data.map((short, idx) => (
                <ShortCard
                  key={`short-${short.id}`}
                  className={'px-2'}
                  imgSrc={
                    short.thumbnail_url ||
                    `${Constant.BASE_URL}img/light/default-thumbnail.png`
                  }
                  videoTitle={short.title}
                  views={short.views}
                  iconHref={`${Constant.BASE_URL}shorts/${short.video_url_id}`}
                  videoTitleHref={`${Constant.BASE_URL}shorts/${short.video_url_id}`}
                  shareLink={`${CLIENT_URL}/shorts/${short.video_url_id}`}
                  onReportVideo={async () => {
                    if (!authData)
                      return toast.error(messages.require_signin_to_report);
                    if (short.is_reported)
                      return toast.info(messages.video_reported_already);

                    try {
                      const report = await dispatch(
                        checkVideoReported(short.id)
                      ).unwrap();
                      const is_reported = report.data.is_reported;

                      if (is_reported)
                        return toast.info(messages.video_reported_already);

                      dispatch(
                        openReportVideoModal({
                          contractVideoId: short.contract_video_id,
                          videoId: short.id,
                          videoUrlId: short.video_url_id,
                        })
                      );
                    } catch (error) {
                      handleError(error);
                    }
                  }}
                  onAddWatchLater={async () => {
                    if (!authData) return toast.info(messages.require_signin);
                    try {
                      const response = await addWatchLater({
                        videoId: short.id,
                        videoUrlId: short.video_url_id,
                      }).unwrap();
                      toast.success(response.message);
                    } catch (error) {
                      handleError(error);
                    }
                  }}
                />
              ))}
            </Slider>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ShortBlock;
