import React from 'react';
import './Avatar.css';

const Avatar = ({
  size,
  imageUrl,
  character,
  className = '',
  style = {},
  onClick,
}) => {
  const avatarStyle = {
    width: size,
    height: size,
    borderRadius: '50%',
    // Add additional styling based on your design requirements
  };
  const fontSize = `${size / 2}px`;
  return (
    <div
      className={`avatar ${className}`}
      style={{ ...avatarStyle, ...style }}
      onClick={onClick}
    >
      {imageUrl ? (
        <img src={imageUrl} alt='Avatar' />
      ) : (
        <span
          style={{
            fontSize,
          }}
          className='avatar-character text-uppercase'
        >
          {character}
        </span>
      )}
    </div>
  );
};

export default Avatar;
