import React from 'react';
import { Container } from 'react-bootstrap';

const TermsAndConditions = () => {
  return (
    <Container fluid='xxl' className='p-2 p-md-4'>
      <h2 className='text-center py-4'>Terms & Conditions- Mirror!</h2>
      <p className='mb-4 text-center'>
        Thank you for making use of the features, offerings, and products made
        available through the Mirror platform (collectively, the "Service").{' '}
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Our Service!</h6>
      <p className='mb-4'>
        You may seek, watch, and share videos and other types of content using
        the service. Additionally, it gives both big and small advertisers as
        well as writers of original material a platform to involve, educate, and
        inspire people everywhere. In our Help Center, we provide a plethora of
        information on our products and how to utilize them. Here you may find
        out more about the Mirror Partner Program, Mirror Kids, and (where
        available) Mirror Paid Memberships and Purchases. You can also learn
        everything there is to know about watching content on a television, a
        game console, or Mirror Home.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Applicable Terms!</h6>
      <p className='mb-4'>
        Your use of the Service is subject to these terms, the Mirror Community
        Guidelines, and the rules, safety, and copyright policies (collectively,
        this "Agreement"). All of these terms are subject to modification at any
        moment. The Mirror Advertising Policies will also be a part of your
        contract with us if you offer sponsorships, advertising, or paid
        commercials to the Service or include paid adverts in your videos. Any
        further references or links are provided just as a courtesy and are not
        part of the Agreement. Please read this Agreement thoroughly and
        understand it. You need to understand the Agreement or agree with any of
        its provisions to be allowed to use the Service. You warrant that you
        are at least 18 years old and possess the necessary legal capacity to
        agree to, abide by, and comply with the provisions of this Agreement by
        accepting it. If you are younger than 18, you are subject to the
        limitations and conditions listed below.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Age Requirements!</h6>
      <p className='mb-4'>
        You must be at least 13 years old to use the Service; however, children
        of any age may use the Service and Mirror Kids (where available) with
        parental or guardian consent. Consent of parents or guardians If you are
        a minor in your country, you certify that your parent or legal guardian
        has given you their consent to use the Service. If feasible, they should
        read this Agreement with you. You are obligated by the terms of this
        Agreement and responsible for your child's online behavior if they are a
        minor in your country and you are their parent or legal guardian. You
        may discover tools and resources to help you manage your family's
        experience on Mirror in our Help Center, including guidelines on how to
        allow a kid under the age of 13 to access the Service and Mirror Kids.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Businesses!</h6>
      <p className='mb-4'>
        If you are using the Service on behalf of a company or organization, you
        represent that you have their permission to do so and that they have
        approved this Agreement.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Content On The Service!</h6>
      <p className='mb-4'>
        Videos, audio (including music and other sounds), graphics, photographs,
        text (including comments and scripts), branding (such as trade names,
        trademarks, service marks, or logos), interactive components, software,
        metrics, and other items all fall under the umbrella term "Content" on
        the service. The person or organization that supplies the service with
        the material is responsible for it. Mirror does not have to host or
        provide Content. You can report Content to us if you think it violates
        this Agreement, the Community Guidelines, or the law.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Your Information!</h6>
      <p className='mb-4'>
        When you use the Service, we will respect your privacy and treat your
        personal information in accordance with our Privacy Policy. More details
        about our privacy practices as they apply solely to Mirror Kids can be
        found in the Mirror Kids Privacy Notice. Unless you posted a video for
        personal or domestic use, we will process any audio or audiovisual
        content you upload to the Service in line with the Mirror Data
        Processing Terms. Study more.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Permissions & Restrictions!</h6>
      <p className='mb-4'>
        In accordance with this Agreement and all applicable laws, you are
        permitted to access and use the Service as it is made available to you.
        In order to use the Content for private, non-commercial reasons, you are
        permitted to watch or listen. The embeddable Mirror player allows users
        to view Mirror videos. You are subject to the restrictions indicated
        above when using the Service. You are prohibited from: You are not
        permitted to: (a) access, reproduce, download, distribute, transmit,
        broadcast, display, sell, license, alter, modify, or otherwise use any
        part of the Service or any Content; or (b) do so without the prior
        written consent of Mirror and, if applicable, the applicable right
        holders. any security-related features or features that (a) prevent or
        restrict the copying or other use of Content or (b) limit the use of the
        Service or Content; circumvent, disable, fraudulently interact with, or
        otherwise interfere with any part of the Service (or attempt to do any
        of these things; Access the Service using any automated method (such as
        robots, botnets, or scrapers), unless (a) done so in accordance with
        Mirror's robots.txt file for public search engines, or (b) with Mirror's
        prior written approval; any information (such as usernames or faces)
        that could be used to identify a person without that person's consent or
        as permitted by clause (3) above; utilize the service to disseminate
        widely accepted or unwelcome marketing materials, advertising, or other
        commercial content; by encouraging erroneous measurements of actual user
        engagement with the Service by paying or rewarding users to boost video
        views, likes, or dislikes or to draw channel subscribers, or by
        participating in any other type of metric manipulation; abuse any
        reporting, flagging, complaint, dispute, or appeals procedure, such as
        by making baseless, unreasonable, or false claims; run competitions over
        the Service or on the Website against Mirror's contest guidelines; using
        the service for anything other than personal, non-commercial use to
        watch or listen to content (For instance, using the Service to broadcast
        music or movies in public is not permitted); or Use the Service for
        either of the following purposes: (a) selling any advertising,
        sponsorships, or promotions on, around, or within the Service or Content
        that are prohibited by the Mirror Advertising Policies (for instance,
        compliant product placements); or (b) selling any advertising,
        sponsorships, or promotions on any page of any website or application
        that only contains Content from the Service or where Content from the
        Service is the primary basis for such sales (for example, selling ads on
        a website).
      </p>
      <h6 className='mb-1 fs-500 fw-600'>License To Mirror!</h6>
      <p className='mb-4'>
        You grant Mirror a worldwide, non-exclusive, transferable, sublicensable
        license to use the content you submit to the service, including the
        right to copy, distribute, convert, publicly display, and perform it.
        Only the Service and Mirror's (and its successors' and Affiliates')
        business, including the promotion and redistribution of all or a portion
        of the Service, may be used in combination with the Content.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>License To Other Users!</h6>
      <p className='mb-4'>
        You also grant each and every other user of the Service a perpetual,
        non-exclusive, transferable license to access your Content through the
        Service and to use it in any way made possible by a feature of the
        Service (such as video playback or embedding), including the right to
        reproduce, distribute, transform, publicly display, and perform your
        Content.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Account Suspension & Termination!</h6>
      <h6 className='mb-1 fs-500 fw-600'>Terminations By You!</h6>
      <p className='mb-4'>
        At any moment, you are free to stop using the Service. To cancel the
        Service from your Mirror Account, turn down your Mirror channel, and
        delete your data, follow these steps. Another option is to first
        download a copy of your data.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Terminations/Suspensions By Mirror!</h6>
      <p className='mb-4'>
        If you materially or repeatedly violate this Agreement, if we are
        required to do so by law or by a court order, or if we believe there has
        been conduct that could expose Mirror or our Affiliates to liability or
        harm, Mirror reserves the right to suspend or terminate your Mirror
        account and your access to all or a portion of the Service.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Notice For Termination or Suspension!</h6>
      <p className='mb-4'>
        We will let you know why your account has been suspended or terminated
        by Mirror unless we have reason to believe that doing so would: (a)
        violate the law or the direction of a legal enforcement authority; (b)
        compromise an investigation; (c) compromise the integrity, operation, or
        security of the Service; or (d) cause harm to any user, other third
        party, Mirror, or our Affiliates. You will be provided as much time as
        is practical to export your Content from the Service before Mirror
        suspends your use of the Service.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Effect Of Termination Or Suspension!</h6>
      <p className='mb-4'>
        You can continue to use some features of the Service, such as viewing
        only, even if your Mirror account is deleted or your access to the
        Service is restricted, and this Agreement will still apply to such
        usage. You may appeal using this form if you think your Mirror account
        was improperly suspended or terminated.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Indemnity!</h6>
      <p className='mb-4'>
        You agree to protect Mirror, its affiliates, officers, directors,
        employees, and agents from and against any and all claims, damages,
        obligations, losses, liabilities, costs or debt, and expenses (including
        but not limited to attorney's fees), resulting from: (i) your use of the
        Service; (ii) your violation of any provision of these Terms of Service;
        and (iii) your violation of any third-party right, including without
        limitation a license. This Agreement and your use of the Service have no
        bearing on your duty to defend and indemnify.
      </p>
      <h6 className='mb-1 fs-500 fw-600'>Third-Party Links!</h6>
      <p className='mb-4'>
        The Service may contain links to websites and online resources run by
        non-Mirror organizations. Mirror has no control over these websites and
        online services and disclaims all liability in connection with them. Be
        cautious when you leave the Service; we advise you to carefully read the
        terms of service and privacy policies of every online service from a
        third party that you visit.
      </p>
    </Container>
  );
};

export default TermsAndConditions;
