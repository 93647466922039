import './SectionHeader.css';
import Dropdown from 'react-bootstrap/Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faSignal,
  faTimesCircle,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

const SectionHeader = ({
  heading,
  headingIconUrl,
  headingIcon,
  icon = null,
  widthCircle = true,
  noIconLabel = false,
  dropdownOnly = null,
  onViewAllClick = null,
  className = '',
}) => {
  let dropdownIcon = icon ? icon : faCaretDown;
  let dropdownIconLabel = noIconLabel ? '' : 'Sort by';

  let dropdownBodyz;

  //   if (dropdownOnly) {
  //     dropdownBodyz = (
  //       <ButtonGroup
  //         dropdownIcon={dropdownIcon}
  //         dropdownIconLabel={dropdownIconLabel}
  //       />
  //     );
  //   } else {
  //     dropdownBodyz = (
  //       <HeadingWrapper heading={heading} headingIconUrl={headingIconUrl}>
  //         <ButtonGroup
  //           dropdownIcon={dropdownIcon}
  //           dropdownIconLabel={dropdownIconLabel}
  //         />
  //       </HeadingWrapper>
  //     );
  //   }

  //   return <>{dropdownBodyz}</>;
  return (
    <HeadingWrapper
      widthCircle={widthCircle}
      heading={heading}
      headingIconUrl={headingIconUrl}
      headingIcon={headingIcon}
      className={className}
    >
      {onViewAllClick && (
        <h6 className='view-all-text mr-2' onClick={onViewAllClick}>
          View All
        </h6>
      )}
    </HeadingWrapper>
  );
};

function ButtonGroup({ dropdownIcon, dropdownIconLabel }) {
  return (
    <>
      <div className='btn-group float-right right-action'>
        <Dropdown>
          <Dropdown.Toggle
            as='a'
            id=''
            className='right-action-link text-gray no-after'
          >
            {dropdownIconLabel}{' '}
            <span>
              <FontAwesomeIcon icon={dropdownIcon} />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href='#'>
              <FontAwesomeIcon icon={faStar} fixedWidth />
              &nbsp;Top Rated
            </Dropdown.Item>
            <Dropdown.Item href='#'>
              <FontAwesomeIcon icon={faSignal} fixedWidth />
              &nbsp;Viewed
            </Dropdown.Item>
            <Dropdown.Item href='#'>
              <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
              &nbsp;Close
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}

function HeadingWrapper({
  children,
  heading,
  headingIconUrl = null,
  widthCircle = true,
  headingIcon,
  className = '',
}) {
  return (
    <>
      <div className={'main-title mb-2 ' + className}>
        <div className='left'>
          {(headingIconUrl || headingIcon) && widthCircle ? (
            <div className='circle'>
              {headingIconUrl ? (
                <img src={headingIconUrl} alt='section' />
              ) : (
                headingIcon
              )}
            </div>
          ) : headingIconUrl ? (
            <div className='mr-2'>
              <img src={headingIconUrl} alt='section' />
            </div>
          ) : headingIcon ? (
            <div className='mr-2'>{headingIcon}</div>
          ) : null}
          <h6>{heading}</h6>
        </div>
        {children ? children : ''}
      </div>
    </>
  );
}

export default SectionHeader;
export { HeadingWrapper };
