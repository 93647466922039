import './ShortCard.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import UilMenuV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import UilShare from '@iconscout/react-unicons/icons/uil-share';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';

import { COLORS } from 'src/constant';
import { handleOpenShare } from 'src/redux/features/share/shareSlice';
import { useDispatch } from 'react-redux';
import { UilExclamationOctagon } from '@iconscout/react-unicons';
import UisClock from '@iconscout/react-unicons-solid/icons/uis-clock';

function ShortCard({
  iconHref = '#',
  imgHref = '#',
  imgSrc,
  imgAlt = '',
  videoTitle = '#',
  videoTitleHref,
  views,
  channel_id = null,
  onRemoveFromHistory = null,
  className = '',
  shareLink,
  onReportVideo,
  onAddWatchLater,
}) {
  const dispatch = useDispatch();

  return (
    <>
      <div
        // style={{ marginRight: '10px', marginLeft: '10px' }}
        className={`short-card ${className}`}
      >
        <div className='short-card-image'>
          <Link
            className='play-icon'
            to={{ pathname: iconHref, state: { channel_id } }}
          >
            <FontAwesomeIcon icon={faPlayCircle} />
          </Link>
          <Link to={{ pathname: imgHref, state: { channel_id } }}>
            <img className='img-fluid' src={imgSrc} alt={imgAlt} />
          </Link>
        </div>
        <div className='short-card-body hover-trigger-container'>
          <div className='short-title'>
            <Link
              to={{ pathname: videoTitleHref, state: { channel_id } }}
              className='text-line-2 w-mins-dot-btn'
            >
              {videoTitle}
            </Link>
          </div>
          <div className='short-view'>{views} views &nbsp;</div>
          {/* DROPDOWN */}
          <Dropdown
            style={{
              position: 'absolute',
              right: '0px',
              top: '8px',
            }}
          >
            <Dropdown.Toggle as='a' id='' className='no-after'>
              <button className='icon-circle-wrapper btn-ripple z-1 trigger-element'>
                <UilMenuV size={18} className='icon' />
              </button>
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ minWidth: 'min-content' }}>
              {onRemoveFromHistory && (
                <Dropdown.Item href='#' onClick={onRemoveFromHistory}>
                  <UilTrashAlt
                    color={COLORS.dark300}
                    size='20'
                    className='mr-2'
                  />
                  <span>Remove from history</span>
                </Dropdown.Item>
              )}

              {onAddWatchLater && (
                <Dropdown.Item href='#' onClick={onAddWatchLater}>
                  <UisClock color={COLORS.dark300} size='20' className='mr-2' />
                  <span>Save to Watch later</span>
                </Dropdown.Item>
              )}

              <Dropdown.Item
                onClick={() => dispatch(handleOpenShare({ shareLink }))}
              >
                <UilShare color={COLORS.dark300} size='20' className='mr-2' />
                <span>Share</span>
              </Dropdown.Item>
              <Dropdown.Item href='#' onClick={onReportVideo}>
                <UilExclamationOctagon size='18' className='mr-2' />
                Report Video
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

export default ShortCard;
