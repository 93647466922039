import React, { useEffect, useState } from 'react';
import { abbreviateNumber } from 'js-abbreviation-number';
import { Form } from 'react-bootstrap';

import Circle from '../atoms/circle/Circle';
import { FIXED_DECIMAL_CONSTANT } from 'src/constant';

const RealTimeViewContent = ({ views = 0, sort, onSelect, options = [] }) => {
  return (
    <div>
      <div className='fs-300 mb-1 text-clr-dark-300'>Real-time Views</div>
      <div className='d-flex align-items-center mb-2'>
        <Circle
          color='var(--clr-live-indicator)'
          size={10}
          className='live-indicator'
        />
        <div className='fs-300 ml-2 text-clr-dark-300'>Updating live</div>
      </div>
      <Form.Control
        as='select'
        className='mb-4'
        value={sort}
        onChange={onSelect}
      >
        {options.map(({ label, value }) => (
          <option value={value} key={label}>
            {label}
          </option>
        ))}
      </Form.Control>

      <div className='d-flex align-items-baseline'>
        <h1>{abbreviateNumber(views, FIXED_DECIMAL_CONSTANT)}</h1>
        <span className='ml-2 fs-300'>views</span>
      </div>
    </div>
  );
};

export default RealTimeViewContent;
