import React from 'react';
import {
  BlockchainSaveTooltip,
  ThumbnailInfoTooltip,
} from '../atoms/CustomCheckTooltips/CustomCheckTooltips';
import Constant, { COLORS, THUMBNAIL_TYPE } from 'src/constant';
import { useRef } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import UilMenuV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import { UilImage } from '@iconscout/react-unicons';
import { IconButton } from '../atoms/buttons';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const UploadThumbnail = ({
  onChange,
  onUploadBoxClick,
  error,
  isUploading = false,
  thumbnails = [],
  value,
  maxThumbnail,
  onSelectThumbnail,
}) => {
  const thumbnailRef = useRef();
  return (
    <div>
      {/* thumbnail */}
      <div className='mt-4 d-flex align-items-center mb-1'>
        <h6 className='fw-600 mr-2'>
          Thumbnail
          <BlockchainSaveTooltip className='ml-1' />
        </h6>
        <ThumbnailInfoTooltip>
          <img
            src={`${Constant.BASE_URL}img/info.svg`}
            alt='info'
            className='cursor-pointer'
          />
        </ThumbnailInfoTooltip>
      </div>
      <p>
        Select or upload a picture that shows what's in your video. Recommended
        ratio is 16:9, 2MB max.
      </p>
      {/* thumbnail input */}
      <Form.Group id='video-thumbnail'>
        <Form.Control
          size='lg'
          type='file'
          hidden
          ref={thumbnailRef}
          onChange={onChange}
        />
      </Form.Group>
      <div className='d-flex align-items-center'>
        {thumbnails.length < maxThumbnail && (
          <div
            onClick={() => {
              const callback = () => thumbnailRef.current.click();
              onUploadBoxClick(callback);
            }}
            className='upload-video-thumbnail rounded-sm d-flex justify-content-center align-items-center p-1 p-sm-2'
            style={{
              border: `1px dashed ${
                error ? 'var(--clr-primary-400)' : '#343434'
              }`,
            }}
          >
            <div className='gray-box h-100 w-100  d-flex flex-column justify-content-center align-items-center rounded-sm'>
              <UilImage size={25} />
              <h6 className='fs-200 fw-200 mt-2 text-line-1 text-center d-none d-sm-block'>
                {isUploading ? 'Uploading...' : 'Upload Thumbnail'}
              </h6>
            </div>
            {/* )} */}
          </div>
        )}
        {thumbnails?.map((thumbnail) => (
          <div
            key={`thumbnails-${thumbnail.id}`}
            className='upload-video-thumbnail position-relative '
            style={
              thumbnail.id === value
                ? {
                    outline: `1.5px solid black`,
                    opacity: 1,
                  }
                : {}
            }
            onClick={() => onSelectThumbnail(thumbnail)}
          >
            {thumbnail.type === THUMBNAIL_TYPE.CUSTOM && isUploading ? (
              <div className='gray-box h-100 w-100  d-flex flex-column justify-content-center align-items-center rounded-sm'>
                <UilImage size={25} />
                <h6 className='fs-200 fw-200 mt-2 '>Uploading</h6>
              </div>
            ) : (
              <img
                width={'100%'}
                height={'100%'}
                src={thumbnail.thumbnail_url}
                alt='thumbnail'
                style={{ objectFit: 'contain' }}
              />
            )}
            {thumbnail.type === THUMBNAIL_TYPE.CUSTOM && (
              <Dropdown
                style={{
                  position: 'absolute',
                  right: '2px',
                  top: '2px',
                  zIndex: '1',
                }}
              >
                <Dropdown.Toggle
                  as='a'
                  id=''
                  className='no-after no-before'
                  flip={true}
                >
                  <IconButton>
                    <UilMenuV size={14} color='#4B4B4B' />
                  </IconButton>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ minWidth: 'min-content' }}>
                  <Dropdown.Item onClick={() => thumbnailRef.current.click()}>
                    <UilImage
                      color={COLORS.dark300}
                      size='20'
                      className='mr-2'
                    />
                    <span>Change</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        ))}
        {(thumbnails?.length === 0 || !thumbnails) && (
          <>
            <Skeleton
              direction='ltr'
              inline
              // className='mt-2'
              containerClassName='d-flex w-100'
              count={3}
              style={{
                maxWidth: '140px',
                width: '100%',
                aspectRatio: '16/9',
                marginRight: '10px',
              }}
            />
            {/* <Skeleton
              direction='ltr'
              inline
              className='mt-2'
              containerClassName='w-100'
              style={{
                width: '100%',
                aspectRatio: '16/9',
                marginRight: '10px',
              }}
            />
            <Skeleton
              direction='ltr'
              inline
              className='mt-2'
              containerClassName='w-100'
              style={{
                width: '100%',
                aspectRatio: '16/9',
                marginRight: '10px',
              }}
            /> */}
          </>
        )}
      </div>
      {error && <Form.Text className='text-danger fs-200'>{error}</Form.Text>}
    </div>
  );
};

export default UploadThumbnail;

// previous code
// {/* thumbnail */}
// <div className='mt-4 d-flex align-items-center mb-1'>
// <h6 className='fw-600 mr-2'>Thumbnail</h6>
// <ThumbnailInfoTooltip>
//   <img
//     src={`${Constant.BASE_URL}img/info.svg`}
//     alt='info'
//   />
// </ThumbnailInfoTooltip>
// </div>
// <p>
// Select or upload a picture that shows what's in your video.
// Recommended ratio is 16:9, 2MB max.
// </p>
// {/* thumbnail input */}
// <div className='d-flex align-items-center'>
// <Form.Group id='video-thumbnail'>
//   <Form.Control
//     size='lg'
//     type='file'
//     hidden
//     ref={thumbnailRef}
//     onChange={async (e) => {
//       const file = e.target.files[0];
//       try {
//         await thumbnailSchema.validate(file);
//         const formData = new FormData();
//         formData.append('thumbnail', file);
//         const { data } = await uploadThumbnail({
//           videoId: videoDetailStore.videoId,
//           formData,
//         }).unwrap();
//         setFieldValue('thumbnail_id', data.thumbnail.id);
//         const remainThumbnails = customThumbnail
//           ? videoDetailStore.thumbnails.slice(1)
//           : videoDetailStore.thumbnails;
//         setVideoDetailStore((prev) => ({
//           ...prev,
//           thumbnail_id: data.thumbnail.id,
//           thumbnails: [data.thumbnail, ...remainThumbnails],
//         }));
//         setFieldTouched('thumbnail_id', true);
//       } catch (error) {
//         setFieldTouched('thumbnail_id', true);
//         handleError(error);
//       }
//       // const reader = new FileReader();
//       // reader.readAsDataURL(file);
//       // reader.onload = () => {
//       //   setFieldValue('thumbnail_url', reader.result);
//       //   handleVideoStore('thumbnail_url', reader.result);
//       // };
//     }}
//   />
// </Form.Group>
// {!customThumbnail && (
//   <div
//     onClick={() => {
//       if (!videoDetailStore?.videoId)
//         return toast.info(messages.uploading_thumbnail);
//       thumbnailRef.current.click();
//     }}
//     className='upload-video-thumbnail rounded-sm d-flex justify-content-center align-items-center p-2 mt-2'
//     style={{
//       border: `1px dashed ${
//         touched.thumbnail_id && errors.thumbnail_id
//           ? 'var(--clr-primary-400)'
//           : '#343434'
//       }`,
//     }}
//   >
//     {/* {values.thumbnail_url ? (
//     <img
//       src={values.thumbnail_url}
//       className='h-100 w-100'
//       style={{ objectFit: 'cover' }}
//       alt='info'
//     />
//   ) : ( */}
//     <div className='gray-box h-100 w-100  d-flex flex-column justify-content-center align-items-center rounded-sm'>
//       {/* <img
//         style={{ width: '25px' }}
//         src={themeImageUrlParser('upload/gallery.svg')}
//         className='mb-2'
//         alt='info'
//       /> */}
//       <UilImage size={25} />
//       <h6 className='fs-200 fw-200 mt-2 '>
//         {thumbnailUploading
//           ? 'Uploading...'
//           : 'Upload Thumbnail'}
//       </h6>
//     </div>
//     {/* )} */}
//   </div>
// )}
// {videoDetailStore?.thumbnails?.map((thumbnail) => (
//   <div
//     key={`thumbnails-${thumbnail.id}`}
//     className='upload-video-thumbnail mt-2 position-relative '
//     style={
//       thumbnail.id === values.thumbnail_id
//         ? {
//             outline: `1.5px solid black`,
//             opacity: 1,
//           }
//         : {}
//     }
//     onClick={() => {
//       setFieldValue('thumbnail_id', thumbnail.id);
//       handleVideoStore('thumbnail_id', thumbnail.id);
//     }}
//   >
//     {thumbnail.type === THUMBNAIL_TYPE.CUSTOM &&
//     thumbnailUploading ? (
//       <div className='gray-box h-100 w-100  d-flex flex-column justify-content-center align-items-center rounded-sm'>
//         <UilImage size={25} />
//         <h6 className='fs-200 fw-200 mt-2 '>Uploading</h6>
//       </div>
//     ) : (
//       <img
//         width={'100%'}
//         height={'100%'}
//         src={thumbnail.thumbnail_url}
//         alt='thumbnail'
//         style={{ objectFit: 'contain' }}
//       />
//     )}
//     {thumbnail.type === THUMBNAIL_TYPE.CUSTOM && (
//       <Dropdown
//         // drop={'left'}
//         style={{
//           position: 'absolute',
//           right: '2px',
//           top: '2px',
//           zIndex: '1',
//         }}
//       >
//         <Dropdown.Toggle
//           as='a'
//           id=''
//           className='no-after no-before'
//           flip={true}
//         >
//           {/* <button className='icon-circle-wrapper'>
//             <UilMenuV size={18} color='#4B4B4B' />
//           </button> */}
//           <IconButton>
//             <UilMenuV size={14} color='#4B4B4B' />
//           </IconButton>
//         </Dropdown.Toggle>

//         <Dropdown.Menu style={{ minWidth: 'min-content' }}>
//           <Dropdown.Item
//             onClick={() => {
//               thumbnailRef.current.click();
//             }}
//           >
//             <UilImage
//               color={COLORS.dark300}
//               size='20'
//               className='mr-2'
//             />
//             <span>Change</span>
//           </Dropdown.Item>
//         </Dropdown.Menu>
//       </Dropdown>
//     )}
//   </div>
// ))}
// </div>
// {touched.thumbnail_id && errors.thumbnail_id && (
// <Form.Text className='text-danger fs-200'>
//   {errors.thumbnail_id}
// </Form.Text>
// )}
