import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import shaka from 'shaka-player';
import {
  Player as ShakaPlayer,
  ui as ShakaUI,
} from 'shaka-player/dist/shaka-player.ui';
import PropTypes from 'prop-types';

import { useAddWatchHistoryMutation } from 'src/redux/features/watch-history/watchHistoryApi';
import {
  useViewVideoMutation,
  useWatchTimeMutation,
} from 'src/redux/features/videos/videoApi';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import Constant from 'src/constant';
import { useSelector } from 'react-redux';
import VerticalVolume from 'src/components/atoms/player-controls/VerticalVolume';
import TheaterMode from 'src/components/atoms/player-controls/TheaterMode';

const VideoPlayer = ({
  video_url,
  video_id,
  watermark_url,
  thumbnail_url,
  handle_name,
}) => {
  // alert(
  //   ` is browser supported shaka player : ${shaka.Player.isBrowserSupported()}`
  // );
  // video_url = 'https://io.pixelsoftwares.com/callforblessing_dev/drive/ottvideo/video_65969e1c57afc/master_playlist.m3u8'
  const history = useHistory();
  const { video_url_id } = useParams();
  const [startTime, setStartTime] = useState(new Date());

  const [addWatchHistory] = useAddWatchHistoryMutation();
  const [viewVideo] = useViewVideoMutation();
  const [watchTime] = useWatchTimeMutation();

  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [shouldHandleTimeUpdate, setShouldHandleTimeUpdate] = useState(true);
  const nextVideo = useSelector((state) => state.videoStore.nextVideo);

  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);

  // Listen for fullscreenchange event and handle watermark element
  useEffect(() => {
    const handleFullScreenChange = () => {
      const watermarkElement = document.getElementById('watermark');
      if (!watermarkElement) return;
      // Position the watermark element over the video
      watermarkElement.style.position = 'absolute';
      watermarkElement.src = watermark_url;
      watermarkElement.style.opacity = '0.5'; // You can adjust the opacity as needed

      if (document.fullscreenElement) {
        watermarkElement.style.bottom = '45px';
        watermarkElement.style.right = '40px';
      } else {
        watermarkElement.style.bottom = '25px';
        watermarkElement.style.right = '25px';
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, [watermark_url]);

  const handlePlayerError = (event) => {
    console.log('Shaka Player Error:', event);
  };

  useEffect(() => {
    let player;
    async function initPlayer(url) {
      // shaka.polyfill.installAll();
      // Check to see if the browser supports the basic APIs Shaka needs.
      if (!shaka.Player.isBrowserSupported()) {
        // Everything looks good!
        alert('Shaka Player is not supported');
      }
      videoContainerRef.current.style.visibility = 'hidden';

      // removing default controls
      const previousControls = document.querySelectorAll(
        '.shaka-bottom-controls'
      );
      const previousWatermarks = document.querySelectorAll('#watermark');
      if (previousControls.length > 0)
        previousControls?.forEach((item) => item.remove());
      if (previousWatermarks.length > 0)
        previousWatermarks?.forEach((item) => item.remove());

      // vertical volume control
      ShakaUI.Controls.registerElement(
        'vertical_volume',
        new VerticalVolume.Factory()
      );

      ShakaUI.Controls.registerElement(
        'theater_mode',
        new TheaterMode.Factory()
      );

      // Create a Player instance.
      player = window.player || new ShakaPlayer();
      await player.attach(videoRef.current);

      const ui = new ShakaUI.Overlay(
        player,
        videoContainerRef.current,
        videoRef.current
      );

      // Attach player to the window to make it easy to access in the JS console.
      window.player = player;

      const uiConfig = {
        enableTooltips: true,
        addSeekBar: true,
        addBigPlayButton: true,
        controlPanelElements: [
          'play_pause',
          // 'rewind',
          // 'fast_forward',
          // 'mute',
          // 'volume',
          'vertical_volume',
          'time_and_duration',
          'spacer',
          'picture_in_picture',
          'theater_mode',
          'fullscreen',
          'overflow_menu',
        ],
        overflowMenuButtons: ['quality', 'playback_rate', 'cast'],
        customContextMenu: true,
        contextMenuElements: ['statistics'],
        statisticsList: ['width', 'height', 'playTime', 'bufferingTime'],
      };
      ui.configure(uiConfig);

      // console.log(player.getConfiguration(), 'player config');
      // player configuration
      // const playerConfig = {
      //   abr: {
      //     useChainsInformation: true,
      //     // enabled: true,
      //   },
      // };
      // player.configure(playerConfig);
      // console.log(player.getConfiguration(), 'after player config');

      // Listen for error events.
      // player.addEventListener('error', onErrorEvent);

      // Try to load a manifest.
      // This is an asynchronous process.
      try {
        await player.load(url);
        // setVisibility(true);
        videoContainerRef.current.style.visibility = 'visible';
        if (watermark_url) {
          // Position the watermark element over the video
          const watermarkElement = document.createElement('img');
          watermarkElement.id = 'watermark';
          watermarkElement.src = watermark_url;
          watermarkElement.style['z-index'] = 1;
          watermarkElement.style.position = 'absolute';
          watermarkElement.style.cursor = 'pointer';
          watermarkElement.style.opacity = '0.5'; // You c
          watermarkElement.style.width = '40px';
          watermarkElement.style.height = '40px';
          watermarkElement.style.bottom = '20px';
          watermarkElement.style.right = '20px';

          watermarkElement.addEventListener('click', () => {
            history.push(`${Constant.BASE_URL}channel/${handle_name}`);
          });
          // Add the watermark element to the video container
          videoRef.current.parentNode.appendChild(watermarkElement);
          // videoElement.parentNode.prepend(watermarkElement);
        }

        if (authData?.token) addWatchHistory(video_id);

        // This runs if the asynchronous load is successful.
        console.log('The video has now been loaded!');
      } catch (e) {
        // onError is executed if the asynchronous load fails.
        handlePlayerError(e);
      }
    }

    if (video_url && videoRef.current && videoContainerRef.current) {
      // // Install built-in polyfills to patch browser incompatibilities.
      // shaka.polyfill.installAll();
      // // Check to see if the browser supports the basic APIs Shaka needs.
      // if (shaka.Player.isBrowserSupported()) {
      // Everything looks good!
      initPlayer(video_url);
      // } else {
      //   // This browser does not have the minimum set of APIs we need.
      //   console.error('Browser not supported!');
      // }
    }
  }, [video_url]);

  // // Listen to the custom shaka-ui-loaded event to wait until the UI is loaded
  // window.document.addEventListener('shaka-ui-loaded', () => {
  //   console.log('Shaka UI loaded!');
  // });
  useEffect(() => {
    // this cleanup function only runs when component unmount
    const cleanup = () => {
      console.log('new cleanup function');
      if (window.player) window.player.unload();
      if (window.player) {
        window.player.removeEventListener('error', handlePlayerError);
      }
      // if (watermarkElement) {
      //   watermarkElement.removeEventListener('click', watermarkClick);
      //   watermarkElement.remove();
      // }
    };
    // Cleanup function: Remove the error event listener when the component unmounts
    return cleanup;
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleTimeUpdate = () => {
      const currentTime = videoElement.currentTime;
      const totalTime = videoElement.duration;
      const minSecondWatch = 5;
      // currentTime >= 10% of total Time
      if (shouldHandleTimeUpdate && currentTime >= minSecondWatch) {
        viewVideo(video_id);
        setShouldHandleTimeUpdate(false);
      }
      // if completed then play next video
      if (currentTime === totalTime && nextVideo) {
        setTimeout(() => {
          history.push(`${Constant.BASE_URL}watch/${nextVideo.video_url_id}`);
        }, 3000);
      }
    };
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [viewVideo, shouldHandleTimeUpdate, video_id, history, nextVideo]);

  // <div
  // "
  // class="video-container">
  return (
    <div
      data-shaka-player-container
      data-shaka-player-cast-receiver-id='07AEE832'
      className='position-relative'
      style={{ maxHeight: '75vh' }}
      ref={videoContainerRef}
    >
      <video
        data-shaka-player
        playsInline
        ref={videoRef}
        id={video_url_id}
        autoPlay
        style={{ aspectRatio: '16/9', background: 'black' }}
        poster={thumbnail_url}
        width='100%'
        onPlay={(e) => {
          setStartTime(new Date());
        }}
        onPause={(e) => {
          const endTime = new Date();
          const body = {
            start_time: startTime,
            end_time: endTime,
            video_id,
          };
          watchTime({ body });
        }}
        onEnded={(e) => {
          const endTime = new Date();
          const body = {
            start_time: startTime,
            end_time: endTime,
            video_id,
          };
          watchTime({ body });
        }}
      >
        {/* Provide fallback content for non-supported browsers */}
        <p>Your browser doesn't support HTML5 video.</p>
      </video>
    </div>
  );
};

VideoPlayer.propTypes = {
  video_url: PropTypes.string.isRequired,
  handle_name: PropTypes.string.isRequired,
  video_id: PropTypes.number.isRequired,
  watermark_url: PropTypes.string.isRequired,
  thumbnail_url: PropTypes.string.isRequired,
};

export default React.memo(VideoPlayer);
