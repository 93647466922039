import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Constant from '../../../constant';
import { NavLink } from 'react-router-dom';
import CommentTable from './CommentTable';
import { ThinFooter } from 'src/components/footer';

const Comments = () => {
  return (
    <>
      <Container fluid='xxl' id='studio-comment-page' className='px-2 p-md-4'>
        <Row className='mx-0'>
          <Col md={12} className='mx-auto p-0'>
            <h3 className='mb-3 fs-500 fw-600'>Comments</h3>
          </Col>
          <Col md={12} className='p-0 mx-auto py-3 py-md-4 studio-card'>
            <div className='custom-tabs mx-4'>
              <NavLink
                exact
                activeClassName='active'
                className='tab-item mr-4'
                to={Constant.BASE_URL + 'studio/comments'}
              >
                All Comments
              </NavLink>
              {/* <NavLink
                      activeClassName='active'
                      className='tab-item mr-4'
                    //   to={Constant.BASE_URL + 'studio/customization/basic-info'}
                    >
                      Mentions
                    </NavLink> */}
            </div>
            <CommentTable />
          </Col>
        </Row>
      </Container>
      <ThinFooter />
    </>
  );
};

export default Comments;
