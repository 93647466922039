import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import {
  useAccount,
  useConnect,
  useWriteContract,
  useSignMessage,
  useSwitchChain,
  useReadContract,
  useConfig,
  usePublicClient,
} from 'wagmi';

// import './Navigation.css';

import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';

import { useDispatch } from 'react-redux';
import { useDisconnect } from 'wagmi';

import './Navigation.css';
import Constant, { COLORS, themeMode } from '../../constant';
import { SidebarContext, ThemeContext } from '../../App';
import {
  useLoginMutation,
  useLogoutMutation,
} from '../../redux/features/users/usersApi';
import { useAuthCheckQuery } from '../../redux/features/auth/authApi';
import { AddCircleSvg } from 'src/assets/svgs';
import rootApi from 'src/redux/rootApi';
import handleError from 'src/utils/handle-error';
import useFindContractData from 'src/hooks/useFindContractData';
import { getNonce } from 'src/redux/features/auth/authSlice';
import SearchInput from './SearchInput';
import ProfileDropdown from './ProfileDropdown';
import config from 'src/config';
import messages from 'src/constant/messages';
import RegisterModal from '../atoms/modals/RegisterModal';
import { useActiveChainId } from 'src/hooks';

const Navigation = () => {
  const { disconnectAsync } = useDisconnect();
  const { toggleMode, mode } = useContext(ThemeContext);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const { openSidebar, setOpenSidebar } = useContext(SidebarContext);
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });

  const { waitForTransactionReceipt } = usePublicClient();
  const history = useHistory();
  const handleMenuToggle = () => {
    // const sidebarItemDropdown = document.querySelector(
    //   '.sidebar-item-dropdown'
    // );
    // if (!openSidebar) sidebarItemDropdown.style.height = 0;
    setOpenSidebar(!openSidebar);
  };

  const { open } = useWeb3Modal({});
  const account = useAccount();
  const activeChainId = useActiveChainId();

  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const dispatch = useDispatch();
  const { signMessageAsync } = useSignMessage();
  const { pendingConnector, isLoading: connecterIsLoading } = useConnect();

  const { abi, contract_address } = useFindContractData();

  const [login] = useLoginMutation();
  const [logout] = useLogoutMutation();

  const [loading, setLoading] = useState(false);

  const { writeContractAsync } = useWriteContract();
  const isJoinedResult = useReadContract({
    address: contract_address,
    abi: JSON.parse(abi),
    functionName: 'isJoined',
    args: [account.address],
  });
  const isBannedResult = useReadContract({
    address: contract_address,
    abi: JSON.parse(abi),
    functionName: 'isBanned',
    args: [account.address],
  });

  const handleLogout = async () => {
    try {
      if (account.isConnected) await disconnectAsync();
      await logout();
      localStorage.removeItem('block_address');
      localStorage.removeItem('accessToken');
      toast.error('Disconnected');
      dispatch(rootApi.util.resetApiState());
      history.push(Constant.BASE_URL);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [show, setShow] = useState(false);
  const openRegisterModal = () => setShow(true);
  const closeRegisterModal = () => {
    setShow(false);
    setLoading(false);
  };

  useEffect(() => {
    const handleLogin = async (address) => {
      console.log(config.ACTIVE_CHAIN.id, activeChainId, 'id hai chainId ki');
      if (activeChainId !== config.ACTIVE_CHAIN.id) {
        console.log('calling switch chain');
        await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
        console.log('chain switched');
      }
      console.log('more forward');
      setLoading(true);
      try {
        // const isJoined = await readContract(config, {
        //   address: contract_address,
        //   abi: JSON.parse(abi),
        //   functionName: 'isJoined',
        //   args: [address],
        // });
                let data = {};
        const {
          data: isJoined,
          isError,
          error,
          ...rest
        } = await isJoinedResult.refetch();

        if (isError) {
          handleError(error);
          setLoading(false);
          return;
        }

        console.log(isJoined, rest, 'isJoined');

        if (!isJoined) {
          // openRegisterModal();
          // return;
          const hash = await writeContractAsync({
            address: contract_address,
            abi: JSON.parse(abi),
            functionName: 'join',
            chainId: config.ACTIVE_CHAIN.id,
          });
          data.txn_hash = hash;
          await waitForTransactionReceipt({ hash });
        } else {
          // account banned or not
          const { data: isBanned } = await isBannedResult.refetch();
          if (isBanned) {
            disconnectAsync();
            toast.info(
              'Your account is suspended or inactive. Please contact the support team.'
            );
            setLoading(false);
            return;
          }
        }

        // fetch nonce
        const {
          data: { nonceToken, message },
        } = await dispatch(getNonce(account.address)).unwrap();

        // generate signature
        const signature = await signMessageAsync({
          message: message,
        });
        data.signature = signature;
        data.nonceToken = nonceToken;

        const response = await login(data).unwrap();
        const { token: accessToken } = response.data;
        dispatch(rootApi.util.resetApiState());
        localStorage.setItem('block_address', address);
        localStorage.setItem('accessToken', accessToken);
        setLoading(false);
      } catch (error) {
        disconnectAsync();
        console.log(error, 'error');
        handleError(error);
        setLoading(false);
      }
    };

    const accessToken = localStorage.getItem('accessToken');
    const blockAddress = localStorage.getItem('block_address');
    const isLoginFunctionCallable =
      localStorage.getItem('isLoginFunctionCallable') === 'true';
    if (isLoginFunctionCallable) {
      if (account.isConnected && account.address && !accessToken) {
        console.log('login call');
        localStorage.setItem('isLoginFunctionCallable', 'false');
        handleLogin(account.address);
      }
    }
    console.log(blockAddress, account.address, '[Account Switched]');
    if (blockAddress && account.address && blockAddress !== account.address) {
      disconnectAsync();
    }
  }, [account.address]);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && account.isDisconnected) {
      console.log('[Disconnected]');
      logout().then(() => {
        localStorage.removeItem('block_address');
        localStorage.removeItem('accessToken');
        // toast.error('Disconnected');
        dispatch(rootApi.util.resetApiState());
        history.push(Constant.BASE_URL);
      });

      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('block_address');
      // toast.error('Disconnected');
      // // dispatch(rootApi.util.invalidateTags(['AUTH_CHECK']));
      // // dispatch(rootApi.util.resetApiState());
    }
  }, [account.isDisconnected, dispatch, history, logout]);

  if (openSearchBar) {
    return (
      <div
        className='d-flex align-items-center p-2 sticky-top'
        style={{ backgroundColor: 'var(--clr-dark-500)' }}
      >
        <div
          className='cursor-pointer mr-1'
          onClick={() => setOpenSearchBar(false)}
        >
          <UilArrowLeft size={20} color={COLORS.white} />
        </div>
        <SearchInput />
      </div>
    );
  }

  return (
    <>
      <RegisterModal
        handleShow={openRegisterModal}
        handleClose={closeRegisterModal}
        show={show}
      />
      <Navbar
        bg='white'
        sticky='top'
        // fixed='top'
        className='osahan-nav'
      >
        <div className='left'>
          <div
            className='text-secondary'
            id='sidebarToggle'
            style={{ width: '90px' }}
            onClick={handleMenuToggle}
          >
            <div
              className='menubar-div'
              style={{
                transform: openSidebar ? 'rotate(0deg)' : 'rotate(90deg)',
              }}
            >
              <FontAwesomeIcon icon={faBars} color='#fff' />
            </div>
          </div>
          <Navbar.Brand className='mr-1 pl-sm-0 pl-2 mb-0'>
            <Link to={Constant.BASE_URL}>
              <img
                className='img-fluid'
                // src={themeImageUrlParser('logo.svg')}
                src={`${Constant.BASE_URL}img/dark/logo.svg`}
                alt=''
              />
            </Link>
          </Navbar.Brand>
        </div>

        <div className='ml-2 d-md-flex flex-grow-1 justify-content-center align-align-items-center  my-2 my-md-0'>
          <SearchInput className='d-none d-md-flex' />
          {authData?.token && (
            <Link
              to={
                authData?.isChannelCreated
                  ? `${Constant.BASE_URL}studio/upload`
                  : `${Constant.BASE_URL}create-channel`
              }
              className='d-none ml-3 d-md-flex align-items-center'
            >
              <AddCircleSvg
                className='mr-2'
                style={{
                  fill: '#fff',
                }}
              />
              <span className='text-white'>Upload Video</span>
            </Link>
          )}
        </div>

        <div
          className='d-flex d-md-none ml-auto'
          onClick={() => setOpenSearchBar(true)}
        >
          <UilSearch size={22} color='white' />
        </div>
        <ul className='navbar-nav ml-md-0 osahan-right-navbar align-items-center'>
          <li className='nav-item mx-1 d-block'>
            <div className='nav-link'>
              <div className='nav-btn-circle' onClick={toggleMode}>
                {mode === themeMode.DARK ? (
                  <img
                    alt='light-mode-icon'
                    src={`${Constant.BASE_URL}img/light-theme.svg`}
                  />
                ) : (
                  <img
                    alt='dark-mode-icon'
                    src={`${Constant.BASE_URL}img/moon.svg`}
                  />
                )}
              </div>
            </div>
          </li>
          {!authData && (
            <li className='nav-item mx-1'>
              <div className='nav-link'>
                {/* {account.address ? (
                  <Button
                    disabled={loading || connecterIsLoading || pendingConnector}
                    onClick={async () => {
                      handleLogin(account.address);
                    }}
                  >
                    {loading ? 'Logging...' : 'Login'}
                  </Button>
                ) : ( */}
                <Button
                  disabled={loading || connecterIsLoading || pendingConnector}
                  onClick={async () => {
                    if (account.isConnected) await disconnectAsync();
                    localStorage.setItem('isLoginFunctionCallable', 'true');
                    open();
                  }}
                >
                  {loading
                    ? 'Logging...'
                    : connecterIsLoading && pendingConnector
                    ? 'Connecting...'
                    : 'Login'}
                </Button>
                {/* )} */}
              </div>
            </li>
          )}
          {authData && <ProfileDropdown handleLogout={handleLogout} />}
        </ul>
      </Navbar>
    </>
  );
};

export default Navigation;
