import { FormControl, InputGroup, Row, Col, Container } from 'react-bootstrap';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import { useRef, useState } from 'react';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  useFetchMyWatchLaterQuery,
  useRemoveWatchLaterMutation,
} from '../../redux/features/watch-later/watchLaterApi';
import Constant, { CLIENT_URL } from '../../constant';

import NoContent from '../../components/atoms/NoContent/NoContent';
import { toast } from 'react-toastify';
import WatchedVideoCard from 'src/components/atoms/WatchedVideoCard/WatchedVideoCard';
import messages from 'src/constant/messages';
import { checkVideoReported } from 'src/redux/features/videos/videoSlice';
import { openReportVideoModal } from 'src/redux/features/report-video/reportVideoSlice';
import handleError from 'src/utils/handle-error';
import { useDispatch } from 'react-redux';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import calculateDuration from 'src/utils/calculate-duration';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';

const WatchLater = () => {
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const history = useHistory();
  const [offset, setOffset] = useState(0);
  const limit = 50;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query');

  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const { data, isLoading, isSuccess, originalArgs } =
    useFetchMyWatchLaterQuery({ query: query || '', limit, offset });
  const [removeWatchLater] = useRemoveWatchLaterMutation();

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      const newQuery = searchRef.current?.value;
      const queryParam = newQuery ? `?query=${newQuery}` : '';
      history.push({
        pathname: `${Constant.BASE_URL}watch-later`,
        search: queryParam,
      });
    }
  };

  const handleSearchClick = () => {
    const newQuery = searchRef.current.value;
    if (!newQuery) searchRef.current.focus();
    const queryParam = newQuery ? `?query=${newQuery}` : '';
    history.push({
      pathname: `${Constant.BASE_URL}watch-later`,
      search: queryParam,
    });
  };

  if (data?.watchLater?.length === 0 && !query)
    return (
      <NoContent
        addContainer={true}
        title='No Videos Yet'
        description='You have not added any videos to your Watch Later section. Go to home and start adding now.'
        imgUrl={`${Constant.BASE_URL}img/illustration/watch-later.svg`}
        buttonText='Go to home'
        handleButtonClick={() => history.push(`${Constant.BASE_URL}`)}
      />
    );

  return (
    <>
      <Container fluid='xxl' className='p-4'>
        {/* <div
          className='video-block section-padding'
          style={{
            maxWidth: '800px',
            margin: 'auto',
          }}
        > */}
        <Row>
          <Col md={12} className='mb-2'>
            <div className='d-flex justify-content-between align-items-center'>
              <h6 className='fs-500 fw-600'>Watch Later</h6>
              <div className='d-flex align-items-center'>
                <InputGroup>
                  <FormControl
                    type='text'
                    placeholder='Search'
                    className='input-search2'
                    ref={searchRef}
                    onKeyPress={handleKeyPress}
                  />{' '}
                  <InputGroup.Append>
                    <InputGroup.Text
                      className='cursor-pointer bg-transparent pl-2 border-0'
                      onClick={handleSearchClick}
                    >
                      <UilSearch size={18} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
            <hr className='clr-white-card-hr' />
          </Col>
          <Col md={12} className='mb-2'>
            <div className='d-flex flex-column'>
              {isLoading && <Loader />}
              {data?.watchLater && data?.watchLater?.length === 0 && (
                <h6 className='text-center h5 fw-600'>
                  There is 0 result for {query}
                </h6>
              )}
              {isSuccess && !!data?.watchLater?.length && (
                <InfiniteScroll
                  dataLength={data?.watchLater?.length || 0} //This is important field to render the next data
                  next={() => setOffset((prev) => prev + limit)}
                  hasMore={data?.total_result_count > data?.watchLater?.length}
                  loader={<Loader />}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      {/* <b>Yay! You have seen it all</b> */}
                    </p>
                  }
                  className='overflow-hidden'
                  style={{ minHeight: '60vh' }}
                >
                  {data?.watchLater?.map((item) => (
                    <WatchedVideoCard
                      key={`watch-later-${item.id}`}
                      imgSrc={
                        item.thumbnail_url ||
                        `${Constant.BASE_URL}img/light/default-thumbnail.png`
                      }
                      videoHref={`${Constant.BASE_URL}watch/${item.video_url_id}`}
                      videoTitle={item.title}
                      timeAgo={moment(item.modified_date).fromNow()}
                      time={calculateDuration(item.duration)}
                      channelName={item.handle_name}
                      views={item?.views || 0}
                      likes={item.like_count || 0}
                      progress='50'
                      progressTime='1:40'
                      verified={item?.verified}
                      channelHref={`${Constant.BASE_URL}channel/${item.handle_name}`}
                      isShort={Boolean(item.is_short)}
                      shareLink={`${CLIENT_URL}/watch/${item.video_url_id}`}
                      onRemoveFromWatchLater={async () => {
                        try {
                          const response = await removeWatchLater({
                            videoId: item.video_id,
                            videoUrlId: item.video_url_id,
                          }).unwrap();
                          toast.success(response.message);
                        } catch (error) {
                          toast.error(error.message);
                        }
                      }}
                      onReportVideo={async () => {
                        if (!authData)
                          return toast.error(messages.require_signin_to_report);
                        if (item.is_reported)
                          return toast.info(messages.video_reported_already);

                        try {
                          const report = await dispatch(
                            checkVideoReported(item.id)
                          ).unwrap();
                          const is_reported = report.data.is_reported;

                          if (is_reported)
                            return toast.info(messages.video_reported_already);

                          dispatch(
                            openReportVideoModal({
                              contractVideoId: item.contract_video_id,
                              videoId: item.id,
                              videoUrlId: item.video_url_id,
                            })
                          );
                        } catch (error) {
                          handleError(error);
                        }
                      }}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </Col>
        </Row>
        {/* </div> */}
      </Container>
      <ThinFooter />
    </>
  );
};

export default WatchLater;
