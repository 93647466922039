import React from 'react';
import Constant from '../../../constant';
import { Dropdown } from 'react-bootstrap';
import { useUpdateVideoVisibilityMutation } from '../../../redux/features/videos/videoApi';
import { toast } from 'react-toastify';
import handleError from 'src/utils/handle-error';
import Loader from 'src/components/atoms/Loader';

const Visibility = ({
  isLast,
  visibility,
  videoId,
  handleOpen,
  handleClose,
  show,
}) => {
  // const [values, setValues] = useState({ visibility });
  // useEffect(() => {
  //   setValues({ visibility });
  // }, [visibility]);

  const [updateVisibility, { isLoading }] = useUpdateVideoVisibilityMutation();

  // const handleChange = (e) => {
  //   setValues(() => ({ visibility: e.target.value }));
  // };

  const handleSubmit = (updatedVisibility) => {
    updateVisibility({ videoId, data: { visibility: updatedVisibility } })
      .then(() => {
        toast.success('Visibility updated successfully.');
        handleClose();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  if (isLoading) return <Loader size='sm' />;

  return (
    <Dropdown>
      <Dropdown.Toggle
        name='visibility'
        variant='default'
        className='pl-0 w-100'
      >
        <div className='d-flex align-items-center cursor-pointer'>
          {visibility === 'PUBLIC' ? (
            <img
              alt='visibility icon'
              className='mr-2'
              style={{ width: '20px', height: '20px' }}
              src={`${Constant.BASE_URL}img/eye-green.svg`}
            />
          ) : (
            <img
              alt='visibility icon'
              className='mr-2'
              src={`${Constant.BASE_URL}img/eye-red.svg`}
            />
          )}
          <h6 className='fs-300 fw-500'>{visibility}</h6>
          <img
            className='ml-1'
            alt='dropdown icon'
            src={`${Constant.BASE_URL}img/dropdown-1.svg`}
          />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          active={visibility === 'PUBLIC'}
          onClick={() => handleSubmit('PUBLIC')}
        >
          Public
        </Dropdown.Item>
        <Dropdown.Item
          active={visibility === 'PRIVATE'}
          onClick={() => handleSubmit('PRIVATE')}
        >
          Private
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
  // return (
  //   <div className='no-arrow position-relative'>
  //     <div
  //       className='d-flex align-items-center cursor-pointer'
  //       onClick={handleOpen}
  //     >
  //       {visibility === 'PUBLIC' ? (
  //         <img
  //           alt='visibility icon'
  //           className='mr-2'
  //           src={`${Constant.BASE_URL}img/eye-green.svg`}
  //         />
  //       ) : (
  //         <img
  //           alt='visibility icon'
  //           className='mr-2'
  //           src={`${Constant.BASE_URL}img/eye-red.svg`}
  //         />
  //       )}
  //       <h6 className='fs-300 fw-500'>{visibility}</h6>
  //       <img
  //         className='ml-1'
  //         alt='dropdown icon'
  //         src={`${Constant.BASE_URL}img/dropdown-1.svg`}
  //       />
  //     </div>
  //     {show && (
  //       <div
  //         className='visibility-dropdown'
  //         style={isLast ? { bottom: '10%' } : { top: '50%' }}
  //       >
  //         <Modal.Body>
  //           <div style={{ overflow: 'auto' }}>
  //             <div className='px-2 py-3 mt-4 rounded-sm border-dropdown'>
  //               <div className='d-flex'>
  //                 {/* <Form.Check type='radio' custom id='customCheck1' /> */}
  //                 <div>
  //                   <h6 className='fw-600 fs-400 text-clr-dark-300'>Publish</h6>
  //                 </div>
  //               </div>
  //               {/* child */}
  //               <div className='d-flex ml-5 mt-2'>
  //                 <Form.Check
  //                   type='radio'
  //                   name='visibility'
  //                   value='PUBLIC'
  //                   custom
  //                   disabled={isLoading}
  //                   id='select-public-check'
  //                   onChange={handleChange}
  //                   checked={values === 'PUBLIC'}
  //                 />
  //                 <div>
  //                   <label
  //                     for='select-public-check'
  //                     className='fw-600 fs-400 text-clr-dark-300'
  //                   >
  //                     Public
  //                   </label>
  //                 </div>
  //               </div>
  //               <div className='d-flex ml-5 mt-2'>
  //                 <Form.Check
  //                   type='radio'
  //                   name='visibility'
  //                   value='PRIVATE'
  //                   custom
  //                   disabled={isLoading}
  //                   id='select-private-check'
  //                   onChange={handleChange}
  //                   checked={values.visibility === 'PRIVATE'}
  //                 />
  //                 <div>
  //                   <label
  //                     for='select-private-check'
  //                     className='fw-600 fs-400 text-clr-dark-300'
  //                   >
  //                     Private
  //                   </label>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </Modal.Body>
  //         <Modal.Footer>
  //           <Button variant='light' onClick={handleClose}>
  //             Cancel
  //           </Button>
  //           <Button
  //             disabled={isLoading || values.visibility === visibility}
  //             variant='primary'
  //             onClick={handleSubmit}
  //           >
  //             Publish
  //           </Button>
  //         </Modal.Footer>
  //       </div>
  //     )}
  //   </div>
  // );
  //   <Dropdown show={show} onToggle={handleOpen}>
  //     <Dropdown.Toggle as={'div'}>
  //       <div className='d-flex align-items-center cursor-pointer'>
  //         {visibility === 'PUBLIC' ? (
  //           <img
  //             alt='visibility icon'
  //             className='mr-2'
  //             src={`${Constant.BASE_URL}img/eye-green.svg`}
  //           />
  //         ) : (
  //           <img
  //             alt='visibility icon'
  //             className='mr-2'
  //             src={`${Constant.BASE_URL}img/eye-red.svg`}
  //           />
  //         )}
  //         <h6 className='fs-300 fw-500'>{visibility}</h6>
  //         <img
  //           className='ml-1'
  //           alt='dropdown icon'
  //           src={`${Constant.BASE_URL}img/dropdown-1.svg`}
  //         />
  //       </div>
  //     </Dropdown.Toggle>
  //     <Dropdown.Menu className='visibility-dropdown'>
  //       <div className=''>
  //         <Modal.Body>
  //           <div
  //             style={{
  //               overflow: 'auto',
  //             }}
  //           >
  //             <div className='px-2 py-3 mt-4 rounded-sm border-dropdown'>
  //               <div className='d-flex'>
  //                 {/* <Form.Check type='radio' custom id='customCheck1' /> */}
  //                 <div>
  //                   <h6 className='fw-600 fs-400'>Publish</h6>
  //                   {/* <p className='fs-300 text-clr-dark-100'>
  //                   Make your video public or private
  //                 </p> */}
  //                 </div>
  //               </div>
  //               {/* child */}
  //               <div className='d-flex ml-5 mt-2'>
  //                 <Form.Check
  //                   type='radio'
  //                   name='visibility'
  //                   value='PUBLIC'
  //                   custom
  //                   disabled={isLoading}
  //                   id='select-public-check'
  //                   onChange={handleChange}
  //                   checked={values.visibility === 'PUBLIC'}
  //                 />
  //                 <div>
  //                   <label for='select-public-check' className='fw-600 fs-400'>
  //                     Public
  //                   </label>
  //                   {/* <p className='fs-300 text-clr-dark-100'>
  //                   Make your video public or private
  //                 </p> */}
  //                 </div>
  //               </div>
  //               {console.log(values, 'val')}
  //               <div className='d-flex ml-5 mt-2'>
  //                 <Form.Check
  //                   type='radio'
  //                   name='visibility'
  //                   value='PRIVATE'
  //                   custom
  //                   disabled={isLoading}
  //                   id='select-private-check'
  //                   onChange={handleChange}
  //                   checked={values.visibility === 'PRIVATE'}
  //                 />
  //                 <div>
  //                   <label for='select-private-check' className='fw-600 fs-400'>
  //                     Private
  //                   </label>
  //                   {/* <p className='fs-300 text-clr-dark-100'>
  //                   Make your video public or private
  //                 </p> */}
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </Modal.Body>
  //         <Modal.Footer>
  //           <Button variant='light' onClick={handleClose}>
  //             Cancel
  //           </Button>
  //           <Button
  //             disabled={isLoading}
  //             variant='primary'
  //             onClick={handleSubmit}
  //           >
  //             Publish
  //           </Button>
  //         </Modal.Footer>
  //       </div>{' '}
  //     </Dropdown.Menu>
  //   </Dropdown>
  // );
};

export default Visibility;
