import React, { createContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import './Shorts.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import ShortPlayer from './components/ShortPlayer';
import { useDispatch, useSelector } from 'react-redux';
import {
  appendVideos,
  fetchShorts,
  setDefaultState,
  setLoading,
  setMuted,
  setOffset,
  setTotalResultCount,
  setVideos,
} from 'src/redux/features/videos/shortSlice';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { useAddWatchHistoryMutation } from 'src/redux/features/watch-history/watchHistoryApi';
import Loader from 'src/components/atoms/Loader';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'src/constant';

export const ShortContext = createContext(null);

const Shorts = (props) => {
  const { video_url_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  console.log(location, props, 'locatio');
  // const { channel_id = null } = location.state;
  // console.log(channel_id, 'channel_id');
  // const limit = 2;
  // const [offset, setOffset] = useState(0);
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [addWatchHistory] = useAddWatchHistoryMutation();

  const {
    limit,
    offset,
    videos,
    totalResultCount,
    isLoading,
    updateIntersectionObserver,
  } = useSelector((state) => state.shortStore);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.custom_xs}px)`,
  });

  // const [videos, setVideos] = useState(null);
  // const [isLoading, setLoading] = useState(false);
  // const [totalResultCount, setTotalResultCount] = useState(0);
  // let { isSuccess, data, isLoading } = useGetShortsQuery({
  //   limit,
  //   offset,
  //   video_url_id,
  // });

  // const setLike = (videoId) => {
  //   const index = videos.findIndex((video) => video.id === videoId);
  //   if (videos[index].user_disliked) {
  //     videos[index].user_disliked = 0;
  //     videos[index].dislike_count -= 1;
  //   }
  //   videos[index].like_count += videos[index].user_liked ? -1 : +1;
  //   videos[index].user_liked = Number(!videos[index].user_liked);
  // };

  // const setDislike = (videoId) => {
  //   const index = videos.findIndex((video) => video.id === videoId);
  //   if (videos[index].user_disliked) {
  //     videos[index].user_disliked = 0;
  //     videos[index].dislike_count -= 1;
  //   }
  //   videos[index].like_count += videos[index].user_liked ? -1 : +1;
  //   videos[index].user_liked = Number(!videos[index].user_liked);
  // };

  useEffect(() => {
    const init = async () => {
      dispatch(setLoading(true));
      try {
        const result = await dispatch(
          fetchShorts({
            limit,
            offset,
            video_url_id,
            token: authData?.token,
            channel_id: location.state?.channel_id,
          })
        ).unwrap();
        dispatch(setVideos(result.data.videos));
        dispatch(setTotalResultCount(result.data.total_result_count));
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    };
    if (!videos) init();
    return () => {
      dispatch(setDefaultState());
    };
  }, []);

  // const callback = (entries) => {
  //   entries.forEach((element) => {
  //     console.log(element, element.target.childNodes, 'nodes');
  //     let video = element.target.childNodes[1];
  //     if (element.isIntersecting) {
  //       let videoId = video.getAttribute('video_id');
  //       let videoUrlId = video.getAttribute('video_url_id');

  //       console.log(
  //         video.paused,
  //         videoId,
  //         videoUrlId,
  //         video.src,
  //         'n123 add in watch'
  //       );
  //       video
  //         .play()
  //         .then(() => {
  //           //if this video is not in viewport then pause it
  //           if (!video.paused && !element.isIntersecting) {
  //             video.pause();
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error, 'error');
  //           // Autoplay was prevented.
  //           video.muted = true;
  //           dispatch(setMuted(true));
  //           video.play();
  //         });
  //       history.push(`${Constant.BASE_URL}shorts/${videoUrlId}`);
  //       console.log('before add in watch history');
  //       if (authData?.token) {
  //         addWatchHistory(videoId);
  //       }
  //     } else {
  //       video.pause();
  //     }
  //     // // Check if the video has a valid source
  //     // if (child.src && child.src !== '') {
  //     // child.play().then(() => {
  //     //   //if this video is not in viewport then pause it
  //     //   if (!child.paused && !element.isIntersecting) {
  //     //     child.pause();
  //     //   }
  //     // });
  //     // }
  //   });
  // };

  // const observer = new IntersectionObserver(callback, {
  //   threshold: 0.9,
  // });

  // useEffect(() => {
  //   let videoElements = document.querySelectorAll('.video-container');
  //   console.log(videos?.length, videoElements.length, 'length of videos');
  //   if (!videos?.length) return;

  //   videoElements.forEach((video) => {
  //     observer.observe(video);
  //   });
  //   return () => {
  //     observer.disconnect();
  //   };
  //   // we can't use videos as dependencies because at any videos state changes it will call useEffect again and which play video if it was passed (ex - action like video).
  // }, [updateIntersectionObserver]);

  // useEffect(() => {
  // const handleScroll = (e) => {
  //   console.log('Scroll', e, e.target, e.target.childNodes);
  //   const videoElements = document.querySelectorAll('.video-container');
  //   // const scrollPosition = window.pageYOffset;

  //   // videoElements.forEach((section) => {
  //   //   const sectionTop = section.offsetTop;
  //   //   console.log(
  //   //     sectionTop,
  //   //     section.offsetHeight,
  //   //     '',
  //   //     section,
  //   //     scrollPosition
  //   //   );
  //   //   const sectionBottom = sectionTop + section.offsetHeight;

  //   //   if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
  //   //     console.log('intersecting');
  //   //     // window.history.replaceState(null, null, `#${section.id}`);
  //   //   }
  //   // });
  // };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  return (
    // <ShortContext.Provider value={{ setLike, setDislike }}>
    <>
      <Container
        fluid='xxl'
        className={`${isDesktop ? 'px-2 px-md-4' : 'mobile'}`}
        id='shorts-page'
      >
        <div className='d-flex flex-column align-items-center short-container'>
          {isLoading && (
            <Loader
              style={{
                marginTop: isDesktop ? '16px' : 'calc(16px + var(--h-navbar)',
              }}
            />
          )}
          {videos && (
            <InfiniteScroll
              height={isDesktop ? 'calc(100vh - var(--h-navbar))' : '100vh'}
              // onScroll={handleScroll}
              scrollThreshold={'1000px'}
              dataLength={videos.length}
              next={async () => {
                console.log('call next');
                const result = await dispatch(
                  fetchShorts({
                    limit,
                    offset: offset + limit,
                    video_url_id,
                    token: authData?.token,
                  })
                ).unwrap();
                dispatch(appendVideos(result.data.videos));
                dispatch(setOffset());
              }}
              hasMore={totalResultCount > videos.length}
              // loader={<Loader />}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  {/* <b>Yay! You have seen it all</b> */}
                </p>
              }
              className='overflow-hidden'
            >
              {videos?.map((short) => (
                <ShortPlayer key={short.id} item={short} />
              ))}
            </InfiniteScroll>
          )}
        </div>
      </Container>
    </>

    // </ShortContext.Provider>
  );
};

export default Shorts;
