import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { abbreviateNumber } from 'js-abbreviation-number';

import { EyeSvg, TimeSvg, WebpageSvg } from 'src/assets/svgs';
import { SORT_BY_TIME } from 'src/constant';
import {
  useFetchMyChannelQuery,
  useViewPerTimeGraphQuery,
} from 'src/redux/features/channels/channelApi';
import CustomAreaGraph from 'src/components/analytics/CustomAreaGraph';
import Circle from 'src/components/atoms/circle/Circle';
import { watchTimeCalculator } from 'src/helpers/general';
import Loader from 'src/components/atoms/Loader';

const SmallCountCard = ({ text, icon: Icon }) => {
  return (
    <div className='d-flex align-items-center'>
      <Circle size={30} style={{ padding: '6px' }} color='#FFEEF1'>
        <Icon />
      </Circle>
      <div className='ml-2 fs-300 fw-500 text-clr-dark-400'>{text}</div>
    </div>
  );
};

const GraphCard = () => {
  const { search: queryString } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(queryString);
  const sort = queryParams.get('sort');
  const [graphCategory, setGraphCategory] = useState('1');
  const [selectedDate, setSelectedDate] = useState(
    sort || SORT_BY_TIME.since_published.value
  );
  const { data: channel } = useFetchMyChannelQuery();

  const { data: graphData, isLoading } = useViewPerTimeGraphQuery({
    queryString,
  });

  return (
    <div
      className='mx-auto py-3 py-md-4 mt-4 studio-card'
      style={{
        minWidth: '600px',
      }}
    >
      <div className='d-flex justify-content-between align-items-start  flex-wrap px-4'>
        <div className='d-flex align-items-center'>
          <div className='fs-300 fw-500 text-clr-dark-400'>Statistics</div>
          <div
            className='mx-3 border border-left-0'
            style={{ borderColor: '#eff2f3', height: '30px' }}
          />
          {isLoading ? (
            <Loader size='sm' />
          ) : graphData ? (
            graphCategory === '1' ? (
              <SmallCountCard
                icon={EyeSvg}
                text={`${abbreviateNumber(
                  graphData.unique_view_count || 0,
                  1
                )} Views`}
              />
            ) : graphCategory === '2' ? (
              <SmallCountCard
                icon={TimeSvg}
                text={`${watchTimeCalculator(
                  graphData.watch_time_count
                )} Watch Time`}
                // text={`${(graphData.watch_time_count / 3600).toFixed(
                //   1
                // )}Hrs Watch Time`}
              />
            ) : (
              <SmallCountCard
                icon={WebpageSvg}
                text={`${abbreviateNumber(
                  graphData.subscriber_count || 0,
                  1
                )} Subscribers`}
              />
            )
          ) : null}{' '}
        </div>
        <div
          className='d-flex align-items-center'
          style={{ transform: 'translateY(25px)' }}
        >
          <Form.Control
            as='select'
            size='lg'
            className='mr-2'
            value={graphCategory}
            onChange={(e) => {
              const { value } = e.target;
              setGraphCategory(value);
            }}
          >
            <option value={'1'}>Views</option>
            <option value={'2'}>Watch Time</option>
            <option value={'3'}>Subscribers</option>
          </Form.Control>
          {channel && (
            <Form.Control
              as='select'
              size='lg'
              style={{ width: '200px' }}
              value={selectedDate}
              onChange={(e) => {
                const { value } = e.target;
                const queryParams = new URLSearchParams(queryString);
                queryParams.set('sort', value);
                setSelectedDate(value);
                history.push(`?${queryParams.toString()}`);
              }}
            >
              <option value={SORT_BY_TIME.since_published.value}>
                {SORT_BY_TIME.since_published.label}
              </option>
              {/* <option value={SORT_BY_TIME.first_24_hours.value}>
                {SORT_BY_TIME.first_24_hours.label}
              </option> */}
              <option value={SORT_BY_TIME.last_7days.value}>
                {SORT_BY_TIME.last_7days.label}
              </option>
              <option value={SORT_BY_TIME.last_28days.value}>
                {SORT_BY_TIME.last_28days.label}
              </option>
              <option value={SORT_BY_TIME.last_90days.value}>
                {SORT_BY_TIME.last_90days.label}
              </option>
              <option value={SORT_BY_TIME.last_365days.value}>
                {SORT_BY_TIME.last_365days.label}
              </option>
              <option value={SORT_BY_TIME.current_year.value}>
                {SORT_BY_TIME.current_year.label}
              </option>
              {new Date(SORT_BY_TIME.current_year.startDate).getFullYear() >=
                new Date(channel.created_date).getFullYear() && (
                <option value={SORT_BY_TIME.last_year.value}>
                  {SORT_BY_TIME.last_year.label}
                </option>
              )}
              <option value={SORT_BY_TIME.current_month.value}>
                {SORT_BY_TIME.current_month.label}
              </option>
              {new Date(SORT_BY_TIME.current_year.startDate) >=
                new Date(channel.created_date) && (
                <option value={SORT_BY_TIME.last_month.value}>
                  {SORT_BY_TIME.last_month.label}
                </option>
              )}
            </Form.Control>
          )}
        </div>
      </div>
      <div className='mt-5'>
        {isLoading ? (
          <Loader className='mt-4' />
        ) : graphData ? (
          graphCategory === '1' ? (
            <CustomAreaGraph
              data={graphData.view_results}
              yLabel='View Count'
            />
          ) : graphCategory === '2' ? (
            <CustomAreaGraph
              data={graphData.watch_time_results}
              yAxisType='second'
            />
          ) : (
            <CustomAreaGraph
              data={graphData.subscriber_results}
              yLabel='Subscriber Count'
            />
          )
        ) : null}
      </div>
    </div>
  );
};

export default GraphCard;
