import axios from 'axios';
import { toast } from 'react-toastify';
import config from 'src/config';

export const pinFileToIPFS = async (
  selectedFile,
  setProgress = () => {},
  success = () => {},
  failure = () => {}
) => {
  if (!config.enabled_pinata)
    return { IpfsHash: 'default', isDuplicate: false };

  const pinataUrl = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

  // initialize the form data
  const formData = new FormData();

  // the endpoint needed to upload the file
  try {
    if (typeof selectedFile === 'string') {
      const { data: fileContent } = await axios.get(
        'https://io.pixelsoftwares.com:4455/drive/video_thumbnails/thumbnail-1703766364312-2.jpeg',
        {
          responseType: 'arraybuffer', // Make sure to set the response type to 'arraybuffer'
        }
      );
      // Create a Blob from the file content
      const blob = new Blob([fileContent]);
      // append the file form data to
      formData.append('file', blob);
    } else {
      formData.append('file', selectedFile);
    }

    const { data } = await axios.post(pinataUrl, formData, {
      maxContentLength: 'Infinity',
      headers: {
        'Content-Type': `multipart/form-data;boundary=${formData._boundary}`,
        pinata_api_key: config.PINATA_API_KEY,
        pinata_secret_api_key: config.PINATA_API_SECRET,
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(percentCompleted, 'percent');
        // callback(percentCompleted);
        setProgress(percentCompleted);
        if (percentCompleted == 100) setProgress(null);
      },
    });
    const { IpfsHash, isDuplicate } = data;
    return { IpfsHash, isDuplicate };
  } catch (err) {
    console.log(err);
    toast.error('Fail to upload file in IPfS');
    throw err;
  }
};

export const pinJSONToIPFS = async (
  json,
  setProgress = () => {},
  success = () => {},
  failure = () => {}
) => {
  if (!config.enabled_pinata)
    return { IpfsHash: 'default', isDuplicate: false };

  const pinataUrl = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
  try {
    const { data } = await axios({
      url: pinataUrl,
      method: 'post',
      headers: {
        pinata_api_key: config.PINATA_API_KEY,
        pinata_secret_api_key: config.PINATA_API_SECRET,
      },
      data: JSON.stringify(json),
    });
    console.log(data, 'unpin file data');
    console.log('Unpinned file from IPFS', data);
    return data;
  } catch (err) {
    console.log(err, 'error');
    toast.error('Fail to upload json in IPfS');
    throw err;
  }
};
