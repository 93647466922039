import React from 'react';
import { Route } from 'react-router-dom';
import Constant from '../constant';
import { useAuthCheckQuery } from '../redux/features/auth/authApi';
import { useEffect } from 'react';
import { isUserDetail } from './ProtectedRoute';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from 'src/components/atoms/Loader';

const PublicRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const { data: authData, isLoading } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });

  useEffect(() => {
    if (authData && !isUserDetail(authData.user)) {
      history.push(`${Constant.BASE_URL}profile`);
    }
  }, [authData, history]);

  if (isLoading) {
    // Render a loading state or a placeholder component
    return <Loader className='pt-4' />;
  }

  return <Route {...rest} render={(props) => children} />;
};

export default PublicRoute;
