import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router } from 'react-router-dom';
import shaka from 'shaka-player';

import './theme/utilities.css';
import './theme/root-variables.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import config from './config';

import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

import { WagmiProvider } from 'wagmi';
// import { arbitrum, mainnet } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Install Shaka Player polyfills
shaka.polyfill.installAll();

// document.addEventListener('shaka-ui-loaded', () => {
//   console.log('index.js Shaka UI loaded!');
// });

const chains = [config.ACTIVE_CHAIN];
const projectId = config.WALLET_CONNECT_PROJECT_ID;

// 0. Setup queryClient
const queryClient = new QueryClient();

// 2. Create wagmiConfig
const metadata = {
  name: 'Mirror - Decentralized Video Sharing Platform',
  description:
    'Experience a decentralized video sharing platform like never before. Mirrror empowers creators and viewers with a truly open, secure, and censorship-resistant environment for sharing and discovering content. Join the revolution today!',
  url: config.project_domain_link, // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  // ...wagmiOptions, // Optional - Override createConfig parameters
});

// 3. Create modal
createWeb3Modal({
  defaultChain: config.ACTIVE_CHAIN,
  wagmiConfig,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});

// Check if the browser supports Shaka Player
if (!shaka.Player.isBrowserSupported()) {
  alert(
    'Shaka Player - Your browser is not supported. Please use a modern browser.'
  );
  console.error(
    'Shaka Player - Your browser is not supported. Please use a modern browser.'
  );
} else {
root.render(
  <React.StrictMode>
<WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
</QueryClientProvider>
      </WagmiProvider>
  </React.StrictMode>
);
// ReactDOM.render(
  //   <React.StrictMode>
  //     <Provider store={store}>
  //       <Router>
  //         <App />
  //       </Router>
  //     </Provider>
  //   </React.StrictMode>,
  //   document.getElementById('root')
  // );
}

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <Router>
//         <App />
//       </Router>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
