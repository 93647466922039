import { useState } from 'react';
import { Media, Button } from 'react-bootstrap';
import moment from 'moment';
import { Link, useHistory, useParams } from 'react-router-dom';
import UilThumbsDown from '@iconscout/react-unicons/icons/uil-thumbs-down';
import { toast } from 'react-toastify';
import UilThumbsUp from '@iconscout/react-unicons/icons/uil-thumbs-up';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAccount, useWriteContract, useSwitchChain, usePublicClient } from 'wagmi';
import { decodeEventLog } from 'viem';
import { abbreviateNumber } from 'js-abbreviation-number';

import Constant, { COLORS } from 'src/constant';
import {
  useCreateCommentMutation,
  useFetchRepliesOfCommentQuery,
  useLikeDislikeCommentMutation,
  useUpdateCommentMutation,
} from 'src/redux/features/comments/commentApi';
import { useAuthCheckQuery } from 'src/redux/features/auth/authApi';
import { useFetchMyChannelQuery } from 'src/redux/features/channels/channelApi';
import Avatar from 'src/components/atoms/Avatar';
import ShowMoreButton from 'src/components/atoms/ShowMoreButton/ShowMoreButton';
import { useFetchVideoByIdQuery } from 'src/redux/features/videos/videoApi';
import { useSaveBlockTransactionMutation } from 'src/redux/features/basic/basicApi';
import handleError from 'src/utils/handle-error';
import config from 'src/config';
import { fetchFeeData } from 'src/utils/gas-price';
import messages from 'src/constant/messages';
import useFindContractData from 'src/hooks/useFindContractData';
import CommentInputBox from 'src/components/atoms/inputs/CommentInputBox';
import { useDispatch } from 'react-redux';
import rootApi from 'src/redux/rootApi';
import { useActiveChainId } from 'src/hooks';

function CommentMedia({ commentData, className = '', limit, offset, sort }) {
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const history = useHistory();
  const { abi, contract_address } = useFindContractData();
  const { waitForTransactionReceipt } = usePublicClient();

  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [comment, setComment] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [createComment] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });

  const [likeDislikeComment] = useLikeDislikeCommentMutation();

  const { data: channel } = useFetchMyChannelQuery(undefined, {
    skip: !(authData?.token || !!localStorage.getItem('accessToken')),
  });

  const {
    writeContractAsync: addReplyContract,
    isLoading: addReplyContractIsLoading,
  } = useWriteContract();

  const dispatch = useDispatch();

  const handleCommit = async () => {
    setDisabled(true);
    // Check user can make comment or not
    if (!authData?.token) return toast.info(messages.require_signin);
    if (!(authData?.user?.full_name || channel?.handle_name)) {
      toast.info('Update your profile first.');
      return history.push(`${Constant.BASE_URL}profile`);
    }
    if (activeChainId !== config.ACTIVE_CHAIN.id) {
      await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
    }
    try {
      // Save in blockchain
      // if reply by comment box then we will got comment data so use contract_comment_id but if
      // reply by reply box then we will get reply data then use contract_parent_comment_id because blockchain function takes comment contract id
      const contractParentCommentId =
        typeof commentData.contract_parent_comment_id === 'number' &&
        commentData.contract_parent_comment_id >= 0
          ? commentData.contract_parent_comment_id
          : commentData.contract_comment_id;

      const args = [
        commentData.contract_video_id,
        contractParentCommentId,
        comment,
      ];
      // const { gasPrice } = await fetchFeeData();
      const hash = await addReplyContract({
        args,
        address: contract_address,
        abi: JSON.parse(abi),
        functionName: 'addReply',
        chainId: config.ACTIVE_CHAIN.id,
        // gasPrice,
      });

      // Save data in DB
      const body = {
        text: comment,
        // contract_comment_id: parseInt(decoded.args.replyId),
        parent_comment_id: commentData.parent_comment_id || commentData.id,
        hash,
      };
      if (commentData.user_id !== authData.user.id)
        body.mentioned_user_id = commentData.user_id;

      const { data: commentResponse } = await createComment({
        videoId: commentData.video_id,
        data: body,
        limit,
        offset,
        sort,
      }).unwrap();
      setComment('');

      // const block_transaction_data = {
      //   action: 'addReply',
      //   address_out: account.address,
      //   address_in: contract_address,
      //   hash,
      //   network_type: 5,
      //   // other_detail: stringifyWithBigInt(res),
      //   request: JSON.stringify(args),
      // };
      // saveBlock({ body: block_transaction_data });

      const res = await waitForTransactionReceipt({ hash });
      const decoded = decodeEventLog({
        abi: JSON.parse(abi),
        data: res.logs[0].data,
        topics: res.logs[0].topics,
      });

      // update comment hash_status
      const response = await updateComment({
        id: commentResponse.comment.id,
        data: {
          contract_comment_id: parseInt(decoded.args.replyId),
        },
        parentCommentId: commentData.parent_comment_id,
      }).unwrap();

      if (!commentData?.parent_comment_id) {
        dispatch(
          rootApi.util.updateQueryData(
            'fetchOneVideoComments',
            { videoId: commentData.video_id, limit, offset, sort },
            (draft) => {
              const index = draft.comments.findIndex((comment) => {
                return comment.id === commentData.id;
              });
              draft.comments[index].has_replies = 1;
              draft.comment_count += 1;
            }
          )
        );
      }

      // update replies cache
      dispatch(
        rootApi.util.updateQueryData(
          'fetchRepliesOfComment',
          commentData.parent_comment_id || commentData.id,
          (draft) => {
            console.log('incide reply root');
            draft.push(response.data);
            return draft;
          }
        )
      );
      toast.success(response.message || 'Successfully commented!');
      setDisabled(false);
      setShowReplyInput(false);
    } catch (error) {
      handleError(error);
      setDisabled(false);
    }
  };

  const channelHref = `${Constant.BASE_URL}channel/${commentData.handle_name}`;
  return (
    <>
      <div className={`reviews-members ${className}`}>
        <Media>
          <Link to={channelHref} className='avatar-div'>
            {commentData.avatar_url ? (
              <img
                className='img-fluid'
                src={commentData.avatar_url}
                alt='Avatar'
              />
            ) : (
              <Avatar
                size={40}
                character={
                  commentData?.channel_name[0] || commentData?.user_name[0]
                }
              />
            )}
          </Link>
          <Media.Body>
            <div className='media-body'>
              <div className='reviews-members-header'>
                <h6 className='mb-1'>
                  <Link
                    className='fs-300 fw-600 text-clr-dark-300'
                    to={channelHref}
                  >
                    {commentData?.handle_name || commentData?.user_name}{' '}
                  </Link>{' '}
                  <small className='text-gray'>
                    {moment(commentData?.created_date).fromNow()}
                  </small>
                </h6>
              </div>
              <div className='reviews-members-body'>
                {commentData.mentioned_username && (
                  <div className='mention'>
                    {commentData.mentioned_username}
                  </div>
                )}
                <div>
                  {commentData.text && (
                    <>
                      <p>
                        {showMore
                          ? commentData.text
                          : commentData.text.length > 200
                          ? `${commentData.text.slice(0, 200)}....`
                          : commentData.text}
                      </p>
                      {commentData.text.length > 200 && (
                        <ShowMoreButton
                          showMore={showMore}
                          handleChange={() => setShowMore(!showMore)}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='reviews-members-footer'>
                <OverlayTrigger
                  placement='top'
                  overlay={(props) => <Tooltip {...props}>Like</Tooltip>}
                >
                  <div className='d-flex align-items-center  mr-2'>
                    <Button
                      variant='light'
                      className='btn-rounded'
                      onClick={() => {
                        if (!authData?.token)
                          return toast.info(messages.require_signin);
                        likeDislikeComment({
                          commentId: commentData.id,
                          videoId: commentData.video_id, // not for api for onQueryStared fn
                          parentCommentId:
                            commentData.parent_comment_id || commentData.id, // not for api
                          data: { like_dislike: 'like' },
                        });
                      }}
                    >
                      <UilThumbsUp
                        size='20'
                        color={
                          commentData.user_liked
                            ? COLORS.primary400
                            : COLORS.dark300
                        }
                      />
                    </Button>
                    <span className='ml-1 align-middle'>
                      {abbreviateNumber(commentData.like_count, 1)}
                    </span>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement='top'
                  overlay={(props) => <Tooltip {...props}>Dislike</Tooltip>}
                >
                  <Button
                    variant='light'
                    className='btn-rounded ml-2'
                    onClick={() => {
                      if (!authData?.token)
                        return toast.info(messages.require_signin);
                      likeDislikeComment({
                        commentId: commentData.id,
                        videoId: commentData.video_id,
                        parentCommentId:
                          commentData.parent_comment_id || commentData.id,
                        data: { like_dislike: 'dislike' },
                      });
                    }}
                  >
                    <UilThumbsDown
                      size='20'
                      color={
                        commentData.user_disliked
                          ? COLORS.primary400
                          : COLORS.dark300
                      }
                    />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='top'
                  overlay={(props) => <Tooltip {...props}>Reply</Tooltip>}
                >
                  <Button
                    variant='light'
                    className='fs-300 fw-500 ml-2'
                    onClick={() => {
                      if (!authData?.token)
                        return toast.info(messages.require_signin);
                      if (commentData.hash_status === 2)
                        return toast.info(
                          'Transaction not confirmed yet, please wait for confirmation.'
                        );
                      // ------
                      setShowReplyInput(true);
                    }}
                  >
                    Reply
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
            {/* reply input */}
            {showReplyInput && (
              <div className='mt-4'>
                <div className='reviews-members pt-0'>
                  <Media>
                    <div className='avatar-div'>
                      {channel?.avatar_url ? (
                        <img
                          src={channel?.avatar_url}
                          className='img-fluid'
                          alt='Avatar'
                        />
                      ) : (
                        <Avatar
                          size={40}
                          character={
                            commentData?.channel_name[0] ||
                            commentData?.user_name[0]
                          }
                        />
                      )}
                    </div>
                    <Media.Body>
                      <CommentInputBox
                        rows={1}
                        value={comment}
                        inputDisabled={disabled}
                        cancelBtnDisabled={disabled}
                        submitBtnDisabled={!comment || disabled}
                        onChange={(e) => setComment(e.target.value)}
                        onFocus={() => {
                          if (!authData?.token)
                            return toast.info(messages.require_signin);
                        }}
                        onCancel={() => {
                          setComment('');
                          setShowReplyInput(false);
                        }}
                        onSubmit={handleCommit}
                        buttonText={
                          addReplyContractIsLoading
                            ? messages.waiting_wallet_confirmation
                            : disabled
                            ? 'Processing...'
                            : 'Comment'
                        }
                      />
                    </Media.Body>
                  </Media>
                </div>
              </div>
            )}
            {!!commentData.has_replies && (
              <OverlayTrigger
                placement='top'
                overlay={(props) => <Tooltip {...props}>Reply</Tooltip>}
              >
                <Button
                  variant='light'
                  className='fs-300 fw-500 ml-2 mt-2'
                  onClick={() => setShowReplies(!showReplies)}
                >
                  Replies
                </Button>
              </OverlayTrigger>
            )}

            {/* replies  */}
            {showReplies && (
              <Replies
                // if has replies then it should be comment not reply
                commentId={commentData.id}
              />
            )}
          </Media.Body>
        </Media>
      </div>
    </>
  );
}

const Replies = ({ commentId }) => {
  const { data: replies, isLoading } = useFetchRepliesOfCommentQuery(commentId);
  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {replies?.map((reply) => (
        <CommentMedia key={`reply-${reply.id}`} commentData={reply} />
      ))}
    </div>
  );
};

const CustomMedia = ({ contractVideoId, limit, offset, sort }) => {
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const { video_url_id } = useParams();
  const account = useAccount();
  const { abi, contract_address } = useFindContractData();
  const { waitForTransactionReceipt } = usePublicClient();

  const [saveBlock] = useSaveBlockTransactionMutation();

  const { data: videoData } = useFetchVideoByIdQuery(video_url_id);

  const history = useHistory();
  const [comment, setComment] = useState('');
  const [disabled, setDisabled] = useState(false);

  const [createComment] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();

  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const { data: channel } = useFetchMyChannelQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });

  const {
    writeContractAsync: addCommentContract,
    isLoading: addCommentContractLoading,
  } = useWriteContract();

  const dispatch = useDispatch();

  const handleCommit = async () => {
    setDisabled(true);
    // Check user can make comment or not
    if (!authData?.token) return toast.info(messages.require_signin);
    if (!(authData?.user?.full_name || channel?.handle_name)) {
      toast.info('Update your profile first.');
      return history.push(`${Constant.BASE_URL}profile`);
    }
    if (activeChainId !== config.ACTIVE_CHAIN.id) {
      await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
    }

    try {
      const args = [contractVideoId, comment];
      // Save to blockchain
      const hash = await addCommentContract({
        args,
        address: contract_address,
        abi: JSON.parse(abi),
        functionName: 'addComment',
        chainId: config.ACTIVE_CHAIN.id,
      });

      // Save to DB
      const body = {
        text: comment,
        // contract_comment_id: parseInt(decoded.args.commentId),
        hash,
      };
      const { data: commentResponse } = await createComment({
        videoId: videoData?.id,
        data: body,
        limit,
        offset,
        sort,
      }).unwrap();

      // const block_transaction_data = {
      //   action: 'addComment',
      //   address_out: account.address,
      //   address_in: contract_address,
      //   hash,
      //   network_type: NETWORK_TYPE.MATIC,
      //   request: JSON.stringify(args),
      //   // other_detail: stringifyWithBigInt(receipt),
      //   hash_verify: 1,
      // };
      // saveBlock({ body: block_transaction_data });

      const receipt = await waitForTransactionReceipt({ hash });
      const decoded = decodeEventLog({
        abi: JSON.parse(abi),
        data: receipt.logs[0].data,
        topics: receipt.logs[0].topics,
      });

      // update comment hash_status
      const response = await updateComment({
        id: commentResponse.comment.id,
        data: {
          contract_comment_id: parseInt(decoded.args.commentId),
        },
        videoId: videoData.id,
        limit,
        offset,
        sort,
      }).unwrap();

      // append comment in cache
      dispatch(
        rootApi.util.updateQueryData(
          'fetchOneVideoComments',
          { videoId: videoData.id, limit, offset, sort },
          (draft) => {
            draft.comments.unshift(response.data);
            draft.comment_count += 1;
          }
        )
      );
      setComment('');
      toast.success(response.message);
      setDisabled(false);
    } catch (error) {
      handleError(error);
      setDisabled(false);
    }
  };

  return (
    <>
      <div className='reviews-members pt-0'>
        <Media>
          <div
            className={`avatar-div ${channel ? 'cursor-pointer' : ''}`}
            onClick={() => {
              if (channel)
                history.push(
                  `${Constant.BASE_URL}channel/${channel.handle_name}`
                );
            }}
          >
            {channel?.avatar_url ? (
              <img
                className='img-fluid'
                src={channel?.avatar_url}
                alt='Avatar'
              />
            ) : (
              <Avatar
                size={40}
                character={
                  channel?.name[0] || authData?.user?.firstname[0] || 'G'
                }
              />
            )}
          </div>
          <Media.Body>
            <CommentInputBox
              value={comment}
              inputDisabled={disabled}
              cancelBtnDisabled={disabled}
              submitBtnDisabled={
                !comment || disabled || addCommentContractLoading
              }
              onChange={(e) => setComment(e.target.value)}
              onFocus={() => {
                if (!authData?.token)
                  return toast.info(messages.require_signin);
              }}
              onCancel={() => {
                setComment('');
              }}
              onSubmit={handleCommit}
              buttonText={
                addCommentContractLoading
                  ? messages.waiting_wallet_confirmation
                  : disabled
                  ? 'Processing...'
                  : 'Comment'
              }
            />
          </Media.Body>
        </Media>
      </div>
    </>
  );
};

// CommentMedia.propTypes = {
//   commentData: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     parent_comment_id: PropTypes.number,
//     contract_parent_comment_id: PropTypes.number,
//     video_id: PropTypes.number.isRequired,
//     contract_video_id: PropTypes.number.isRequired,
//     user_id: PropTypes.number.isRequired,
//     created_date: PropTypes.string.isRequired,
//     like_count: PropTypes.number.isRequired,
//     dislike_count: PropTypes.number.isRequired,
//     text: PropTypes.string.isRequired,
//     has_replies: PropTypes.number.isRequired,
//     user_liked: PropTypes.number.isRequired,
//     user_disliked: PropTypes.number.isRequired,
//     mentioned_username: PropTypes.string.isRequired,
//     avatar_url: PropTypes.string.isRequired,
//     user_name: PropTypes.string,
//     handle_name: PropTypes.string,
//   }).isRequired,
// };
export default CommentMedia;
export { CustomMedia, CommentMedia };
