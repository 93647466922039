import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import './Content.css';
import VideosContent from './VideosContent';
import { ThinFooter } from 'src/components/footer';

const Content = () => {
  const [key, setKey] = useState('videos');

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4' id='studio-content-page'>
        <Row className='mx-0'>
          <Col md={12} className='mx-auto p-0'>
            <h3 className='mb-3 fs-500 fw-600'>Content</h3>
          </Col>
          <Col md={12} className='p-0 mx-auto py-3 py-md-4 studio-card'>
            <Tabs
              id='controlled-tab-example'
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className='px-3 px-md-3 custom-tabs'
            >
              <Tab eventKey='videos' title='Videos'>
                {/* filter button */}
                {/* <div className='filter'>
                    <img alt='filter' src={themeImageUrlParser('filter.svg')} />
                    <h6 className='fs-400 fw-500 ml-2'>Filter</h6>
                  </div> */}
                <VideosContent />
              </Tab>
              {/* <Tab eventKey='playlist' title='Playlist'>
                  <h1>Video</h1>
                </Tab>
                <Tab eventKey='podcast' title='Podcast'>
                  <h1>Video</h1>
                </Tab> */}
            </Tabs>
          </Col>
        </Row>
      </Container>
      <ThinFooter />
    </>
  );
};

export default Content;
