import React from 'react';

const ShowMoreButton = ({
  showMore,
  handleChange,
  className = '',
  style = {},
}) => {
  return (
    <div
      className={`more-less fw-600 fs-300 cursor-pointer ${className}`}
      style={style}
      onClick={handleChange}
    >
      {showMore ? 'Less' : 'More'}
    </div>
  );
};

export default ShowMoreButton;
