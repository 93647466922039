import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import './modal.css';
import { useAccount, useWriteContract, useSignMessage } from 'wagmi';
import { useLoginMutation } from '../../../redux/features/users/usersApi';
import { HeadingWrapper } from '../SectionHeader/SectionHeader';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import useFindContractData from 'src/hooks/useFindContractData';
import config from 'src/config';
import { getNonce } from 'src/redux/features/auth/authSlice';
import { useDispatch } from 'react-redux';
import rootApi from 'src/redux/rootApi';
import { toast } from 'react-toastify';

const userValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required.'),
  lastname: Yup.string().required('Last name is required.'),
  phone: Yup.string().length(10).required('Phone number is required.'),
  email: Yup.string().email().required('Email address is required.'),
});

const initialValues = {
  firstname: '',
  lastname: '',
  phone: '',
  email: '',
};

const RegisterModal = ({ show, handleClose, handleShow }) => {
  const account = useAccount();
  const dispatch = useDispatch();
  const history = useHistory();
  const { signMessageAsync } = useSignMessage();
  const [login] = useLoginMutation();

  const { abi, contract_address } = useFindContractData();

  const { writeContractAsync } = useWriteContract();

  //   const [login, { isError, isSuccess, error, data }] = useLoginMutation();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={true}
        keyboard={false}
        id='save-modal'
      >
        <Modal.Header closeButton>
          <HeadingWrapper className='pl-3 mt-2' heading='Register' />
        </Modal.Header>
        <Modal.Body>
          <div style={{ overflow: 'auto' }}>
            <Container fluid='xl' className='upload-details px-3 pb-3'>
              {/* <Row>
                <Col className='mb-4'>
                  <HeadingWrapper className='ml-0 text-center mx-auto' heading='Sign Up' />
                </Col>
              </Row> */}
              <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                  const data = { ...values, block_address: account.address };
                  try {
                    const hash = await writeContractAsync({
                      address: contract_address,
                      abi: JSON.parse(abi),
                      functionName: 'join',
                      chainId: config.ACTIVE_CHAIN.id,
                    });
                    data.txn_hash = hash;

                    // fetch nonce
                    const {
                      data: { nonceToken, message },
                    } = await dispatch(getNonce(account.address)).unwrap();

                    // generate signature
                    const signature = await signMessageAsync({
                      message: message,
                    });
                    data.signature = signature;
                    data.nonceToken = nonceToken;

                    const response = await login(data).unwrap();
                    const { token: accessToken } = response.data;
                    dispatch(rootApi.util.resetApiState());
                    localStorage.setItem('block_address', account.address);
                    localStorage.setItem('accessToken', accessToken);
                    toast.success(
                      response.data.message || 'Successfully Logged In.'
                    );
                  } catch (error) {
                    console.log(error);
                    toast.error(error.message);
                  }
                }}
                enableReinitialize
                validationSchema={userValidationSchema}
              >
                {({
                  handleBlur,
                  handleChange,
                  errors,
                  values,
                  touched,
                  handleSubmit,
                  isValid,
                  dirty,
                  resetForm,
                  isSubmitting,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group as={Col} md={6} controlId='first-name'>
                        <Form.Label>
                          First Name
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter your first name'
                          required
                          size='lg'
                          name='firstname'
                          value={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.firstname && !!errors.firstname}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.firstname}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md={6} controlId='last-name'>
                        <Form.Label>
                          Last Name
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter your last name'
                          required
                          size='lg'
                          name='lastname'
                          value={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.lastname && !!errors.lastname}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.lastname}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md={6} controlId='phone-number'>
                        <Form.Label>
                          Phone
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='number'
                          min={0}
                          placeholder='xxxxxxxxxx'
                          required
                          size='lg'
                          name='phone'
                          value={values.phone}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const value = inputValue.slice(0, 10);
                            e.target.value = value;
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          isInvalid={touched.phone && !!errors.phone}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md={6} controlId='email'>
                        <Form.Label>
                          Email Address
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='email'
                          placeholder='example@example.com'
                          size='lg'
                          name='email'
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId='address'>
                        <Form.Label>
                          Block Address
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          required
                          size='lg'
                          placeholder='0xxxxxxxxxxxxxxxxxxxxx'
                          name='block_address'
                          value={account.address}
                          disabled
                        />
                      </Form.Group>
                    </Form.Row>
                    <Row>
                      <Col className='text-center text-sm-right'>
                        <Button
                          variant='primary'
                          disabled={isSubmitting}
                          type='submit'
                        >
                          Register
                        </Button>
                      </Col>{' '}
                    </Row>
                  </Form>
                )}
              </Formik>
            </Container>{' '}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// export default React.memo(LoginModal);
export default RegisterModal;
