import moment from 'moment';

const calculateDuration = (duration) => {
  if (duration <= 0) return null;
  const full_duration = moment.utc(duration * 1000).format('HH:mm:ss');
  const display_duration =
    full_duration.substring(0, 2) === '00'
      ? full_duration.substring(3)
      : full_duration;
  return display_duration;
};

export default calculateDuration;
