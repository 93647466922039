import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  InputGroup,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import UilCopy from '@iconscout/react-unicons/icons/uil-copy';
import UilCheck from '@iconscout/react-unicons/icons/uil-check-circle';

import { HeadingWrapper } from 'src/components/atoms/SectionHeader/SectionHeader';
import {
  useFetchUserDataQuery,
  useUpdateUserDataMutation,
} from 'src/redux/features/users/usersApi';
import { isUserDetail } from 'src/routes/ProtectedRoute';
import Constant, { COLORS } from 'src/constant';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';
import {
  useSendMailForEmailVerificationMutation,
  useSendOtpForPhoneVerificationMutation,
} from 'src/redux/features/auth/authApi';
import { VerifiedTooltip } from 'src/components/atoms/CustomCheckTooltips/CustomCheckTooltips';
import OtpVerifyModal from 'src/components/atoms/modals/OtpVerifyModal';
import CopyToClipboard from 'react-copy-to-clipboard';

const phoneRegex = /(\+91\ )[6-9]{1}[0-9 ]{4}[0-9 ]{4}[0-9]{3}/;

const userValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required.'),
  lastname: Yup.string().required('Last name is required.'),
  phone: Yup.string().length(10).label('Phone number'),
  // phone: Yup.string()
  //   .length(10)
  //   // regexr.com/6anqd
  //   .matches(phoneRegex, {
  //     message: 'Invalid Indian number',
  //     excludeEmptyString: false,
  //   }),
  email: Yup.string().email(),
  block_address: Yup.string().required('Block address is required.'),
});

const Settings = () => {
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    block_address: '',
  });
  const [openOtpModal, setOpenOtpModal] = useState(false);

  const { isFetching, data: userData } = useFetchUserDataQuery();
  const [updateUser, { isLoading }] = useUpdateUserDataMutation();
  const [sendMailForEmailVerification, sendMailForEmailVerificationOptions] =
    useSendMailForEmailVerificationMutation();

  const [sendOtp, sendOtpOptions] = useSendOtpForPhoneVerificationMutation();

  useEffect(() => {
    if (
      sendMailForEmailVerificationOptions.isSuccess &&
      sendMailForEmailVerificationOptions.data
    ) {
      console.log(sendMailForEmailVerificationOptions.data.message, 'data');
      toast.success(sendMailForEmailVerificationOptions.data.message);
    }
  }, [
    sendMailForEmailVerificationOptions.isSuccess,
    sendMailForEmailVerificationOptions.data,
  ]);

  useEffect(() => {
    if (userData) {
      setInitialValues({
        firstname: userData?.firstname,
        lastname: userData?.lastname,
        phone: userData?.phone,
        email: userData?.email,
        block_address: userData?.block_address,
      });
    }
  }, [userData]);

  const [copied, setCopied] = useState(false);

  const handlePhoneVerify = async (phone) => {
    try {
      const response = await sendOtp(phone.toString()).unwrap();
      setOpenOtpModal(phone.toString());
      toast.success(response.message);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  if (isFetching) return <Loader className='mt-4' />;

  return (
    <>
      <OtpVerifyModal
        show={openOtpModal}
        handleClose={() => setOpenOtpModal(false)}
      />
      <Container fluid='xl' className='upload-details p-4'>
        {!isUserDetail(userData) && (
          <Alert variant='info'>
            Kindly provide the required information as we move forward.
          </Alert>
        )}
        <Row>
          <Col className='mb-4'>
            <HeadingWrapper heading='Profile details' className='h1 mt-2' />
          </Col>
        </Row>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            const body = { ...values };
            delete body.block_address;
            const data = await updateUser({
              ...body,
              phone: values.phone.toString(),
            }).unwrap();
            toast.success(data?.message || 'Successfully Updated.');
            setEdit(false);
            history.push(`${Constant.BASE_URL}`);
          }}
          enableReinitialize
          validationSchema={userValidationSchema}
        >
          {({
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
            handleSubmit,
            isValid,
            dirty,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col} md={6} controlId='first-name'>
                  <Form.Label>
                    First Name
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter first name'
                    size='lg'
                    name='firstname'
                    disabled={!edit}
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.firstname && !!errors.firstname}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.firstname}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group md={6} as={Col} controlId='last-name'>
                  <Form.Label>
                    Last Name
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter last name'
                    disabled={!edit}
                    size='lg'
                    name='lastname'
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.lastname && !!errors.lastname}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.lastname}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId='phone-number'>
                  <Form.Label>Phone Number</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type='number'
                      placeholder='Enter phone number'
                      size='lg'
                      disabled={!edit}
                      name='phone'
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.phone && !!errors.phone}
                    />
                    <InputGroup.Prepend>
                      {userData?.phone_status === 1 ? (
                        <Button
                          variant='success'
                          className='ml-2'
                          style={{ borderRadius: '5px' }}
                        >
                          <VerifiedTooltip color='white' />
                        </Button>
                      ) : (
                        <Button
                          style={{ borderRadius: '5px' }}
                          className='ml-2'
                          variant='primary'
                          disabled={!edit || sendOtpOptions.isLoading}
                          onClick={() => handlePhoneVerify(values.phone)}
                        >
                          Verify
                        </Button>
                      )}
                    </InputGroup.Prepend>
                    <Form.Control.Feedback type='invalid'>
                      {errors.phone}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group as={Col} md={6} controlId='email'>
                  <Form.Label>Email Address</Form.Label>
                  <InputGroup className='position-relative'>
                    <Form.Control
                      type='email'
                      placeholder='Enter email address'
                      size='lg'
                      name='email'
                      disabled={!edit}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <InputGroup.Prepend className=''>
                      {userData.email_status === 1 ? (
                        <Button
                          variant='success'
                          className='ml-2'
                          style={{ borderRadius: '5px' }}
                        >
                          <VerifiedTooltip color='white' />
                        </Button>
                      ) : (
                        <Button
                          className='ml-2'
                          style={{ borderRadius: '5px' }}
                          variant='primary'
                          disabled={
                            !edit ||
                            sendMailForEmailVerificationOptions.isLoading
                          }
                          onClick={() =>
                            sendMailForEmailVerification(values.email)
                          }
                        >
                          Verify
                        </Button>
                      )}
                    </InputGroup.Prepend>

                    <Form.Control.Feedback type='invalid'>
                      {errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId='address'>
                  <Form.Label>
                    Wallet Address
                    {/* <span className='text-danger'>*</span> */}
                  </Form.Label>
                  <div className='d-flex gap-2 align-items-center'>
                    <span className='mr-1'>{values.block_address}</span>
                    <div className='cursor-pointer'>
                      <CopyToClipboard
                        text={values.block_address}
                        onCopy={() => {
                          setCopied(true);
                          setTimeout(() => {
                            setCopied(false);
                          }, 2000);
                        }}
                      >
                        {copied ? (
                          <UilCheck color={COLORS.primary400} size='20' />
                        ) : (
                          <UilCopy color={COLORS.primary400} size='20' />
                        )}
                      </CopyToClipboard>
                    </div>
                  </div>
                </Form.Group>
              </Row>

              <div
                className='mt-2'
                // className='text-center text-sm-right'
              >
                {edit ? (
                  <>
                    {/* <Button
                        variant='light'
                        className='mr-4'
                        disabled={isLoading || !dirty}
                        onClick={resetForm}
                      >
                        Undo Changes
                      </Button> */}
                    <Button
                      variant='primary'
                      size='lg'
                      // disabled={!isValid || isLoading || !dirty}
                      disabled={isLoading || !dirty}
                      type='submit'
                    >
                      Save Changes
                    </Button>
                  </>
                ) : (
                  <Button
                    size='lg'
                    variant='primary'
                    onClick={() => setEdit(true)}
                  >
                    Edit Profile
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Container>
      <ThinFooter />
    </>
  );
};

export default Settings;
