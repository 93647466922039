import react from 'react'
import Constant, { COLORS } from '../../../constant';
import './ShortButton.css';
export function SampleNextArrow(props) {
    const slidesToShow = props.slidesToShow;
    // Make sure to get the correct number of slides to show
    if (props.currentSlide >= props.totalSlides - slidesToShow) {
      return null;
    }
    return (
      <button
        className='custom-slick-btn customShorts-slick-next d-none d-md-block'
        onClick={props.onClick}
      >
        <img alt='right-arrow' src={`${Constant.BASE_URL}img/right.svg`} />
      </button>
    );
  }

   export function SamplePrevArrow(props) {
    // Check if it's the first visible slide, and hide the previous arrow
    if (props.currentSlide <= 0) {
      return null;
    }
    return (
      <button
        className='custom-slick-btn customShorts-slick-prev d-none d-md-block'
        onClick={props.onClick}
      >
        <img src={`${Constant.BASE_URL}img/left.svg`} />
      </button>
    );
  }
