import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap';

import CustomPaginate from 'src/components/atoms/Paginate/CustomPaginate';
import Constant, {
  CONTRACT_SYMBOL,
  DATE_FORMAT,
  PAYMENT_TYPE,
  PROFIT_TYPE,
  TIME_FORMAT,
} from 'src/constant';
import {
  useGetMyAccountsQuery,
  useRewardMetricsQuery,
} from 'src/redux/features/reward/rewardApi';
import './Reward.css';
import { RewardsSvg } from 'src/assets/svgs/profile-dropdown';
import { DBSvg, DisabledLogo, SubscribeSvg } from 'src/assets/svgs';
import RedeemModal from './components/RedeemModal';
import config from 'src/config';
import { ACCOUNTS_STATUS } from '../../constant';
import { formatBigIntDecimal } from 'src/helpers/general';
import NoContent from 'src/components/atoms/NoContent/NoContent';
import useFindContractData from 'src/hooks/useFindContractData';
import { ThinFooter } from 'src/components/footer';
import Loader from 'src/components/atoms/Loader';

const Reward = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const { decimal, symbol } = useFindContractData(CONTRACT_SYMBOL.MirrorToken);

  const { data: rewardMetrics } = useRewardMetricsQuery();
  const { data, isLoading } = useGetMyAccountsQuery({
    limit,
    offset: (pageNumber - 1) * limit,
  });
  const [redeemModalOpen, setRedeemModalOpen] = useState(false);

  const handleClose = () => setRedeemModalOpen(false);
  const handleOpen = () => setRedeemModalOpen(true);

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4'>
        {rewardMetrics && (
          <RedeemModal
            data={{ balance: rewardMetrics?.balance || 0 }}
            handleClose={handleClose}
            handleShow={handleOpen}
            show={redeemModalOpen}
          />
        )}
        <Row className='reward-metric-row mx-0'>
          <Col md={6} lg={3} className='mb-4 gx-2'>
            <Card className='reward-level-card'>
              <Card.Body>
                <h6 className='fs-300 mb-2 text-clr-dark-300'>
                  Achieved Level
                </h6>
                <h3>{rewardMetrics?.level_title || 'No Level'}</h3>
                {/* {rewardMetrics?.level_image_url && ( */}
                <div className='position-relative d-flex justify-content-end align-items-baseline mt-2'>
                  {rewardMetrics?.level_image_url ? (
                    <img
                      alt='level'
                      src={rewardMetrics.level_image_url}
                      style={{ width: '30px' }}
                    />
                  ) : (
                    <div style={{ height: '30px', width: '30px' }}></div>
                  )}
                  <DisabledLogo
                    style={{
                      position: 'absolute',
                      right: '0',
                      top: '50%',
                      transform: 'translate(14px, -50%)',
                      width: '60px',
                      height: '60px',
                    }}
                  />
                </div>
                {/* )} */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className='mb-4'>
            <Card className='total-reward-earning-card'>
              <Card.Body>
                <h6 className='fs-300 mb-2 text-clr-dark-300'>
                  Total Reward Earning
                </h6>
                <h3>
                  {formatBigIntDecimal(rewardMetrics?.total_earning, decimal)}{' '}
                  {symbol}
                </h3>
                <div className='d-flex justify-content-end align-items-center mt-3'>
                  <RewardsSvg />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className='mb-4'>
            <Card>
              <Card.Body>
                <h6 className='fs-300 mb-2 text-clr-dark-300'>
                  Total No. Of Subscribers
                </h6>
                <h3>{rewardMetrics?.subscribers_count || 0}</h3>
                <div className='d-flex justify-content-end align-items-center mt-3'>
                  <SubscribeSvg />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className='mb-4'>
            <Card>
              <Card.Body>
                <h6 className='fs-300 mb-2 text-clr-dark-300'>
                  Current Balance
                </h6>
                <h3
                  className='d-flex'
                  title={`${formatBigIntDecimal(
                    rewardMetrics?.balance,
                    decimal
                  )} ${symbol}`}
                >
                  <span className='text-ellipse overflow-hidden  text-nowrap'>
                    {formatBigIntDecimal(rewardMetrics?.balance, decimal)}
                  </span>
                  {symbol}
                </h3>
                <div className='d-flex justify-content-between align-items-center mt-3'>
                  <Button onClick={handleOpen} size='sm'>
                    Redeem {'> '}
                  </Button>
                  <DBSvg />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='mx-0'>
          <Col md={12} className=''>
            <h3 className='mb-3 fs-500 fw-600'>Transactions</h3>
          </Col>
          <Col md={12} className=' py-3 py-md-4 studio-card'>
            <div>
              <Table responsive className='my-4' hover>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th className='text-center'>Hash</th>
                    <th className='text-center'>Type</th>
                    {/* <th className='text-center'>Payment Type</th> */}
                    {/* <th className='text-center'>Views / Subscription</th> */}
                    <th className='text-center'>Amount ({symbol})</th>
                    <th className='text-right'>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {isLoading && (
                    <tr>
                      <td colSpan={5} className='text-center'>
                        <Loader />
                      </td>
                    </tr>
                  )}
                  {data?.accounts?.length === 0 && (
                    <td colSpan={5} className='text-center'>
                      <NoContent
                        imgUrl={`${Constant.BASE_URL}img/illustration/no-transaction.png`}
                        title={'No Transaction'}
                        addContainer={false}
                      />
                    </td>
                  )}
                  {data?.accounts?.map((account, idx) => (
                    <tr key={`my-account-${account.id}-${idx}`}>
                      <td>
                        {moment(account.created_date).format(DATE_FORMAT)}
                        <br />
                        {moment(account.created_date).format(TIME_FORMAT)}
                      </td>
                      <td className='text-center'>
                        {account?.hash ? (
                          <a
                            target='_blank'
                            href={`${config.polygonscan_base_url}/tx/${account.hash}`}
                            rel='noreferrer'
                          >
                            {account?.hash?.slice(0, 4)}...
                            {account?.hash?.slice(-4)}
                          </a>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td className='text-center'>
                        {Object.keys(PROFIT_TYPE).find(
                          (item) => PROFIT_TYPE[item] === account.profit_type
                        )}
                        <br />
                        <span
                          className={
                            account.type === PAYMENT_TYPE.CREDIT
                              ? 'bg-success text-white px-1'
                              : 'bg-danger text-white px-1'
                          }
                        >
                          {Object.keys(PAYMENT_TYPE).find(
                            (item) => PAYMENT_TYPE[item] === account.type
                          )}
                        </span>
                      </td>
                      <td className='text-center'>
                        {formatBigIntDecimal(account.amount, decimal)}
                      </td>
                      <td className='text-right'>
                        {ACCOUNTS_STATUS.RECEIPT === account.status ? (
                          <span className='text-success'>Paid</span>
                        ) : ACCOUNTS_STATUS.CONFIRMED === account.status ? (
                          <span className='text-success'>Confirmed</span>
                        ) : ACCOUNTS_STATUS.PENDING === account.status ? (
                          <span className='text-danger'>Pending</span>
                        ) : ACCOUNTS_STATUS.PROCESSING === account.status ? (
                          <span className='text-info'>Processing</span>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className='pr-md-4'>
                {data?.total_result_count > 0 && (
                  <CustomPaginate
                    pageNumber={pageNumber}
                    limit={limit}
                    totalPages={Math.ceil(data?.total_result_count / limit)}
                    handlePageChange={(page) => setPageNumber(page)}
                    handleLimitChange={(limit) => {
                      if (pageNumber !== 1) setPageNumber(1);
                      setLimit(limit);
                    }}
                    totalResultCount={data?.total_result_count}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ThinFooter />
    </>
  );
};

export default Reward;
