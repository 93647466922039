import { Link } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import Constant from '../../constant';

const FourZeroFour = () => {
  return (
    <Row>
      <Col md={8} className='mx-auto text-center pt-4 pb-5'>
        <h1>
          <img
            alt='404'
            src={`${Constant.BASE_URL}img/404.png`}
            className='img-fluid'
          />
        </h1>
        <h1>Sorry! Page not found.</h1>
        <p className='land'>
          Unfortunately the page you are looking for has been moved or deleted.
        </p>
        <div className='mt-5'>
          <Link to={`${Constant.BASE_URL}`}>
            <Button as='a' variant='outline-primary'>
              GO TO HOME PAGE
            </Button>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default FourZeroFour;
