import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  title: '',
  description: '',
  shareLink: '',
};

const shareSlice = createSlice({
  initialState,
  name: 'shareStore',
  reducers: {
    handleOpenShare: (state, { payload = null }) => {
      state.open = true;
      state.title = payload?.title || '';
      state.description = payload?.description || '';
      state.shareLink = payload?.shareLink || '';
    },
    handleCloseShare: () => {
      return initialState;
    },
  },
});

export const { handleOpenShare, handleCloseShare } = shareSlice.actions;
export default shareSlice.reducer;
