import React from 'react';
import { Container } from 'react-bootstrap';

function Privacy() {
  return (
    <Container fluid='xxl' className='p-2 p-md-4'>
      <h1 className='py-4 text-center'>Privacy Policy- Mirror!</h1>
      <h6 className='mb-1 fw-600'>Information We Collect!</h6>
      <p className='mb-4 fs-300'>
        We gather information to better serve all of our clients, from simple
        tasks like determining your language preference to more difficult tasks
        like determining your most important online friends or your favorite
        Mirror videos. Depending on how you use our services and how you set
        your privacy options, Mirror may collect different types of information
        and use it in different ways. We retain the data we gather using unique
        identifiers connected to the browser, application, or device you are
        using even if you are not registered into a Mirror Account. This enables
        us to remember your preferences, such as the language you've selected or
        whether to show ads or search results that are more relevant to your
        browsing activity, from one browser session to the next. While you are
        logged in, we also collect information connected to your Mirror Account,
        which we categorize as personal data.
      </p>
      <h6 className='mb-1 fw-600'>Things You Provide!</h6>
      <p className='mb-4 fs-300'>
        You give us personal information like your name and password when you
        establish a Mirror Account. You can also change your account's contact
        or payment details. You may opt to give us information, like an email
        address, even if you aren't logged into a Mirror Account in order to get
        in touch with Mirror or to receive updates about our services. When
        using our services, you could post, create, or receive material from
        others. Among other things, this includes the emails you send and
        receive, the pictures and videos you keep, the spreadsheets and
        documents you generate, as well as the comments you leave on Mirror
        videos.
      </p>
      <h6 className='mb-1 fw-600'>Maintain Your Services!</h6>
      <p className='mb-4 fs-300'>
        We also use your information to track outages and resolve issues that
        you report to us so that our services function as intended. We also use
        your information to enhance our offerings. For instance, by identifying
        the most frequently misspelled search terms, we can improve the
        spell-check features we use all throughout our services. We build new
        services with the help of the information we acquire from our current
        ones. For instance, understanding how Picasa, Mirror's original
        photo-organizing program, was used by users to arrange their photos,
        helped with the development and launch of Mirror photos.
      </p>
      <h6 className='mb-1 fw-600'>Provide Personalized Content!</h6>
      <p className='mb-4 fs-300'>
        Utilizing the information we collect, we customize our services for you
        by providing suggestions, personalized content, and relevant search
        results. For instance, Security Checkup provides security guidance that
        is specific to the way you use Mirror products. Mirror Play also uses
        information from your installed apps and Mirror videos to suggest new
        apps you might like. Depending on your settings, we may also show you
        advertisements that are specifically catered to your interests. For
        instance, if you search "mountain bikes," you may get sporting-goods
        commercials on Mirror. You may control the information we use to give
        you advertising by looking at your ad settings.
      </p>
      <h6 className='mb-1 fw-600'>Measure Performance!</h6>
      <p className='mb-4 fs-300'>
        We analyze and measure the usage of our services using data. For
        example, among other things, we study website visitor statistics to
        enhance product design. By using information about the adverts you
        interact with, we also assist advertisers in evaluating the success of
        their marketing initiatives. To accomplish this, we employ a number of
        techniques, including Mirror Analytics. You have the choice to allow
        Mirror to connect information about your activity from one website or
        app with activity from other websites or apps that use our ad services
        when you visit websites or use applications that use Mirror Analytics.
      </p>
      <h6 className='mb-1 fw-600'>Communicate With You!</h6>
      <p className='mb-4 fs-300'>
        We utilize the information we obtain, including your email address, to
        interact with you directly. For instance, we might notify you if we
        discover unusual activity, such as an attempt to log into your Mirror
        Account from an unfamiliar location. Or we might let you know about
        upcoming changes or improvements to our services. Additionally, if you
        contact Mirror, we'll keep a record of it so we can assist you if you
        run into any issues in the future.
      </p>
      <h6 className='mb-1 fw-600'>When You Share Your information!</h6>
      <p className='mb-4 fs-300'>
        Through a number of our services, you can limit the amount of
        information you share with others. For example, on Mirror, you can
        decide whether to broadcast your videos publicly or secretly. Remember
        that if you publish anything online, Mirror Search and other search
        engines might be able to access it. When you use various Mirror services
        while logged in, such as commenting on a Mirror video or rating an app
        on Play, your name and photo are shown next to your activity. We may
        also utilize this data in adverts, depending on your Shared Endorsements
        settings.
      </p>
      <h6 className='mb-1 fw-600'>
        We Build Security Into Our Services To Protect Our Information!
      </h6>
      <p className='mb-4 fs-300'>
        Every Mirror product has robust security protections that constantly
        protect your information. Because of the information we learn by keeping
        up with our services, we are able to recognize and immediately stop
        security risks before they ever reach you. And if we do come across
        something hazardous that we think you ought to be aware of, we'll let
        you know about it and help you take the appropriate safety measures. We
        take every precaution to prevent unauthorized access, alteration,
        disclosure, or destruction of the information we hold about you and
        Mirror. We encrypt all data in transit to protect the privacy of your
        information. To assist you in safeguarding your account, we provide a
        number of security features like Safe Browsing, Security Checkup, and
        Two-Step Verification. To stop unauthorized access to our systems, we
        regularly evaluate our information gathering, storing, and processing
        procedures as well as our physical security measures. We only give
        access to Mirror staff members, contractors, and agents who require it
        to handle personal data. Strict contractual confidentiality duties apply
        to everyone who has access to this information, and those who break them
        face punishment or termination.
      </p>
      <h6 className='mb-1 fw-600'>Retaining Your Information!</h6>
      <p className='mb-4 fs-300'>
        We retain the information we gather for a range of times, depending on
        what it is, how we use it, and how you configure your settings: You have
        the freedom to erase certain information whenever you wish, including
        your personal information and the content you create or upload, like
        photographs and documents. Additionally, you have the choice to either
        manually clear activity data that has been saved in your account after a
        specified period of time or to automatically wipe it. We'll keep this
        information in your Mirror Account until you remove it or decide to have
        it deleted. Other information, such as advertising data in server logs,
        is automatically removed or anonymized after a predetermined amount of
        time. We retain certain data, such as usage statistics, until the point
        at which you delete your Mirror Account. When it's necessary for
        important business or legal reasons, such as security, preventing fraud
        and abuse, or maintaining financial records, we also retain some data
        for longer periods of time. We follow a process when you erase data to
        make sure it is securely deleted from our servers or is only kept in an
        anonymous form. We work to stop information from being erased
        unintentionally or on purpose by using our services. As a result, there
        can be a delay between when you delete something and when copies in our
        active and backup systems are also destroyed.
      </p>
      <h6 className='mb-1 fw-600'>Data Transfers!</h6>
      <p className='mb-4 fs-300'>
        We operate servers all over the world, therefore it's possible that your
        information will be processed there if you live in a different country.
        The laws governing data protection vary from country to country, with
        some providing greater protection than others. No matter where your
        information is processed, we take the same security measures described
        in this policy. Furthermore, we adhere to particular regulatory
        frameworks that control the flow of data. We get in touch with the
        complainant to discuss formally documented complaints. If you and we are
        unable to come to an agreement about the transfer of your data, we work
        with the relevant regulatory bodies, including local data protection
        authorities.
      </p>
      <h6 className='mb-1 fw-600'>When This Policy Applies!</h6>
      <p className='mb-4 fs-300'>
        All services provided by Mirror LLC and its affiliates, such as Mirror,
        Android, and services made available on third-party websites, such as
        advertising services, are covered by this privacy policy. Services with
        separate privacy policies than those covered by this one are not covered
        by this policy. This privacy policy does not apply in the following
        circumstances: information handling procedures for businesses and
        organizations that promote our services This policy is applicable to
        services provided by other businesses or people, including goods or
        websites that they sell that might include Mirror services, as well as
        goods or websites that are shown to you in search results or that are
        connected from our services.
      </p>
      <h6 className='mb-1 fw-600'>Changes To This Policy!</h6>
      <p className='mb-4 fs-300'>
        This privacy policy is periodically updated. Without your specific
        permission, we will not limit your rights under this privacy policy. We
        always provide you access to prior versions for your consideration and
        always indicate when the most recent modifications were made. If there
        are significant changes, we'll give a more obvious notice, which for
        some services may include email notification.
      </p>
    </Container>
  );
}

export default Privacy;
