import { NavLink } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { DropdownIcon } from 'src/assets/icons';
import { SidebarContext } from 'src/App';
import { useContext } from 'react';
import { DotSvg } from 'src/assets/svgs';

const NavItem = ({
  href,
  label,
  SvgIcon,
  dropdown = false,
  dropdownItems = [],
  exact = false,
  onClick,
}) => {
  const { openSidebar, setOpenSidebar } = useContext(SidebarContext);
  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   const navItems = document.querySelectorAll('.sidebar li');
  //   if (window.innerWidth < 768)
  //     navItems.forEach((item) => {
  //       item.addEventListener('click', (e) => {
  //         setOpenSidebar(true);
  //       });
  //     });
  // }, []);

  return (
    <>
      <li
        title={label}
        className='nav-item'
        onClick={() => {
          if (window.innerWidth < 768) setOpenSidebar(true);
          onClick && onClick();
        }}
      >
        {!dropdown ? (
          <NavLink
            to={href}
            exact={exact}
            className='nav-link'
            activeClassName='active'
          >
            {/* <img
            src={`${Constant.BASE_URL}${imgPath}`}
            alt='sidebar icon'
            className='mb-1 img-fluid'
          /> */}

            {SvgIcon && <SvgIcon />}
            <span className='overflow-hidden  text-ellipse'>{label}</span>
          </NavLink>
        ) : (
          <>
            <div
              className='nav-link cursor-pointer d-flex align-items-center'
              onClick={() => {
                if (openSidebar) setOpenSidebar(false);

                const sidebarItemDropdown = document.querySelector(
                  '.sidebar-item-dropdown'
                );
                console.log(
                  sidebarItemDropdown.style.height,
                  'sidebarItemDropdown.style.height'
                );
                if (sidebarItemDropdown.style.height === '0px')
                  sidebarItemDropdown.style.height = '100%';
                else {
                  sidebarItemDropdown.style.height = 0;
                }
                setIsOpen(!isOpen);
              }}
            >
              {SvgIcon && <SvgIcon />}
              <span>{label}</span>
              <DropdownIcon
                style={{
                  fill: '#fff',
                  width: '12px',
                  marginLeft: 'auto',
                  transition: 'all .4s ease',
                  transform: isOpen ? `rotate(180deg)` : 'rotate(0deg)',
                }}
              />
            </div>
            <div
              className='sidebar-item-dropdown overflow-hidden pl-4'
              style={{
                height: 0,
                // height: isOpen ? '100%' : 0,
                transition: 'all .4s ease',
              }}
            >
              {dropdownItems.map((item) => (
                <NavLink
                  to={item.href}
                  exact
                  className='nav-link d-flex align-items-center'
                  activeClassName='active'
                >
                  <DotSvg className='mr-2' style={{ width: '10px' }} />
                  {item.label}
                </NavLink>
              ))}
            </div>
          </>
        )}
      </li>
    </>
  );
};

export default NavItem;
