import React, { useRef, useState } from 'react';
import {
  Button,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { useAccount, useWriteContract, useSwitchChain } from 'wagmi';
import { UilRedo } from '@iconscout/react-unicons/index';
import { abbreviateNumber } from 'js-abbreviation-number';

import {
  useMyVideosQuery,
  useRemoveVideoMutation,
  useRetryVideoProcessingMutation,
} from 'src/redux/features/videos/videoApi';
import Constant, {
  COLORS,
  COMMON_STATUS,
  DATE_FORMAT,
  PROCESSING_STATUS,
  SORT_ORDER_TYPE,
  TIME_FORMAT,
} from 'src/constant';
import { VideoDeleteModal } from 'src/components/atoms/modals';
import Visibility from './Visibility';
import { DeleteIconSvg, EditIconSvg, VideoIconSvg } from 'src/assets/icons';
import NoContent from 'src/components/atoms/NoContent/NoContent';
import CustomPaginate from 'src/components/atoms/Paginate/CustomPaginate';
import { useSaveBlockTransactionMutation } from 'src/redux/features/basic/basicApi';
import handleError from 'src/utils/handle-error';
import { AnalyticsSvg, PolygonScanSvg, ShortIcon } from 'src/assets/svgs';
import { useMediaQuery } from 'react-responsive';

// import PolyGonPng from 'src/'
import useFindContractData from 'src/hooks/useFindContractData';
import SortButton from 'src/components/atoms/buttons/SortButton';
import messages from 'src/constant/messages';
import Loader from 'src/components/atoms/Loader';
import config from 'src/config';
import { useActiveChainId } from 'src/hooks';

const VideosContent = () => {
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const account = useAccount();
  const videoRef = useRef(null);
  const [show, setShow] = useState(false);
  const [activeVisibilityDropDown, setActiveVisibilityDropDown] =
    useState(null);
  const history = useHistory();
  const [saveBlock] = useSaveBlockTransactionMutation();
  const [removeVideo] = useRemoveVideoMutation();
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [sort, setSort] = useState(null);
  const [order, setOrder] = useState(SORT_ORDER_TYPE.descending);
  const { abi, contract_address } = useFindContractData();

  const { data, isSuccess, isLoading, originalArgs } = useMyVideosQuery({
    limit,
    offset: (pageNumber - 1) * limit,
    order: order,
    sort: sort,
  });
  const isBigScreen = useMediaQuery({ query: '(min-width: 991px)' });

  const [retryVideoProcessing, { isLoading: isLoadingRetryVideoProcessing }] =
    useRetryVideoProcessingMutation();

  const { writeContractAsync: deleteVideoContract } = useWriteContract();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // Function to handle row hover
  const handleRowHover = (index) => setHoveredRow(index);

  // Function to handle row hover exit
  const handleRowHoverExit = () => setHoveredRow(null);

  const navigateToAnalytic = (video) => {
    if (video.visibility === 'DRAFT')
      history.push(
        `${Constant.BASE_URL}studio/upload?vid=${video.video_url_id}&vtype=DRAFT`
      );
    else
      history.push(
        `${Constant.BASE_URL}studio/video/${video.video_url_id}/analytics/tab-overview`
      );
  };

  const navigateToVideoDetail = (video) => {
    if (video.visibility === 'DRAFT')
      history.push(
        `${Constant.BASE_URL}studio/upload?vid=${video.video_url_id}&vtype=DRAFT`
      );
    else
      history.push(
        `${Constant.BASE_URL}studio/video/${video.video_url_id}/edit`
      );
  };

  const handleSort = (sortBy) => {
    setOrder((prev) => {
      if (prev === SORT_ORDER_TYPE.ascending) return SORT_ORDER_TYPE.descending;
      else return SORT_ORDER_TYPE.ascending;
      // const idx = orders.findIndex((order) => order === prev);
      // return orders[(idx + 1) % orders.length];
    });
    setSort(sortBy);
  };

  if (data?.videos?.length === 0)
    return (
      <NoContent
        title='Video Not Found'
        imgUrl={`${Constant.BASE_URL}img/video-not-found.png`}
        buttonText='Go to home'
        handleButtonClick={() => history.push(`${Constant.BASE_URL}`)}
      />
    );

  return (
    <>
      <VideoDeleteModal
        show={show}
        handleClose={handleClose}
        video={videoRef.current}
        onConfirm={async () => {
          if (activeChainId !== config.ACTIVE_CHAIN.id) {
            await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
          }
          try {
            let txn_hash;
            if (videoRef.current.contract_video_id >= 0) {
              const args = [videoRef.current.contract_video_id];
              const hash = await deleteVideoContract({
                args,
                address: contract_address,
                abi: JSON.parse(abi),
                functionName: 'deleteVideo',
                chainId: config.ACTIVE_CHAIN.id,
              });
              txn_hash = hash;
              // const block_transaction_data = {
              //   action: 'deleteVideo',
              //   address_out: account.address,
              //   address_in: contract_address,
              //   hash,
              //   network_type: 5,
              //   // other_detail: stringifyWithBigInt(res),
              //   request: JSON.stringify(args),
              // };
              // saveBlock({ body: block_transaction_data });
              // await waitForTransactionReceipt({ hash });
            }
            const response = await removeVideo({
              videoId: videoRef.current.id,
              body: { hash: txn_hash },
              extraArg: {
                myVideos: originalArgs,
              },
            }).unwrap();
            handleClose();
            toast.success(response.message);
          } catch (error) {
            handleError(error);
          }
        }}
      />

      <Table responsive className='my-4' hover>
        <thead>
          <tr>
            <th>
              <div className='d-flex align-items-center'>
                {/* <Form.Check type='checkbox' custom id='df' /> */}
                <h6 className='fs-400 fw-600'>Video</h6>
              </div>
            </th>
            <th>
              <h6 className='fs-400 fw-600'>Visibility</h6>
            </th>
            <th>
              <h6
                className='fs-400 fw-600 cursor-pointer'
                onClick={() => handleSort('date')}
              >
                Date
                <span>
                  <SortButton order={order} />
                </span>
              </h6>
            </th>
            <th className='text-center'>
              <h6 className='fs-400 fw-600'>Views</h6>
            </th>
            <th className='text-center'>
              <h6 className='fs-400 fw-600'>Comments</h6>
            </th>
            <th>
              <h6 className='fs-400 fw-600 text-nowrap text-right'>
                Likes(vs Dislikes)
              </h6>
            </th>
          </tr>
        </thead>

        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={6} className='text-center'>
                <Loader />
              </td>
            </tr>
          )}
          {data?.videos?.map((video, idx) => (
            <tr
              key={`my-video-${video.id}-${idx}`}
              onMouseEnter={() => handleRowHover(idx)} // Set the hovered row index on mouse enter
              onMouseLeave={handleRowHoverExit} // Clear the hovered row index on mouse leave
            >
              <td>
                <Row className='flex-nowrap'>
                  <Col
                    md={4}
                    // className='d-flex align-items-start position-relative'
                  >
                    <div className='d-flex align-items-start position-relative'>
                      {video.processing_status === PROCESSING_STATUS.FAIL && (
                        <Button
                          variant='link'
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'rgb(252, 97, 115)',
                            width: '100%',
                            // background: 'var(--clr-black2white)',
                            background: '#080606b3',
                            borderRadius: '0',
                            height: '50%',
                            textDecoration: 'none',
                            fontWeight: 700,
                          }}
                          className='py-0'
                          onClick={async () => {
                            try {
                              await retryVideoProcessing({
                                videoId: video.id,
                              });
                            } catch (error) {
                              handleError(error);
                            }
                          }}
                          disabled={isLoadingRetryVideoProcessing}
                        >
                          {!isLoadingRetryVideoProcessing && (
                            <UilRedo
                              color='rgb(252, 97, 115)'
                              size={20}
                              className='mr-2'
                            />
                          )}
                          {isLoadingRetryVideoProcessing
                            ? 'Processing...'
                            : 'Retry'}
                        </Button>
                      )}
                      {/* for short video */}
                      {Boolean(video.is_short) && (
                        <ShortIcon
                          style={{
                            position: 'absolute',
                            bottom: '3px',
                            right: '10px',
                            width: '22px',
                            height: '22px',
                          }}
                        />
                      )}

                      <img
                        onClick={() => navigateToAnalytic(video)}
                        src={
                          video.thumbnail_url ||
                          `${Constant.BASE_URL}img/light/default-thumbnail.png`
                        }
                        style={{
                          aspectRatio: '16 / 9',
                          objectFit: 'contain',
                        }}
                        className='w-100 cursor-pointer'
                        alt='thumbnail'
                      />
                    </div>
                  </Col>
                  <Col
                    md={8}
                    className='d-flex flex-column justify-content-start overflow-hidden '
                    style={{ minHeight: '-webkit-fill available' }}
                  >
                    <h6
                      className='fs-400 fw-600 mb-1 text-line-1 cursor-pointer'
                      onClick={() => navigateToAnalytic(video)}
                    >
                      {video.title}
                    </h6>

                    {hoveredRow === idx ? (
                      <>
                        <div className='video-edit-web-view mt-auto'>
                          <div
                            className='mt-auto d-flex'
                            style={{ gap: '20px' }}
                          >
                            {video.txn_status !== COMMON_STATUS.PROCESSING && (
                              <OverlayTrigger
                                placement='top'
                                overlay={(props) => (
                                  <Tooltip {...props}>Edit Details</Tooltip>
                                )}
                              >
                                <div
                                  onClick={() => navigateToVideoDetail(video)}
                                  // onClick={() =>
                                  //   history.push(
                                  //     `${Constant.BASE_URL}studio/upload?vid=${
                                  //       video.video_url_id
                                  //     }&vtype=${
                                  //       video.visibility === 'DRAFT'
                                  //         ? 'DRAFT'
                                  //         : 'EDIT'
                                  //     }`
                                  //   )
                                  // }
                                >
                                  <EditIconSvg className='icon cursor-pointer' />
                                </div>
                              </OverlayTrigger>
                            )}
                            <OverlayTrigger
                              placement='top'
                              overlay={(props) => (
                                <Tooltip {...props}>View on Mirror</Tooltip>
                              )}
                            >
                              <div
                                onClick={() =>
                                  history.push(
                                    `${Constant.BASE_URL}${
                                      video.is_short ? 'shorts' : 'watch'
                                    }/${video.video_url_id}`
                                  )
                                }
                              >
                                <VideoIconSvg
                                  style={{ width: '22px' }}
                                  className='icon cursor-pointer'
                                />
                              </div>
                            </OverlayTrigger>
                            {video?.visibility !== 'DRAFT' && (
                              <>
                                <OverlayTrigger
                                  placement='top'
                                  overlay={(props) => (
                                    <Tooltip {...props}>Analytics</Tooltip>
                                  )}
                                >
                                  <div
                                    onClick={() => navigateToAnalytic(video)}
                                  >
                                    <AnalyticsSvg className='icon cursor-pointer' />
                                  </div>
                                </OverlayTrigger>
                                {isBigScreen && (
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={(props) => (
                                      <Tooltip {...props}>
                                        {video.video_hash}
                                      </Tooltip>
                                    )}
                                  >
                                    <div>
                                      <PolygonScanSvg className='icon cursor-pointer' />
                                    </div>
                                  </OverlayTrigger>
                                )}
                              </>
                            )}

                            <OverlayTrigger
                              placement='top'
                              overlay={(props) => (
                                <Tooltip {...props}>Delete Video</Tooltip>
                              )}
                            >
                              <div
                                onClick={() => {
                                  videoRef.current = video;
                                  handleShow();
                                }}
                              >
                                <DeleteIconSvg className='icon cursor-pointer' />
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {video?.processing_status ===
                        PROCESSING_STATUS.PROCESSING ? (
                          <div className='mt-2 text-clr-dark-100'>
                            Video under processing...
                          </div>
                        ) : video?.processing_status ===
                          PROCESSING_STATUS.FAIL ? (
                          <div className='mt-2 text-danger'>
                            Processing Failed!
                          </div>
                        ) : (
                          isBigScreen && (
                            <div
                              className='mt-2 text-clr-dark-100 text-line-2 description-text-style-same'
                              dangerouslySetInnerHTML={{
                                __html: video?.description,
                              }}
                            />
                          )
                        )}
                      </>
                    )}

                    {/* for mobile view */}
                    <div className='mobile-view-video-edit'>
                      {video?.processing_status ===
                      PROCESSING_STATUS.PROCESSING ? (
                        <div className='mt-2 text-clr-dark-100'>
                          Video under processing...
                        </div>
                      ) : video?.processing_status ===
                        PROCESSING_STATUS.FAIL ? (
                        <div className='mt-2 text-danger'>
                          Processing Failed!
                        </div>
                      ) : (
                        <div
                          className='mt-2 text-clr-dark-100 text-line-2 description-text-style-same'
                          dangerouslySetInnerHTML={{
                            __html: video?.description,
                          }}
                        />
                      )}

                      <div
                        className='mt-auto d-flex '
                        style={{ gap: '15px', width: '300px' }}
                      >
                        {video.txn_status !== COMMON_STATUS.PROCESSING && (
                          <OverlayTrigger
                            placement='top'
                            overlay={(props) => (
                              <Tooltip {...props}>Edit Details</Tooltip>
                            )}
                          >
                            <div
                              onClick={() => navigateToVideoDetail(video)}
                              // onClick={() =>
                              //   history.push(
                              //     `${Constant.BASE_URL}studio/upload?vid=${
                              //       video.video_url_id
                              //     }&vtype=${
                              //       video.visibility === 'DRAFT'
                              //         ? 'DRAFT'
                              //         : 'EDIT'
                              //     }`
                              //   )
                              // }
                            >
                              <EditIconSvg className='icon cursor-pointer' />
                            </div>
                          </OverlayTrigger>
                        )}
                        <OverlayTrigger
                          placement='top'
                          overlay={(props) => (
                            <Tooltip {...props}>View on Mirror</Tooltip>
                          )}
                        >
                          <div
                            onClick={() =>
                              history.push(
                                `${Constant.BASE_URL}${
                                  video.is_short ? 'shorts' : 'watch'
                                }/${video.video_url_id}`
                              )
                            }
                          >
                            <VideoIconSvg
                              style={{ width: '22px' }}
                              className='icon cursor-pointer'
                            />
                          </div>
                        </OverlayTrigger>
                        {video?.visibility !== 'DRAFT' && (
                          <>
                            <OverlayTrigger
                              placement='top'
                              overlay={(props) => (
                                <Tooltip {...props}>Analytics</Tooltip>
                              )}
                            >
                              <div onClick={() => navigateToAnalytic(video)}>
                                <AnalyticsSvg className='icon cursor-pointer' />
                              </div>
                            </OverlayTrigger>
                            {!isBigScreen && (
                              <OverlayTrigger
                                placement='top'
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {video.video_hash}
                                  </Tooltip>
                                )}
                              >
                                <div>
                                  <PolygonScanSvg className='icon cursor-pointer' />
                                </div>
                              </OverlayTrigger>
                            )}
                          </>
                        )}

                        <OverlayTrigger
                          placement='top'
                          overlay={(props) => (
                            <Tooltip {...props}>Delete Video</Tooltip>
                          )}
                        >
                          <div
                            onClick={() => {
                              videoRef.current = video;
                              handleShow();
                            }}
                          >
                            <DeleteIconSvg className='icon cursor-pointer' />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                    {/* end mobile view */}
                  </Col>
                </Row>
              </td>

              <td>
                {!video.is_active ? (
                  <div className='d-flex align-items-center'>
                    <img
                      alt='visibility icon'
                      className='mr-2'
                      src={`${Constant.BASE_URL}img/eye-red.svg`}
                    />
                    <h6 className='fs-300 fw-500'>Blocked</h6>
                  </div>
                ) : video.txn_status === COMMON_STATUS.PROCESSING ? (
                  <OverlayTrigger
                    placement='top'
                    overlay={(props) => (
                      <Tooltip {...props}>
                        {messages.pending_transaction}
                      </Tooltip>
                    )}
                  >
                    <div className='d-flex align-items-center cursor-pointer'>
                      <img
                        alt='visibility icon'
                        className='mr-2'
                        style={{ width: '18px' }}
                        src={`${Constant.BASE_URL}img/transaction.svg`}
                      />
                      <h6 className='fs-300 fw-500'>In Progress</h6>
                    </div>
                  </OverlayTrigger>
                ) : video.visibility === 'DRAFT' ? (
                  <>
                    <div className='d-flex align-items-center'>
                      <img
                        alt='visibility icon'
                        className='mr-2'
                        src={`${Constant.BASE_URL}img/draft-file.png`}
                      />
                      <h6 className='fs-300 fw-500'>{video.visibility}</h6>
                    </div>
                  </>
                ) : (
                  <Visibility
                    isLast={idx !== 0 && data.videos.length - 1 === idx}
                    visibility={video.visibility}
                    videoId={video.id}
                    handleOpen={() => setActiveVisibilityDropDown(video.id)}
                    handleClose={() => setActiveVisibilityDropDown(null)}
                    show={activeVisibilityDropDown === video.id}
                  />
                )}
              </td>
              <td>
                <h6 className='fs-300 fw-500 text-nowrap'>
                  {moment(video.created_date).format(DATE_FORMAT)}
                  <br />
                  {moment(video.created_date).format(TIME_FORMAT)}
                </h6>
              </td>
              <td className='text-center'>
                <h6 className='fs-300 fw-500'>
                  {abbreviateNumber(video?.views || 0, 1)}
                </h6>
              </td>
              <td className='text-center'>
                <h6 className='fs-300 fw-500'>
                  {abbreviateNumber(video.comment_count, 1)}
                </h6>
              </td>
              <td className='text-right'>
                {video.visibility === 'DRAFT' ? (
                  <Button
                    as={Link}
                    to={`${Constant.BASE_URL}studio/upload?vid=${video.video_url_id}&vtype=DRAFT`}
                    variant='primary'
                    // className='py-0'
                    // style={{ color: COLORS.primary400, fontWeight: 700 }}
                  >
                    Edit Draft
                  </Button>
                ) : (
                  <h6 className='fs-300 fw-500'>
                    {abbreviateNumber(video.likes, 1)}/
                    {abbreviateNumber(video.dislikes, 1)}
                  </h6>
                )}
              </td>
            </tr>
          ))}
          {/* - pagination */}
          {/* <tr>
                <td colSpan='6' colSpan={6}></td>
              </tr> */}
        </tbody>
      </Table>
      <div className='pr-md-4'>
        <CustomPaginate
          pageNumber={pageNumber}
          limit={limit}
          totalPages={Math.ceil(data?.total_result_count / limit)}
          handlePageChange={(page) => setPageNumber(page)}
          handleLimitChange={(limit) => {
            if (pageNumber !== 1) setPageNumber(1);
            setLimit(limit);
          }}
          totalResultCount={data?.total_result_count}
        />
      </div>
    </>
  );
};

export default VideosContent;
