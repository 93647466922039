import { configureStore } from '@reduxjs/toolkit';
import watchHistorySlice from './features/watch-history/watchHistorySlice';
import watchLaterSlice from './features/watch-later/watchLaterSlice';
import likedVideoSlice from './features/liked-videos/likedVideoSlice';
import videoSlice from './features/videos/videoSlice';
import shortSlice from './features/videos/shortSlice';
import shareSlice from './features/share/shareSlice';
import channelSlice from './features/channels/channelSlice';
import reportVideoSlice from './features/report-video/reportVideoSlice';
import rootApi from './rootApi';
import authSlice from './features/auth/authSlice';

export default configureStore({
  reducer: {
    authStore: authSlice,
    watchHistoryStore: watchHistorySlice,
    watchLaterStore: watchLaterSlice,
    likedVideoStore: likedVideoSlice,
    videoStore: videoSlice,
    shortStore: shortSlice,
    shareStore: shareSlice,
    channelStore: channelSlice,
    reportVideoStore: reportVideoSlice,
    [rootApi.reducerPath]: rootApi.reducer,
    // [channelsApi.reducerPath]: channelsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootApi.middleware),
});
