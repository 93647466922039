import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';

const SubscriberPerViewContent = ({ views = 0, subscribers = 0 }) => {
  const subscribedPercentage = useMemo(() => {
    const percentage = ((subscribers / views) * 100).toFixed(1);
    return percentage;
  }, []);

  return (
    <div>
      <div className='fs-300 mb-1 text-clr-dark-300'>Viewers Analytics</div>
      <div className='fs-300 mb-1 text-clr-dark-300'>1. Subscribed</div>
      <div className='d-flex align-items-center'>
        <Form.Control
          style={{ cursor: 'default' }}
          value={views === 0 ? 0 : subscribedPercentage}
          type='range'
          className='flex-grow-1 custom-slide-range'
          min={0}
          max={100}
        />
        <div className='ml-2'>{views === 0 ? 0 : subscribedPercentage}%</div>
      </div>
      <div className='fs-300 mb-1 mt-2 text-clr-dark-300'>
        2. Not Subscribed
      </div>
      <div className='d-flex align-items-center'>
        <Form.Control
          type='range'
          style={{ cursor: 'default' }}
          value={views === 0 ? 0 : 100 - subscribedPercentage}
          className='flex-grow-1 custom-slide-range'
          min={0}
          max={100}
        />
        <div className='ml-2'>
          {views === 0 ? 0 : 100 - subscribedPercentage}%
        </div>
      </div>
    </div>
  );
};

export default React.memo(SubscriberPerViewContent);
