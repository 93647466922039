import { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarContext } from '../../App';

const NavItem = ({ href, label, SvgIcon }) => {
  const { openSidebar, setOpenSidebar } = useContext(SidebarContext);

  // useEffect(() => {
  //   const navItems = document.querySelectorAll('.sidebar li');
  //   if (window.innerWidth < 768)
  //     navItems.forEach((item) => {
  //       item.addEventListener('click', (e) => {
  //         setOpenSidebar(true);
  //       });
  //     });
  // }, []);

  return (
    <>
      <li
        className='nav-item'
        onClick={() => {
          if (window.innerWidth < 768) setOpenSidebar(true);
        }}
      >
        <NavLink to={href} exact className='nav-link' activeClassName='active'>
          {/* <img alt='label' src={imgPath} className='mb-1' /> */}
          {SvgIcon && <SvgIcon />}
          {!openSidebar && <span>{label}</span>}
        </NavLink>
      </li>
    </>
  );
};

export default NavItem;
