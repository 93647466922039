import config from 'src/config';
import {
  GWEI_DECIMAL,
  IncrementalGasPrice,
  IncrementalMaxPriorityFeePerGas,
} from 'src/constant';
import { parseGwei, parseUnits } from 'viem';

// import { fetchFeeData as wagmiFetchFeeData } from 'wagmi/actions';

const fetchFeeData = async () => {
  try {
    // const data = await wagmiFetchFeeData({
    //   formatUnits: 'gwei',
    //   chainId: config.ACTIVE_CHAIN.id,
    // });
    // console.log(data, 'data');
    // const gasPrice = data.gasPrice + IncrementalGasPrice;
    // const maxPriorityFeePerGas =
    //   data.maxPriorityFeePerGas + IncrementalMaxPriorityFeePerGas;
    // const maxFeePerGas =
    //   parseUnits('2', GWEI_DECIMAL) * gasPrice + maxPriorityFeePerGas;

    // const gasPrice = (parseFloat(data.formatted.gasPrice) + 0.5).toString();
    // const maxPriorityFeePerGas = data.formatted.maxPriorityFeePerGas;
    // const maxFeePerGas =
    //   2 * parseFloat(gasPrice) + parseFloat(maxPriorityFeePerGas);

    // console.log(gasPrice, maxPriorityFeePerGas, maxFeePerGas, 'gas');
    // return { gasPrice };
    // [ISSUE] not sending because sometime gas fee increase lot with error account does not have enough amount
    return {};
  } catch (error) {
    throw Error(error);
  }
};

export { fetchFeeData };
