import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { CLIENT_URL } from '../../../constant';
import {
  useFetchMyChannelQuery,
  useUpdateChannelBasicInfoMutation,
} from '../../../redux/features/channels/channelApi';

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
  handle_name: Yup.string().min(2).required(),
  description: Yup.string(),
  email: Yup.string().email(),
});

const BasicInfo = () => {
  const { data: channel } = useFetchMyChannelQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [updateChannelBasicInfo, { isLoading }] =
    useUpdateChannelBasicInfoMutation();

  const [initialValues, setInitialValues] = useState({
    name: '',
    handle_name: '',
    description: '',
    email: '',
  });

  useEffect(() => {
    if (channel) {
      setInitialValues({
        name: channel?.name,
        handle_name: channel?.handle_name,
        description: channel?.description,
        email: channel?.email,
      });
    }
  }, [channel]);

  return (
    <div className='py-3 py-md-4 px-3 px-md-4'>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          const data = await updateChannelBasicInfo(values).unwrap();
          toast.success(data?.message || 'Successfully Updated.');
        }}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          handleBlur,
          handleChange,
          errors,
          touched,
          values,
          isValid,
          handleSubmit,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={10}>
                <Form.Group controlId='channel-name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    name='name'
                    placeholder='Enter channel name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='handle-name'>
                  <Form.Label>Handle</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    name='handle_name'
                    placeholder='Set your handle name'
                    value={values.handle_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.handle_name && !!errors.handle_name}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.handle_name ||
                      `${CLIENT_URL}/${values.handle_name}`}
                  </Form.Control.Feedback>
                  {!errors.handle_name && (
                    <Form.Text muted>
                      {`${CLIENT_URL}/${values.handle_name}`}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId='video-description'>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    as='textarea'
                    rows={3}
                    name='description'
                    placeholder='Tell viewers about your channel'
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.description && !!errors.description}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='channel-url'>
                  <Form.Label>Channel URL</Form.Label>
                  <Form.Control
                    size='lg'
                    name='channel_url'
                    placeholder='Mirror.com/'
                    type='text'
                    value={`${CLIENT_URL}/channel/${channel?.id}`}
                    disabled
                  />
                </Form.Group>

                {/* contact info */}
                <h6 className='fw-600 mb-2 mt-4'>Contact Info</h6>
                <Form.Group controlId='email-address'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    name='email'
                    placeholder='Enter Email Address (Optional)'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <hr className='clr-white-card-hr' />
              </Col>
              <Col md={12} className='d-flex'>
                <Button
                  variant='outlined'
                  className='ml-auto px-4'
                  onClick={() => resetForm()}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  className='px-4 ml-4'
                  type='submit'
                  disabled={isLoading || !isValid}
                >
                  Publish
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BasicInfo;
