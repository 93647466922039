import './VideoCard.css';

import { VerifiedTooltip } from '../CustomCheckTooltips/CustomCheckTooltips';
import { Link, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { abbreviateNumber } from 'js-abbreviation-number';
import { Button, Dropdown } from 'react-bootstrap';
import UilMenuV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import { UilTrashAlt } from '@iconscout/react-unicons';
import { COLORS, themeMode } from 'src/constant';
import { UilShare } from '@iconscout/react-unicons';
import UisClock from '@iconscout/react-unicons-solid/icons/uis-clock';
import { UilExclamationOctagon } from '@iconscout/react-unicons';
import { useContext } from 'react';
import { ThemeContext } from 'src/App';
import { ShortIcon } from 'src/assets/svgs';

function VideoCard({
  isShort,
  iconHref = '#',
  imgHref = '#',
  imgSrc,
  imgAlt = '',
  time = '-:-',
  videoTitle = '#',
  videoTitleHref,
  views = 0,
  timeAgo,
  channelName,
  verified = null,
  channelHref,
  onRemoveFromHistory,
  onAddWatchLater,
  onReportVideo,
  onShare,
}) {
  const history = useHistory();
  const { mode } = useContext(ThemeContext);

  return (
    <>
      <div className='video-card'>
        <div className='video-card-image'>
          <Link className='play-icon' to={iconHref}>
            <FontAwesomeIcon icon={faPlayCircle} />
          </Link>
          <Link to={imgHref}>
            <img
              style={{
                aspectRatio: '16 / 9',
                objectFit: isShort ? 'contain' : 'cover',
              }}
              className='img-fluid'
              src={imgSrc}
              alt={imgAlt}
              loading='lazy'
            />
          </Link>
          {isShort ? (
            <div
              style={{
                position: 'absolute',
                bottom: '8px',
                right: '8px',
              }}
            >
              <ShortIcon
                style={{
                  width: '35px',
                  height: '35px',
                }}
              />
            </div>
          ) : (
            <div className='time'>{time}</div>
          )}
        </div>
        <div className='video-card-body position-relative hover-trigger-container'>
          {/* DROPDOWN */}
          <Dropdown
            style={{
              position: 'absolute',
              right: '0px',
              top: '8px',
            }}
            drop='up'
          >
            <Dropdown.Toggle as='a' id='' className='no-after'>
              <button className='icon-circle-wrapper btn-ripple trigger-element'>
                <UilMenuV
                  size={18}
                  className='icon'
                  // color={
                  //   themeMode.LIGHT === mode ? COLORS.dark300 : COLORS.light2
                  // }
                />
              </button>
              {/* <Button
                onMouseDown={(e) => e.preventDefault()}
                className=''
                style={{
                  borderRadius: '50%',
                  aspectRatio: 1,
                }}
                size='sm'
                variant='light'
              >
                <UilMenuV size={18} color='#4B4B4B' />
              </Button> */}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ minWidth: 'min-content' }}>
              {onRemoveFromHistory && (
                <Dropdown.Item href='#' onClick={onRemoveFromHistory}>
                  <UilTrashAlt
                    color={COLORS.dark300}
                    size='20'
                    className='mr-2'
                  />
                  <span>Remove from history</span>
                </Dropdown.Item>
              )}

              {onAddWatchLater && (
                <Dropdown.Item href='#' onClick={onAddWatchLater}>
                  <UisClock color={COLORS.dark300} size='20' className='mr-2' />
                  <span>Save to Watch later</span>
                </Dropdown.Item>
              )}

              <Dropdown.Item onClick={onShare}>
                <UilShare color={COLORS.dark300} size='20' className='mr-2' />
                <span>Share</span>
              </Dropdown.Item>
              <Dropdown.Item href='#' onClick={onReportVideo}>
                <UilExclamationOctagon size='18' className='mr-2' />
                Report Video
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className='video-title w-mins-dot-btn'>
            <Link to={videoTitleHref}>{videoTitle}</Link>
          </div>
          <div
            // className={
            //   verified
            //     ? 'video-page text-success-custom cursor-pointer'
            //     : 'video-page text-danger-custom cursor-pointer'
            // }
            className={'video-page  cursor-pointer'}
            onClick={() => history.push(channelHref)}
          >
            {channelName} {verified ? <VerifiedTooltip /> : null}
          </div>
          <div className='video-view'>
            {abbreviateNumber(views || 0, 1)} views
            <FontAwesomeIcon icon={faCalendarAlt} className='ml-2' /> {timeAgo}
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoCard;
