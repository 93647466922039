import React, { useContext, useState } from 'react';
import { Accordion, Container, Card } from 'react-bootstrap';
import UilAngleUp from '@iconscout/react-unicons/icons/uil-angle-up';
import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';

import './style.css';
import { ThemeContext } from 'src/App';
import { COLORS } from 'src/constant';
import { ThinFooter } from 'src/components/footer';

const Content2 = () => {
  return (
    <>
      <p>
        The user first has to create an account by logging in at Metamask and
        then he/she will be further directed to add details that are absolutely
        customizable. Furthermore, a user can proceed to create a channel and
        add the channel details which are again customizable. Lastly, you are
        free to upload a video on your channel.
      </p>
      <p>
        In addition, the best part is that a user can watch videos without even
        logging in by making an account. Whereas, a user can like/ dislike,
        comment, and subscribe only after creating an account on the Mirror
        platform.
      </p>
    </>
  );
};

const Content3 = () => {
  return (
    <>
      <p className='mb-2'>
        In the Mirror platform, there are two types of earnings:
      </p>
      <ul>
        <li>
          <b>Level Earning:</b> This kind of earning works on the basis of the
          level of subscribers. More the subscribers, the better the level on
          the platform and Miror Tokens/ credits are provided respectively.
          These levels can be White Level, Gold Level, and more.
        </li>
        <li>
          <b>Trending Earning:</b> This kind of earning is based on the video
          that is trending on the platform. Trending videos can be Video of the
          Day, Video of the Week, Video of the Month, and Video of the Year.{' '}
        </li>
      </ul>
    </>
  );
};

const questions = [
  {
    id: 1,
    title: 'How does Mirror Work?',
    Content: () => (
      <p>
        Mirror is a video streaming platform where users can log in and upload
        videos. The user has to create an account on Metamask and then utilize
        the Polygon Network to start their account on the platform. One notable
        thing about the Mirror platform is that every single feature on the
        platform is based on the Blockchain. Additionally, this is a
        decentralized platform where videos can be uploaded and those videos are
        publicly accessible to other users.
      </p>
    ),
  },
  {
    id: 2,
    title: 'How do I use Mirror?',
    Content: Content2,
  },
  {
    id: 3,
    title: 'How does earning work? ',
    Content: Content3,
  },
  {
    id: 4,
    title: 'How does the token economy work?',
    Content: () => (
      <p>
        Once the rewards are credited to the user’s account as Level Earning or
        Trending Earning, this calls for the minting of new Mirror Tokens.{' '}
      </p>
    ),
  },
  {
    id: 5,
    title: 'Where does the money come from?',
    Content: () => (
      <p>
        Again as stated above, the minting of new tokens is initiated for the
        users on the platform at the time of reward distribution itself and
        distributed to the users who are eligible for the reward (Level
        Earning/Trending Earning).
      </p>
    ),
  },
  {
    id: 6,
    title: 'Why does Mirror manage censorship?',
    Content: () => (
      <p>
        If there is any video that exists on the platform that is heavily
        reported by the users, then there is a dedicated team of “Moderators”
        who analyze the video and if they find anything illegal or prohibited,
        then the video is censored by the team.
      </p>
    ),
  },
  {
    id: 7,
    title: 'How do videos get ranked? ',
    Content: () => (
      <p>
        As stated earlier, videos are ranked on the basis of their popularity
        and the views on it. The more popular the video, the better the rank on
        the platform.
      </p>
    ),
  },
  {
    id: 8,
    title: 'Can I change my account name? ',
    Content: () => (
      <p>
        You can change the account name by going to the Mirror Studio. There the
        user can customize their profile photo, Avatars, Cover Image, and other
        important details.
      </p>
    ),
  },
  {
    id: 9,
    title: 'Does Mirror take beneficiary rewards? ',
    Content: () => (
      <p>
        No, the Mirror platform doesn’t take any kind of beneficiary rewards.
      </p>
    ),
  },
];

const Help = () => {
  const { mode } = useContext(ThemeContext);
  const [activeKey, setActiveKey] = useState(1);

  return (
    <>
      <Container fluid='xxl' className='p-2 p-md-4' id='faq'>
        <h2 className='py-4'>FAQ</h2>
        <Accordion defaultActiveKey={1} activeKey={activeKey}>
          <Card>
            {questions.map(({ id, title, Content }) => (
              <>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={id}
                  className='d-flex justify-content-between align-items-center cursor-pointer'
                  onClick={() => setActiveKey(id)}
                >
                  <h6>{title}</h6>
                  {activeKey === id ? (
                    <UilAngleUp
                      size={30}
                      color={COLORS.primary400}
                      // color={
                      //   mode === themeMode.LIGHT ? COLORS.dark300 : COLORS.light
                      // }
                    />
                  ) : (
                    <UilAngleDown
                      color={COLORS.primary400}
                      size={30}
                      // color={
                      //   mode === themeMode.LIGHT ? COLORS.dark300 : COLORS.light
                      // }
                    />
                  )}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={id}>
                  <Card.Body>
                    <Content />
                  </Card.Body>
                </Accordion.Collapse>
              </>
            ))}
          </Card>
        </Accordion>
      </Container>
      {/* <ThinFooter /> */}
    </>
  );
};

export default Help;
