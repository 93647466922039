import './ChannelCard.css';

import Button from 'react-bootstrap/Button';
import { abbreviateNumber } from 'js-abbreviation-number';

import { VerifiedTooltip } from '../CustomCheckTooltips/CustomCheckTooltips';
import {
  useSubscribeMutation,
  useUnsubscribeMutation,
} from '../../../redux/features/subscribers/subscribersApi';
import { useAuthCheckQuery } from '../../../redux/features/auth/authApi';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Constant, { COMMON_STATUS } from '../../../constant';
import Avatar from '../Avatar';
import { ConfirmModal, ProcessingModal } from '../modals';
import { useState } from 'react';
import { useAccount, useWriteContract, useSwitchChain } from 'wagmi';
import handleError from 'src/utils/handle-error';
import {
  // useSaveBlockTransactionMutation,
  pendingTxnCheck,
} from 'src/redux/features/basic/basicApi';
import { fetchFeeData } from 'src/utils/gas-price';
import useFindContractData from 'src/hooks/useFindContractData';
import messages from 'src/constant/messages';
import { useDispatch } from 'react-redux';
import config from 'src/config';
import { useActiveChainId } from 'src/hooks';

function ChannelCard({
  channelId,
  handleName,
  imgSrc,
  imgAlt = '',
  imgHref = '#',
  views,
  channelName,
  subscriberCount,
  channelHref = '#',
  verified,
  isSubscribed,
  outline = false,
  subscribeTo,
}) {
  const account = useAccount();
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [subscribed] = useSubscribeMutation();
  const [unsubscribed] = useUnsubscribeMutation();
  const { data: authData } = useAuthCheckQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  // const [saveBlock] = useSaveBlockTransactionMutation();
  const { abi, contract_address } = useFindContractData();

  const subscribeContract = useWriteContract();
  const unsubscribeContract = useWriteContract();

  let buttonVariant;

  if (outline) {
    buttonVariant = isSubscribed ? 'outline-secondary' : 'outline-primary';
  } else {
    buttonVariant = isSubscribed ? 'secondary' : 'primary';
  }
  const buttonText = isSubscribed ? 'Subscribed' : 'Subscribe';

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ProcessingModal
        metamask={true}
        show={processing}
        handleClose={() => setProcessing(false)}
      />
      <ConfirmModal
        show={show}
        handleClose={handleClose}
        onConfirm={async () => {
          try {
            if (!authData?.token) return toast.info(messages.require_signin);
            handleClose();
            setProcessing(true);
            const hash = await unsubscribeContract.writeContractAsync({
              address: contract_address,
              abi: JSON.parse(abi),
              functionName: 'unsubscribe',
              args: [subscribeTo],
              chainId: config.ACTIVE_CHAIN.id,
              ...(await fetchFeeData()),
            });
            // const receipt = await waitForTransactionReceipt({ hash });
            // const block_transaction_data = {
            //   action: 'unsubscribe',
            //   address_out: account.address,
            //   address_in: contract_address,
            //   hash,
            //   network_type: 5,
            //   request: JSON.stringify([subscribeTo]),
            //   // other_detail: stringifyWithBigInt(receipt),
            // };
            // saveBlock({ body: block_transaction_data });
            await unsubscribed({ channelId, body: { hash } }).unwrap();
            setProcessing(false);
          } catch (error) {
            handleError(error);
            setProcessing(false);
          }
        }}
        title={`Unsubscribe from ${channelName}?`}
        confirmButtonText='Unsubscribe'
      />
      <div className='channels-card'>
        <div className='channels-card-left-section'>
          <div className='channels-card-image'>
            <Link to={channelHref}>
              {imgSrc ? (
                <img
                  className='img-fluid'
                  loading='lazy'
                  src={imgSrc}
                  alt={imgAlt}
                />
              ) : (
                <Avatar character={channelName[0]} size={80} />
              )}
            </Link>
            <div className='channels-card-image-btn web-subscribe-btn'>
              <Button
                variant={buttonVariant}
                disabled={processing}
                onClick={async () => {
                  if (!authData?.token)
                    return toast.info('Please login first!');
                  if (activeChainId !== config.ACTIVE_CHAIN.id) {
                    await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
                  }
                  const response = await dispatch(
                    pendingTxnCheck({
                      id: channelId,
                      type: 'subscribe',
                    })
                  ).unwrap();
                  if (response.data.txn_status === COMMON_STATUS.PROCESSING) {
                    return toast.info(
                      response.message || messages.pending_transaction
                    );
                  }

                  if (isSubscribed) handleShow();
                  else {
                    try {
                      setProcessing(true);

                      const hash = await subscribeContract.writeContractAsync({
                        address: contract_address,
                        abi: JSON.parse(abi),
                        functionName: 'subscribe',
                        args: [subscribeTo],
                        chainId: config.ACTIVE_CHAIN.id,
                        ...(await fetchFeeData()),
                      });
                      // const receipt = await waitForTransactionReceipt({ hash });
                      // const block_transaction_data = {
                      //   action: 'subscribe',
                      //   address_out: account.address,
                      //   address_in: contract_address,
                      //   hash,
                      //   network_type: NETWORK_TYPE.MATIC,
                      //   request: JSON.stringify([subscribeTo]),
                      //   // other_detail: stringifyWithBigInt(receipt),
                      // };
                      // saveBlock({ body: block_transaction_data });
                      await subscribed({ channelId, body: { hash } }).unwrap();
                      setProcessing(false);
                    } catch (error) {
                      handleError(error);
                      setProcessing(false);
                    }
                  }
                }}
              >
                {processing ? 'Processing...' : buttonText}{' '}
                <strong>{views}</strong>
              </Button>
            </div>
          </div>
          <div className='channels-card-body'>
            <div className='channels-title'>
              <Link to={`${Constant.BASE_URL}channel/${handleName}`}>
                {channelName} {verified ? <VerifiedTooltip /> : ''}
              </Link>
            </div>
            <div className='fw-500'>{handleName}</div>
            <div className='channels-view'>
              {abbreviateNumber(subscriberCount, 1)} subscribers
            </div>
          </div>
        </div>
        <div className='channels-card-image-btn mob-subscribe-btn'>
          <Button
            variant={buttonVariant}
            disabled={processing}
            style={{ minWidth: '112px' }}
            onClick={async () => {
              if (!authData?.token) return toast.info('Please login first!');
              if (activeChainId !== config.ACTIVE_CHAIN.id) {
                await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
              }
              const response = await dispatch(
                pendingTxnCheck({
                  id: channelId,
                  type: 'subscribe',
                })
              ).unwrap();
              if (response.data.txn_status === COMMON_STATUS.PROCESSING) {
                return toast.info(
                  response.message || messages.pending_transaction
                );
              }
              if (isSubscribed) handleShow();
              else {
                try {
                  setProcessing(true);
                  const hash = await subscribeContract.writeContractAsync({
                    address: contract_address,
                    abi: JSON.parse(abi),
                    functionName: 'subscribe',
                    args: [subscribeTo],
                    chainId: config.ACTIVE_CHAIN.id,
                    ...(await fetchFeeData()),
                  });
                  // const receipt = await waitForTransactionReceipt({ hash });
                  // const block_transaction_data = {
                  //   action: 'subscribe',
                  //   address_out: account.address,
                  //   address_in: contract_address,
                  //   hash,
                  //   network_type: NETWORK_TYPE.MATIC,
                  //   request: JSON.stringify([subscribeTo]),
                  //   // other_detail: stringifyWithBigInt(receipt),
                  // };
                  // saveBlock({ body: block_transaction_data });
                  await subscribed({ channelId, body: { hash } }).unwrap();
                  setProcessing(false);
                } catch (error) {
                  handleError(error);
                  setProcessing(false);
                }
              }
            }}
          >
            {processing ? 'Processing...' : buttonText} <strong>{views}</strong>
          </Button>
        </div>
      </div>
    </>
  );
}

export default ChannelCard;
