import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus-square';

import { COLORS } from '../../../constant';
import './modal.css';
import {
  useAddWatchLaterMutation,
  useRemoveWatchLaterMutation,
} from '../../../redux/features/watch-later/watchLaterApi';
import { toast } from 'react-toastify';
import { useFetchVideoByIdQuery } from 'src/redux/features/videos/videoApi';

const SaveToModal = ({
  show,
  handleClose,
  handleShow,
  isWatchLater = false,
}) => {
  // const [isAddPlaylist, setIsAddPlaylist] = useState(false);
  const { video_url_id } = useParams();
  const { data: videoData } = useFetchVideoByIdQuery(video_url_id);

  const [watchLater, setWatchLater] = useState(false);
  const [addWatchLater] = useAddWatchLaterMutation();
  const [removeWatchLater] = useRemoveWatchLaterMutation();

  useEffect(() => {
    setWatchLater(isWatchLater);
  }, [isWatchLater]);

  useEffect(() => {
    return () => {
      setWatchLater(isWatchLater);
    };
  }, []);

  // useEffect(() => {
  //   const init = async () => {
  //     if (watchLater) {
  //       const data = await addWatchLater(videoId);
  //       console.log(data, 'data');
  //       toast.success('Successfully Added!');
  //     } else {
  //       const data = await removeWatchLater(videoId);
  //       console.log(data, 'data');
  //       toast.success('Successfully Removed!');
  //     }
  //   };
  //   init();
  // }, [watchLater, addWatchLater, removeWatchLater, videoId]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={true}
        keyboard={false}
        id='save-modal'
        size='sm'
      >
        <Modal.Header closeButton>
          <Modal.Title>Save to..</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              maxHeight: '150px',
              overflow: 'auto',
            }}
          >
            <div className='d-flex align-items-center mb-2 px-4 py-2 rounded-lg w-100 gray-box'>
              <Form.Check
                id='sdfsd'
                type='checkbox'
                custom
                size='lg'
                checked={watchLater}
                onChange={async (e) => {
                  setWatchLater(e.target.checked);
                  if (!watchLater) {
                    const res = await addWatchLater({
                      videoId: videoData.id,
                      videoUrlId: videoData.video_url_id,
                    }).unwrap();
                    toast.success(res.message || 'Successfully Added!');
                  } else {
                    const res = await removeWatchLater({
                      videoId: videoData.id,
                      videoUrlId: videoData.video_url_id,
                    }).unwrap();
                    toast.success(res.message || 'Successfully Removed!');
                  }
                }}
              />
              <label
                htmlFor='sdfsd'
                className='d-block fs-400 text-clr-dark-300 fw-600 ml-2 mb-0'
              >
                Watch Later
              </label>
            </div>
            {/* ))} */}
          </div>
          {/* <div
            className='d-flex align-items-center px-4 py-2 rounded-lg bg-light w-100 cursor-pointer my-4'
            onClick={() => setIsAddPlaylist(true)}
          >
            {!isAddPlaylist && (
              <UilPlus color={COLORS.dark300} size='18' className='mr-2' />
            )}
            <h6 className='d-block fs-400 text-clr-dark-300 fw-600 ml-2'>
              Create new playlist
            </h6>
          </div>
          {isAddPlaylist && (
            <>
              <Form.Group controlId='create-channel-handle-name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  size='lg'
                  type='text'
                  name='handleName'
                  placeholder='Enter Playlist Name'
                />
              </Form.Group>

              <Form.Group controlId='video-language'>
                <Form.Label>Privacy</Form.Label>
                <Form.Control
                  size='lg'
                  as='select'
                  name='language'
                  className='custom-select'
                  custom
                >
                  <option>Select</option>
                  <option>Public</option>
                  <option>Private</option>
                </Form.Control>
              </Form.Group>
            </>
          )} */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SaveToModal;
