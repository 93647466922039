import { useConfig } from 'wagmi';
// import gConfig from 'src/config';

function useActiveChainId() {
  const config = useConfig();
  const chainId = config.state.connections.get(config.state.current)?.chainId;
  //   const gConfig.ACTIVE_CHAIN.id === chainId;
  return chainId;
}

export default useActiveChainId;
