import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row } from 'react-bootstrap';

import {
  useChannelTransactionVerifyMutation,
  useDeleteChannelBrandingMutation,
  useFetchMyChannelQuery,
  useUpdateChannelInfoMutation,
} from 'src/redux/features/channels/channelApi';
import './Customization.css';
import {
  AvatarCropModal,
  BannerCropModal,
  ProcessingModal,
  WatermarkCropModal,
} from 'src/components/atoms/modals';
import Avatar from 'src/components/atoms/Avatar';
import Constant, { CLIENT_URL, COMMON_STATUS } from 'src/constant';
import {
  useAccount,
  useWriteContract,
  useSwitchChain,
  usePublicClient,
} from 'wagmi';
import { useSaveBlockTransactionMutation } from 'src/redux/features/basic/basicApi';
import handleError from 'src/utils/handle-error';
import useFindContractData from 'src/hooks/useFindContractData';
import {
  BlockchainSaveTooltip,
  TransactionStatusTooltip,
} from 'src/components/atoms/CustomCheckTooltips/CustomCheckTooltips';
import { handleNameExist } from 'src/redux/features/channels/channelSlice';
import { useDispatch } from 'react-redux';
import { yupHandleNameValidation } from 'src/pages/create-channel/CreateChannel';
import { pinFileToIPFS, pinJSONToIPFS } from 'src/utils/ipfs';
import config from 'src/config';
import messages from 'src/constant/messages';
import { useActiveChainId } from 'src/hooks';

const maxAvatarFileSize = 4 * 1048576; // 4 MB
const maxBannerFileSize = 6 * 1048576; // 6 MB
const maxWatermarkFileSize = 1 * 1048576; // 1 MB

const minBannerResolutionWidthHeight = {
  width: 800,
  height: 200,
};
const minAvatarResolutionWidthHeight = {
  width: 98,
  height: 98,
};
const minWatermarkResolutionWidthHeight = {
  width: 150,
  height: 150,
};

const SUPPORT_FILE_TYPE = ['image/jpeg', 'image/png'];

let timeoutId = null;
const validateAvatar = ({ avatar_url }) =>
  Yup.mixed()
    .test(
      'fileType',
      'Invalid file type (supported file type jpeg and png)',
      (value) => !value || (value && SUPPORT_FILE_TYPE.includes(value.type))
    )
    .test(
      'fileSize',
      'File size is too large (4MB or less)',
      (value) => !value || (value && value.size <= maxAvatarFileSize)
    )
    .test(
      'dimensions',
      'Avatar dimensions should be atleast 98x98 pixels',
      (value) => {
        if (!value) return true; // Skip validation if no file is selected

        return new Promise((resolve, reject) => {
          const image = new Image();
          const reader = new FileReader();

          reader.onload = function (event) {
            image.onload = function () {
              if (
                image.width >= minAvatarResolutionWidthHeight.width &&
                image.height >= minAvatarResolutionWidthHeight.height
              )
                resolve(true);
              else resolve(false);
            };
            image.src = event.target.result;
          };

          reader.readAsDataURL(value);
        });
      }
    )
    .test('Required', 'Avatar is Required!', (value) => {
      if (value || avatar_url)
        return true; // Skip validation if no file is selected
      else return false;
    });

const validateBanner = ({ banner_url }) =>
  Yup.mixed()
    .test('fileSize', 'File size is too large (6MB or less)', (value) => {
      return !value || (value && value.size <= maxBannerFileSize);
    })
    .test(
      'fileType',
      'Invalid file type (supported file type jpeg and png)',
      (value) => !value || (value && SUPPORT_FILE_TYPE.includes(value.type))
    )
    .test(
      'dimensions',
      `Banner dimensions should be atleast ${minBannerResolutionWidthHeight.width}x${minBannerResolutionWidthHeight.height}  pixels`,
      (value) => {
        if (!value) return true; // Skip validation if no file is selected
        console.log(this, 'this');
        return new Promise((resolve, reject) => {
          const image = new Image();
          const reader = new FileReader();

          reader.onload = function (event) {
            image.onload = function () {
              // if (image.width >= 2048 && image.height >= 1152) resolve(true);
              if (
                image.width >= minBannerResolutionWidthHeight.width &&
                image.height >= minBannerResolutionWidthHeight.height
              )
                resolve(true);
              else resolve(false);
            };
            image.src = event.target.result;
          };

          reader.readAsDataURL(value);
        });
      }
    )
    .test('Required', 'Banner is Required!', (value) => {
      if (value || banner_url)
        return true; // Skip validation if no file is selected
      else return false;
    });

const validateWatermark = ({ watermark_url }) =>
  Yup.mixed()
    .test('fileSize', 'File size is too large (1MB or less)', (value) => {
      return !value || (value && value.size <= maxWatermarkFileSize);
    })
    .test(
      'fileType',
      'Invalid file type (supported file type jpeg and png)',
      (value) => !value || (value && SUPPORT_FILE_TYPE.includes(value.type))
    )
    .test(
      'dimensions',
      `Watermark dimensions should be atleast ${minWatermarkResolutionWidthHeight.width}x${minWatermarkResolutionWidthHeight.height}  pixels`,
      (value) => {
        if (!value) return true; // Skip validation if no file is selected
        console.log(this, 'this');
        return new Promise((resolve, reject) => {
          const image = new Image();
          const reader = new FileReader();

          reader.onload = function (event) {
            image.onload = function () {
              if (
                image.width >= minWatermarkResolutionWidthHeight.width &&
                image.height >= minWatermarkResolutionWidthHeight.height
              )
                resolve(true);
              else resolve(false);
            };
            image.src = event.target.result;
          };

          reader.readAsDataURL(value);
        });
      }
    )
    .test('Required', 'Watermark is Required!', (value) => {
      if (value || watermark_url)
        return true; // Skip validation if no file is selected
      else return false;
    });

const requiredMessage = "Field can't be empty";

const validationSchema = ({ banner_url, avatar_url }) =>
  Yup.object().shape({
    name: Yup.string().max(50).required(),
    handle_name: yupHandleNameValidation,
    description: Yup.string().max(1000),
    email: Yup.string().email(),
    // avatar: validateAvatar({ avatar_url }),
    // banner: validateBanner({ banner_url }),
    // deleteAvatar: Yup.boolean(),
    // deleteBanner: Yup.boolean(),
  });

const CustomizationContent = () => {
  const activeChainId = useActiveChainId();
  const { switchChainAsync } = useSwitchChain({
    onError: (err) => {
      toast.error(messages.network_switch_error);
    },
  });
  const bannerRef = useRef(null);
  const profileRef = useRef(null);
  const watermarkRef = useRef(null);
  const dispatch = useDispatch();
  const account = useAccount();
  const [handleNameAlreadyExists, setHandleNameAlreadyExists] = useState(false);
  const [isAvatarCropModal, setIsAvatarCropModal] = useState(false);
  const [isBannerCropModal, setIsBannerCropModal] = useState(false);
  const [isWaterMarkCropModal, setIsWaterMarkCropModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: '',
    handle_name: '',
    description: '',
    email: '',
    avatar: '',
    banner: '',
    watermark: '',
    // deleteAvatar: false,
    // deleteBanner: false,
  });
  const { abi, contract_address } = useFindContractData();
  const { waitForTransactionReceipt } = usePublicClient();

  const [saveBlock] = useSaveBlockTransactionMutation();
  const { data: channel } = useFetchMyChannelQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [updateChannelInfo] = useUpdateChannelInfoMutation();
  const [channelTransactionVerify] = useChannelTransactionVerifyMutation();
  const [deleteChannelBranding, { deleteIsLoading }] =
    useDeleteChannelBrandingMutation();

  const { writeContractAsync: updateProfileContract } = useWriteContract();

  useEffect(() => {
    if (channel) {
      setInitialValues((prev) => ({
        ...prev,
        name: channel?.name,
        handle_name: channel?.handle_name,
        description: channel?.description,
        email: channel?.email,
      }));
    }
  }, [channel]);

  return (
    <div className='py-4 px-3 px-md-4' id='branding'>
      <ProcessingModal
        metamask={true}
        show={disabled}
        handleClose={() => setDisabled(false)}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          if (activeChainId !== config.ACTIVE_CHAIN.id) {
            await switchChainAsync({ chainId: config.ACTIVE_CHAIN.id });
          }
          setDisabled(true);
          const { avatar, banner, watermark, ...rest } = values;
          try {
            const formData = new FormData();
            Object.keys(rest).forEach((key) =>
              formData.append(key, values[key])
            );
            const args = [
              values.handle_name,
              channel.description_hash || 'default',
              channel.avatar_hash || 'default',
              channel.banner_hash || 'default',
            ];
            // FLOW 2
            // upload avatar to pinata which return avatar_hash // not saving data anywhere
            // let avatar_hash = 'default';
            if (avatar) {
              const { IpfsHash } = await pinFileToIPFS(avatar, (progress) => {
                console.log(progress, 'progress');
              });
              args[2] = IpfsHash;
              formData.append('avatar', avatar);
              formData.append('avatar_hash', IpfsHash);
            }

            // upload banner to pinata which return banner_hash // not saving data anywhere
            // let banner_hash = 'default';
            if (banner) {
              const { IpfsHash } = await pinFileToIPFS(banner, (progress) => {
                console.log(progress, 'progress');
              });
              args[3] = IpfsHash;
              formData.append('banner', banner);
              formData.append('banner_hash', IpfsHash);
            }

            // upload description to pinata which return description_hash // not saving data
            if (values.description !== channel.description) {
              const { IpfsHash } = await pinJSONToIPFS(
                values.description,
                (progress) => {
                  console.log(progress, 'progress');
                }
              );
              args[1] = IpfsHash;
              formData.append('description_hash', IpfsHash);
            }

            if (watermark) formData.append('watermark', watermark);

            // then call updateProfile contract function which return hash
            if (
              channel.handle_name !== values.handle_name ||
              channel.description !== values.description ||
              avatar ||
              banner
            ) {
              const hash = await updateProfileContract({
                args,
                address: contract_address,
                abi: JSON.parse(abi),
                functionName: 'updateProfile',
                chainId: config.ACTIVE_CHAIN.id,
              });
              // const block_transaction_data = {
              //   action: 'updateProfile',
              //   address_out: account.address,
              //   address_in: contract_address,
              //   hash,
              //   network_type: NETWORK_TYPE.MATIC,
              //   request: JSON.stringify(args),
              // };
              // saveBlock({ body: block_transaction_data });

              // call channelInfoUpdate function which save data in logs_v_channels
              formData.append('txn_hash', hash);
              await updateChannelInfo(formData).unwrap();

              // waitForTransactions
              await waitForTransactionReceipt({ hash });

              const response = await channelTransactionVerify().unwrap();
              toast.success(response?.message || 'Successfully Updated.');
            }
            // when no block_chain field updated
            else {
              const response = await updateChannelInfo(formData).unwrap();
              toast.success(response?.message || 'Successfully Updated.');
            }
            setDisabled(false);

            // call channelInfoUpdate again to update data from logs_v_channels to v_channels

            // // old code
            // // For now we are not string avatar, banner, description in pinata
            // const response = await updateChannelInfo(formData).unwrap();
            // console.log(response, 'response');
            // const { handle_name, description_hash, avatar_hash, banner_hash } =
            //   response.data.channel;

            // if (
            //   channel.handle_name !== handle_name ||
            //   channel.description !== description ||
            //   channel.avatar_hash !== avatar_hash ||
            //   channel.banner_hash !== banner_hash
            // ) {
            //   if (
            //     handle_name &&
            //     description_hash &&
            //     avatar_hash &&
            //     banner_hash
            //   ) {
            //     const args = [
            //       handle_name,
            //       description_hash,
            //       avatar_hash,
            //       banner_hash,
            //     ];
            //     const hash = await updateProfileContract({
            //       args,
            //     });
            //     const block_transaction_data = {
            //       action: 'updateProfile',
            //       address_out: account.address,
            //       address_in: contract_address,
            //       hash,
            //       network_type: NETWORK_TYPE.MATIC,
            //       request: JSON.stringify(args),
            //     };
            //     saveBlock({ body: block_transaction_data });
            //   }
            // }

            // setDisabled(false);
            // toast.success(response?.message || 'Successfully Updated.');
            //
            // if (values.avatar || values.banner) {
            //   const formData = new FormData();
            //   formData.append('avatar', values.avatar);
            //   formData.append('banner', values.banner);
            //   const data = await updateChannelBranding(formData).unwrap();
            //   toast.success(data?.message || 'Successfully Updated.');
            // }
            // if (values.deleteAvatar || values.deleteBanner) {
            //   const data = await deleteChannelBranding({
            //     avatar: values.deleteAvatar,
            //     banner: values.deleteBanner,
            //   }).unwrap();
            //   toast.success(data?.message || 'Successfully Updated.');
            // }
            resetForm();
          } catch (error) {
            handleError(error);
            setDisabled(false);
          }
        }}
        enableReinitialize
        validationSchema={() =>
          validationSchema({
            banner_url: channel.banner_url,
            avatar_url: channel.avatar_url,
          })
        }
      >
        {({
          handleBlur,
          handleChange,
          errors,
          touched,
          values,
          isValid,
          handleSubmit,
          setFieldValue,
          resetForm,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={10}>
                {/* - Avatar crop modal */}
                <AvatarCropModal
                  show={isAvatarCropModal}
                  file={values.avatar}
                  handleClose={() => {
                    setIsAvatarCropModal(false);
                    setFieldValue('avatar', null);
                  }}
                  handleSaveFile={(file) => {
                    setFieldValue('avatar', file);
                    setIsAvatarCropModal(false);
                  }}
                />
                <Form.Group controlId='channel-profile'>
                  <Form.Label className='mb-0'>
                    Picture
                    <BlockchainSaveTooltip className='ml-1' />
                  </Form.Label>
                  <p className='mb-4'>
                    Upload your profile picture for your fans/viewers to find
                    you instantly. It will appear on your channel and just next
                    to your uploaded videos and comments.
                  </p>
                  <div
                    className='d-flex  flex-wrap flex-md-nowrap'
                    style={{ gap: '2rem' }}
                  >
                    <div className='avatar-preview-container'>
                      <ProfilePreview
                        file={values.avatar}
                        fileUrl={channel?.avatar_url}
                      />
                      {errors.avatar && (
                        <Form.Text className='text-danger fs-200 fw-400 mt-1'>
                          {errors.avatar}
                        </Form.Text>
                      )}
                    </div>
                    <div>
                      <Form.Control
                        size='lg'
                        type='file'
                        hidden
                        ref={profileRef}
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          console.log(file, 'fileobject');
                          try {
                            await validateAvatar({
                              avatar: channel.avatar_url,
                            }).validate(file);
                            setFieldValue('avatar', file);
                            setIsAvatarCropModal(true);
                          } catch (error) {
                            handleError(error);
                          }
                        }}
                      />
                      <p>
                        It’s recommended to use a picture that’s at least{' '}
                        {minAvatarResolutionWidthHeight.width} x
                        {minAvatarResolutionWidthHeight.height} pixels and 4MB
                        or less. Use a PNG, JPG, or JPEG file. Make sure your
                        picture follows the Mirror Community Guidelines.{' '}
                      </p>
                      {values.avatar && (
                        <Button
                          className='mt-4'
                          variant='light'
                          onClick={() => {
                            if (values.avatar) setFieldValue('avatar', '');
                            // else setFieldValue('deleteAvatar', true);
                          }}
                        >
                          Remove
                        </Button>
                      )}
                      {
                        // !values.deleteAvatar &&
                        channel?.avatar_url || values.avatar ? (
                          <>
                            {/* <Button
                            className='mt-4'
                            variant='light'
                            onClick={() => {
                              if (values.avatar) setFieldValue('avatar', '');
                              else setFieldValue('deleteAvatar', true);
                            }}
                          >
                            Remove
                          </Button> */}
                            <Button
                              className='mt-4 ml-4'
                              onClick={() => {
                                // if (values.deleteAvatar)
                                //   setFieldValue('deleteAvatar', false);
                                profileRef.current.click();
                              }}
                              disabled={disabled}
                            >
                              Change
                            </Button>
                          </>
                        ) : (
                          <Button
                            className='mt-4'
                            onClick={() => {
                              // if (values.deleteAvatar)
                              //   setFieldValue('deleteAvatar', false);
                              profileRef.current.click();
                            }}
                            disabled={disabled}
                            // disabled={values.avatar}
                          >
                            Upload
                          </Button>
                        )
                      }
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={10}>
                {/* - Banner crop modal */}
                <BannerCropModal
                  show={isBannerCropModal}
                  file={values.banner}
                  handleClose={() => {
                    setIsBannerCropModal(false);
                    setFieldValue('banner', null);
                  }}
                  handleSaveFile={(file) => {
                    setFieldValue('banner', file);
                    setIsBannerCropModal(false);
                  }}
                />
                <Form.Group controlId='channel-banner'>
                  <Form.Label className='mb-0'>
                    Banner image
                    <BlockchainSaveTooltip className='ml-1' />
                  </Form.Label>
                  <p className='mb-4'>
                    Add a banner image to your Mirror profile. The banner image
                    will give your channel a credibility and your viewers will
                    find you easily.
                  </p>
                  <div
                    className='d-flex  flex-wrap flex-md-nowrap'
                    style={{ gap: '2rem' }}
                  >
                    <div className='banner-preview-container'>
                      <BannerPreview
                        file={values.banner}
                        fileUrl={values.deleteBanner ? '' : channel?.banner_url}
                      />
                      {errors.banner && (
                        <Form.Text className='text-danger fs-200 fw-400 mt-1'>
                          {errors.banner}
                        </Form.Text>
                      )}
                    </div>
                    <div>
                      <Form.Control
                        size='lg'
                        type='file'
                        hidden
                        ref={bannerRef}
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          try {
                            await validateBanner({
                              banner_url: channel.banner_url,
                            }).validate(file);
                            setFieldValue('banner', e.target.files[0]);
                            setIsBannerCropModal(true);
                          } catch (error) {
                            handleError(error);
                          }
                        }}
                      />
                      <p>
                        For the best results on all devices, use an image that’s
                        at least {minBannerResolutionWidthHeight.width} x
                        {minBannerResolutionWidthHeight.height} pixels and 6MB
                        or less.
                      </p>
                      {values.banner && (
                        <Button
                          className='mt-4'
                          variant='light'
                          onClick={() => {
                            if (values.banner) setFieldValue('banner', '');
                            // else setFieldValue('deleteBanner', true);
                          }}
                        >
                          Remove
                        </Button>
                      )}
                      {channel?.banner_url || values.banner ? (
                        <>
                          <Button
                            disabled={disabled}
                            className='mt-4 ml-4'
                            onClick={() => {
                              // if (values.deleteBanner)
                              //   setFieldValue('deleteBanner', false);
                              bannerRef.current.click();
                            }}
                          >
                            Change
                          </Button>
                        </>
                      ) : (
                        <Button
                          className='mt-4'
                          onClick={() => {
                            // if (values.deleteBanner)
                            //   setFieldValue('deleteBanner', false);
                            bannerRef.current.click();
                          }}
                          disabled={disabled}
                        >
                          Upload
                        </Button>
                      )}
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={10}>
                {/* - Watermark crop modal */}
                <WatermarkCropModal
                  show={isWaterMarkCropModal}
                  file={values.watermark}
                  handleClose={() => {
                    setIsWaterMarkCropModal(false);
                    setFieldValue('watermark', null);
                  }}
                  handleSaveFile={(file) => {
                    setFieldValue('watermark', file);
                    setIsWaterMarkCropModal(false);
                  }}
                />
                <Form.Group controlId='channel-banner'>
                  <Form.Label className='mb-0'>Video watermark</Form.Label>
                  <p className='mb-4'>
                    The watermark will appear on your videos in the right-hand
                    corner of the video player
                  </p>
                  <div
                    className='d-flex  flex-wrap flex-md-nowrap'
                    style={{ gap: '2rem' }}
                  >
                    <div className='banner-preview-container'>
                      <WatermarkPreview
                        file={values.watermark}
                        fileUrl={channel?.watermark_url}
                      />
                      {errors.watermark && (
                        <Form.Text className='text-danger fs-200 fw-400 mt-1'>
                          {errors.watermark}
                        </Form.Text>
                      )}
                    </div>
                    <div>
                      <Form.Control
                        size='lg'
                        type='file'
                        hidden
                        ref={watermarkRef}
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          try {
                            await validateWatermark({
                              watermark_url: channel.watermark_url,
                            }).validate(file);
                            setFieldValue('watermark', e.target.files[0]);
                            setIsWaterMarkCropModal(true);
                          } catch (error) {
                            handleError(error);
                          }
                        }}
                      />
                      <p>
                        An image that’s{' '}
                        {minWatermarkResolutionWidthHeight.width} x{' '}
                        {minWatermarkResolutionWidthHeight.height} pixels is
                        recommended. Use a PNG, JPG, or JPEG file that’s 1MB or
                        less.
                      </p>
                      {values.watermark && (
                        <Button
                          className='mt-4'
                          variant='light'
                          onClick={() => {
                            if (values.watermark)
                              setFieldValue('watermark', '');
                            // else setFieldValue('deleteBanner', true);
                          }}
                        >
                          Remove
                        </Button>
                      )}
                      {channel?.watermark_url || values.watermark ? (
                        <>
                          <Button
                            disabled={disabled}
                            className='mt-4 ml-4'
                            onClick={() => {
                              // if (values.deleteBanner)
                              //   setFieldValue('deleteBanner', false);
                              watermarkRef.current.click();
                            }}
                          >
                            Change
                          </Button>
                        </>
                      ) : (
                        <Button
                          className='mt-4'
                          onClick={() => {
                            // if (values.deleteBanner)
                            //   setFieldValue('deleteBanner', false);
                            watermarkRef.current.click();
                          }}
                          disabled={disabled}
                        >
                          Upload
                        </Button>
                      )}
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={10}>
                <Form.Group controlId='channel-name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    name='name'
                    placeholder='Enter channel name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                    disabled={disabled}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='handle-name'>
                  <Form.Label>
                    Handle
                    <BlockchainSaveTooltip className='ml-1' />
                  </Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    name='handle_name'
                    placeholder='Set your handle name'
                    value={values.handle_name}
                    onChange={async (e) => {
                      if (!values.handle_name)
                        e.target.value = '@' + e.target.value;
                      handleChange(e);
                      if (timeoutId) clearTimeout(timeoutId);

                      timeoutId = setTimeout(() => {
                        dispatch(handleNameExist(e.target.value))
                          .unwrap()
                          .then((result) => {
                            console.log(result, 'result');
                            const isExist = result?.data?.isHandleNameExist;
                            console.log(isExist, errors, 'channel validation');
                            setHandleNameAlreadyExists(isExist);
                          })
                          .catch((error) => {
                            console.log(error, 'error');
                          });
                      }, 2000);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.handle_name && !!errors.handle_name}
                    disabled={disabled}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.handle_name ||
                      `${CLIENT_URL}/channel/${values.handle_name}`}
                  </Form.Control.Feedback>

                  {handleNameAlreadyExists ? (
                    <Form.Text
                      muted
                      className={`overflow-hidden text-ellipse text-danger`}
                    >
                      {values.handle_name} handle name is already taken.
                    </Form.Text>
                  ) : !errors.handle_name ? (
                    <Form.Text muted className={`overflow-hidden text-ellipse`}>
                      {`${CLIENT_URL}/channel/${values.handle_name}`}
                    </Form.Text>
                  ) : null}
                </Form.Group>

                <Form.Group controlId='video-description'>
                  <Form.Label>
                    Description
                    <BlockchainSaveTooltip className='ml-1' />
                  </Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    as='textarea'
                    rows={4}
                    name='description'
                    placeholder='Tell viewers about your channel'
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.description && !!errors.description}
                    disabled={disabled}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* <Form.Group controlId='channel-url'>
                  <Form.Label>Channel URL</Form.Label>
                  <Form.Control
                    size='lg'
                    name='channel_url'
                    placeholder='Mirror.com/'
                    type='text'
                    disabled
                  />
                </Form.Group> */}

                {/* contact info */}
                <h6 className='fw-600 mb-2 mt-4'>Contact Info</h6>
                <Form.Group controlId='email-address'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    name='email'
                    placeholder='Enter Email Address (Optional)'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                    disabled={disabled}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <hr className='clr-white-card-hr' />
              </Col>
              <Col md={12} className='d-flex'>
                <Button
                  variant='light'
                  className='ml-auto px-4'
                  onClick={resetForm}
                  disabled={disabled || !dirty}
                >
                  Cancel
                </Button>
                <TransactionStatusTooltip
                  show={channel.txn_status === COMMON_STATUS.PROCESSING}
                >
                  <Button
                    variant='primary'
                    className='ml-2 px-4'
                    type='submit'
                    disabled={
                      deleteIsLoading ||
                      !isValid ||
                      !Object.values(values).some((val) => !!val) ||
                      disabled ||
                      !dirty ||
                      channel.txn_status === COMMON_STATUS.PROCESSING
                    }
                  >
                    {disabled ? 'Saving' : 'Save'}
                  </Button>
                </TransactionStatusTooltip>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const ProfilePreview = ({ file, fileUrl }) => {
  const [imageUrl, setImageUrl] = useState(fileUrl);
  const { data: channel } = useFetchMyChannelQuery(undefined, {
    skip: !localStorage.getItem('accessToken'),
  });

  const loadFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageUrl(reader.result);
    };
  };

  useEffect(() => {
    if (file) loadFile(file);
    else if (fileUrl) setImageUrl(fileUrl);
    else setImageUrl(null);
  }, [file, fileUrl]);

  return (
    <div className='preview gray-box'>
      {imageUrl ? (
        <img src={imageUrl} alt='profile' className='profile-preview-img' />
      ) : (
        <Avatar size={140} character={channel?.name[0]} />
      )}
    </div>
  );
};

const BannerPreview = ({ file, fileUrl }) => {
  const [imageUrl, setImageUrl] = useState('');
  const loadFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageUrl(reader.result);
    };
  };

  useEffect(() => {
    if (file) loadFile(file);
    else if (fileUrl) setImageUrl(fileUrl);
    else setImageUrl(null);
  }, [file, fileUrl]);

  return (
    <div className='preview gray-box'>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt='profile'
          className='banner-preview-img'
          style={{
            aspectRatio: '16/4',
          }}
        />
      ) : (
        <img
          src={`${Constant.BASE_URL}img/illustration/banner-design.png`}
          alt='profile'
          className='banner-preview-img'
        />
      )}
    </div>
  );
};

const WatermarkPreview = ({ file, fileUrl }) => {
  const [imageUrl, setImageUrl] = useState('');
  const loadFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageUrl(reader.result);
    };
  };

  useEffect(() => {
    if (file) loadFile(file);
    else if (fileUrl) setImageUrl(fileUrl);
    else setImageUrl(null);
  }, [file, fileUrl]);

  return (
    <div
      className='preview gray-box'
      style={{
        background: `url(${`${Constant.BASE_URL}img/light/watermark.png`})`,
      }}
    >
      <img
        src={imageUrl || `${Constant.BASE_URL}img/light/watermark.png`}
        alt='profile'
        className='watermark-preview-img'
      />
    </div>
  );
};

export default CustomizationContent;
