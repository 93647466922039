import React from 'react';

const Circle = ({
  color = '#FFEEF1',
  size = 40,
  className = '',
  style = {},
  children,
}) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${className}`}
      style={{
        background: color,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Circle;
